import React from "react";
import { Provider } from "react-redux";
import { BoardMinutesBuildingsList } from "./BoardMinutesBuildingsList";
import { store } from "../../store";

export default (props) => (
  <Provider store={store}>
    <BoardMinutesBuildingsList {...props} />
  </Provider>
);
