import React, { useContext, useRef, useEffect } from 'react'
import StripeCard from '../../../../../../../../javascript/components/utility/stripe_card'
import { StripeAccContext } from '../../../../contexts/StripeAccContext'
import { currencyMask } from '../../../../currency'
import Confirmation from './Confirmation'
import { ModalContext } from '../../../../../ModalContext'

const returnToken = (token) => {
  return token
}

const CardInfoForm = ({setStep, amount, setAmount, setTotalAmount, totalAmount, closeModal, setPaymentExists, showCloseButton}) => {
  const cardRef = useRef()
  const { cards, paymentMethodId, setPaymentMethodId, userPayment, amountWithCommission } = useContext(StripeAccContext)
  const { setCurrentModal } = useContext(ModalContext)

  const beforeSubmitting = () => {
    if (paymentMethodId === 'new-card') {
      const success = (token) => {
        setCurrentModal(<Confirmation onSuccessReturnToken={() => returnToken(token)} amount={amount} />)
        closeModal()
      }
      cardRef.current
        .generateToken()
        .then(success)
        .catch(e => console.log('error', e))
    } else {
      setCurrentModal(<Confirmation amount={amount} />)
      closeModal()
    }
  }

  const handleClick = () => {
    showCloseButton ? closeModal() : setStep('select_amount')
  }

  useEffect(() => {
    if (userPayment) {
      setPaymentExists(true)
      setAmount(+userPayment?.amount)
      setTotalAmount(+amountWithCommission)
    }
  }, [])

  return (
    <div>
      <h3>Authorize Fees {currencyMask(totalAmount)}</h3>
      <StripeCard
        ref={cardRef}
        cards={cards}
        state_abbreviations={true}
        onChangePaymentMethod={setPaymentMethodId}
        onlyRenderForm={true}
      />
      <div className="button-container">
        <button type='button' className='cancel' onClick={handleClick}>
          {showCloseButton ? 'Cancel' : 'Back'}
        </button>
        <button className='green' onClick={beforeSubmitting}>Authorize</button>
      </div>
    </div>
  )
}

export default CardInfoForm
