import React from 'react' 

class Countdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.finish = this.props.finish

    if (this.state.finish)
      this.tick()
  }

  componentDidMount() {
    window.BoardMinutesCountdown = this
  }

  componentWillUnmount() {
    if (window.BoardMinutesCountdown == this)
      delete window.BoardMinutesCountdown
  }

  startAt(finish) {
    if (this.state.finish)
      return

    this.setState({finish: finish})
    setTimeout(() => this.tick(), 250)
  }

  tick() {
    let time = parseInt((this.state.finish - new Date().getTime()) / 1000)
    if (time <= 0) 
      return window.location.reload()
    
    const state = {}

    state.hours = parseInt(time / 3600)
    time -= (state.hours * 3600)

    state.minutes = parseInt(time / 60)
    time -= (state.minutes * 60)

    state.seconds = time
    this.setState(state)

    setTimeout(() => this.tick(), 250)
  }

  render() {
    if (!this.state.finish)
      return <div></div>

    return <div className="countdown">
      <div>
        <div>{this.state.hours}</div><div>Hours</div>
      </div>
      <div>
        <div>{this.state.minutes}</div><div>Minutes</div>
      </div>
      <div>
        <div>{this.state.seconds}</div><div>Seconds</div>
      </div>
    </div>
  }
}

export default Countdown