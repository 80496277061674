import axios from 'axios'

export async function submitToProcessors() {
  const url = "leases/submit_to_processors"

  // axios.post(url).then(({ data: { submit_date } }) => setSubmittedAt(formatRailsTimestamp(submit_date)))
  try {
    const response = await axios.post(url)
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}
