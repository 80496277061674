import React from 'react'
import { connect } from 'react-redux'

import Store from './store'
import File from './file'

class Files extends React.Component {
  constructor(props) {
    super(props)

    this.reorderFiles = this.reorderFiles.bind(this)
    this.persistReordering = this.persistReordering.bind(this)

    this.handleRemoveSection = this.handleRemoveSection.bind(this)
  }

  componentDidMount() {
    this.el = $(this.refs.file_list)

    this.el.sortable({
      update: this.reorderFiles,
      cancel: ".not-draggable",
      containment: "#boardshare-show-page",
      connectWith: "#boardshare-show-page .share-files",
      start: (evt, ui) => { $(ui.item).addClass("is-dragging")},
      stop: (evt, ui) => { $(ui.item).removeClass("is-dragging")}
    }).disableSelection()
  }

  componentWillUnmount() {
    delete this.el
  }

  // Will fire twice if moved between sections, so wait a moment before sending the update to the server.
  //
  reorderFiles(event, ui) {
    const el = $(ui.item)
    if (!el.data("react"))
      return

    const file_id = el.data("react").file().id,
          old_section = this.props.section_id,
          new_section = el.closest(".section").data("react").section().id,
          files_in_section = el.parent().children(".share-file").toArray().map(file => $(file).data("react").file().id),
          place = files_in_section.indexOf(file_id)

    // This is a weirdness of using jQuery with React. When we update the internal, React rebuilds, but not by rebuilding
    // It does so by removing the elements in the previous iteration and adding the new element.
    const sender = ui.sender || this.el,
          old_place = this.props.section_files[old_section].indexOf(file_id),
          old_prev = old_place > 0 ? this.props.section_files[old_section][old_place - 1] : null
    if (old_place == 0)
      sender.prepend(el)
    else {
      const old_prev_el = sender.children().toArray().filter(file_el => $(file_el).data("react") && $(file_el).data("react").file().id == old_prev)[0]
      $(old_prev_el).after(el)
    }

    this.props.moveFile(file_id, place, old_section, new_section)

    clearTimeout(this.persist_reordering_timeout)
    this.persist_reordering_timeout = setTimeout(this.persistReordering, 200)
  }

  persistReordering() {
    const url = BoardShare.Constants.reorder_files_path.replace(":id", this.props.share.id),
          data = { sections_and_files: this.props.section_files }

    $.ajax({
      url: url,
      type: "post",
      data: hashToPostQueryString({share: data}),
      success: () => { BoardShare.Show.resetView() }
    })
  }

  handleRemoveSection(evt) {
    evt.preventDefault()
    this.props.removeSection()
  }

  renderHasFiles() {
    if (this.props.files.length == 0)
      return ""

    return this.props.files.map(file => <File file={file} key={file.id} />)
  }

  renderNoFiles() {
    if (this.props.files.length > 0)
      return ""

    return <div className="has-no-files not-draggable">
      <div className="no-files">There are no files in this section.</div>
      Upload files, drag some in from another section, or <a href="#" onClick={this.handleRemoveSection}><span>click here</span> to remove this section</a>.
    </div>
  }

  render() {
    return  <div className="share-files" ref="file_list">
        {this.renderHasFiles()}
        {this.renderNoFiles()}
    </div>
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    share: state.share.share,
    files: (state.share.section_files[ownProps.section_id] || []).map(file_id => state.share.files[file_id]),
    section_files: state.share.section_files
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    moveFile: (file_id, order, old_section, new_section) => dispatch(Store.moveFile(file_id, order, old_section, new_section))
    //startFileUpload: files => dispatch(Store.prepareFilesforUpload(ownProps.section.id, files))
    //reorderSectionsInState: ids => dispatch(Store.reorderSections(ids))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Files)
