import React from 'react'

class DenialModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = this.blankState()
  }

  componentDidMount() {
    window.BoardMinutesDenial = this
  }

  componentWillUnmount() {
    if (window.BoardMinutesDenial == this)
      delete window.BoardMinutesDenial
  }

  blankState() {
    return {
      document_request: null,
      denial_reason: ""
    }
  }

  clear() {
    this.setState(this.blankState())
  }

  loadRequest(random_id) {
    if (this.loading_response)
      return new Promise((res, rej) => {})

    this.loading_response = true

    return new Promise((resolve, reject) => {
      const url = BoardMinutes.Constants.Routes.get_document_request.replace("<<ID>>", random_id) + "?requester&property&processing_status&responses"

      $.ajax({ 
        url: url,
        complete: xhr => this.loading_response = false, 
        success: response => {
          this.setState({document_request: response.document_request})
          resolve(response)
        }, 
        error: xhr => reject(xhr)  
      })
    })
  }

  denyTo(row) {
    this.row = row
    this.loadRequest(this.row.random_id()).then(() => this.open())
  }

  open() {
    $(this.refs.modal).modal("show")
  }

  cancel() {
    $(this.refs.modal).modal("hide")
  }

  checkSubmitting() {
    if (this.submitting)
      return true

    this.setState({submitting: true})
    this.submitting = true
    return false
  }

  freeSubmitting() {
    this.setState({submitting: false})
    this.submitting = false
  }

  success() {
    this.row.deny()
    $(this.refs.modal).modal("hide")

    const swal_options = { 
      icon: "error",
      title: "Request Denied",
      text: `The request from ${this.requester_name()} has been denied`
    }

    swal(swal_options)

    if (typeof(this.props.onDenial) == "function")
      this.props.onDenial()
  }

  submit() {
    if (this.checkSubmitting())
      return


    const url = BoardMinutes.Constants.Routes.deny_document_request.replace("<<ID>>", this.state.document_request.random_id),
          data = {}

    if (!this.state.denial_reason.match(/^\s*$/))
      data.denial_reason = this.state.denial_reason

    $.ajax({
      url: url,
      type: "post",
      data: hashToPostQueryString({document_request: data}),
      complete: () => this.freeSubmitting(),
      success: () => this.success()
    })
  }

  requester_name() {
    if (!this.state.document_request)
      return null

    return this.state.document_request.requester.name
  }

  renderHeader() {
    const title_text = `Deny Request from ${this.requester_name()}`
    return <div className="modal-header">
      <h5 className="modal-title">{title_text}</h5>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  }

  renderForm() {
    return <div className="modal-body">
      <div className="header">
        <h2>Board Minutes Request</h2>
      </div>
      <div className="icon">
        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
      </div>
      <div>Are you sure you want to deny this request?</div>
      <div className="reason">
        <textarea placeholder="(Optional) &#10;Add an optional reason for the denial here." onChange={e => this.setState({denial_reason: e.target.value})}></textarea>
      </div>
    </div>
  }

  renderFooter() {
    return <div className="modal-footer">
      <div className="buttons">
        <button type="button" onClick={() => this.cancel()}>Cancel</button>
        <button type="button" className="deny" onClick={() => this.submit()}>Confirm Denial</button>
      </div>
    </div>
  }

  render() {
    return <div id="board-minutes-response-denial" className="modal" tabIndex="-1" role="dialog" ref="modal">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <i className="fa fa-times close-modal" onClick={() => {this.cancel()}} />
          {this.renderForm()}
          {this.renderFooter()}
        </div>
      </div>
    </div>
  }
}

export default DenialModal
