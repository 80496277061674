import React from 'react'
import JSEncrypt from 'jsencrypt'
import CryptoJS from 'crypto-js'
// PDFJs needs to be imported from global

function pixelToValue(pix) {
  return parseFloat(pix.replace(/[^\-0-9.]/, ""))
}

class Loader extends React.Component{
  constructor(props) {
    super(props)

    this.state = {}
    this.state.current_page = 1
    this.state.scale = 3
    this.state.rotation = 0
    this.state.show_loader = false

    this.pass = this.props.pass

    this.scale_levels = [0.25, 0.5, 0.75, 1, 1.5, 2, 3, 5]

    if (this.props.autload)
      this.request()

    this.captureKeyMovements = this.captureKeyMovements.bind(this)
    this.releasedTheKey = () => this.paused_on_holding_key = false
  }

  componentDidMount() {
    window.BoardMinutesEncryptionLoader = this

    $(window).on("keydown", this.captureKeyMovements)
    $(window).on("keyup", this.releasedTheKey)
  }

  componentWillUnmount() {
    delete window.BoardMinutesEncryptionLoader
    $(window).off("keydown", this.captureKeyMovements)
    $(window).off("keyup", this.releasedTheKey)
  }

  isHoldingDownTheKey(key) {
    if (this.holding_key == key && this.paused_on_holding_key)
      return true

    this.holding_key = key
    this.paused_on_holding_key = true
    setTimeout(() => this.paused_on_holding_key = false, 250)
    return false
  }

  captureKeyMovements(e) {
    if (this.isHoldingDownTheKey(e.which))
      return

    // Left Key
    if ([37].includes(e.which))
      this.loadPage(this.state.current_page - 1)

    // Right Key
    if ([39].includes(e.which))
      this.loadPage(this.state.current_page + 1)
  }

  async request() {
    this.setState({show_loader: true})

    const url = `/minutes/access/${this.pass}/info`,
          public_key = await this.publicKey()

    $.ajax({ url, type: "post", data: hashToPostQueryString({ public_key }), success: res => this.downloadAndDecryptFile(res)  })
  }

  async publicKey() {
    const crypt = await this.getRSACrypt()
    return crypt.getPublicKey()
  }

  async privateKey() {
    const key = window.localStorage.getItem("private_key-2048")
    if (key)
      return key

    return await this.generatePrivateKey()
  }

  async getRSACrypt() {
    const crypt = new JSEncrypt({default_key_size: 2048}),
          key = await this.privateKey()

    crypt.setPrivateKey(key)
    return crypt
  }

  generatePrivateKey() {
    const crypt = new JSEncrypt({default_key_size: 2048})
    return new Promise( (resolve, reject) => {
      crypt.getKey(() => {
        window.localStorage.setItem("private_key-2048", crypt.getPrivateKey())
        resolve(crypt.getPrivateKey())
      })
    })
  }

  async decryptKey(key) {
    const crypt = await this.getRSACrypt()
    return crypt.decrypt(key)
  }

  async downloadAndDecryptFile(res)  {
    const url = res.file,
          iv = res.iv,
          key = await this.decryptKey(res.key)

    // Start the timer
    window.BoardMinutesCountdown.startAt(res.finish)

    const oReq = new XMLHttpRequest()
    oReq.open("GET", url, true)
    oReq.responseType = "text"
    oReq.onload = oEvent => this.downloadCompleted(oReq.response, key, iv).then(file_data => this.loadPdfs(file_data))
    oReq.send()
  }

  downloadCompleted(text, key, iv) {
    return new Promise( (resolve, reject) => {
      setTimeout(() => {
        const cipher = CryptoJS.enc.Base64.parse(text),
              parsed_key = CryptoJS.enc.Hex.parse(key)

        const decrypted = CryptoJS.AES.decrypt( { ciphertext: cipher }, parsed_key, {
          iv: CryptoJS.enc.Hex.parse(iv),
          keySize: 256 / 32,
          padding: CryptoJS.pad.Pkcs7,
          mode: CryptoJS.mode.CBC
        })

        const pdf_data = atob(decrypted.toString(CryptoJS.enc.Base64))
        resolve(pdf_data)
      }, )
    })
  }

  loadPdfs(pdf_data) {
    window.pdfjsLib.getDocument({data: pdf_data}).promise.then(pdf => {
      this.pdf = pdf
      this.setState({total_pages: this.pdf.numPages})
      setTimeout(() => this.loadPage(this.state.current_page), 50)
    })
  }

  locked_during_loading() {
    if (this.loading_page_lock)
      return true

    this.loading_page_lock = true
    return false
  }

  unlock_loading_page() {
    this.loading_page_lock = false
  }

  scaleUp() {
    if (this.state.scale + 1 == this.scale_levels.length)
      return

    this.setState({scale: this.state.scale + 1})
    setTimeout(() => this.loadPage(this.state.current_page), 50)
  }

  scaleDown() {
    if (this.state.scale == 0)
      return

    this.setState({scale: this.state.scale - 1})
    setTimeout(() => this.loadPage(this.state.current_page), 50)
  }

  rotate() {
    this.setState({rotation: (this.state.rotation + 90) % 360})
    setTimeout(() => this.loadPage(this.state.current_page), 50)
  }

  isPdfAvailable(page_number) {
    clearTimeout(this.checking_for_pdf)
    if (this.pdf)
      return true

    this.checking_for_pdf = setTimeout(() => this.loadPage(page_number), 200)
    return false
  }

  async loadPage(page_number) {
    this.setState({show_loader: true})

    if (page_number > this.state.total_pages || page_number < 1)
      return

    if (!this.isPdfAvailable(page_number))
      return

    if (this.locked_during_loading())
      return

    const page = await this.pdf.getPage(page_number),
          viewport = page.getViewport({scale: this.scale_levels[this.state.scale]}),
          canvas = this.refs.canvas,
          container = this.refs.page_container,
          container_styles = getComputedStyle(container),
          offset_width = pixelToValue(container_styles["padding-left"]) + pixelToValue(container_styles["padding-right"]),
          offset_height = pixelToValue(container_styles["padding-top"]) + pixelToValue(container_styles["padding-bottom"]),
          on_its_side = this.state.rotation % 180 == 90

    canvas.style.transform = `rotate(${this.state.rotation}deg)`

    canvas.height = viewport.height
    canvas.width = viewport.width

    container.style.minWidth = (canvas[on_its_side ? "height" : "width"] + offset_width) + "px"
    container.style.minHeight = (canvas[on_its_side ? "width" : "height"] + offset_height) + "px"

    page.render({canvasContext: canvas.getContext('2d'), viewport}).promise.then(() => {
      this.setState({current_page: page_number, show_loader: false})
      this.unlock_loading_page()
    })
  }

  renderTopBar() {
    const next_disabled = this.state.current_page >= this.state.total_pages,
          prev_disabled = this.state.current_page <= 1

    return <div className="pdf-navbar">
      <div className="doc-name"></div>
      <div className="pages">
        <button className={prev_disabled ? "disabled" : ""} disabled={prev_disabled} onClick={() => this.loadPage(this.state.current_page - 1)}>Back</button>

        <span className="page">{this.state.current_page}</span>
        <span className="divider">/</span>
        <span className="page">{this.state.total_pages || 0}</span>

        <button className={next_disabled ? "disabled" : ""} disabled={next_disabled} onClick={() => this.loadPage(this.state.current_page + 1)}>Next</button>
      </div>
      <div></div>
    </div>
  }

  renderLoader() {
    return this.state.show_loader ? <div className="lds-dual-ring"></div> : ""
  }

  render() {
    return <div ref="container" className="encryption-loader">
      {this.renderTopBar()}
      <div className="current-page" ref="page_container">
        {this.renderLoader()}
        <canvas ref="canvas" onContextMenu={e => e.preventDefault()}></canvas>

        <div className="scale-buttons">
          <div onClick={() => this.rotate()}>
            <div className="backdrop"></div>
            <div><i className="fa fa-repeat " /></div>
          </div>
          <div onClick={() => this.scaleUp()}>
            <div className="backdrop"></div>
            <div><i className="fa fa-plus" /></div>
          </div>
          <div onClick={() => this.scaleDown()}>
            <div className="backdrop"></div>
            <div><i className="fa fa-minus" /></div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default Loader