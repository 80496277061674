import React from 'react'
import { connect } from 'react-redux'

import Store from './store'
import Files from './files'
import UploadArea from './upload_area'
import FileUploader from './upload_files'

  class SectionTitle extends React.Component {
    constructor(props) {
      super(props)
      this.state = {}

      this.state.is_editing = false
      this.state.title = props.title
      this.state.editing_title = null

      this.handleShowEditing = this.handleShowEditing.bind(this)
      this.handleHideEditing = this.handleHideEditing.bind(this)

      this.inputEditingTitle = this.inputEditingTitle.bind(this)
      this.updateEditingTitle = this.updateEditingTitle.bind(this)

      this.cancelDraggingEvents = this.cancelDraggingEvents.bind(this)
    }

    cancelDraggingEvents() {
      return { onMouseEnter: () => setTimeout(() => this.props.setCanDrag(false), 10), 
               onMouseLeave: () => setTimeout(() => this.props.setCanDrag(true), 10) }
    }

    handleShowEditing(evt) {
      evt ? evt.preventDefault() : null

      this.props.setCanDrag(true)
      this.setState({is_editing: true})

      setTimeout(() => {
        this.refs.input.focus()
        this.refs.input.value = ""
        this.refs.input.value = this.state.title
      }, 10)
    }

    handleHideEditing(evt) {
      evt ? evt.preventDefault() : null
      this.props.setCanDrag(true)
      this.setState({is_editing: false})
    }

    updateEditingTitle(evt) {
      evt.preventDefault()

      this.setState({title: this.state.editing_title})

      const data = { section_title: this.state.editing_title }

      $.ajax({
        url: this.props.url,
        type: "put",
        data: hashToPostQueryString({share: data}),
        success: () => {
          this.handleHideEditing()
          BoardShare.Show.resetView()
        }
      })
      
    }

    inputEditingTitle(evt) {
      this.setState({editing_title: evt.target.value})
    }

    renderViewTitle() {
      if (this.state.is_editing)
        return ""

      return <div className="view">
        <span>{this.state.title}</span>
        <a href="#" {...this.cancelDraggingEvents()} onClick={this.handleShowEditing}><i className="fa fa-pencil" /></a>
      </div>
    }

    renderEditTitle() {
      if (!this.state.is_editing)
        return ""

      return <div className="edit">
        <input ref="input" {...this.cancelDraggingEvents()} type="text" onInput={this.inputEditingTitle} defaultValue={this.state.title} />
        <a href="#" {...this.cancelDraggingEvents()} onClick={this.updateEditingTitle}><i className="fa fa-check" /></a>
        <a href="#" {...this.cancelDraggingEvents()} onClick={this.handleHideEditing}><i className="fa fa-times" /></a>
      </div>
    }

    render() {
      return <div className="section-title">
        {this.renderViewTitle()}
        {this.renderEditTitle()}
      </div>
    }

  }

  class Section extends React.Component {
    constructor(props) {
      super(props)

      this.state = {}
      this.state.allowDragging = true

      this.setCanDrag = this.setCanDrag.bind(this)
      this.cancelDraggingEvents = this.cancelDraggingEvents.bind(this)
      this.startFileUpload = this.startFileUpload.bind(this)

      this.removeSection = this.removeSection.bind(this)
    }

    url() {
      return BoardShare.Constants.section_id_path.replace(":id", this.props.share.id).replace(":section_id", this.props.section.id)
    }

    cancelDraggingEvents() {
      return { onMouseEnter: () => setTimeout(() => this.setCanDrag(false), 10), 
               onMouseLeave: () => setTimeout(() => this.setCanDrag(true), 10) }
    }

    setCanDrag(can_drag) {
      this.setState({allowDragging: can_drag})
    }

    section() {
      return this.props.section
    }

    componentDidMount() {
      this.el = $(this.refs.section)
      this.el.data("react", this)

      //this.el.on("dragover", this.dragFileOver)
    }

    componentWillUnmount() {
      this.el.data("react", null)
      delete this.el
    }
    
    startFileUpload(files) {
      this.props.startFileUpload(files)
      BoardShare.Show.FileUploader.Modal.open()
    }

    removeSection() {
      const swal_options = { 
        title: "Are you sure?", 
        text: `This action will permanently delete this section. Do you want to proceed?`,
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Nevermind",
        confirmButtonText: "Delete Section",
        confirmButtonColor: "#dd6b55"
      }

      const swal_err_options = {
        title: "Oops!",
        text: "There was a problem removing the section",
        type: "error"
      }

      const ajax_options = {
        url: this.url(),
        type: "delete",
        data: hashToPostQueryString({}),
        success: () => this.props.removeSection(),
        error: () => setTimeout(() => swal(swal_err_options), 500)
      }

      swal(swal_options, confirm => confirm ? $.ajax(ajax_options) : null)
    }

    render() {
      const classes = ["section"]
      if (!this.state.allowDragging)
        classes.push("not-draggable")

      return <div className={classes.join(" ")} ref="section" onMouseEnter={() => this.setCanDrag(true)}>
        <SectionTitle title={this.props.section.title} setCanDrag={this.setCanDrag} url={this.url()} />
        <div className="main">
          <Files section_id={this.props.section.id} removeSection={this.removeSection} />
          <UploadArea inputChanged={this.startFileUpload} extraFunctionality={this.cancelDraggingEvents()} />
        </div>
      </div>
    }
  }

  const mapStateToProps = (state, ownProps) => {
    return {
      share: state.share.share
    }
  }

  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      startFileUpload: files => dispatch(Store.prepareFilesforUpload(ownProps.section.id, files)),
      removeSection: () => dispatch(Store.removeSection(ownProps.section.id))
      //reorderSectionsInState: ids => dispatch(BoardShare.Show.Store.reorderSections(ids))
    }
  }

  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Section)