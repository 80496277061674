import React from 'react'
class ListItems extends React.Component {
	constructor(props) {
		super(props)

		this.state = {}
		this.state.templates = {}
		
		for (let template of this.props.templates)
			this.state.templates[template.id] = template
		
		this.state.template_order = this.props.templates.map(template => template.id)

		this.resetTemplate = this.resetTemplate.bind(this)
		this.handleClickRemove = this.handleClickRemove.bind(this)
	}

	resetTemplate(template) {
		const templates = $.extend(true, this.state.templates)

		this.removeMatchingDefaults(templates, template)

		templates[template.id] = Object.assign({}, templates[template.id], template)
		this.setState({templates: templates})
	}

	removeMatchingDefaults(templates, template) {
		for (let i in templates) {
			if (template.category != templates[i].category)
				continue

			for (let def_type of template.default_for_types) {
				let idx = templates[i].default_for_types.indexOf(def_type)
				if (idx >= 0)
					templates[i].default_for_types.splice(idx)
			}
		}
	}

	removeTemplate(template) {
		const templates = Object.assign({}, this.state.templates),
					template_order = this.state.template_order.concat([]),
					idx = template_order.indexOf(template.id)

		template_order.splice(idx, 1)
		delete templates[template.id]

		this.setState({templates: templates, template_order: template_order})
	}

	editTemplate(template) {
    if (BoardShare.Templates.EditModal.Instance)
      BoardShare.Templates.EditModal.Instance.openForTemplate(template, this.resetTemplate)
	}

	handleClickRemove(template) {
    const swal_options = { 
      title: "Are you sure?", 
      text: "Clicking the button below will remove the template from the system. Please note that no previously-generated BoardShares will be affected.",
      type: "error",
      showCancelButton: true,
      cancelButtonText: "Nevermind",
      confirmButtonText: "Remove Template",
      confirmButtonColor: "#F27474"
    }

    const ajax_options = {
    	url: BoardShare.Constants.Templates.update_path.replace(":id", template.id),
    	type: "delete",
    	data: hashToPostQueryString({}),
    	success: () => this.removeTemplate(template)
    }

    swal(swal_options, confirm => confirm ? $.ajax(ajax_options) : console.log(confirm))
	}

	renderTypes(types) {
		return types.map(type => BoardShare.Constants.BuildingTypes[type]).join(", ")
	}

	renderColumns(template) {
		if (!template)
			return ""

		const tds = [
			<td key="title">{template.title}</td>,
			<td key="category">{template.category_display}</td>,
			<td key="building_types">{template.building_types_display}</td>
		]

		if (this.props.org_admin) {
			tds.push(<td key="personal">{template.personal ? "Personal" : "Org-wide"}</td>)
			tds.push(<td key="default">{template.default_for_types.length > 0 ? [<i key="check" className="fa fa-check" />,<span key="types" className="default-types">({this.renderTypes(template.default_for_types)})</span>] : ""}</td>)
		}

		tds.push(<td key="buttons">
			<button className="btn btn-sm btn-primary" onClick={() => this.editTemplate(template)}>Edit</button>
			<button className="btn btn-sm btn-danger" onClick={() => this.handleClickRemove(template)}>Remove</button>
		</td>)

		return <tr key={template.id}>{tds}</tr>
	}

	renderHead() {
		const ths = [
			<th key="title">Template Name</th>,
			<th key="category">Share Type</th>,
			<th key="building_types">Building Types</th>
		]

		if (this.props.org_admin) {
			ths.push(<th key="personal">Personal or Org-wide</th>)
			ths.push(<th key="default">Default</th>)
		}

		ths.push(<th key="buttons"></th>)

		return <tr>
			{ths}
		</tr>
	}

	renderRows() {
		return this.state.template_order.map(template_id => this.renderColumns(this.state.templates[template_id]))
	}

	render() {
		return <table className="table templates-list">
			<thead>
			{this.renderHead()}
			</thead>
			<tbody>
			{this.renderRows()}
			</tbody>
		</table>
	}
}

export default ListItems