import React from 'react'

class AccessibilityWarning extends React.Component {
  open() {
    $(this.refs.modal).modal("show")
  }

  close() {
    $(this.refs.modal).modal("hide")
  }

  accept() {
    if (typeof(this.props.onAccept) == "function")
      this.props.onAccept()
  }

  cancel() {
    window.location = "/"
  }

  render() {
    return <div className="modal" ref="modal" id="board-minutes-accessibility-warning" tabIndex="-1" role="dialog" data-show="true" data-backdrop="static">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <h3>Accessibility</h3>
            <div>Visibility of the document is limited, starting when you access this document.</div>

            <div className="clock">
              <div><img src={BoardMinutes.Constants.Images.clock} /></div>
              <div> 
                You may access the file  <br/> {this.props.number_of_sessions} time{this.props.number_of_sessions == 1 ? "" : "s"} in {this.props.time_limit / 3600} hours.
              </div>
            </div>

            <div className="notes">
              <div>Please Remember</div>
              <ul>
                <li>No duplication, sharing or other distribution of the document</li>
                <li>All information herewith must be kept private &amp; confidential</li>
              </ul>
            </div>
          </div>
          <div className="modal-footer">
            <div className="buttons">
              <button type="button" className="cancel" onClick={() => this.cancel()}>Cancel</button>
              <button type="button" className="accept" onClick={() => this.accept()}>View Minutes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default AccessibilityWarning