import { useCallback, useMemo, useState, useEffect } from 'react';

export function useFilterableHeader({ buildings }) {
  const [filteredBuildings, setFilteredBuildings] = useState([]);

  useEffect(() => {
    setFilteredBuildings(buildings);
  }, [buildings, setFilteredBuildings]);

  const filterProperty = useCallback(
    (input) => {
      const searchedBuildings = buildings.filter((building) => {
        return building.searchTerm.includes(input?.toLowerCase());
      });

      setFilteredBuildings(searchedBuildings);
    },
    [buildings, setFilteredBuildings]
  );

  return useMemo(
    () => ({
      filterProperty: filterProperty,
      filteredBuildings: filteredBuildings,
    }),
    [filterProperty, filteredBuildings]
  );
}
