import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { submitToProcessors } from '../api'
import { dispatchEvent } from '../../../../../javascript/lib/utilities'
import { ModalContext } from '../../ModalContext'

const ReadyToSubmit = () => {
  const { setCurrentModal } = useContext(ModalContext)
  const [show, setShow] = useState(true)
  const close = () => {
    setShow(false)
    setCurrentModal(null)
  }
  const submit = () => {
    submitToProcessors().then(({ submit_date }) => {
      dispatchEvent('lease-submitted-to-processors', { submit_date: submit_date })
      close()
    })
  }

  return (
    <Modal show={show} centered={true} size='md' dialogClassName='modal-size' className="lease-submit-modals lease-ready-to-share-modal">
      <div className='modal-icon'>
        <i className='fa-thin fa-circle-check'></i>
      </div>
      <div className='content'>
        <h3>Ready to Submit!</h3>
        <p>Congratulations! <span>All requirements have been fulfilled for submission.</span></p>
        <p>Would you like to submit the Agreement + Fee Authorizations to Management on behalf of <u>all participating parties</u>?</p>
        <div className='button-container'>
          <button className='cancel' onClick={close}>Still Reviewing</button>
          <button className='green' onClick={submit}>Yes, Submit</button>
        </div>
      </div>
    </Modal>
  )
}

export default ReadyToSubmit
