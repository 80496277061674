import React, { useContext, useState } from 'react'
import { StripeAccContext } from '../../contexts/StripeAccContext'
import { currencyMask } from '../../currency'

const PaymentOption = ({ id, value, onChange, label, feeName, feeText, isSelected, hasSelection, type}) => {
  const unselected = !isSelected && hasSelection
  const isChecked = type === value

  return (
    <div className='payment-option'>
      <input
        type='radio'
        name='payment'
        id={id}
        value={value}
        onChange={onChange}
        checked={isChecked}
      />
      <label htmlFor={id} className={unselected ? "unselected" : ""}>
        {label}
      </label>
      {(isSelected || !hasSelection) && (
        <div className='small-labels'>
          <p>{feeName}</p>
          <p>{feeText}</p>
        </div>
      )}
    </div>
  )

}

const PaymentRadioSelector = () => {
  const {type, setType, ACH_FEE, CARD_RATE} = useContext(StripeAccContext)
  const [hasSelection, setHasSelection] = useState(!!sessionStorage.getItem('paymentMethodType'))

  const handlePaymentSelection = (e) => {
    setType(e.target.value)
    sessionStorage.setItem('paymentMethodType', e.target.value)
    setHasSelection(true)
  }

  return (
    <>
      <h3>Authorize</h3>
      <p className='radio-selector-header'>Select Payment Method:</p>
      <div className='payment-selectors'>
        <PaymentOption
          id='ach'
          value='ach'
          onChange={handlePaymentSelection}
          isSelected={type === "ach"}
          hasSelection={hasSelection}
          label='ACH Payment'
          feeName='App Admin Fee'
          feeText={`+ ${currencyMask(ACH_FEE)}`}
          type={type}
        />
        <PaymentOption
          id='card'
          value='card'
          onChange={handlePaymentSelection}
          isSelected={type === "card"}
          hasSelection={hasSelection}
          label='Credit Card'
          feeName='App Admin Fee'
          feeText={`+ ${CARD_RATE * 100}%`}
          type={type}
        />
      </div>
    </>
  )
}

export default PaymentRadioSelector
