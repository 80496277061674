import React, { useRef, useEffect, useState, useContext } from "react"
import OnRevoke from "./components/OnRevoke"
import Confirmation from "./components/Confirmation"
import { ModalContext } from "../../../ModalContext"
import { LeaseContext } from "../../context/LeaseContext"

const RevokeAccess = ({setRevokeTriggered}) => {
  const ref = useRef()
  const { setCurrentModal } = useContext(ModalContext)
  const { selectedToRevoke } = useContext(LeaseContext)
  const [step, setStep] = useState('on_revoke')

  const cleanup = () => $(ref.current).modal("hide")

  useEffect(() => {
    if (!ref.current) return

    const close = () => setCurrentModal(null)
    const modal = $(ref.current)
    modal.on("hidden.bs.modal", close)
    modal.modal("show")

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  return (
    <div className='lease-fees-modal lease-share-modal'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box revoke'>
          {step === 'on_revoke' && <OnRevoke setRevokeTriggered={setRevokeTriggered} selectedToRevoke={selectedToRevoke} setStep={setStep} cleanup={cleanup} />}
          {step === 'confirmation' && <Confirmation selectedToRevoke={selectedToRevoke} setStep={setStep} cleanup={cleanup} />}
        </div>
      </div>
    </div>
  )
}

export default RevokeAccess
