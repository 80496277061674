import React, { useContext, useEffect, useRef, useState } from "react"
import { ModalContext } from "../../../../ModalContext"

const AccountRemoved = () => {
  const { setCurrentModal } = useContext(ModalContext)

  const ref = useRef()

  const cleanup = () => $(ref.current).modal("hide")

  useEffect(() => {
    if (!ref.current) return

    const close = () => setCurrentModal(null)
    const modal = $(ref.current)
    modal.on("hidden.bs.modal", close)
    modal.modal({ backdrop: "static", keyboard: false })

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  return (
    <div className='lease-fees-modal'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box account-removed'>
          <div className='modal-icon'>
            <i className='shield-xmark'></i>
          </div>
          <h3>Account Removed</h3>
          <div className='body'>
            <p>
              Account verification was canceled and all
              <br />
              associated account data was permanently
              <br />
              removed from <i className="stripe-wordmark"></i>.
            </p>
          </div>
          <div className='button-container center'>
            <button className='cancel' onClick={cleanup}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountRemoved
