import React, { useState } from 'react'
import AddFee from './AddFee'
import SetByToolTip from './SetByToolTip'

const NoFees = () => {
  const [hovered, setHovered] = useState(false)

  return (
    <>
      <div style={{ marginBottom: "20px" }} className='set-by'>
        Set by Patricia Demo{" "}
        <i
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          className='fa-light fa-clock-rotate-left'
        ></i>
        {hovered && <SetByToolTip />}
      </div>
      <AddFee />
    </>
  )
}

export default NoFees
