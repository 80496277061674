import React, { createContext, useMemo, useState } from 'react'
const ModalContext = createContext()

const ModalContextProvider = props => {
  const [currentModal, setCurrentModal] = useState(null)
  const value = useMemo(() => ({
    setCurrentModal
  }), [setCurrentModal])

  return (
    <ModalContext.Provider value={value}>
      { props.children }
      { currentModal }
    </ModalContext.Provider>
  )
}

export { ModalContextProvider, ModalContext };
