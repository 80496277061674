import React, { useCallback, useReducer, useState } from 'react'
import { findIndex, omit } from 'lodash'
import { Provider as EventProvider } from '@deathbyjer/react-event-manager'

import DrawArea from 'components/utility/draw_area'

import { mergeTools } from 'components/form_creator/tools/base'
import getSignatureToolbar, { separateComponents as separateSignatureComponents} from 'components/form_creator/tools/signature'


function arrayReducer(state, action) {
  const signatures = state.signatures.concat([])
  const {type, signature } = action

  let index
  switch(type) {
    case 'add':
      for (let i in signatures)
        signatures[i] = omit(signatures[i], ['newlyAded'])

      index = findIndex(signatures, ({id}) => id == signature.id)
      index >= 0 ? null : signatures.push(signature)
      return { ...state, ...{signatures}}
    case 'update':
      index = findIndex(signatures, ({id}) => id == signature.id)

      if (index >= 0)
        signatures[index] = signature

      return { ...state, ...{signatures}}
    case 'remove':
      return { ...state, ...{signatures: signatures.filter(({id}) => id != signature.id)}}
  }

  return state
}

const initialState = {
  signatures: [
    { id: 1, positions: { signature: { x: "4%", y: "5%", width: "20%", height: "10%"}}},
    { id: 2, positions: { signature: { x: "8%", y: "25%", width: "15%", height: "8%"}}},
    { id: 3, positions: { signature: { x: "20%", y: "45%", width: "30%", height: "15%"}}}
  ]
}

export default function(props) {
  const [{signatures}, dispatcher] = useReducer(arrayReducer, initialState)

  const [selected, setSelected] = useState([])

  const api = {
    create(componentData, id) {
      if(!id)
        id = signatures.length + 1

      const signature = { id, ...componentData, newlyAdded: true }
      dispatcher({ type: "add", signature })
    },

    read(id) {
      const index = findIndex(signatures, item => item.id == id)
      return index >= 0 ? signatures[index] : null
    },

    update(id, componentData) {
      const signature = { id, ...componentData }
      dispatcher({ type: "update", signature })
    }
  }

  const tools = mergeTools([
    getSignatureToolbar({name: 'signature', api })
  ])

  const style = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start'
  }

  function addRect(rect) {
    const signature = { width: 0, height: 0, ...rect }
    api.create({positions: { signature }})
  }

  // Important example for component generation
  const generateComponent = useCallback(({type, id, props}) => {
    const key = [type, id].join("#")
    const opts = {
      allSelected: selected
    }

    return tools.generateComponent(type, { key, ...props }, opts)
  }, [ selected ])

  const signatureItems = separateSignatureComponents('signature', signatures).map(generateComponent)

  const drawAreaStyle = {
    position: 'relative',
    height: '400px',
    width: '100%'
  }

  const toolbarStyle = {
    width: '120px'
  }

  const onDrop = (componentId, rect) => {
    tools.tryDroppingItem(componentId, rect)
  }

  const drawProps = {
    onEmptyMousedown: addRect,
    onUpdatedSelected: s => setSelected(s),
    onDrop
  }

  return <EventProvider>
    <div style={style}>
      <div style={drawAreaStyle}>
        <DrawArea {...drawProps}>
          { signatureItems }
        </DrawArea>
      </div>
      <div style={toolbarStyle}>
        { tools.gatherToolbarsForSelected(selected) }
      </div>
    </div>
  </EventProvider>
}
