import { trim } from "lodash-es";

function removeItem(draftArray, itemBeRemoved) {
  const index = draftArray.findIndex((item) => item === itemBeRemoved);

  if (index !== -1) {
    draftArray.splice(index, 1);
  }
}

export const fileValidateRules = {
  meetingDate: { presence: true },
};

/**
 * @param formState: Immer writable state.form
 * @param rules
 * */
export const validateItem = (formState, rules, { id, type, key, value }) => {
  if (!rules.hasOwnProperty(key)) {
    return;
  }

  const rule = rules[key];
  const formId = `${type}-${id}`;

  formState[formId] ||= { touched: true, errors: {} };
  formState[formId].errors[key] ||= [];

  if (rule["presence"] === true) {
    if (trim(value) === "") {
      !formState[formId].errors[key].includes("presence") && formState[formId].errors[key].push("presence");
    } else {
      removeItem(formState[formId].errors[key], "presence");
    }
  }

  if (rule["max_length"]) {
    if (trim(value).length > rule["max_length"]) {
      !formState[formId].errors[key].includes("max_length") && formState[formId].errors[key].push("max_length");
    } else {
      removeItem(formState[formId].errors[key], "max_length");
    }
  }
};

export const validateAllItems = (state) => {
  const fileAttributesToValidate = Object.keys(fileValidateRules);

  Object.values(state.files).forEach((file) => {
    for (const key of fileAttributesToValidate) {
      validateItem(state.form, fileValidateRules, {
        type: "file",
        id: file.id,
        key,
        value: file[key],
      });
    }
  });
};
