import React, { useContext, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { compact, uniqBy } from 'lodash'
import { LeaseContext } from "../context/LeaseContext"
import RolesInterstitial from "../RolesInterstitial"
import Row from "./Row"
import Table from "./Table"
import { FeeContext } from "../../fees/contexts/FeeContext"

function ResidentFeesLockedLabel() {
  const { documentsFinished } = useContext(LeaseContext)
  const { feesSet } = useContext(FeeContext)
  let labelText = null

  if (!documentsFinished && !feesSet) {
    labelText = 'Lock Documents + Confirm fees to share with residents.'
  } else if (!documentsFinished) {
    labelText = 'Lock Documents to share with residents.'
  } else if (!feesSet) {
    labelText = 'Confirm fees to share with residents.'
  }

  if (!labelText) return

  return <>
    <i className='fa-light fa-circle-info' /> {labelText}
  </>
}

function GroupName({group: group_id}) {
  const { groups } = useContext(LeaseContext)
  const group = groups[group_id]
  const label = group ? group.label : "Other"
  const infoLabel = group_id == 'resident' ? <ResidentFeesLockedLabel /> : ''

  return <div className="header">
    {label}
    <span className='pull-right' style={{ color: '#b30000' }}>{infoLabel}</span>
  </div>
}

function People({ people, disabled }) {
  // todo: disable checkbox if not shareable
  const uniquePeople = useMemo(() => uniqBy(people, 'email'), [people])

  if (uniquePeople.length == 0)
    return <div className="text-center empty">There are none yet added.</div>

  return <>
    {uniquePeople.map(item => (<Row key={item.email} person={item} disabled={disabled} />))}
  </>
}

function ShareRows({ group, people, hideHeader = false }) {
  const { feesSet } = useContext(FeeContext)
  const { documentsFinished } = useContext(LeaseContext)
  const disabled = group == 'resident' && (!feesSet || !documentsFinished)

  return <>
    { hideHeader || <GroupName group={group} /> }
    <People people={people} disabled={disabled} />
  </>
}

const wrapArray = arr => Array.isArray(arr) ? arr : compact([arr])

const groupByArray = (array, attribute) => array.reduce((hash, item) => {
  for (let group of wrapArray(item[attribute]))
    (hash[group] ||= []).push(item)

  return hash
}, {})

const ShareArea = (props) => {
  const [showRoles, setShowRoles] = useState(false)
  const package_id = useSelector(state => state.package_id)
  const participants = useSelector(state => state.participants)
  const participantOrder = useSelector(state => state.participantOrder)

  const watching = [ participants, participantOrder ]
  const requiredParticipants = useMemo(() => participantOrder.map(email => participants[email]).filter(p => p.active), watching)
  const nonRequiredParticipants = useMemo(() => participantOrder.map(email => participants[email]).filter(p => !p.active), watching)

  const groupedRequired = useMemo(() => groupByArray(requiredParticipants, 'sections'), requiredParticipants)

  const savedRoles = () => window.location.reload()
  const closedRoles = () => setShowRoles(false)

  return (
    <>
      <div className='section-header'>
        <h3 className='title'>Active</h3>
        <p>Assigned a role or permission with required actions in Agreement.</p>
      </div>
      <Table>
        {Object.entries(groupedRequired).map(([group, people]) => <ShareRows key={group} group={group} people={people} />)}
      </Table>
      <div className='section-header'>
        <h3 className='title'>Additional</h3>
        <p>Participation not required for completed Agreement.</p>
      </div>
      <Table>
        <ShareRows people={nonRequiredParticipants} hideHeader={true} />
      </Table>
      <p className='update-rp'>
        <span>Missing Deal Party?</span> Update <button onClick={() => setShowRoles(true)}><span>Roles + Permissions</span>.</button>
      </p>
      { showRoles && <RolesInterstitial package_id={package_id} onSave={savedRoles} onClose={closedRoles} /> }
    </>
  )
}
export default ShareArea
