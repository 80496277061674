import React, { useRef, useEffect, useContext } from "react"
import { ModalContext } from "../../../../ModalContext"
import { StripeAccContext } from "../../../contexts/StripeAccContext"
import { currencyMask } from "../../../currency"

const CollectionErrors = ({errors, isCollection}) => {
  const { setCurrentModal } = useContext(ModalContext)
  const { allPayments } = useContext(StripeAccContext)
  const failedPayments = allPayments.filter(payment => payment.declined_at)
  const balance = failedPayments.reduce((acc, curr) => acc + (+curr.amount), 0)
  const ref = useRef()

  const cleanup = () => $(ref.current).modal("hide")


  useEffect(() => {
    if (!ref.current) return

    const modal = $(ref.current)
    const close = () => setCurrentModal(null)
    modal.on("hidden.bs.modal", close)
    modal.modal({ backdrop: "static", keyboard: false })

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  return (
    <div className='lease-fees-modal'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box collection-errors'>
          <div className='modal-icon'>
            <i className='exclamation-circle-red'></i>
          </div>
          <h3>{isCollection ? "Collection " : ""}Incomplete</h3>
          <div className='body'>
            <p>
              {isCollection ? (
                "Previously initiated payment(s) failed to be collected"
              ) : (
                <>
                  <span>Oops!</span> Some Payments could not be initiated.
                </>
              )}
            </p>
            <div className='failed-payments scrollable'>
              {failedPayments.map(payment => {
                return (
                  <div
                    key={payment.payment_method_id}
                    className='payment-info-details'
                  >
                    <div className='row-item'>
                      <div className='column-1'>Payor</div>
                      <div className='column-2'>{payment.user_name}</div>
                    </div>
                    <div className='row-item'>
                      <div className='column-1'>Payment Method</div>
                      <div className='column-2'>
                        Acct Ending in {payment.last_4}
                      </div>
                    </div>
                    <div className='row-item'>
                      <div className='column-1'>Amount</div>
                      <div className='column-2'>
                        {currencyMask(payment.amount)}
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
            <div className='balance'>
              <div className='row-item'>
                <div className='column-1'>Balance</div>
                <div className='column-2'>{currencyMask(balance)}</div>
              </div>
            </div>
            <p>
              <span className='normal'>
                The responsible parties were notified to resolve the issue.
                We'll notify you once this is resolved and all fees are
                collected.
              </span>
            </p>
          </div>
          <div className='button-container center'>
            <button className='cancel' onClick={cleanup}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollectionErrors
