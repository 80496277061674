import React from 'react'

class FileDropZone extends React.Component {
  constructor(props) {
    super(props)

    this.handleDrop = this.handleDrop.bind(this)
    this.handleDragOver = this.handleDragOver.bind(this)
    this.handleDragLeave = this.handleDragLeave.bind(this)
  }

  componentDidMount() {
    const zones = DropZone.activeZones

    if (!zones.includes(this))
      zones.push(this)
    if (window && zones.length > 0)
      window.addEventListener("dragover", FileDropZone.windowDrag)
  }

  componentDidUnmount() {
    const zones = DropZone.activeZones

    var idx = zones.indexOf(this)
    if (idx != -1)
      zones.splice(idx, 1)
    if (window && zones.length == 0)
      window.removeEventListener("dragover", FileDropZone.windowDrag)
  }

  handleDrop(event) {
    if (this.props.filesOnly && !FileDropZone.eventHasFiles(event))
      return

    const files = (event.dataTransfer) ? event.dataTransfer.files : null;
    if (typeof(this.props.onDrop) == "function")
      this.props.onDrop(files, event)
  }

  handleDragOver() {
    if (this.props.filesOnly && !FileDropZone.eventHasFiles(event))
      return

    if (typeof(this.props.onDragOver) == "function")
      this.props.onDragOver(event)
  }

  handleDragLeave() {
    if (typeof(this.props.onDragLeave) == "function")
      this.props.onDragLeave(event)
  }

  render() {
    <div className={this.props.className} onDrop={this.handleDrop} onDragOver={this.handleDragOver} onDragLeave={this.handleDragLeave}>
      {this.props.children}
    </div>
  }
}

FileDropZone.activeZones = []
FileDropZone.windowDrag = evt => evt.preventDefault()
FileDropZone.eventHasFiles = function (event) {
  if (!event.dataTransfer)
    return false

  const types = event.dataTransfer.types
  for (let i in types) 
    if (types[i] === 'Files') 
      return true  
  
  return false
}

export default FileDropZone