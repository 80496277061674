import React from 'react'

class InputGroup extends React.Component {

  errors() {
    return this.props.errors || {}
  }

  errorKeys() {
    return $.isArray(this.props.error_keys) ? this.props.error_keys : Array(this.props.error_keys)  
  }

  gatherErrors() {
    const keys = this.errorKeys(), errors = []

    for (var err of keys)
      if (this.errors()[err])
        errors.push(this.errors()[err])

    return errors
  }

  renderErrors() {
    return this.gatherErrors().map(error => <div className="error" key={error}>{error}</div>)
  }

  render() {
    const classes = (this.props.classes || []).concat(["row"]),
        errors = this.renderErrors()

    if (errors.length > 0)
      classes.push("has-error")

    return <div className={classes.join(" ")}>
      <label className={`col-sm-${this.props.labelColumns || "3"}`} htmlFor={this.props.labelFor}>{this.props.label}</label>
      <div className={`col-sm-${this.props.labelColumns ? (12 - this.props.labelColumns) : 9}`}>
        <div className="input">{this.props.children}</div>
        {errors}
      </div>
    </div>
  }
}

export default InputGroup