import React from 'react'

class UploadArea extends React.Component {
  constructor(props) {
    super(props)

    this.dropStuff = this.dropStuff.bind(this)
    this.clickUpload = this.clickUpload.bind(this)
    this.inputChanged = this.inputChanged.bind(this)
  }

  componentDidMount() {
    this.el = $(this.refs.upload_area)
    this.el.on("drop", this.dropStuff)
    this.createFileInput()
  }

  componentWillUnmount() {
    delete this.el
  }

  createFileInput() {
    if (this.file_input)
      this.file_input.remove()

    this.file_input = $("<INPUT />").attr("type", "file").attr("multiple", "multiple").on("change", this.inputChanged)
    this.el.append(this.file_input)
  }

  prepareFiles(file_list) {
    const files = []
    let file
    
    for (let i in file_list) {
      file = BoardShare.Globals.prepareUploadFile(file_list[i])

      if (file.type)
        files.push(file)
    }

    return files
  }

  dropStuff(evt) {
    const transfer = evt.originalEvent.dataTransfer,
          files = this.prepareFiles(transfer.files)

    if (files.length == 0)
      return

    if (this.props.inputChanged)
      this.props.inputChanged(files)
  }

  clickUpload() {
    this.file_input[0].click()
  }

  inputChanged() {
    const files = this.prepareFiles(this.file_input[0].files)

    if (files.length == 0)
      return

    if (this.props.inputChanged)
      this.props.inputChanged(files)

    this.createFileInput()
  }

  extraFunctionality() {
    if (this.props.extraFunctionality)
      return this.props.extraFunctionality

    return {}
  }

  render() {
    return  <div ref="upload_area" className="upload-area" onClick={this.clickUpload}  {...this.extraFunctionality()}>
      <span className="file-icons">
        <i className="fa fa-file-o" />
        <i className="fa fa-plus" />
      </span>
      <span>Drop Files Here</span>
    </div>
  }
}

export default UploadArea