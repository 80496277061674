import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchBuildingsApi } from "./apis";

const initialState = {
  buildings: [],
};

// export const fetchBuildings = createAsyncThunk("boardMinutesList/fetchBuildings", async () => {
//   const response = await fetchBuildingsApi();
//   const { buildings } = response.data;
//   return { buildings };
// });

const boardMinutesBuildingsListSlice = createSlice({
  name: "boardMinutesBuildingsList",
  initialState,
  reducers: {
    setInitialState: (state, action) => {
      state.buildings = action.payload.buildings;
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(fetchBuildings.fulfilled, (state, action) => {
    //   state.buildings = action.payload.buildings;
    // });
  },
});

export const selectBoardMinutesBuildingsListState = (state) => state.boardMinutesBuildingsList;

export const { setInitialState } = boardMinutesBuildingsListSlice.actions;

export default boardMinutesBuildingsListSlice.reducer;
