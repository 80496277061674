import React, { useContext, useEffect } from 'react'
import Layout from '../../../Layout'
import { FeeContext } from '../../contexts/FeeContext'
import FeesSetModal from '../modals/set_fees/FeesSetModal'
import { ModalContext } from "../../../ModalContext"
import NoFees from './NoFees'
import FeeTable from './FeeTable'
import { LeaseContext } from '../../contexts/LeaseContext'
import { StripeAccContext } from '../../contexts/StripeAccContext'
import CollectionSuccess from '../modals/manage_fees/CollectionSuccess'

const ManageFees = () => {
  const { hasBeenShared } = useContext(LeaseContext)
  const { fees, feesSet } = useContext(FeeContext)
  const { paymentCollectionStatus } = useContext(StripeAccContext)
  const { setCurrentModal } = useContext(ModalContext)

  useEffect(() => {
    if (feesSet && !hasBeenShared && paymentCollectionStatus === 'noneAuthorized')
      setCurrentModal(<FeesSetModal />)
  }, [feesSet, paymentCollectionStatus])

  useEffect(() => {
    if (paymentCollectionStatus === "fullyCollected") {
      setCurrentModal(<CollectionSuccess />)
    }
  }, [paymentCollectionStatus])

  return (
    <Layout
      className='manage-fees'
      left={fees.length < 1 ? <NoFees /> : <FeeTable />}
    />
  )
}

export default ManageFees
