import React, { useRef, useEffect, useContext } from "react"
import { ModalContext } from '../../../../ModalContext'

const ActionRequired = () => {
  const { setCurrentModal } = useContext(ModalContext)
  const ref = useRef()

  const cleanup = () => $(ref.current).modal("hide")

  useEffect(() => {
    if (!ref.current) return

    const close = () => setCurrentModal(null)
    const modal = $(ref.current)
    modal.on("hidden.bs.modal", close)
    modal.modal("show")

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  return (
    <div className='lease-fees-modal'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box action-required'>
          <div className='modal-icon'>
            <i className='shield-check-orange'></i>
          </div>
          <h3>Action Required</h3>
          <div className='body'>
            <p>
              This account has been added, but it needs to be verified before it
              is ready to authorize payments.
            </p>
            <p>
              <span className="bp-orange">Next Steps:</span> You will receive an email in <span className="normal">1-2 days</span> with
              instructions on how to verify your account.
            </p>
          </div>
          <div className='button-container center'>
            <button className='cancel' onClick={cleanup}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ActionRequired
