import React from 'react'

class BuildingForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.building = props.building
    this.state.addresses = props.addresses || (this.state.building ? this.state.building.addresses || [] : [])
    this.state.address = this.state.addresses.length == 1 ? this.state.addresses[0] : props.address
    this.state.unit = props.unit
    this.state.buildings = []

    if (typeof(props.onSubmit) == "function")
      this.onSubmitListener = props.onSubmit

    if (typeof(props.onBack) == "function")
      this.onBackListener = props.onBack
  }

  clearData(e) {
    e ? e.preventDefault() : null

    const state = { buildings: [], addresses: [], building: null, address: null }
    this.setState(state)
  }

  setBuildingAndAddress(building, address) {
    const state = {}
    state.building = building
    state.addresses = [ address ]
    state.address = address
    this.setState(state)
  }

  setBuildingAndAddresses(building, addresses) {
    const state = {}
    state.building = building
    state.addresses = addresses || []
    if (state.addresses.length == 1)
      state.address = state.addresses[0]

    this.setState(state)
  }

  rateLimitQueryUpdater(new_value) {
    this.setState({query: new_value.match(/^\s*$/) ? null : new_value, looking_up: true})

    clearTimeout(this.rated_limited_lookup)
    this.rated_limited_lookup = setTimeout(() => this.findPossibleBuildings(), 500)
  }

  findPossibleBuildings() {
    if (!this.state.query || this.state.query.length < 2)
      return this.setState({buildings: [], addresses: []})

    $.ajax({
      url: BoardMinutes.Constants.Routes.building_suggestions + "?query=" + this.state.query,
      success: response => this.setState({buildings: response.buildings, addresses: [], looking_up: null})
    })
  }

  canSubmit() {
    return this.state.building && this.state.address
  }

  onBack() {
    this.onBackListener()
  }

  onSubmit() {
    if (!this.canSubmit())
      return

    const out = {}
    out.building = this.state.building
    out.address = this.state.address
    out.unit = this.state.unit

    if (this.onSubmitListener)
      this.onSubmitListener(out)
  }

  renderBuildingPossibilities() {
    if (!this.state.query || this.state.query.length <=1)
      return ""

    const buildings = []
    for ( let building of this.state.buildings )
      buildings.push(<div key={building.id} onClick={e => this.setBuildingAndAddresses(building, building.addresses)}>{building.title}</div> )

    if (buildings.length == 0 && !this.state.looking_up)
      buildings.push(<div key="none-found" className="none-found">
        <div>No digital Board Minutes were found for this property address at this time.</div>
        <div>Contact <a href="mailto:info@domecile.com">info@domecile.com</a> if you have any questions or requests.</div>
      </div>)

    return <div className="building-possibilities">
      {buildings}
    </div>
  }

  renderBuildingSearch() {
    if (this.state.building)
      return

    return <div className="building-select">
      <div className="input-container">
        <i className="fa-thin fa-magnifying-glass fa-lg"></i>
        <input type="text" placeholder="find your property" onChange={e => this.rateLimitQueryUpdater(e.target.value)} />
      </div>
      {this.renderBuildingPossibilities()}
    </div>
  }

  renderSelectedBuilding() {
    const building = this.state.building
    if (!building)
      return

    const new_buildings = this.props.is_static ? "" : <div className="new-building"><a href="#" onClick={e => this.clearData(e)}>&lt;&nbsp;select new building</a></div>

    const address = this.state.addresses.length == 1 ? <div className="address">{this.state.addresses[0].address}</div> : ""

    return <div className="selected-building">
      <div className="title">{building.title}</div>
      {address}
      <div className="city">{building.city}, {building.state} {building.zip}</div>
      {new_buildings}
    </div>
  }

  selectAddress(address_id) {
    if (this.state.addresses)
      for (let address of this.state.addresses)
        if (parseInt(address_id) == parseInt(address.id))
          return this.setState({address: address})

    this.setState({address: null})
  }

  renderAlternateAddressSelect() {
    const addresses = [<option key="_blank_" value="">-- Select an Address --</option>]
    for (let address of this.state.addresses)
      addresses.push(<option key={address.id} value={address.id}>{address.address}</option>)

    if (this.state.addresses.length < 2)
      return ""

    return <div className="address-select">
      <div className="explanation">Which address are you requesting minutes for?</div>
      <select onChange={e => this.selectAddress(e.target.value)} defaultValue={this.state.address ? this.state.address.id : ""}>{addresses}</select>
    </div>
  }
//
  renderUnit() {
    if (!this.state.building)
      return ""

    return <div className="unit-request">
      <div className="input-container">
        <input type="text" placeholder="Unit # (if applicable)" defaultValue={this.state.unit} onChange={e => this.setState({unit: e.target.value})} />
      </div>
    </div>
  }

  render() {
    const button_classes = ["btn-base", "btn-green-stroke"],
          back_button = this.onBackListener ? <button className="btn-link back-link" onClick={() => this.onBack()}>back</button> : ""

    if (!this.canSubmit())
      button_classes.push("btn-disabled")

    return <div className="building">
      {this.renderSelectedBuilding()}
      {this.renderBuildingSearch()}
      {this.renderAlternateAddressSelect()}
      {this.renderUnit()}

      <div className="buttons">
        {back_button}
        <button type="button" className={button_classes.join(" ")} disabled={!this.canSubmit()} onClick={() => this.onSubmit()}>next</button>
      </div>
    </div>
  }
}

export default BuildingForm
