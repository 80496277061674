import React, { useContext } from 'react'
import { ModalContext } from '../../../ModalContext'
import CollectionSummary from '../modals/manage_fees/CollectionSummary'
import ConditionCheckList from './ConditionCheckList'
import ServerError from '../modals/manage_fees/ServerError'
import { StripeAccContext } from '../../contexts/StripeAccContext'
import { processPayments } from '../../api'

const FeeCollection = () => {
  const { setCurrentModal } = useContext(ModalContext)
  const { setAllPayments, setFailedInitiation, setCaptureInitiatedAt } = useContext(StripeAccContext)

  const collectFees = async () => {
    try {
      const data = await processPayments()
      if (!data) {
        throw new Error('data object undefined')
      }
      if (data.errors) {
        setFailedInitiation(true)
      }
      setAllPayments(data.all_payments)
      setCaptureInitiatedAt(data.capture_initiated_at)
      setCurrentModal(<CollectionSummary />)

    } catch (error) {
      console.error(error)
      setCurrentModal(<ServerError />)
    }
  }

  return (
    <div className='fee-collection'>
      <ConditionCheckList collectFees={collectFees} />
      <p className='footer-note'><em>For more insight, go to the </em><span>Activity</span> <em>section.</em></p>
    </div>
  )
}

export default FeeCollection
