import React from 'react'

class FormField extends React.Component {
  onBlur() {
    if (typeof(this.props.onBlur) == "function")
      this.props.onBlur()

  }

  onChange(value) {
    if (typeof(this.props.onChange) == "function")
      this.props.onChange(value)
  }

  render() {
    const classes = ["input-container"],
      required = this.props.required ? "required" : null

    if (this.props.error)
      classes.push("error")

    const error = this.props.error ? <div className="error-message">{this.props.error}</div> : ""

    const tooltip = this.props.tooltip ? <div className='additional-tooltip'>{this.props.tooltip}</div> : null

    return <div className="field">
      <label htmlFor={this.props.name} className={required}>{this.props.labelText}</label>
      {tooltip}
      <div className={classes.join(" ")}>
        <input text="text" defaultValue={this.props.defaultValue} onBlur={() =>this.onBlur()} onChange={e => this.onChange(e.target.value)} name={this.props.name}/>
      </div>
      {this.props.description}
      {error}
    </div>
  }
}

export default FormField
