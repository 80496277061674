import React, { useContext, useMemo } from 'react'
import { LeaseContext } from '../../../context/LeaseContext'
import { addCSRF } from 'lib/utilities'

const OnRevoke = ({ setStep, setRevokeTriggered, selectedToRevoke, cleanup }) => {
  const { sharedWith, setSharedWith } = useContext(LeaseContext)
  const person = useMemo(() => sharedWith.find(p => p == selectedToRevoke), [sharedWith, selectedToRevoke])

  const handleClick = async () => {
    const url = "leases/mock_revoke_agreement"
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(addCSRF({ person })),
    })
    const data = await response.json()
    console.log(data)
    //TODO: add error handling
    setStep("confirmation")
    setRevokeTriggered(true)
    setSharedWith(sharedWith.filter(p => p !== data.person))
  }

  return (
    <>
      <div className='modal-icon'>
        <i className='fa-light fa-exclamation'></i>
      </div>
      <div className='content'>
        <h3>Are you sure?</h3>
        <p>By revoking {person}'s access you understand:</p>
        <ul className='bullet-point'>
          <li>All of their signatures will be removed.</li>
          <li>Any authorizations made by them will be reset.</li>
        </ul>
        <div className='button-container'>
          <button className='cancel' onClick={cleanup}>Close</button>
          <button className='danger' onClick={handleClick}>Revoke Access</button>
        </div>
      </div>
    </>
  )
}

export default OnRevoke
