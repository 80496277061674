import React, { useRef, useEffect, useContext } from "react"
import { ModalContext } from "../../../../ModalContext"

const CollectionSuccess = () => {
  const { setCurrentModal } = useContext(ModalContext)
  const ref = useRef()

  const cleanup = () => $(ref.current).modal("hide")

  useEffect(() => {
    if (!ref.current) return

    const modal = $(ref.current)
    const close = () => setCurrentModal(null)
    modal.on("hidden.bs.modal", close)
    modal.modal({ backdrop: "static", keyboard: false })

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  return (
    <div className='lease-fees-modal'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box collection-success'>
          <div className='modal-icon'>
            <i className='doc-dollar'></i>
          </div>
          <h3>Fees Collected</h3>
          <div className='body'>
            <p>
              <span>Disclaimer: </span>
              Collection has been completed but funds have not been deposited in
              the associated bank accounts. Deposit times can vary based on
              payment method (Card, ACH, etc) and account payout schedule.
            </p>
          </div>
          <div className='button-container center'>
            <button className='cancel' onClick={cleanup}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollectionSuccess
