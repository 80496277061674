import React, { useState, useEffect } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { compact } from 'lodash'

import { offsetFromParent } from 'components/utility/event'
import Page from 'components/form/page'
import DrawArea from 'components/utility/draw_area'

import { getUserForm } from '../api'

import {  useTools,
          useToolPencil,
          useDrawPageDrop,
          usePageToolComponents } from './tools'

// Stores
import store, { setActiveButton, setSelectedComponents }  from './store'
import { loadIntoStore } from './store/form'

function useDraggable({id, draggable, classes}) {
  const [isDragging, setDragging] = useState(false)

  if (draggable)
    classes.push('draw-area-droppable')
  if (isDragging)
    classes.push("is-dragging")

  const onDragStart = evt => {
    const offset = offsetFromParent(evt)
    const rect = evt.target.getBoundingClientRect()

    evt.dataTransfer.setData("component-id", id.join(","))
    evt.dataTransfer.setData("starting-offset-x", offset.x)
    evt.dataTransfer.setData("starting-offset-y", offset.y)
    evt.dataTransfer.setData("component-width", rect.width)
    evt.dataTransfer.setData("component-height", rect.height)
  }
  const onDrag = _ => setDragging(true)
  const onDragEnd = _ => setDragging(false)

  return {
    draggable,
    onDragStart,
    onDrag,
    onDragEnd
  }
}

function ComponentSidebar(props) {
  return <div className="component-sidebar">
    <ComponentButton type="signature">Signature</ComponentButton>
    <ComponentButton type="highlight">Highlight</ComponentButton>
  </div>
}

function ComponentButton({type, children}) {
  const id = ['__new', type]

  const dispatch = useDispatch()
  const isActive = useSelector(state => state.general.activeButton == type)
  const classes = compact(["component-button", isActive ? "active" : null])

  const buttonProps = useDraggable({id, classes, draggable: true})
  buttonProps.onClick = evt => dispatch(setActiveButton(isActive ? null : type))

  return <div className={classes.join(" ")} {... buttonProps}>
    { isActive ? <i className="fas fa-check" /> : null }
    { children }
  </div>
}

function ToolSidebar({tools}) {
  const selected = useSelector(state => state.general.selectedComponents)
  return <div className="tool-sidebar">
    { tools.gatherToolbarsForSelected(selected) }
  </div>
}

function FormPage({id: page_id, tools}) {
  const dispatch = useDispatch()
  const page = useSelector(state => state.form.pages[page_id])
  const page_file = useSelector(state => state.form.page_files[page_id])

  const components = usePageToolComponents(tools, page_id)
  const onEmptyMousedown = useToolPencil(page_id)
  const onDrop = useDrawPageDrop(tools, page_id)

  const drawProps = {
    onEmptyMousedown,
    onUpdatedSelected: s => dispatch(setSelectedComponents(s)),
    onDrop
  }

  return <Page page={page} page_file={page_file}>
    <DrawArea { ... drawProps}>
      { components }
    </DrawArea>
  </Page>
}

function Form() {
  const page_ids = useSelector(state => Object.keys(state.form.pages))

  useEffect(() => {
    if (document.body.style.overflow != "hidden")
      document.body.style.overflow = "hidden"

    return () => document.body.style.overflow = ''
  })

  const tools = useTools()

  return <div className="loaded-form">
    <ComponentSidebar />
    <div className="form-pages">
      { page_ids.map(id => <FormPage key={id} id={id} tools={tools} />)}
    </div>
    <ToolSidebar tools={tools} />
  </div>
}

function FormLoader({package_id, form_id}) {
  const currentId = useSelector(state => state.form.id)
  const dispatch = useDispatch()

  useEffect(() => {
    getUserForm({package_id, form_id}).then(data => dispatch(loadIntoStore(data)))
  }, [package_id, form_id]);

  // Loading
  if (!form_id || form_id != currentId)
    return <div className="loading">Loading</div>

  return <Form />
}


export default function(props) {
  return <Provider store={store}>
    <div className="leases-edit-custom-form">
      <FormLoader {...props} />
    </div>
  </Provider>
}
