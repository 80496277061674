import React, { useContext, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { select as selectPerson, deselect as deselectPerson, confirmRevoke } from '../state/state'

import { ModalContext } from '../../ModalContext'
import ShareInfoToolTip from './ShareInfoToolTip'
import { unshare } from '../api'

import Confirmation from '../modals/revoke/components/Confirmation'
import Warning from '../modals/revoke/components/Warning'

// Rebuild stuff

const RevokeArea = ({person}) => {
  const dispatch = useDispatch()
  const package_id = useSelector(state => state.package_id)
  const { setCurrentModal } = useContext(ModalContext)

  const closeRevoking = useCallback(() => setCurrentModal(null), [setCurrentModal])

  const confirmRevoking = useCallback(() => {
    const onUnshare = ({users}) => {
      dispatch(confirmRevoke(users))
      setCurrentModal(
        <Confirmation person={person} onClose={closeRevoking} />
      )
    }

    unshare(package_id, person.email).then(onUnshare)
  }, [ person, package_id, dispatch, closeRevoking, setCurrentModal])

  const startRevoking = useCallback(() => setCurrentModal(
    <Warning person={person} onConfirm={confirmRevoking} onClose={closeRevoking} />
  ), [ person, setCurrentModal, confirmRevoking, closeRevoking])

  return <>
    <button type='button' onClick={startRevoking}>Revoke</button>
  </>
}

function ShareAndRevoke({person}) {
  return <>
    <p>
      <i className='fa fa-check'></i>
      Shared
    </p>
    <RevokeArea person={person} />
  </>
}

function AutomaticAccess({person}) {
  return <p>
    <i className='fa fa-check'></i>
  </p>
}

const ShareActions = ({ person, disabled }) => {
  const { email: account, sent_at, has_access} = person
  const shared = person.explicit_access == "granted"
  const revoked = person.explicit_access == "denied"
  const automatic = person.automatic_access

  const dispatch = useDispatch()
  const selected = useSelector(state => state.selected.includes(account))

  const clickCheckbox = useCallback(() => {
    dispatch(selected ? deselectPerson(account) : selectPerson(account))
  }, [ account, selected, dispatch ])

  return (
    <div className='actions'>
      { shared || revoked ? <ShareInfoToolTip person={person} /> : null }
      { automatic && <AutomaticAccess person={person} />}
      { shared && !automatic && <ShareAndRevoke person={person} /> }
      {!shared && !automatic && <input type='checkbox' name='share' id='share' checked={selected} onChange={() => { }} onClick={clickCheckbox} disabled={disabled} />}
    </div>
  )
}

export default ShareActions
