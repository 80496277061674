import React from 'react'

import BuildingForm from './building_form'
import ContactForm from './contact_form'
import PaymentForm from './payment_form'

class Request extends React.Component {
  constructor(props) {
    super(props)

    this.building = props.building

    this.state = this.blankState()
  }

  type() {
    return BoardMinutes.Constants.DocumentTypes[this.state.document_type || this.props.document_type || "minutes"]
  }

  setType(type) {
    if (BoardMinutes.Constants.DocumentTypes[type])
      this.setState({document_type: type})
  }

  blankState() {
    const state = {}
    state.active_step = "property"
    state.building = this.building || this.props.building
    state.address = null
    state.contact_info = null
    state.payment_method = null
    state.card = null
    state.billing_details = null
    state.submit_errors = null
    state.active_step = "property"
    return state
  }

  clear() {
    this.setState(this.blankState())
  }

  checkAlreadySubmitted(contact_info) {
    if (this.checking_submission)
      return

    this.checking_submission = true

    const data = {}
    data.email = contact_info.email
    data.document_type_id = this.type().id
    data.building_id = this.state.building.id

    this.setState({contact_info: contact_info})

    $.ajax({
      url: BoardMinutes.Constants.Routes.already_checked,
      type: "post",
      data: hashToPostQueryString({document_request: data}),
      complete: () => this.checking_submission = false,
      success: res => this.setState({ active_step: res.requested ? "duplicate" : "payment", duplicate_request: res.requested })
    })
  }

  lockOnSubmitting() {
    if (this.submitting)
      return true

    this.submitting = true
    this.setState({is_submitting: true})
    return false
  }

  unlockSubmitting() {
    this.setState({is_submitting: false})
    this.submitting = false
  }

  submitWithPaymentMethod() {
    if (this.lockOnSubmitting())
      return

    this.submitting = true

    const data = {}
    data.document_type_id = this.type().id

    data.building_id = this.state.building.id
    data.address_id = this.state.address.id
    data.unit = this.state.unit

    data.name = this.state.contact_info.name
    data.email = this.state.contact_info.email
    data.phone = this.state.contact_info.phone
    data.company_name = this.state.contact_info.company_name
    data.attorney_license = this.state.contact_info.attorney_license
    data.reason = this.state.contact_info.reason

    data.payment_method = this.state.payment_method

    $.ajax({
      url: BoardMinutes.Constants.Routes.document_request,
      type: "post",
      data: hashToPostQueryString({document_request: data}),
      complete: () => this.unlockSubmitting(),
      success: res => {
        if (typeof(this.props.onSubmit) == "function")
          this.props.onSubmit({building: this.state.building, address: this.state.address})

        this.clear()
      },
      error: xhr => {
        const err = JSON.parse(xhr.responseText)
        this.setState({submit_errors: err.error})
      }
    })
  }

  renderTitle() {
    return (
      <div className='title'>
        <div className="title__text">
          <i className="fa-thin fa-alarm-clock"></i>
          <h2>view {this.type()?.display_name.toLowerCase()}</h2>
        </div>
        <div className='description'>
          Request {this.type()?.display_name} viewing from property management
          in three simple steps.
        </div>
      </div>
    )
  }

  renderRepeatRequest() {
    return (
      <>
        <div className="title error repeat-request">
          <i className="fa-light fa-info-circle" aria-hidden="true"></i>
          <h2>repeat request</h2>
        </div>
        <div className='title-error-message'>
          <p>It looks like you already requested this document.</p>
          <p>Are you sure you want to continue?</p>
        </div>
      </>
    )
  }

  renderSteps() {
    const classes = [["active"], ["center"], []]

    if (this.state.active_step != "property")
      classes[1].push("active")
    if (!["property", "contact", "duplicate"].includes(this.state.active_step))
      classes[2].push("active")

    return <div className="steps">
      <div className={classes[0].join(" ")}>1. property</div>
      <div className={classes[1].join(" ")}>2. contact</div>
      <div className={classes[2].join(" ")}>3. payment</div>
    </div>
  }

  renderRowInfo(label, content) {
    return (
      <div className="row-infos" key={label}>
        {this.state.active_step === 'contact' ? null : <div className="left-item">{label}</div>}
        <div className="right-item">{content}</div>
      </div>
    )
  }
//
  renderCompleted() {
    const completed = [],
          classes = ["completed"]

    if (this.state.active_step == "property")
      classes.push("empty")

    if (this.state.active_step != "property")
      completed.push(this.renderRowInfo("property", this.renderCompletedBuilding()))

    if (!["property", "contact"].includes(this.state.active_step)) {
      completed.push(this.renderRowInfo("contact", this.renderCompletedContact()))
    }

    if (this.state.active_step == "submit") {
      completed.push(this.renderRowInfo("card info", this.renderCompletedPayment()))
    }

    return <div className={classes.join(" ")}>{completed}</div>
  }

  renderBuilding() {
    if (this.state.active_step != "property")
      return ""

    return  <BuildingForm is_static={this.props.building ? true : false} building={this.state.building} address={this.state.address} unit={this.state.unit} onSubmit={data => this.setState(Object.assign({}, data, {active_step: "contact"}))} onBack={this.props.onBackListener} />
  }

  renderCompletedBuilding() {
    return <div className="building" key="building"><div>
        <div className="building-title">{this.state.building.title}</div>
        <div className="address">{this.state.address.address}</div>
        <div className="city">{this.state.building.city}, {this.state.building.state} {this.state.building.zip}</div>
      </div></div>
  }

  renderContact() {
    switch(this.state.active_step) {
    case "property":
    case "duplicate":
      return ""
    case "contact":
      return  <ContactForm contact_info={this.state.contact_info} onSubmit={data => this.checkAlreadySubmitted(data)} onBack={() => this.setState({active_step: "property"})} />
    default:
      return ""
    }
  }

  renderCompletedContact() {
    return <div className="contact" key="contact"><div>
        <div className="name">{this.state.contact_info.name}</div>
        {this.state.contact_info.company_name ? <div className="company-name">{this.state.contact_info.company_name}</div> : ""}
        <div className="email">{this.state.contact_info.email}</div>
        <div className="phone">{this.state.contact_info.phone}</div>
      </div></div>
  }

  renderPayment() {
    switch(this.state.active_step) {
    case "property":
    case "contact":
    case "duplicate":
      return ""
    case "payment":
      return  <PaymentForm price={this.state.building.price} paid={this.state.building.subsidizes_fee}
                        onSubmit={data => this.setState(Object.assign({}, data, {active_step: "submit"}))} onBack={() => this.setState({active_step: "contact"})} />
    default:
      return ""
    }
  }

  renderCompletedPayment() {
    if (this.state.building.subsidizes_fee) {
      return <div className="payment" key="payment"><div>
        <div className="no-charge">No Charge</div>
      </div></div>
    }

    const icon_classes = ["fa"],
            icon_lookups = {
              visa: "fa-cc-visa", mastercard: 'fa-cc-mastercard',
              amex: 'fa-cc-amex', discover: 'fa-cc-discover'
            }

    icon_classes.push(icon_lookups[this.state.card.brand] || 'fa-cc-credit-card-alt')

    return <div className="payment" key="payment"><div>
      <div className="name">{this.state.billing_details.name}</div>
      <div className="card">
        <div><i className={icon_classes.join(" ")}></i> ...{this.state.card.last4}</div>
        <div>{this.state.card.exp_month}/{this.state.card.exp_year}</div>
      </div>
    </div></div>

  }

  renderSubmitErrors() {
    if (!this.state.submit_errors)
      return ""

    const errors = []
    for (let err in this.state.submit_errors)
      errors.push(<div key={err}>{this.state.submit_errors[err]}</div>)

    return <div className="errors">{errors}</div>
  }

  renderSubmit() {
    if (this.state.active_step != "submit")
      return ""

    const submit_classes = ["btn-base", "btn-go-green"],
          back_classes = ["btn-link", "back-link"]

    if (this.state.is_submitting) {
      submit_classes.push("btn-disabled")
      back_classes.push("disabled")
    }

    return <div className="submit">
      {this.renderSubmitErrors()}

      <div className="terms">
        By submitting, you agree to the <a href="/about/board_minutes_legal" target="_blank">Terms + Conditions</a>.
      </div>

      <div className="buttons">
        <button type="button" disabled={this.state.is_submitting} className={back_classes.join(" ")} onClick={() => this.setState({active_step: "payment", submit_errors: null})}>back</button>
        <button type="button" disabled={this.state.is_submitting} className={submit_classes.join(" ")} onClick={() => this.submitWithPaymentMethod()}>
          submit
        </button>
      </div>
    </div>
  }

  renderSubmitIfDuplicate() {
    if (this.state.active_step != "duplicate")
      return ""

    return <div className="submit">
      {/* <div className="description">
        It looks like you already requested this document.
      </div>
      <div className="question">
        Are you sure you want to continue?
      </div> */}
      <div className="buttons">
        <button type="button" className="btn-link back-link" onClick={() => this.setState({active_step: "contact"})}>back</button>
        <button type="button" className="btn-base btn-green-stroke" onClick={() => this.setState({active_step: "payment"})}>continue</button>
      </div>
    </div>
  }

  render() {
    return <div id="board-minutes-request">
      {this.state.active_step != "duplicate" ? this.renderTitle() : this.renderRepeatRequest()}
      {this.renderSteps()}
      {this.renderCompleted()}
      {this.renderBuilding()}
      {this.renderContact()}
      {this.renderPayment()}
      {this.renderSubmit()}
      {this.renderSubmitIfDuplicate()}
    </div>
  }
}

export default Request
