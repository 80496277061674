import React from 'react'

import ViewingAgreement from './viewing_agreement'
import TwoFactorModal from './two_factor_modal'
import AccessibilityWarning from './accessibility_warning'

class ViewModals extends React.Component {
  componentDidMount() {
    this.refs.agreement.open()
  }

  acceptedAgreement() {
    this.refs.agreement.close()

    if (this.props.requires_2fa)
      return this.refs.two_fa.open()

    if (this.props.first_time)
      return this.refs.accessibility_warning.open()

    this.loadDocument()
  }

  confirmed2fa() {
    this.refs.two_fa.close()

    if (this.props.first_time)
      return this.refs.accessibility_warning.open()

    this.loadDocument()
  }

  acceptedAccessibility() {
    this.refs.accessibility_warning.close()
    this.loadDocument()
  }

  loadDocument() {
    window.BoardMinutesEncryptionLoader.request()
  }

  render() {
    return <div>
      <ViewingAgreement ref="agreement" onAccept={() => this.acceptedAgreement()} name={this.props.name} firm={this.props.firm} license={this.props.license} />
      <TwoFactorModal ref="two_fa" onConfirm={() => this.confirmed2fa()} passphrase={this.props.passphrase} masked_phone={this.props.masked_phone} code_length={this.props.code_length} />
      <AccessibilityWarning  ref="accessibility_warning"  number_of_sessions={this.props.number_of_sessions} time_limit={this.props.time_limit} onAccept={() => this.acceptedAccessibility()} />
    </div>
  }
}

export default ViewModals