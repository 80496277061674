import SelectDocumentsImpl from './javascripts/select_documents'
import AssignFeesImpl from './javascripts/fees/App'
import FillDocumentsImpl from './javascripts/fill_documents'
import OverviewImpl from './javascripts/overview/Overview'
import ShareLeaseImpl from './javascripts/share/Share'
import ChatImpl from './javascripts/chat/Chat'
import DocumentsUnavailableImpl from './javascripts/documents_unavailable'

export const SelectDocuments = SelectDocumentsImpl
export const DocumentsUnavailable = DocumentsUnavailableImpl
export const AssignFees = AssignFeesImpl
export const FillDocuments = FillDocumentsImpl
export const Overview = OverviewImpl
export const Share = ShareLeaseImpl
export const Chat = ChatImpl
