import React from 'react'

import MultiSelect from 'components/utility/multi_select'
import InputGroup from '../utility/input_group'

import { assignIntoNamespace, removeFromNamespace, getFromNamespace } from 'lib/utilities'

const GLOBAL_INSTANCE = 'BoardShare.Templates.EditModal.Instance'

class EditModal extends React.Component {
  constructor(props) {
    super(props)

    this.last_id = 0

    this.state = {}

    this.state.errors = {}
    this.state.new_error = null
    this.state.personal = true

    this.state.template = {}
    this.state.defaults = []
    this.state.sections = []
    this.state.building_types = []
    this.state.new_section = ""

    this.handleSubmit = this.handleSubmit.bind(this)
    this.startReorder = this.startReorder.bind(this)
    this.reorderSections = this.reorderSections.bind(this)

    this.handleTitle = this.handleTitle.bind(this)
    this.handleTitleOverwrite = this.handleTitleOverwrite.bind(this)

    this.handleCategory = this.handleCategory.bind(this)
    this.handleBuildingTypes = this.handleBuildingTypes.bind(this)
    this.handleOrg = this.handleOrg.bind(this)
    this.handleNewTitleInput = this.handleNewTitleInput.bind(this)
    this.handleNewTitleClick = this.handleNewTitleClick.bind(this)

    this.resetFormElements = this.resetFormElements.bind(this)
  }

  resetFormElements() { 
    this.modal.find("input, select").val("")
    this.modal.find(".react-multi-select").each((idx, el) => $(el).data("react") ? $(el).data("react").default() : null)
  }

  componentDidMount() {
    this.modal = $(this.refs.modal)
    // We need to wait a tiny bit to make sure everything is mounted inside the modal, after shown
    this.modal.on("shown.bs.modal", () => setTimeout(this.resetFormElements, 100))

    this.sections_el = $(this.refs.sections)
    this.new_section_element = $(this.refs.new_section)

    this.sections_el.sortable({
      start: this.startReorder,
      update: this.reorderSections,
      cancel: ".not-draggable"
    }).disableSelection()

    assignIntoNamespace(GLOBAL_INSTANCE, this)
  }

  componentWillUnmount() {
    delete this.modal
    delete this.sections_el
    delete this.new_section_element

    if (getFromNamespace(GLOBAL_INSTANCE) == this)
      removeFromNamespace(GLOBAL_INSTANCE)
  }

  startReorder() {
    const sections = this.sections_el.children().not(".ui-sortable-placeholder").toArray()
    this.sections_el.data("pre-sorted", sections)
  }

  revertOrder() {
    this.sections_el.empty()
    for (let el of this.sections_el.data("pre-sorted"))
      this.sections_el.append(el)
  }

  reorderSections(event, u) {
    const sections = this.sections_el.children().toArray().map(el => $(el).attr("data-title") )

    this.revertOrder()
    this.setState({sections: sections})
  }

  open() {
    this.setState({errors: {}, new_section: "", new_error: null}, this.resetFormElements)

    this.modal.modal("show")
  }

  openBlank() {
    this.setState({template: {}, sections: [], defaults: [], title: "", overwrite_title: 1, personal: true, category: null, building_types: []})

    this.on_save = null

    this.open()
  }

  openForTemplate(template, on_save) {
    this.setState({template: template, sections: template.template, title: template.title, 
        personal: template.personal, category: template.category, building_types: template.building_types, defaults: template.default_for_types})

    this.on_save = typeof(on_save) == "function" ? on_save : null

    this.open()
  }

  isEditing() {
    return this.state.template.id ? true : false
  }

  handleTitle(evt) {
    const val = (evt.target.value || "").trim()
    this.setState({title: val})
  }

  handleTitleOverwrite(evt) {
    const val = evt.target.value || 1
    this.setState({overwrite_title: parseInt(val)})
  }

  handleCategory(cat) {
    this.setState({category: cat[0]})
  }

  handleBuildingTypes(types) {
    this.setState({building_types: this.state.building_types.concat(types)})
    this.refs.type_select.clear()
  }

  handleBuildingTypeDefault(type) {
    const defaults = this.state.defaults.concat([]),
          idx = defaults.indexOf(type)

    idx >= 0 ? defaults.splice(idx, 1) : defaults.push(type)

    this.setState({defaults: defaults})
  }

  handleRemoveBuildingType(type) {
    const types = this.state.building_types.concat([]),
          defaults = this.state.defaults.concat([]),
          idx = types.indexOf(type),
          default_idx = defaults.indexOf(type)

    if (type < 0)
      return

    types.splice(idx, 1)
    if (default_idx >= 0)
      defaults.splice(default_idx, 1)

    this.setState({building_types: types, defaults: defaults})
  }

  handleNewTitleInput(evt) {
    const val = (evt.target.value || "").trim()
    this.setState({new_section: val, new_error: null})
  }

  handleOrg(for_org) {
    this.setState({personal: for_org && for_org[0] == "1" ? false : true})
  }

  filteredBuildingTypes() {
    const output = Object.assign({}, BoardShare.Constants.BuildingTypes)

    for (let t of this.state.building_types)
      delete output[t]

    return output
  }

  sections() {
    return this.state.sections || []
  }

  sectionForId(id) {
    id = parseInt(id)
    for (let section of this.sections())
      if (id == section.id)
        return section

    return null
  }

  newTitleHasErrors() {
    const sections = this.sections(),
          section_title = this.state.new_section || ""

    if (section_title.match(/^\s*$/)) {
      this.setState({new_error: "Title is required for a new section"})
      return true
    }

    if (sections.some(section => section.toLowerCase() == section_title.toLowerCase())) {
      this.setState({new_error: "Title is already used"})
      return true
    }

    return false
  }

  handleNewTitleClick() {
    if (this.newTitleHasErrors())
      return

    const section = this.generateSection(this.state.new_section),
          sections = this.sections().concat([section])

    this.new_section_element.val("")
    this.setState({sections: sections, new_section: "", new_error: null})
  }

  removeSection(section) {
    const sections = this.sections().concat([]),
          index = sections.indexOf(section)

    if (index < 0)
      return

    sections.splice(index, 1)
    this.setState({sections: sections})
  }

  generateSection(name) {
    return name
  }

  canSubmit() {
    if (this.state.sections.length == 0)
      return false

    if (!this.state.template.id) {
      if (!this.state.title)
        return false

      if (!this.state.category)
        return false
    }

    return true
  }

  handleSubmit() {
    if (this.saving)
      return

    this.saving = true
    this.state.template.id ? this.update() : this.create()
  }

  additionalDataToUpdate(data) {
    data.building_types = this.state.building_types
    data.sections = this.state.sections

    if (!this.state.personal)
      data.defaults = this.state.defaults
  }

  create() {
    const data = {
      category: this.state.category,
      title: this.state.title,
      personal: this.state.personal ? 1 : 0
    }

    this.additionalDataToUpdate(data)
    this.buildRequest( BoardShare.Constants.Templates.create_path, "post", data, () => window.location.reload() )
  }

  update() {
    const url = BoardShare.Constants.Templates.update_path.replace(":id", this.state.template.id),
          data = {}

    this.additionalDataToUpdate(data)
    this.buildRequest(url, "put", data, this.on_save)
  }

  buildRequest(url, type, data, success) {
    $.ajax({
      url: url,
      type: type,
      data: hashToPostQueryString({template: data}),
      success: res => { 
        this.saving = false
        this.modal.modal("hide")
        if (success)
          success(res.template) 
      },
      error: (xhr) => {
        this.saving = false
        const errors = JSON.parse(xhr.responseText)
        this.setState({errors: errors.error})
      }
    })
  }

  renderHeader() {
    return <div className="modal-header">
      <h4 className="modal-title">{this.isEditing() ? "Edit an Existing" : "Create a"} Template</h4>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  }

  renderSection(section) {
    return <div className="section" key={section} data-title={section}>
      <div className="title">{section}</div>
      <i className="fa fa-times" onClick={() => this.removeSection(section)} />
    </div>
  }

  renderSections() {
    return <div className="sections" ref="sections">
      {this.state.sections.map((section) => this.renderSection(section))}
    </div>
  }

  renderNewSection() {
    return <div className="input-group new-section">
      <input type="text" className="form-control" placeholder="New Section Title" ref="new_section" onInput={this.handleNewTitleInput} />
      <div className="input-group-append input-group-btn">
        <button className="btn btn-secondary" type="button" onClick={this.handleNewTitleClick}>Add Section</button>
      </div>
    </div>
  }

  renderBuildingType(type) {
    const is_default = this.state.defaults.includes(type),
          default_classes = ["btn", "btn-sm"].concat(is_default ? ["is-default"] : [])

    return <div className="building-type" key={type}>
      <div className="title">{BoardShare.Constants.BuildingTypes[type]}</div>
      <div className="default">
        <button className={default_classes.join(" ")} onClick={() => this.handleBuildingTypeDefault(type)}>DEFAULT</button>
      </div>
      <div className="remove"><i onClick={() => this.handleRemoveBuildingType(type)} className="fa fa-times" /></div>
    </div>
  }

  renderDefaultBuildingTypeWarning() {
    if (this.state.defaults.length == 0)
      return ""

    return <div className="building-type-warning">Setting this template as the default for the Type / Building Type combination will overwrite any existing defaults</div>
  }

  renderBuildingTypes() {
    return <InputGroup label="Building Types" errors={this.state.errors} error_keys="building_types">
      <div className="show-types">
        {this.state.building_types.map(type => this.renderBuildingType(type))}
      </div>
      {this.renderDefaultBuildingTypeWarning()}
      <MultiSelect max="1" ref="type_select" onChange={this.handleBuildingTypes} options={this.filteredBuildingTypes()} placeholder=" -- Select a Building Type --" />
    </InputGroup>
  }

  renderType() {
    let type_area = this.state.template.category_display
    if (!this.isEditing()) 
      type_area = <MultiSelect max="1" options={BoardShare.Constants.Categories} defaultValue={this.state.category} placeholder="-- Select a Type --" onChange={this.handleCategory} />

    return <InputGroup label="Type" errors={this.state.errors} error_keys="category">
      {type_area}
    </InputGroup>
  }

  renderNameInput() {
    const overwrite = <div>
      <div>Overwrite if Exists?</div>
      <select onChange={this.handleTitleOverwrite}>
        <option value="1">Yes</option>
        <option value="0">No</option>
      </select>
    </div>

    return <div className="name-input">
      <input type="text" onInput={this.handleTitle} />
    </div>
  }

  renderName() {
    const name_area = this.isEditing() ? this.state.template.title : this.renderNameInput()

    return <InputGroup label="Template Name" errors={this.state.errors} error_keys="title">
      {name_area}
    </InputGroup>
  }

  renderOrganizationCheckbox() {
    if (!this.props.org_admin)
      return ""

    const options = { "1": "Yes", "0": "No" }

    let box_area = this.state.template.personal ? "No" : "Yes"
    if (!this.isEditing()) 
      box_area = <MultiSelect max="1" options={options} defaultValue={this.state.template.personal === false ? "1" : "0"} onChange={this.handleOrg} />

    return <InputGroup label="Share Across Organization" errors={this.state.errors} error_keys="personal">
      {box_area}
    </InputGroup>
  }

  renderBody() {
    const empty_sections = (this.state.sections.length == 0) ? <div className="ensure-sections">Please make sure to add sections to your template</div> : ""


    return <div className="modal-body">

      <div className="grouped-fields">
        {this.renderType()}
        {this.renderBuildingTypes()}
      </div>

      <div className="grouped-fields">
        <InputGroup label="Sections" errors={this.state.errors} error_keys={["sections", "template"]}>
          {empty_sections}
          {this.renderSections()}
          {this.renderNewSection()}
        </InputGroup>
      </div>

      <div className="identifiers">
        {this.renderName()}
        {this.renderOrganizationCheckbox()}
      </div>
    </div>
  }

  renderFooter() {
    return <div className="modal-footer">
      <button className="btn btn-bp-green" disabled={!this.canSubmit() || this.saving} onClick={this.handleSubmit}>Submit</button>
    </div>
  }

  render() {
    return <div className="modal" id="boardshare-template-edit-modal" tabIndex="-1" role="dialog" ref="modal">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    </div>
  }
}

export default EditModal