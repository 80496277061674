import React, { useContext, useEffect, useRef, useState } from 'react'
import { ModalContext } from '../../../../ModalContext'
import { StripeAccContext } from '../../../contexts/StripeAccContext'
import { getPaymentMethodByID } from '../../../helpers'
import Verification from './components/Verification'

const AttemptsExceeded = ({cleanup}) => {
  return (
    <div className='attempts-exceeded'>
      <div className='modal-icon'>
        <i className='fa-thin fa-shield-xmark'></i>
      </div>
      <h3>Verification Unsuccessful</h3>
      <div className='body'>
        <p>
          There are <span className='error'>no remaining attempts</span> left to
          <br /> verify this account.
        </p>
        <p>
          <em>
            Please use another account or card to authorize future payments or
            contact{" "}
          </em>
          <a href='mailto:info@boardpackager.com'>info@boardpackager.com</a>{" "}
          <em>
            for more assistance.
          </em>
        </p>
      </div>
      <div className='button-container center'>
        <button className='cancel' onClick={cleanup}>
          Close
        </button>
      </div>
    </div>
  )
}

const MicroTransactionVerification = () => {
  const { setCurrentModal } = useContext(ModalContext)
  const { paymentMethodId, bankAccounts } = useContext(StripeAccContext)
  const [descriptorCode, setDescriptorCode] = useState(null)
  const [attemptedCount, setAttemptedCount] = useState(0)
  const [error, setError] = useState(null)
  const ALLOWED_ATTEMPTS = 10

  const ref = useRef()

  const cleanup = () => $(ref.current).modal("hide")

  useEffect(() => {
    if (!ref.current) return

    const close = () => setCurrentModal(null)
    const modal = $(ref.current)
    modal.on("hidden.bs.modal", close)
    modal.modal({ backdrop: "static", keyboard: false })
    const { attempts } = getPaymentMethodByID(paymentMethodId, bankAccounts)
    setAttemptedCount(attempts)

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  return (
    <div className='lease-fees-modal'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box micro-deposit'>
          {error?.code ===
            "payment_method_microdeposit_verification_attempts_exceeded" ||
          attemptedCount == ALLOWED_ATTEMPTS ? (
            <AttemptsExceeded cleanup={cleanup} />
          ) : (
            <Verification
              cleanup={cleanup}
              descriptorCode={descriptorCode}
              setDescriptorCode={setDescriptorCode}
              attemptedCount={attemptedCount}
              setAttemptedCount={setAttemptedCount}
              allowedAttempts={ALLOWED_ATTEMPTS}
              error={error}
              setError={setError}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default MicroTransactionVerification
