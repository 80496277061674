import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { deleteDocumentApi } from "./apis";

const initialState = {
  deleteMinuteModalVisible: false,
  deleteMinuteSuccessModalVisible: false,
  isDocumentDeleted: false,
};

export const deleteDocument = createAsyncThunk(
  "boardMinutesList/deleteDocument",
  async (data) => {
    const response = await deleteDocumentApi(data);
    return response.data;
  }
);

const deleteMinuteSlice = createSlice({
  name: "deleteMinute",
  initialState,
  reducers: {
    openDeleteBoardMinutesModal: (state, action) => {
      state.deleteMinuteModalVisible = true;
    },
    closeDeleteBoardMinutesModal: (state, _action) => {
      state.deleteMinuteModalVisible = false;
    },
    openDeleteBoardMinutesSuccessModal: (state, _action) => {
      state.deleteMinuteSuccessModalVisible = true;
    },
    closeDeleteBoardMinutesSuccessModal: (state, _action) => {
      state.deleteMinuteSuccessModalVisible = false;
      state.isDocumentDeleted = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deleteDocument.fulfilled, (state, _action) => {
      state.isDocumentDeleted = true;
    });
  },
});

const { reducer, actions } = deleteMinuteSlice;

export const selectDeleteMinuteState = (state) => state.deleteMinute;

export const {
  openDeleteBoardMinutesModal,
  closeDeleteBoardMinutesModal,
  openDeleteBoardMinutesSuccessModal,
  closeDeleteBoardMinutesSuccessModal,
} = actions;

export default reducer;
