import React, { useRef, useEffect, useState, useContext } from "react"
import axios from 'axios'
import { ModalContext } from "../../ModalContext"

const GetStartedModal = props => {
  const ref = useRef()
  const { setCurrentModal } = useContext(ModalContext)

  const cleanup = () => {
    axios.post("leases/acknowledged_get_started")
    $(ref.current).modal("hide")
  }

  const closeModal = () => {
    $('#overview-get-started-modal').modal('hide');
    onClose();
  };

  useEffect(() => {
    if (!ref.current) return

    const modal = $(ref.current)
    const close = () => setCurrentModal(null)
    modal.on("hidden.bs.modal", close)
    modal.modal({ backdrop: "static", keyboard: false })

    return () => modal.off("hidden.bs.modal", close)
  }, [])


  return <div ref={ref} id='overview-get-started-modal' className='modal fade'>
    <div className='modal-box'>
      <div className='modal-icon-container'>
        <div className='file-check-grey'></div>
      </div>
      <div className='header'>
        <h3>application approved</h3>
        <p>Next, setup Lease Requirements:</p>
      </div>
      <div className='body'>
        <div className="row-section">
          <div className='row-icon'>
            <div className='signature-file-grey'></div>
          </div>
          <div className='row-text'>
            <div className="row-header"><p>Fill in Documents</p></div>
            <div className="row-body"><p>Select Lease Type + riders. Then, fill in any necessary information.</p></div>
          </div>
        </div>
        <div className="row-section">
          <div className='row-icon'>
            <div className='money-check-dollar-pen-grey'></div>
          </div>
          <div className='row-text'>
            <div className="row-header"><p>Confirm Fees</p></div>
            <div className="row-body"><p>Confirm amounts to be authorized upon submission.</p></div>
          </div>
        </div>
        <div className="row-section">
          <div className='row-icon'>
            <div className='fa-sharp fa-light fa-share-nodes'></div>
          </div>
          <div className='row-text'>
            <div className="row-header"><p>Share Lease</p></div>
            <div className="row-body"><p>Share with Deal Parties for signatures and payment authorizations.</p></div>
          </div>
        </div>
        <div className="button-container">
          <button className="cancel-btn" onClick={closeModal}>
            <span className="cancel-icon"></span>
            <span className="cancel-text">cancel</span>
          </button>
          <button className='get-started-btn green' onClick={cleanup}>get started</button>
        </div>
      </div>
    </div>
  </div>
}

export default GetStartedModal
