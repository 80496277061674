import React from 'react'
import Layout from '../../../Layout'
import EditTable from './EditTable'

const SetFees = () => {

  return (
    <Layout className='set-fees' left={<EditTable />}
    />
  )
}

export default SetFees
