import React from 'react'
import FeeArea from './components/FeeArea';
import FeeContextProvider from './contexts/FeeContext';
import StripeAccContextProvider from './contexts/StripeAccContext';
import { LeaseContextProvider } from './contexts/LeaseContext';
import { StatusContextProvider } from '../../../../javascript/plugins/plugins/leases_plugin/javascripts/StatusContext';
import { ModalContextProvider } from '../ModalContext';
import ComposedContextProviders from '../documents/ComposedContextProviders';

function App(props) {
  const { cards, bank_accounts, all_payments, user_payment, user_id, icons, ACH_FEE, CARD_RATE, capture_initiated_at, building_short_address, fees_submitted, ...rest } = props
  const providers = () => {
    return [
      <LeaseContextProvider
        hasBeenShared={props.hasBeenShared}
        userRole={props.user_role}
        allRequiredSignaturesSigned={props.all_required_signatures_signed}
        documentsFinished={props.documents_marked_as_reviewed}
        buildingShortAddress={building_short_address}
        permissions={props.permissions}
        userRoles={props.roles}
        notifiedReadyToShare={props.notified_ready_to_share}/>,
      <StripeAccContextProvider
        cards={cards}
        bank_accounts={bank_accounts}
        all_payments={all_payments}
        user_payment={user_payment}
        user_id={user_id}
        ACH_FEE={ACH_FEE}
        CARD_RATE={CARD_RATE}
        capture_initiated_at={capture_initiated_at}
        fees_submitted={fees_submitted} />,
      <FeeContextProvider {...rest} />,
      <ModalContextProvider />,
      <StatusContextProvider
        sidebarStatuses={props.sidebar_statuses}
        userRoles={props.roles}
        permissions={props.permissions} />
    ]
  }

  return (
    <ComposedContextProviders providers={providers()}>
      <FeeArea />
    </ComposedContextProviders>
  )
}

export default App;
