import React from 'react'

const SetByToolTip = () => {
  return (
    <div className='set-by--box'>
      <p>Last Set <span>10/19/22 10:04 am</span></p>
      <p>Set By <span>Patricia Demo</span></p>
    </div>
  )
}

export default SetByToolTip
