import React, { useState } from 'react'
import Activity from './Activity'
import Summary from './Summary'
import Tab from './Tab'
import GetStartedModal from '../modals/GetStartedModal'

const OverviewArea = (props) => {
  const { user_role, package_id, unit, address, timeline, participants, leaseData, feeTotal, authorizedFees, feesConfirmedAt, getStartedAcknowledged, activityFeedUpdates, permissions, signatureCounts, allPaymentsCaptured } = props
  const [currentTab, setCurrentTab] = useState('summary')

  return (
    <div className='lease-plugin-overview'>
      {!getStartedAcknowledged ? <GetStartedModal /> : null  }
      <Tab currentTab={currentTab} setCurrentTab={setCurrentTab} leaseData={leaseData} />

      {currentTab == "summary" && (
        <Summary
          user_role={user_role}
          package_id={package_id}
          unit={unit}
          address={address}
          timeline={timeline}
          participants={participants}
          leaseData={leaseData}
          feeTotal={feeTotal}
          authorizedFees={authorizedFees}
          feesConfirmedAt={feesConfirmedAt}
          permissions={permissions}
          signatureCounts={signatureCounts}
          allPaymentsCaptured={allPaymentsCaptured}
        />
      )}
      {currentTab == "activity" && <Activity activityFeedUpdates={activityFeedUpdates} permissions={permissions} />}
    </div>
  )
}

export default OverviewArea
