import React, { useContext } from 'react'
import { FeeContext } from '../../contexts/FeeContext'
import SetFees from '../set_fees/SetFees'

const AddFee = () => {
  const { dispatch, subMerchants, setCurrentView } = useContext(FeeContext)

  const handleClick = (e) => {
    const feeType = e.target.id
    dispatch({type: 'ADD_FEE', payload: { feeType, subMerchants }})
    setCurrentView(<SetFees />)
  }

  return (
    <div className='add-items' id='add'>
      <div className='fee-type-buttons'>
        <div id='fee' className='fee-button' onClick={handleClick}>
          <i className='fa-light fa-dollar-sign fa-dollar-sign-light'></i>
          Add a fee
        </div>
      </div>
    </div>
  )
}

export default AddFee
