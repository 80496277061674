import { createSlice, combineReducers, configureStore } from '@reduxjs/toolkit'
import { reducer as formReducer } from './form'


const initialGeneralState = {
  activeButton: null,
  selectedComponents: []
}

const generalStore = createSlice({
  name: "general",
  initialState: initialGeneralState,
  reducers: {
    setActiveButton(state, { payload: type}) {
      state.activeButton =  type
    },

    setSelectedComponents(state, { payload: selected}) {
      state.selectedComponents = selected
    }
  }
})

export const { setActiveButton, setSelectedComponents } = generalStore.actions

const combinedReducer = combineReducers({
  form: formReducer,
  general: generalStore.reducer
})

export default configureStore({
  reducer: combinedReducer
})