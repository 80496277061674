import { addCSRF } from 'lib/utilities'
import axios from 'axios'

const emptyPromise = () => new Promise((res) => res())

export function share(package_id, emails) {
  emails = Array.isArray(emails) ? emails : [ emails ]

  if (emails.length < 1)
    return emptyPromise()

  const url = `/packages/v3/${package_id}/share`
  const headers = {
    'Content-Type': 'application/json',
  }
  const body = JSON.stringify(addCSRF({emails}))

  return new Promise((res, rej) => {
    fetch(url, { method: 'post', headers, body})
      .then(response => response.json())
      .then(({emails, users, sent_at}) => res({emails, users}))
      .catch(err => rej(err))
  })
}

export function unshare(package_id, emails) {
  emails = Array.isArray(emails) ? emails : [ emails ]

  if (emails.length < 1)
    return emptyPromise()

  const url = `/packages/v3/${package_id}/unshare`
  const headers = {
    'Content-Type': 'application/json',
  }
  const body = JSON.stringify(addCSRF({emails}))

  return new Promise((res, rej) => {
    fetch(url, { method: 'post', headers, body})
      .then(response => response.json())
      .then(({emails, users, sent_at}) => res({emails, users}))
      .catch(err => rej(err))
  })
}

export function setNotifiedOfReadyToShare() {
  const url = "leases/notified_ready_to_share"
  axios.post(url)
    .then(response => console.log('Response:', response.data))
    .catch(error => console.error('Error:', error))
}
