import React, { useContext } from 'react'
import LeaseFeeList from './LeaseFeeList'
import { currencyMask } from '../../currency'
import { FeeContext } from '../../contexts/FeeContext'
import { ModalContext } from '../../../ModalContext'
import AddItems from './AddItems'
import FeesContainer from './FeesContainer'
import WarningModals from '../modals/set_fees/WarningModals'
import { addCSRF } from 'lib/utilities'
import ManageFees from '../manage_fees/ManageFees'

const postFees = async (fees) => {
  const url = "leases/set_fees"
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(addCSRF({ fees_data: fees })),
  })

  return await response.json()
}

const EditTable = () => {
  const { fees, subTotal, setCurrentView, setFeesSet, dispatch } = useContext(FeeContext)
  const { setCurrentModal } = useContext(ModalContext)

  const setFeesRequest = async () => {
    try {
      const feesData = await postFees(fees)
      dispatch({ type: 'UPDATE_FEES', updatedFees: feesData.fees})
      setCurrentView(<ManageFees />)
      setFeesSet(fees.length > 0)
    } catch (error) {
      console.error('Error setting fees', error)
    }
  }

  const resetFees = async () => {
    const response = await fetch('leases/reset_fees', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
    })
    const data = await response.json()
    dispatch({ type: 'RESET_DEFAULT_FEES', editedFees: data.fees })
  }

  return (
    <>
      <h3>Fee Breakdown</h3>
      <FeesContainer>
        <LeaseFeeList
          title='Fee Breakdown'
          fees={fees.filter(fee => fee.type === "fee")}
        />
        <div className='breakdown-amounts'>
          <div className='breakdown-row'>
            <p>Total</p>
            <p style={{fontWeight: 600}}>{currencyMask(subTotal)}</p>
          </div>
        </div>
      </FeesContainer>
      <div className='inline'>
        <AddItems />
        <button
          onClick={() =>
            setCurrentModal(<WarningModals type='reset_fees' func={resetFees} />)
          }
          className='reset button'
        >
          Reset to Default
        </button>
        {fees.length > 0 && (
          <button onClick={setFeesRequest} className='set-fee button'>
            Confirm Fees
          </button>
        )}
      </div>
      <br />
    </>
  )
}

export default EditTable
