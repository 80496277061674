import React from 'react'
import Modal from './modal'

class Sidebar extends React.Component {
  constructor(props) {
    
    super(props)
    this.state = {}
    this.state.showNotesModal = false
    this.state.packageNotesCount = null
    this.state.unreadNotesCount = null
    this.state.loaded = null
    this.state.notesIcon = null

    this.updatePackageNotesCount = this.updatePackageNotesCount.bind(this)
  }

  componentDidMount() {
    this.getPackageNotesCount()
  }

  buttonText(){
    return this.state.packageNotesCount > 0 ? 'View' : 'Add'
  }

  getPackageNotesCount() {
    $.ajax({
      type: 'GET',
      url: `/package_hook/${this.props.package_id}/package_notes/package_notes_count`,
      success: response => {
        let noteIcon = response.unread_notes_count > 0 ? 'solid' : 'duotone' 
        this.setState({packageNotesCount: response.package_notes_count, unreadNotesCount: response.unread_notes_count, loaded: true, notesIcon: noteIcon })
      },
      error: response => {
      }
    })
  }

  updatePackageNotesCount(){
    this.setState({
      notesIcon: 'duotone',
      unreadNotesCount: 0
    })
  }

  renderUnreadNotesIcon() {
    if (this.state.unreadNotesCount == 0) return null

    return <span className="fa-layers-counter fa-2x fa-layers-top-right" style={{backgroundColor: '#F68A41', marginTop: '-5px', marginRight: '-5px'}}>{this.state.unreadNotesCount}</span>
  }

  showNotesModal(e) {
    e.preventDefault()
    this.setState({showNotesModal: true})
  }
  renderNotesModal(){
    if (!this.state.showNotesModal) return 

    return (
      <Modal
        unit={this.props.unit}
        address={this.props.address}
        package_id = {this.props.package_id}
        onClose={() => this.setState({showNotesModal: null})}
        updateCount= {this.updatePackageNotesCount}
      />
    )
  }
  renderSidebar(){
    return(this.state.loaded &&
      <div onClick={(e)=> this.showNotesModal(e)} style={{cursor:'pointer'}}>
        <div className='note-container' style={{display: 'flex'}} >

          <div className='notes'>
            <h4 className=''>Notes</h4>
          </div>
              
          <div className='notes-icon fa-layers fa-fw fa-2x' style={{marginRight: 'auto'}}>
            <i className={`fa-${this.state.notesIcon} fa-note`} ></i>
            {this.renderUnreadNotesIcon()}
          </div>
          <div className='notes-button'>
            <button className="" style={{padding: '5px', backgroundColor: 'white', borderWidth: '1px', borderColor: '#898989', color: '#898989', paddingLeft: '15px', paddingRight: '15px'}}>
              {this.buttonText()}
            </button>
          </div>
        </div>
        <hr/>
      </div>
      
    )
  }
  render() {
    return <div>
      {this.renderSidebar()}
      {this.renderNotesModal()}
      </div>
    }
}

export default Sidebar