import React from 'react'
import UpdatePhoneModal from './update_phone_modal'

class Row extends React.Component {
  constructor(props) {
    super(props)

    this.state = {openText: "open"}
    this.state.document_request = props.document_request

    this.state.showEditPhoneModal = false
    this.state.show_extra = false

    this.updateLocalPhoneNumber = this.updateLocalPhoneNumber.bind(this)
  }

  random_id() {
    return this.state.document_request.random_id
  }

  building() {
    return this.state.document_request.building
  }

  isAccessed() {
    return this.state.document_request.accessed
  }

  isActive() {
    return this.state.document_request.is_active

  }

  isBoardMinuteViewEnabled() {
    return this.state.document_request.board_minute_view_enabled
  }

  filesAvailable() {
    return this.state.document_request.files_available
  }

  setRequest(document_request) {
    this.setState({ document_request: document_request })
  }

  checkListener(listener) {
    switch (typeof (listener)) {
      case "function":
        listener()
        break
      case "string":
        if (typeof (window[listener]) == "function")
          window[listener]()
        break
    }
  }

  approve() {
    if (this.props.history) {
      this.reload()
    } else {
      this.props.remove()
    }

    this.checkListener(this.props.onApprove)
  }

  deny() {
    this.props.remove()
    this.checkListener(this.props.onDeny)
  }

  reload() {
    if (this.loading_response)
      return

    this.loading_response = true

    const data = {
      property: true,
      requester: true,
      responses: true,
      response_sessions: true
    }

    $.ajax({
      url: BoardMinutes.Constants.Routes.get_document_request.replace("<<ID>>", this.state.document_request.random_id),
      data,
      success: response => {
        this.setState({ document_request: response.document_request })
        this.loading_response = false
      },
      error: xhr => {
        this.loading_response = false
      }
    })
  }

  renderDate(date_string) {
    const date = new Date(Date.parse(date_string)),
      month = BoardMinutes.Constants.Months[date.getMonth()],
      hours = date.getHours() % 12 == 0 ? 12 : date.getHours() % 12,
      minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes(),
      ampm = date.getHours() < 12 ? "am" : "pm",
      time = `${hours}:${minutes} ${ampm}`

    return <div><div>{month} {date.getDate()}, {date.getFullYear()}</div><div>{time}</div></div>
  }

  renderApprovalDates() {
    if (!this.state.document_request.responded)
      return "Awaiting Response"

    if (!this.state.document_request.latest_response)
      return "Request Denied"

    return this.state.document_request.responses.map(response => <div key={response.id}>{this.renderDate(response.responded_at)}</div>)
  }

  renderAccessedDate() {
    if (!this.state.document_request.responded || !this.state.document_request.latest_response)
      return ""

    return this.state.document_request.responses.map(response => <div key={response.id}>{response.accessed_at ? this.renderDate(response.accessed_at) : "Not Yet Accessed"}</div>)
  }

  resendLink() {
    const url = BoardMinutes.Constants.Routes.resend_link.replace("<<ID>>", this.state.document_request.random_id),
      type = "post",
      data = hashToPostQueryString({})

    const success = () => {
      swal({
        icon: 'success',
        title: 'Success',
        text: `A new access link has been emailed to ${this.state.document_request.requester.name}`
      })
    }

    swal({
      title: 'Resend Access',
      text: "Are you sure you want to resend the access link?",
      showCancelButton: true,
      confirmButtonColor: '#50C17A',
      cancelButtonColor: '#999',
      confirmButtonText: 'Resend Link'
    }, confirm => confirm ? $.ajax({ url, type, data, success }) : null)
  }

  historyButtons() {
    if (!this.state.document_request.latest_response)
      return []

    if (this.isActive())
      return [<button key="resend" className="dome-btn dome-btn-base dome-btn-green-stroke" onClick={() => this.resendLink()}>open</button>]

    let options = { reconfirm: true, files_available: this.filesAvailable() }
    if (this.isBoardMinuteViewEnabled()) {
      options = { ...options, loadDocuments: () => this.loadDocuments() }
    }
    const click = () => window.BoardMinutesResponse.open(this, options)
    return [<button key="approve" className="dome-btn dome-btn-base dome-btn-green-stroke" onClick={click}>open</button>]
  }

  renderProperty() {
    const items = []

    items.push(<div key="building">{this.state.document_request.building.title}</div>)
    items.push(<div key="unit">{this.state.document_request.requester.unit}</div>)

    return <div key="property" className="property">{items}</div>
  }

  renderRequestingParty() {
    const items = [],
      requester = this.state.document_request.requester,
      two_fa_enabled = this.state.document_request.two_fa_enabled

    items.push(<div key="name">{requester.name}</div>)
    items.push(<div key="email">{requester.email}</div>)
    items.push(<div key="phone">{requester.phone} <div className={`dome-color-aqua btn btn-sm btn-link ${two_fa_enabled ? 'visible' : 'invisible'}`} style={{textDecoration: 'none'}} onClick={() => this.setState({ showEditPhoneModal: true })}>update</div></div>)

    return <div key="requesting-party" className="requesting-party">{items}</div>
  }

  setEditPhoneState() {
    this.setState({ showEditPhoneModal: true })
  }

  renderFirm() {
    const items = [],
      requester = this.state.document_request.requester

    items.push(<div key="company">{requester.company_name}</div>)
    items.push(<div key="attorney-num">{requester.attorney_license}</div>)

    return <div key="firm" className="firm">{items}</div>
  }

  renderNotes() {
    const classes = ["fa-thin", "fa-note"]

    let onClick = () => { }
    if (this.state.document_request.requester.reason) {
      onClick = () => this.setState({ show_extra: !this.state.show_extra })
      classes.push("active")
    }

    return <div key="notes" className="notes"><i className={classes.join(" ")} onClick={onClick} /></div>
  }

  renderDataRow() {
    let buttons = []

    if (this.state.document_request.processing) {
      buttons.push(<button key="view-processing" className="dome-btn dome-btn-base dome-btn-grey-stroke" onClick={() => window.BoardMinutesResponse.open(this, { check_processing: true })}>view status</button>)
    }
    else if (this.props.history) {
      buttons = buttons.concat(this.historyButtons())
      // buttons.push(<i key="hide" className="fa fa-times remove" onClick={() => this.resendLink()}></i>)
    } else {
      let options = { onCloseHandler: () => this.updateLocalProcessing(false) }
      if (this.isBoardMinuteViewEnabled()) {
        options = { ...options, loadDocuments: () => this.loadDocuments() }
      }
      buttons.push(<button key="approve" className="dome-btn dome-btn-base dome-btn-green-stroke" onClick={() => window.BoardMinutesResponse.open(this, options)}>{this.state.openText}</button>)
      buttons.push(<button key="deny" className="dome-btn dome-btn-base dome-btn-grey-stroke" onClick={() => window.BoardMinutesDenial.denyTo(this)}>deny</button>)
    }

    const columns = []
    columns.push(this.renderProperty())
    columns.push(this.renderRequestingParty())

    if (this.props.history) {
      columns.push(<div key="request-approved" className="request-approved">{this.renderApprovalDates()}</div>)
      columns.push(<div key="request-accessed" className="request-accessed">{this.renderAccessedDate()}</div>)
    } else {
      columns.push(this.renderFirm())
      columns.push(<div key="date-requested" className="date-requested">{this.renderDate(this.state.document_request.created_at)}</div>)
      columns.push(this.renderNotes())
    }

    columns.push(<div key="actions" className="actions">{buttons}</div>)
    return <div key={this.state.document_request.id} className="data-row">{columns}</div>
  }

  renderExtraRow() {
    const style = {
      maxHeight: this.state.show_extra ? "200px" : "0px"
    }

    return <div className="extra-row" style={style}><div>
      <div>Notes:</div>
      <div>{this.state.document_request.requester.reason}</div>
    </div></div>
  }

  renderEditPhoneNumber(requester) {
    if (!this.state.showEditPhoneModal) {
      return null
    }
    return <UpdatePhoneModal
      onClose={() => this.setState({ showEditPhoneModal: null })}
      building={this.state.document_request.building}
      requester={this.state.document_request.requester}
      document_id={this.state.document_request.random_id}
      updateLocalPhoneNumber={this.updateLocalPhoneNumber}
    />
  }

  updateLocalProcessing(processingStatus) {
    this.setState(prevState => {
      const document_request = prevState.document_request
      document_request.processing = processingStatus
      return { document_request }
    })
  }

  updateLocalPhoneNumber(phoneNumber) {
    this.setState(prevState => {
      const document_request = prevState.document_request
      document_request.requester = Object.assign({}, document_request.requester, { phone: phoneNumber })
      return { document_request }
    })
  }

  loadDocuments() {
    return new Promise((resolve, reject) => {
      const url = `/minutes/buildings/${this.building().id}/documents.json`

      $.ajax({
        url: url,
        success: response => {
          resolve(response)
        },
        error: xhr => reject(xhr)
      })
    })
  }

  render() {
    return <div ref="container">
      {this.renderDataRow()}
      {this.renderExtraRow()}
      {this.renderEditPhoneNumber()}
    </div>
  }
}

export default Row
