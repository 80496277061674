import React, { useContext, useEffect, useRef, useState } from 'react'
import { FeeContext } from '../../../../contexts/FeeContext'
import { StripeAccContext } from '../../../../contexts/StripeAccContext'
import { currencyMask } from '../../../../currency'
import { stripNumber } from '../../../../currency'

const RadioSelectors = ({selected, setSelected, amount, setAmount, paymentExists, optionAmount, setOptionAmount, optionBalance, setOptionBalance}) => {
  const { subTotal } = useContext(FeeContext)
  const { authorizedTotal } = useContext(StripeAccContext)
  const inputRef = useRef()
  const radioRef = useRef()
  const [optionOneActive, setOptionOneActive] = useState(false)
  const [optionTwoActive, setOptionTwoActive] = useState(false)

  const remainingBalance = () => parseFloat(subTotal - authorizedTotal)
  const eventHandler = (e) => {
    const id = e.target.id
    switch(id) {
      case 'amount-hidden':
        $(radioRef.current).prop('checked', true)
      case 'custom-amount':
        setOptionOneActive(true)
        setOptionTwoActive(false)
        $(inputRef.current).focus()
        setAmount(optionAmount)
        break
      case 'remaining-amount':
        setOptionTwoActive(true)
        setOptionOneActive(false)
        setOptionBalance(remainingBalance())
        setAmount(remainingBalance())
        break
    }

    setSelected(true)
  }

  const focusFields = () => {
    if (optionOneActive) {
      $(inputRef.current).focus()
    } else if (optionTwoActive) {
      $(inputRef.current).blur()
    }
  }
  // FIXME: not working yet
  useEffect(() => {
    if (paymentExists) {
      focusFields()
    }
  }, [])

  useEffect(() => focusFields(), [optionOneActive, optionTwoActive])
  useEffect(() => setAmount(optionAmount), [optionAmount])

  return (
    <div className='amount-selectors'>
      <div className='radio-sections' style={{background: optionOneActive ? '#f9f9f9' : 'white'}}>
        <input
          ref={radioRef}
          type='radio'
          name='amount'
          id='custom-amount'
          onChange={eventHandler}
        />
        <label htmlFor='custom-amount'>Enter Amount</label>
        <input
          ref={inputRef}
          id='amount'
          type='text'
          disabled={optionTwoActive || !optionOneActive}
          value={currencyMask(optionAmount || '0')}
          onChange={(e) => setOptionAmount(stripNumber(e.target.value, true))}
        />
        <div className='hidden-clickable' id='amount-hidden' onClick={eventHandler}></div>
      </div>
      <div className='radio-sections' style={{background: optionTwoActive ? '#f9f9f9' : 'white'}}>
        <input
          type='radio'
          name='amount'
          id='remaining-amount'
          onChange={eventHandler}
        />
        <label htmlFor='remaining-amount'>All Remaining</label>
        <p>{currencyMask(remainingBalance())}</p>
      </div>
    </div>
  )
}

export default RadioSelectors
