import React from 'react'
import {connect} from 'react-redux'

import Store from './store'
import User from './user'

function compare(a,b) {
  if (a == b)
    return 0

  return a > b ? 1 : -1
}

class OrgUsers extends React.Component {
  constructor(props) {
    super(props)

    this.share = this.share.bind(this)
    this.clickSelect = this.clickSelect.bind(this)
  }

  clickSelect() {
    if (this.props.selected_users.length == 0)
      this.props.selectAll()
    else
      this.props.unselectAll()
  }

  possible_users_path() {
    return BoardShare.Constants.possible_users_path.replace(":id", this.props.share.id)
  }

  add_user_path() {
    return BoardShare.Constants.add_user_path.replace(":id", this.props.share.id)
  }

  shareText() {
    return this.props.share.shared_at ? "Remind Board Members by Email" : "Share with Board Members"
  }

  renderIfUsers() {
    if (this.props.users.length == 0)
      return

    return this.props.users.map(user => <User type="board_member" key={user.id} user={user} is_current_user={user.id == this.props.current_user} />)
  }

  possible_to_select() {
    return this.props.users.filter(user => user.id != parseInt(this.props.share.user_id))
  }

  possible_and_selected() {
    return this.possible_to_select().filter(user => this.props.selected_users.includes(user.id))
  }

  share() {
    const url = BoardShare.Constants.share_with_users.replace(":id", this.props.share.id)

    $.ajax({
      url: url,
      type: "post",
      data: hashToPostQueryString({share: { user_ids: this.possible_and_selected().map(user => user.id)}}),
      success: res => this.props.addSharesToState(res.shares)
    })
  }

  renderNoUsers() {
    if (this.props.users.length > 0)
      return

    return <div className="no-users">
      There are no Board Members listed on these buildings
    </div>
  }

  renderUsers() {
    return <div className="user-list">
      <div className="user header">
        <div className="checkbox">
          <input type="checkbox" checked={this.props.selected_users.length > 0} onClick={this.clickSelect} readOnly={true} />
        </div>
        <div className="user-details">
          <div className="buildings">
            <div>
              <div className="role">Name</div>
              <div className="building-name">Property</div>
            </div>
          </div>
        </div>
        <div className="email">Email</div>
        <div className="user-icons">Access</div>
      </div>
      {this.renderIfUsers()}
      {this.renderNoUsers()}
    </div>
  }

  renderButtons() {
    const num_selected = this.props.selected_users.length,
          num_users = this.possible_to_select().length,
          num_to_show = num_selected >= num_users ? "All" : num_selected

    if (num_selected == 0 || num_users == 0)
      return ""

    return <div className="send-buttons">
      <button className="btn btn-primary" onClick={this.share}>Share with {num_to_show} Board Members</button>
    </div>
  }

  render() {
    return <div className="board-members">
      { this.props.children }
      { this.renderUsers() }
      { this.renderButtons() }
    </div>
  }
}

const mapStateToProps = state => {
  const users = state.users.board_members.map(user_id => Object.assign({}, state.users.users[user_id], {id: user_id})) 
  return {
    share: state.users.share,
    users: users.sort( (a,b) => compare((a.last || a.last_name || "").toLowerCase(), (b.last || b.last_name || "").toLowerCase())),
    selected_users: state.presentation.selected_board_members.filter(user_id => user_id != parseInt(state.users.share.user_id)),
    current_user: state.presentation.current_user_id
  }
}

const mapDispatchToProps = dispatch => {
  return {
    addSharesToState: shares => dispatch(Store.shareWithUsers(shares)),
    selectAll: () => dispatch(Store.selectAllUsers("board_members")),
    unselectAll: () => dispatch(Store.unselectAllUsers("board_members"))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgUsers)