import React, { useCallback, useContext, useMemo } from 'react'
import { LeaseContext } from '../contexts/LeaseContext'
import { StripeAccContext } from '../contexts/StripeAccContext'
import { FeeContext } from '../contexts/FeeContext'
import Header from './Header'

// selects different copy text depending on state of lease
const HeaderDescriptionSelector = ({title}) => {
  const { paymentCollectionStatus, feesSubmitted } = useContext(StripeAccContext)

  const leaseStateSelector = () => {
    if (feesSubmitted && paymentCollectionStatus === 'noneAuthorized') {
      return 'feesSubmitted'
    } else if (feesSubmitted && paymentCollectionStatus !== 'noneAuthorized') {
      return 'feeCollectionInitiated'
    } else {
      return 'readyToAuthorize'
    }
  }

  return (
    <Header leaseStates={leaseStateSelector()} title={title}/>
  )
}

export default HeaderDescriptionSelector
