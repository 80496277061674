import React from 'react'
import { trim, isEmpty } from 'lodash-es'
import FormField from './form_field'

const CONTACT_FIELDS = ['name', 'email', 'phone', 'company_name', 'attorney_license', 'reason']
class ContactForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { errors: {} }

    CONTACT_FIELDS.forEach(field => this.state[field] = (props.contact_info || {})[field])

    if (typeof(props.onSubmit) == "function")
      this.onSubmitListener = props.onSubmit

    if (typeof(props.onBack) == "function")
      this.onBackListener = props.onBack
  }

  componentDidMount() {
    this.checkErrors()
    $(this.refs.container).find("[data-tooltip]").tooltip()
  }

  componentDidUpdate() {
    $(this.refs.container).find("[data-tooltip]").tooltip()
  }

  updateField(name, value) {
    const state = {errors: Object.assign({}, this.state.errors)}

    state[name] = trim(value)
    delete state.errors[name]

    this.setState(state)
  }

  canSubmit() {
    return !this.hasErrors()
  }

  onBack() {
    if (this.onBackListener)
      this.onBackListener()
  }

  onSubmit() {
    if (this.checkErrors({submitting: true}))
      return

    const contact = {}
    for (let field of CONTACT_FIELDS)
      contact[field] = this.state[field]

    if (this.onSubmitListener)
      this.onSubmitListener(contact)
  }

  missingRequiredFields() {
    return !this.state.name || !this.state.email || !this.state.confirm_email || !this.state.phone || !this.state.company_name
  }

  hasErrors() {
    return !isEmpty(this.state.errors)
  }

  checkErrors(options) {
    const errors = Object.assign({}, this.state.errors)

    if (typeof(options) != "object")
      options = {}

    if (this.state.name) {
      if (this.state.name.match(/^\s*$/))
        errors.name = "This cannot be blank"
      else
        delete errors.name
    }
    else if (options.name || options.submitting)
      errors.name = "This cannot be blank"

    if (this.state.email) {
      if (this.state.email.match(/^\s*$/))
        errors.email = "This cannot be blank"
      else if (!this.state.email.match(/^.+@.+\..+$/))
        errors.email = "This is not a valid email"
      else
        delete errors.email
    } else if (options.email || options.submitting)
      errors.email = "This cannot be blank"

    if (this.state.confirm_email) {
      if (this.state.confirm_email.match(/^\s*$/))
        errors.confirm_email = "This cannot be blank"
      else if (this.state.email != this.state.confirm_email)
        errors.confirm_email = "Emails must match!"
      else
        delete errors.confirm_email
    } else if (options.confirm_email || options.submitting)
      errors.confirm_email = "This cannot be blank"

    if (this.state.phone) {
      if (this.state.phone.match(/^\s*$/))
        errors.phone = "This cannot be blank"
      else
        delete errors.phone
    } else if (options.phone || options.submitting)
      errors.phone = "This cannot be blank"

    if (this.state.company_name) {
      if (this.state.company_name.match(/^\s*$/))
        errors.company_name = "This cannot be blank"
      else
        delete errors.company_name
    }
    else if (options.company_name || options.submitting)
      errors.company_name = "This cannot be blank"

    if (this.state.attorney_license) {
      if (this.state.attorney_license.match(/^\s*$/))
        delete errors.attorney_license
      else if (this.state.attorney_license.length < 6)
        errors.attorney_license = "Attorney License must be at least 6 characters in length"
      else
        delete errors.attorney_license
    }

    this.setState({errors: errors})
    return !isEmpty(errors)
  }

  renderPhoneTooltip() {
    const tooltip_text = "The phone number provided must be able to receive text (SMS) messages for security purposes. Mobile messaging rates may apply."

    return <i className="fa-thin fa-circle-info" data-tooltip={true} title={tooltip_text} />
  }

  renderField(name, options = {}) {
    return <FormField name={name}
                      defaultValue={this.state[name]}
                      error={this.state.errors[name]}
                      labelText={options.labelText}
                      description={options.description}
                      required={options.required}
                      tooltip={options.tooltip}
                      onChange={value => this.updateField(name, value)}
                      onBlur={() => this.checkErrors({[name]: true})} />
  }

  renderForm() {
    return <div className="form">
      <div className="column">
        {this.renderField("name", { labelText: "name", required: true})}
        {this.renderField("email", { labelText: "email", required: true})}
      </div>
      <div className="column">
        {this.renderField("confirm_email", { labelText: "confirm email", required: true})}
        {this.renderField("phone", { labelText: "phone", required: true, tooltip: this.renderPhoneTooltip()})}
      </div>
      <div className="column">
        {this.renderField("company_name", { labelText: "firm", required: true})}
        {this.renderField("attorney_license", { labelText: "attorney license #"})}
      </div>
      <div className="column">
        <div className="field">
          <label htmlFor="notes">notes</label>
          <div className="input-container textarea">
            <textarea name="notes" defaultValue={this.state.reason} onBlur={() => this.checkErrors()} onChange={e => this.updateField("reason", e.target.value)}></textarea>
          </div>
        </div>
      </div>
    </div>
  }

  render() {
    const button_classes = ["btn-base", "btn-green-stroke"],
          disabled = this.missingRequiredFields() || this.hasErrors()

    if (disabled)
      button_classes.push("btn-disabled")

    return <div className="contact" ref="container">
      {this.renderForm()}
      <div className="buttons">
        <button type="button" className="btn-link back-link" onClick={() => this.onBack()}>back</button>
        <button type="button" className={button_classes.join(" ")} onClick={() => this.onSubmit()}>next</button>
      </div>
    </div>
  }
}

export default ContactForm
//
