import React from 'react'
import { connect } from 'react-redux'

import Store from './store'
import User from './user'
import Select from './select'

function compare(a,b) {
  if (a == b)
    return 0

  return a > b ? 1 : -1
}

class OrgUsers extends React.Component {
  constructor(props) {
    super(props)

    this.addNewUser = this.addNewUser.bind(this)

    this.successfullyAddedUser = this.successfullyAddedUser.bind(this)

    this.share = this.share.bind(this)
    this.clickSelect = this.clickSelect.bind(this)
  }

  possible_users_path() {
    return BoardShare.Constants.possible_users_path.replace(":id", this.props.share.id)
  }

  add_user_path() {
    return BoardShare.Constants.add_user_path.replace(":id", this.props.share.id)
  }

  clickSelect() {
    if (this.props.selected_users.length == 0)
      this.props.selectAll()
    else
      this.props.unselectAll()
  }

  addNewUser(user) {
    $.ajax({
      url: this.add_user_path().replace(":user_id", user.id),
      type: "post",
      data: hashToPostQueryString({}),
      success: this.successfullyAddedUser
    })
  }

  possible_to_select() {
    return this.props.users.filter(user => user.id != parseInt(this.props.share.user_id))
  }

  possible_and_selected() {
    return this.possible_to_select().filter(user => this.props.selected_users.includes(user.id))
  }

  share() {
    const url = BoardShare.Constants.share_with_users.replace(":id", this.props.share.id)

    $.ajax({
      url: url,
      type: "post",
      data: hashToPostQueryString({share: { user_ids: this.possible_and_selected().map(user => user.id)}}),
      success: res => this.props.addSharesToState(res.shares)
    })
  }

  successfullyAddedUser(res) {
    this.props.addEditUser(res.user, res.building_roles, res.shares)
    this.refs.select_user.clear()
  }

  userType() {
    return this.props.non_org ? "non_org_user" : "org_user"
  }

  renderUsers() {
    return <div className="user-list">
      <div className="user header">
        <div className="checkbox">
          <input type="checkbox" checked={this.props.selected_users.length > 0} onClick={this.clickSelect} readOnly={true} />
        </div>
        <div className="user-details">
          <div className="buildings">
            <div>
              <div className="role">Name</div>
              <div className="building-name">Property</div>
            </div>
          </div>
        </div>
        <div className="email">Email</div>
        <div className="user-icons">Access</div>
      </div>

      { this.props.users.map(user => <User type={this.userType()} key={user.id} user={user} is_current_user={user.id == this.props.current_user} />)}
    </div>
  }

  renderButtons() {
    const num_selected = this.props.selected_users.length,
          num_users = this.possible_to_select().length,
          num_to_show = num_selected >= num_users ? "All" : num_selected

    if (num_selected == 0 || num_users == 0)
      return ""

    return <div className="send-buttons">
      <button className="btn btn-primary" onClick={this.share}>Share with {num_to_show} Users</button>
    </div>
  }

  renderNewUser() {
    if (this.props.non_org)
      return

    return <Select ref="select_user" search_path={this.possible_users_path()} selectUser={this.addNewUser} />
  }

  render() {
    return <div className="org-users">
      { this.props.children }
      { this.renderNewUser() }
      { this.renderUsers() }
      { this.renderButtons() }
    </div>
  }
}

const mapStateToProps = (state, ownProps) => {
  const user_type = ownProps.non_org ? "non_org_users" : "org_users"
  const selected_user_type = "selected_" + user_type

  const users = state.users[user_type].map(user_id => Object.assign({}, state.users.users[user_id], {id: user_id}))
  return {
    share: state.users.share,
    users: users.sort( (a,b) => compare((a.last || a.last_name || "").toLowerCase(), (b.last || b.last_name || "").toLowerCase())),
    selected_users: state.presentation[selected_user_type].filter(user_id => user_id != parseInt(state.users.share.user_id)),
    current_user: state.presentation.current_user_id
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  const user_type = ownProps.non_org ? "non_org_users" : "org_users"
  return {
    addEditUser: (user, building_roles) => dispatch(Store.addEditUser(user, building_roles)),
    selectAll: () => dispatch(Store.selectAllUsers(user_type)),
    unselectAll: () => dispatch(Store.unselectAllUsers(user_type)),
    addSharesToState: shares => dispatch(Store.shareWithUsers(shares))
//      reorderSectionsInState: ids => dispatch(Store.reorderSections(ids))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrgUsers)
