import React, { useContext } from 'react'
import { LeaseContext } from '../../contexts/LeaseContext'

const FeesContainer = (props) => {
  const { permissions } = useContext(LeaseContext)

  return (
    <div className='fees-container'>
      <div className='fee-attributes'>
        <div className='column-1 title'>Fee Name</div>
        {permissions.includes("view-additional-fee-info") ?
          <>
            <div className='column-2 title'>Routing Account</div>
            <div className='column-3 title'>GL Code</div>
            <div className='column-4 title'>Charge Code</div>
          </>
          : null}
        <div className='column-5 title'>Amount</div>
      </div>
      {props.children}
    </div>
  )
}

export default FeesContainer
