export const currencyMask = (val, option) => {
  if (isNaN(val) || val == null) return val
  let number = val.toString()

  if (option === 'percentage') {
    number = number.split('.')[0]
  }
  number = parseFloat(number
    .replace(/(.*){1}/, '0$1')
    .replace(/[^\d]/g, '')
    .replace(/(\d\d?)$/, '.$1')
    ).toFixed(2)
  return `$${number.toString().replace(/(?=(\d{3})+(\D))\B/g, ",")}`
}

export const stripNumber = (val, isDigitsOnly) => {
  if (!isDigitsOnly && typeof val == 'string') return val
  let numericString = val.toString()
  return parseFloat(numericString.replace(/\$/, '').replace(/\./, '').replaceAll(/,/g, ''))
}

export const hasCredits = (fees) => {
  const credits = fees.filter(fee => fee.type === "credit")
  return credits.length > 0
}
