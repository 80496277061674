import { stripe } from "../Stripe"

export const submitPayment = async (dataObject) => {
  try {
    const reqBody = {
      payment_method_id: dataObject.payment_method_id,
      stripeToken: dataObject.stripeToken || null,
      amount: dataObject.amount || null,
      commission: dataObject.commission || null,
    }
    const response = await fetch("leases/set_payment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBody),
    })

    const data = await response.json()
    return data
  } catch (error) {
    console.error('Unexpected error: ', error)
  }
}

export const processPayments = async () => {
  const url = "leases/process_payments"
  const options = {
    method: "POST",
    body: JSON.stringify({}),
  }
  try {
    const response = await fetch(url, options)
    const data = await response.json()
    if (!response.ok) {
      throw new Error(data)
    }
    return data
  } catch (e) {
    console.error("Unexpected server error.")
  }
}

export const setupBankAccountApi = async () => {
  try {
    const response = await fetch("/users/create_setup_intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ type: "bank" }),
    })
    if (!response.ok) {
      const { error } = await response.json()
      throw new Error(error || 'An unspecified error occured.')
    }
    return await response.json()
  } catch (error) {
    throw error
  }
}

export const verifyBankAccount = async id => {
  const response = await fetch("/users/verify_payment_method", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ payment_method_id: id }),
  })

  return await response.json()
}

export const incrementVerificationCount = async id => {
  const response = await fetch("/users/increment_verification_count", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ payment_method_id: id }),
  })

  return await response.json()
}

export const verifyMicrodeposits = async (id, descriptor_code) => {
  const response = await fetch('/users/setup_intent_for_payment_method?' + new URLSearchParams({ payment_method_id: id }))
  const { secret } = await response.json()
  const { setupIntent, error } = await stripe().verifyMicrodepositsForSetup(secret, { descriptor_code })

  let data = {}

  if (error) {
    console.error(error.message)
    data = { code: error.code, message: error.message }
  } else if (setupIntent.status === 'succeeded') {
    await verifyBankAccount(id)
    data = { success: true }
  }
  const { verification_count, bank_accounts } = await incrementVerificationCount(id)

  return { ...data, verification_count, bank_accounts }
}
