import React from 'react'

class Row extends React.Component {
  constructor(props) {
    super(props)
  }

  renderNoteRow() {
    return(
      <div>
        <div style={{color: '#4A4A4A'}}>
        {this.props.userFulleName} noted on {this.props.createdAtDate} at {this.props.createdAtTime}: 
        </div>
        <div dangerouslySetInnerHTML={{ __html: this.props.message}}>
        </div>
        <hr/>
      </div>
    )
  }


  render(){
    return (this.renderNoteRow())

  }
}

export default Row