import React, { useContext } from 'react'
import Modal from '../../../../package_notes_plugin/javascripts/modal'
import Container from './Container'
import Note from './Note'
import { ModalContext } from '../../ModalContext'
import { NoteContext } from '../contexts/NoteContext'

const Notes = ({unit, address, package_id }) => {
  const { notes, setNotes } = useContext(NoteContext)
  const { setCurrentModal } = useContext(ModalContext)

  const handleClick = () => {
    setCurrentModal(
      <Modal
        onClose={() => setCurrentModal(null)}
        unit={unit}
        address={address}
        package_id={package_id}
        updateCount={() => {}}
        updateParentRenderer={setNotes}
      />
    )
  }

  return (
    <>
      <Container title={notes.package_notes?.length ? 'Notes:' : 'Notes'} nameOfClass='container-small'>
        <div onClick={handleClick} className='note-count'>
          {notes.package_notes?.length || ''}
        </div>
        <Note recentNote={notes?.package_notes?.[0]}/>
        <button onClick={handleClick} className='new-note'>
          <i className="fa-regular fa-plus"></i>
        </button>
      </Container>
    </>
  )
}

export default Notes
