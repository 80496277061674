import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useWatchableRef } from "lib/hooks";

export function EditMinuteSuccessModal(props) {
  const { open, onClose } = props;
  const modalRef = useWatchableRef(null);

  useEffect(() => {
    if (!modalRef.current) return;

    const localRefValue = modalRef.current;
    const handleClose = (_e) => onClose();

    $(localRefValue).on("hidden.bs.modal", handleClose);
    return () => $(localRefValue).off("hidden.bs.modal", handleClose);
  }, [onClose, modalRef.version]);

  useEffect(() => {
    if (!modalRef.current) return;

    if (open) {
      $(modalRef.current).modal("show");
    } else {
      $(modalRef.current).modal("hide");
    }
  }, [open, modalRef.version]);

  return (
    <div
      ref={modalRef.ref}
      className="modal edit-minute-success-modal"
      tabIndex="-1"
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content new-brp-modal">
          <div className="modal-header">
            <i className="fa-thin fa-circle-check fa-3x"></i>
          </div>
          <div className="modal-body">
            <div>
              <h4 className="modal-title">Minutes Updated!</h4>
            </div>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-custom-cancel"
              data-dismiss="modal"
              onClick={onClose}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

EditMinuteSuccessModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
