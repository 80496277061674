import React, { useRef, useEffect, useContext } from "react"
import { ModalContext } from "../../../../ModalContext"
import useLoader from "../../../hooks/useLoader"

const FeesCollectPrompt = ({onCollectFees}) => {
  const { setCurrentModal } = useContext(ModalContext)
  const { loading, finishedLoading, withLoader } = useLoader()
  const ref = useRef()

  const cleanup = () => $(ref.current).modal("hide")
  const handleClick = async () => {
    await onCollectFees()
    cleanup()
  }

  const handleCLickWithLoader = () => {
    withLoader(handleClick)
  }

  useEffect(() => {
    if (!ref.current) return

    const modal = $(ref.current)
    const close = () => setCurrentModal(null)
    modal.on("hidden.bs.modal", close)
    modal.modal({ backdrop: "static", keyboard: false })

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  return (
    <div className='lease-fees-modal'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box initiated'>
          <div className='modal-icon'>
            <i className='exclamation-circle-orange'></i>
          </div>
          <h3>Are you sure?</h3>
          <div className='body'>
            <p>
              By initiating collection you are confirming that all fee amounts
              listed are correct.
            </p>
          </div>
          <div className='button-container'>
            <button className='cancel' disabled={loading || finishedLoading} onClick={cleanup}>
              Cancel
            </button>
            <button className='green confirm-collect' disabled={loading || finishedLoading} onClick={handleCLickWithLoader}>
              {loading ? <i
                className='fa-duotone fa-loader fa-spin-pulse'
                style={{"--fa-primary-color": '#f7f7f7', "--fa-secondary-color": '#c9c9c9'}}
              ></i> : 'Confirm + Collect'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeesCollectPrompt
