import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ShareActions from './ShareActions'

const Row = ({person, disabled}) => {
  const currentUser = useSelector(state => state.user)
  const { name, role_labels, email: account} = person

  const shared = person.sent_at && person.has_access
  const [isRowSelected, setIsRowSelected] = useState(false)

  let rowClassName = `t-row ${(isRowSelected || shared) && 'selected-row'}`
  if (disabled) rowClassName += ' disabled'

  return (
    <div className={rowClassName}>
      <div className='t-col'>{name}</div>
      <div className='t-col'>
        {role_labels.map((role_label, idx) => (
          <div key={idx} className='inner'>{role_label}</div>
        ))}
      </div>
      <div className='t-col'>{account}</div>
      <div className='t-col'>
        { person.email != currentUser.email && <ShareActions person={person} setIsRowSelected={setIsRowSelected} disabled={disabled} /> }
      </div>
    </div>
  )
}

export default Row
