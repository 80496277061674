import React, { useContext, useEffect, useState } from 'react'
import { currencyMask } from '../../fees/currency'
import { NoteContext } from '../contexts/NoteContext'
import Container from './Container'

const feeStatus = (authorizedFees, feeTotal, hasBeenShared, allPaymentsCaptured) => {
  if (!hasBeenShared || authorizedFees === 0)
    return null

  if (allPaymentsCaptured)
    return "collected"

  return authorizedFees < feeTotal ? 'authorized' : 'ready-to-collect'
}

const getStatusLabel = (status) => {
  const statusLabels = {
    'authorized': 'Authorized',
    'ready-to-collect': 'Ready to Collect',
    'collected': 'Collected'
  }

  return statusLabels[status] || ''
}

const AtaGlance = ({ feeTotal, authorizedFees, feesConfirmedAt, hasBeenShared = true, signatureCounts, allPaymentsCaptured }) => {
  // temporary state until data can be passed in from backend
  // code below is temporary for demo
  const [status, setStatus] = useState(null)

  const isCollected = status === 'collected'

  useEffect(() => {
    setStatus(feeStatus(authorizedFees, feeTotal, hasBeenShared, allPaymentsCaptured))

  }, [])

  return (
    <Container title='At a Glance' nameOfClass='container-small'>
      <div className='info signatures'>
        <div>
          <p>Signatures: </p>
        </div>
        <div>
          <p className={isCollected ? 'collected' : ''}>{signatureCounts.signed_required}/{signatureCounts.required}</p>
        </div>
      </div>
      <div className='info fees'>
        <div>
          <p>Fees: </p>
        </div>
        <div>
          <p className={isCollected ? 'collected' : ''}>
            {feesConfirmedAt
              ? `${currencyMask(authorizedFees)}/${currencyMask(feeTotal)}`
              : `${currencyMask(0)}/${currencyMask(0)}`}
          </p>
          {feesConfirmedAt && (
            <p className={`status status-${status}`}>
              {getStatusLabel(status)}
            </p>
          )}
        </div>
      </div>
    </Container>
  )
}

export default AtaGlance
