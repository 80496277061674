import React, { useState, useRef, useEffect } from 'react';

export default function ResponseFilesDropdown({ documents, onToggleDocument, onToggleAll, selectedDocuments }) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => setExpanded((expanded) => !expanded);
  const blurringRef = useRef(null);

  const handleBlur = () => {
    blurringRef.current = setTimeout(() => {
      setExpanded(false);
    }, [1]);
  };

  const handleFocus = () => {
    if (blurringRef.current) clearTimeout(blurringRef.current);
  };

  const handleClickAddAll = () => {
    onToggleAll(true);
  };

  const handleCheckboxAddDocument = (checked, doc_id) => {
    onToggleDocument(doc_id, checked);
  };

  return (
    <>
      <div id="dropdownMenuBoardMinutes" tabIndex={0} onBlur={handleBlur}>
        <div className={`card-header ${expanded ? 'expanded' : ''} `} onClick={toggleExpanded}>
          <div className="card-header__title">
            <i className="fal fa-folder" style={{ margin: '0px 5px 0px 10px' }}></i>
            Select files from Library
          </div>
          <i className="fa-light fa-chevron-down" style={{ marginRight: '5px' }}></i>
        </div>
        <div className={`collapse ${expanded ? 'in' : ''}`} tabIndex={0} onFocus={handleFocus}>
          <div className="card-body">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="col-md-4">Meeting Date</th>
                  <th className="col-md-6">File</th>
                  <th className="col-md-2 link-primary link-primary--default-cursor">
                    <div onClick={handleClickAddAll}>+ Add All</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {documents &&
                  documents.map((doc) => {
                    return (
                      <tr key={doc.id}>
                        <td>{new Date(doc.meeting_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}</td>
                        <td>{doc.file_identifier}</td>
                        <td className="text-right" style={{ width: '100%' }}>
                          <input
                            type="checkbox"
                            name={`document_${doc.id}`}
                            id={`document_${doc.id}`}
                            checked={selectedDocuments[doc.id] || false}
                            onChange={(event) => handleCheckboxAddDocument(event.target.checked, doc.id)}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
