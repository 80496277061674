import React from 'react'
import { currencyMask } from '../../fees/currency'
import Container from './Container'

const Progress = ({participants}) => {
  return (
    <Container title='Participant Progress' nameOfClass='container-large participant-progress'>
      <div className='progress-table'>
        <div className='table-heading'>
          <div className='column0'></div>
          <div className='column1'>Participant</div>
          <div className='column2'>Role</div>
          <div className='column3'>Access</div>
          <div className='column4'>Signatures</div>
          <div className='column5'>Amount</div>
          <div style={{marginRight: '10px'}} className='column6'></div>
        </div>
        <div className='table-content-rows'>
          {participants.map((p, i) => {
            return (
              <div key={i} className='table-row'>
                <div className='column0'></div>
                <div className='column1'>{p.name}</div>
                <div className='column2'>
                  {p.role_labels.map((label, idx) => (
                      <div key={idx} className='role'>{label}</div>
                  ))}
                </div>
                <div className='column3'>{p.access ? <i style={{color: '#56a256'}} className='fa-light fa-check'></i> : 'No'}</div>
                <div className='column4'>{p.signed_signatures}/{p.required_signatures}</div>
                <div className='column5'>{currencyMask(p.amount || 0)}</div>
                <div className='column6'></div>
              </div>
            )
          })}
        </div>
      </div>
    </Container>
  )
}

export default Progress
