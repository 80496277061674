import React, { memo, useEffect, useRef, useState } from 'react';

function TableHeaderWithInputText({ filterProperty }) {
  const [inputText, setInputText] = useState('');
  const [isSearchInputShown, setIsSearchInputShown] = useState(false);

  const searchInputRef = useRef();

  useEffect(() => {
    filterProperty(inputText);
  }, [inputText, filterProperty]);

  useEffect(() => {
    if (!isSearchInputShown) return;

    searchInputRef.current.focus();
  }, [isSearchInputShown]);

  return (
    <th>
      <div className="header-input">
        <i
          className="fa-regular fa-magnifying-glass fa-flip-horizontal fa-sm bp-orange"
          onClick={() => setIsSearchInputShown(!isSearchInputShown)}
        ></i>
        <div>
          <input
            ref={searchInputRef}
            className="header-input__search"
            style={{ visibility: isSearchInputShown ? 'visible' : 'hidden' }}
            type="search"
            onChange={event => setInputText(event.currentTarget.value)}
            value={inputText}
            placeholder="Search"
          />
          <label className="header-input__text" style={{ visibility: isSearchInputShown ? 'hidden' : 'visible' }}>
            Property
          </label>
        </div>
      </div>
    </th>
  );
}

export default memo(TableHeaderWithInputText);
