import React, { createContext, useCallback, useContext, useMemo, useState, useEffect, useRef } from 'react'
import { useEventListeners } from '@deathbyjer/react-event-manager'

import Interstitial from 'components/utility/interstitial'

import { ajax } from 'jquery'
import { isNull, groupBy, mapValues, compact, sum } from 'lodash'
import { getNextNonNullIndex } from "../../../javascript/lib/utilities"

import Form from 'components/form'
import RoleAssignment from 'components/packages/role_assignment'
import { ModalContext } from './ModalContext'
import ElectronicSignatureConsentModal from './documents/components/modals/ElectronicSignatureConsentModal'
import { LeaseContext } from './fees/contexts/LeaseContext'
import { useSelector } from 'react-redux'


const UnfinishedDataContext = createContext()

function UnfinishedData({children}) {
  const [unfinishedData, setUnfinishedData] = useState({})

  const get = useCallback(() => unfinishedData, [unfinishedData])

  const ctxt = useMemo(() => ({
    get,
    set: data => setUnfinishedData(data)
  }), [unfinishedData])

  return <UnfinishedDataContext.Provider value={ctxt}>
    { children }
  </UnfinishedDataContext.Provider>
}

function DocumentButton({title, onClick, direction}) {
  if (!title)
    return <button className="btn disabled">{direction}</button>

  return <button className="btn btn-green" onClick={onClick}>
    <strong>{direction}</strong>: {title}
  </button>
}

function CurrentDocumentButton({document, index, totalDocs, onClick}) {
  const [opened, setOpened] = useState(false)
  const container = useRef()

  useEffect(() => {
    if (!opened)
      return

    const clicked = evt => {
      if (!container.current.contains(evt.target))
        setOpened(false)
    }

    window.addEventListener('click', clicked)
    return () => window.removeEventListener('click', clicked)
  }, [container.current, setOpened, opened])

  if (!document)
    return null

  const classes = compact([
    'current-document-button-container',
    opened ? "dropdown-opened" : null
  ]).join(" ")

  return <div className={classes} ref={container}>
    <div className="current-document-button" onClick={() => setOpened(!opened)}>
      <i className="fa-light fa-list"></i>
      <div>
        <div className="title">{document.name}</div>
        <div className="count">Doc {index + 1} of { totalDocs }</div>
      </div>
    </div>
    { opened ? <DocumentList onClick={() => setOpened(false)} /> : null }
  </div>
}

function SingleDocument({document, onClick}) {
  const details = []
  const totalRequiredSignatures = sum(Object.values(document.required_signatures))

  if (totalRequiredSignatures > 0) {
    details.push(<div key="required-signatures">
      <span className="number">{totalRequiredSignatures}</span>
      <span>required signature(s)</span>
    </div>)
  }

  return <div className="document-item" onClick={onClick}>
    <div className="title">{document.name}</div>
    { details.length > 0 ? <div className="details">{details}</div> : null }
  </div>
}

function DocumentList({onClick }) {
  const [state, setters] = useDocuments()
  const { package_id, documents, documentOrder, currentDocument } = state
  const { setCurrentDocument } = setters

  const clickDocument = document_id => {
    setCurrentDocument(document_id)
    onClick()
  }

  const clickAddRemove = () => window.location = `/package_hook/${package_id}/documents?edit=1`

  return <div className="documents-list">
    <div className="add-remove" onClick={clickAddRemove}>
      <i className="fa-thin fa-files"></i>
      <span>Add / Remove Documents</span>
    </div>
    { documentOrder.map(document_id =><SingleDocument document={documents[document_id]} key={document_id} onClick={() => clickDocument(document_id)}  /> )}
  </div>
}

const documentsWithSignatures = (documents, roles) => {
  const documentsList = Object.values(documents)
  return documentsList.filter(document => roles.some(role => document.required_signatures[role] > 0))
}

function Documents(props) {
  const unfinishedData = useContext(UnfinishedDataContext)
  const [documentState, documentSetters] = useDocuments()
  const [documentsWithRequiredSignatures, setDocumentsWithRequiredSignatures] = useState([])

  const { documents, documentOrder, currentDocument } = documentState
  const { setCurrentDocument } = documentSetters
  const index = documentOrder.indexOf(currentDocument)
  const prev = index > 0 ? index - 1 : null
  const next = index < documentOrder.length - 1 ? index + 1 : null

  const initialData = unfinishedData.get()
  const onClose = data => unfinishedData.set(data)
  const currentForm = documents[currentDocument]?.form_instance_id
  const { setCurrentModal } = useContext(ModalContext)
  const { electronicSigningConsentedAt, permissions, userRoles } = useContext(LeaseContext)

  const signaturesLeft = useSelector(state => state.signatures_left)

  useEffect(() => {
    setDocumentsWithRequiredSignatures(documentsWithSignatures(documents, userRoles))
  }, [documents, userRoles])

  useEffect(() => {
    if (!signaturesLeft[currentDocument.form_instance_id]) return
    if (electronicSigningConsentedAt) return
    if (!permissions.includes('sign_documents')) return

    setCurrentModal(<ElectronicSignatureConsentModal onCancel={props.onClose} />)
  }, [signaturesLeft, electronicSigningConsentedAt, permissions])

  const nextDocumentWithSignatureId = () => {
    const numberOfDocuments = documentsWithRequiredSignatures.length

    for (let offset = 1; offset < numberOfDocuments; offset++) {
      const nextIndex = (index + offset) % numberOfDocuments
      const document = documentsWithRequiredSignatures[nextIndex]
      const instanceId = document.form_instance_id
      let requiresSignature = false

      if (signaturesLeft[instanceId] > 0) {
        requiresSignature = true
      }

      if (requiresSignature) {
        return document.id
      }
    }
    // temporary functionality, ideally should scroll into view of already signed signature.
    // if no more documents with signatures, go to the next document. when you reach the end, start at beginning
    return documentOrder[next || 0]
  }

  const nextId = nextDocumentWithSignatureId()

  useEventListeners({
    'nextDocumentRequested': () => {
      const nextNonNullIndex = getNextNonNullIndex(documentOrder, index);
      if (nextNonNullIndex !== -1) {
        setCurrentDocument(documentOrder[nextNonNullIndex])
      }
    },
    'goToNextFormWithSignatures': () => setCurrentDocument(nextId)
  })

  return <div className="documents">
    <div className="documents-header interstitial-header">
      <div>
        <DocumentButton direction="Previous" title={!isNull(prev) ? documents[documentOrder[prev]].name : null} onClick={() => setCurrentDocument(documentOrder[prev])} />
      </div>
      <div>
        <CurrentDocumentButton document={documents[currentDocument]} index={index} totalDocs={documentOrder.length} />
      </div>
      <div>
        <DocumentButton direction="Next" title={!isNull(next) ? documents[documentOrder[next]].name : null} onClick={() => setCurrentDocument(documentOrder[next])} />
      </div>
    </div>
    <Form
      {...props}
      canSign={permissions.includes('sign_documents')}
      form_id={currentForm}
      dataOnClose={onClose}
      initialData={initialData} />
  </div>
}

const CONTENT_TYPES = {
  documents: Documents,
  role_assignment: RoleAssignment
}



function useDocumentsForContext(package_id, current = null) {
  const [documents, setDocumentHash] = useState({})
  const [documentOrder, setDocumentOrder] = useState([])
  const [currentDocument, setCurrentDocument] = useState(current)

  const setDocuments = useCallback(documentList => {
    const documents = mapValues(groupBy(documentList, 'id'), arr => arr[0])
    setDocumentHash(documents)
    setDocumentOrder(documentList.map(doc => doc.id))
    setCurrentDocument( documents[currentDocument] ? currentDocument : documentList[0].id )
  }, [currentDocument, setDocumentHash, setDocumentOrder, setCurrentDocument])

  const getters = useMemo(() => ({
    package_id, documents, documentOrder, currentDocument
  }), [documents, documentOrder, currentDocument, package_id])

  const setters = useMemo(() => ({
    setDocuments, setCurrentDocument
  }), [setDocuments, setCurrentDocument])

  return [getters, setters]
}

// The Tool Context
const DocumentsContext = React.createContext()

function DocumentsProvider({children, package_id, current}) {
  const [getters, setters] = useDocumentsForContext(package_id, current)

  useEffect(() => {
    refreshDocuments()
  }, [package_id])

  useEventListeners({
    'signatureAdded': () => refreshDocuments(),
    'signatureDeleted': () => refreshDocuments()
  })

  const refreshDocuments = () => {
    ajax({
      url: `/package_hook/${package_id}/leases/documents`,
      success: ({ documents }) => {
        setters.setDocuments(documents || [])
      }
    })
  }

  return React.createElement(DocumentsContext.Provider, { value: [getters, setters] }, children)
}

function useDocuments() {
  return React.useContext(DocumentsContext)
}

// Rebuild
function DocumentsInterstitial(props) {
  const [content, setContent] = useState(CONTENT_TYPES[props.content] ? props.content : 'documents')
  const Content = CONTENT_TYPES[content]

  const { document_id, package_id, onClose } = props

  useEventListeners({
    'openRoleAssignment': () => setContent('role_assignment'),
    'closeRoleAssignment': () => setContent('documents')
  })

  useEffect(() => {
    const signaturesCompleteModalClosed = ({ detail }) => {
      onClose()
    }

    window.addEventListener('signatures-complete-modal-closed', signaturesCompleteModalClosed)

    return () => window.removeEventListener('signatures-complete-modal-closed', signaturesCompleteModalClosed)
  })

  return <Interstitial onClose={onClose}>
    <UnfinishedData>
      <DocumentsProvider package_id={package_id} current={document_id}>
        <Content {...props}  />
      </DocumentsProvider>
    </UnfinishedData>
  </Interstitial>
}

function GlobalInterstitial(props) {
  const [show, setShow] = useState(props.show || false)

  const onClose = useCallback(() => setShow(false), [setShow])

  window[props.global_method || "toggleLeaseDocumentInterstitial"] = (opt) => setShow(isNull(opt) ? !show : opt)

  return show ? <DocumentsInterstitial {...props} onClose={onClose} /> : null
}


export default DocumentsInterstitial
