import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { loadDocumentsApi, downloadDocumentApi } from './apis';

const initialState = {
  buildingsCount: 0,
  currentBuilding: {},
  documents: [],
  isExportingDocumentUrl: false,
  documentUrl: null,
};

export const loadDocuments = createAsyncThunk(
  'boardMinutesList/loadDocuments',
  async ({ building_id }, { dispatch }) => {
    const response = await loadDocumentsApi(building_id);
    return dispatch(setInitialState(response.data));
  }
);

export const downloadDocument = createAsyncThunk(
  'boardMinutesList/downloadDocument',
  async ({ building_id, document_id }) => {
    const response = await downloadDocumentApi({ building_id, document_id });
    return response;
  }
);

const boardMinutesListSlice = createSlice({
  name: 'boardMinutesList',
  initialState,
  reducers: {
    resetState: (_state, _action) => {
      return initialState;
    },
    setInitialState: (state, action) => {
      state.documents = action.payload.documents;
      state.currentBuilding = action.payload.building;
      state.buildingsCount = action.payload.buildings_count;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(downloadDocument.pending, (state) => {
      state.isExportingDocumentUrl = true;
    });
    builder.addCase(downloadDocument.fulfilled, (state, action) => {
      state.isExportingDocumentUrl = false;
      state.documentUrl = action.payload.data.url;
    });
    builder.addCase(downloadDocument.rejected, (state) => {
      state.isExportingDocumentUrl = false;
    });
  },
});
const { reducer, actions } = boardMinutesListSlice;

export const selectBoardMinutesListState = (state) => state.boardMinutesList;

export const { resetState, setInitialState } = actions;

export default reducer;
