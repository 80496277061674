import React, { useContext } from 'react'
import { ModalContext } from '../../../ModalContext'
import { StripeAccContext, collectionState } from '../../contexts/StripeAccContext'
import { currencyMask } from '../../currency'
import ResetAuthorizationModal from '../modals/fee_auth/ResetAuthorizationModal'
import LinkBox from './LinkBox'
import { format } from 'date-fns'
import PaymentRadioSelector from './PaymentRadioSelector'
import StripeConnectFlow from './StripeConnectFlow'

const NoUserPayment = () => {
  return (
    <>
      <h3>Your Payment</h3>
      <div className='collection-status-container'>
        <p>No payment associated with your account was submitted.</p>
      </div>
    </>
  )
}

const NotCollected = () => {
  const { userPayment } = useContext(StripeAccContext)
  const createdAtDateTime = userPayment?.created_at
  let formattedCreatedAt = null
  if (createdAtDateTime) {
    formattedCreatedAt = format(new Date(createdAtDateTime), "MM/dd/yyyy, hh:mm aaa")
  }

  return (
    <>
      <h3>You Authorized</h3>
      <div className='collection-status-container'>
        <p className='datetime light'>{formattedCreatedAt}</p>
      </div>
    </>
  )
}

const Collected = () => {
  const { userPayment } = useContext(StripeAccContext)
  const capturedAtDateTime = userPayment?.captured_at
  let formattedCapturedAt = null
  if (capturedAtDateTime) {
    formattedCapturedAt = format(new Date(capturedAtDateTime), "MM/dd/yyyy, hh:mm aaa")
  }

  return (
    <>
      <h3>Your Payment <i className='success fa-regular fa-check'></i></h3>
      <div className="collection-status-container">
        <p className='datetime success'>
          <span>Collected</span>
          <br />
          {formattedCapturedAt}
        </p>
      </div>
    </>
  )
}

const Declined = () => {
  const { userPayment } = useContext(StripeAccContext)
  const declinedDateTime = userPayment?.declined_at
  const formattedDeclined = format(new Date(declinedDateTime), "MM/dd/yyyy, hh:mm aaa")
  const prettyDeclineCode = (declineCode) => {
    if (!declineCode) return

    const separateWords = declineCode.split('_')

    let capitalized = separateWords.map((word, idx) => {
      if (idx === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1)
      }
      return word
    })
    return capitalized.join(' ')
  }

  return (
    <>
      <h3>Your Payment <i className="critical fa-light fa-triangle-exclamation"></i></h3>
      <div className="collection-status-container">
        <div className="fail-reason">
          The attempted payment below failed due to: <span>{prettyDeclineCode(userPayment.decline_code)}</span>
        </div>
        <p className='datetime'>
          <span>Declined </span>{formattedDeclined}
        </p>
      </div>
    </>
  )
}



const TypeSwitch = () => {
  const { type, setType } = useContext(StripeAccContext)
  const handleClick = () => {
    const newType = type === "ach" ? "card" : "ach"
    setType(newType)
    sessionStorage.setItem("paymentMethodType", newType)
  }

  return (
    <div className='switch-type'>
      <button className='switch-type-btn' onClick={handleClick}>
        {type === "ach" ? "Use card instead" : "Use ACH transfer instead"}
      </button>
    </div>
  )
}

const PaymentStatusHeader = ({isCollected, hasBalance, isProcessing, notAuthorized}) => {
  const { feesSubmitted, userPayment } = useContext(StripeAccContext)
  return (
    <>
      {feesSubmitted && !userPayment && <NoUserPayment />}
      {(notAuthorized || isProcessing) && userPayment && <NotCollected />}
      {isCollected && userPayment && <Collected />}
      {hasBalance && userPayment && <Declined />}
    </>
  )
}

const SectionOne = ({ isCollected, hasBalance, isProcessing, notAuthorized }) => {
  const { paymentSuccess, feesSubmitted, userPayment } = useContext(StripeAccContext)
  return (
    <>
      {!paymentSuccess && !feesSubmitted && <PaymentRadioSelector />}
      {(feesSubmitted || userPayment) && (
        <PaymentStatusHeader
          isCollected={isCollected}
          hasBalance={hasBalance}
          isProcessing={isProcessing}
          notAuthorized={notAuthorized}
        />
      )}
    </>
  )
}

const SectionTwo = () => {
  const { amountWithCommission } = useContext(StripeAccContext)
  return (
    <div className='section-1'>
      <p className='title'>Amount</p>
      <p className='amount-lrg'>{currencyMask(amountWithCommission)}</p>
    </div>
  )
}

const SectionThree = () => {
  const { userPayment } = useContext(StripeAccContext)
  const type = userPayment?.type
  const last_4 = userPayment?.last_4

  return (
    <div className='section-2'>
      <p className='title'>Payment Method</p>
      <p className='account-ending'>
        {type === "card" ? "Card " : "Account "} Ending in {last_4}
      </p>
      <div className='logo'>
        <i className='pwrd-by-stripe'></i>
      </div>
    </div>
  )
}

const SectionFour = () => {
  const { setCurrentModal } = useContext(ModalContext)

  return (
    <div className='reset-authorization-link'>
      <a
        role='button'
        onClick={() => setCurrentModal(<ResetAuthorizationModal />)}
      >
        <i className='fa-regular fa-arrow-rotate-left'></i> Reset Authorization
      </a>
    </div>
  )
}

const SectionFive = ({ hasBalance, bankAccountsExist, pendingVerification }) => {
  return (
    <StripeConnectFlow
      hasBalance={hasBalance}
      bankAccountsExist={bankAccountsExist}
      pendingVerification={pendingVerification}
    />
  )
}

const SectionSix = ({isAfterCollectionForACH, pendingVerification, bankAccountsExist}) => {
  const { userPayment, feesSubmitted, type } = useContext(StripeAccContext)
  const isAch = type === 'ach'
  const preSubmissionNoPayment = !userPayment && !feesSubmitted

  return (
    <>
      {isAfterCollectionForACH && (
        <div className='ach-notice'>
          <p className='light'>
            Your payment may take up to 5 days to finish processing. Once
            complete, we will email you a receipt.
          </p>
        </div>
      )}
      {preSubmissionNoPayment && isAch && !bankAccountsExist && (
          <div className='note'>
            <span>NOTE: </span>Depending on your bank, it can take{" "}
            <span>up to 5 business days</span> to verify your account for ACH
            transfer.
          </div>
        )}
      {preSubmissionNoPayment && isAch && pendingVerification && (
        <div className='micro-deposit-note'>
          <p>Having trouble?</p>
          <p>
            <a href=''>Learn more.</a>
          </p>
        </div>
      )}
      {preSubmissionNoPayment && type && !pendingVerification && (bankAccountsExist || type === 'card') && (
        <div className='manage-account-note'>
          <p>
            To further manage your payment methods go to{" "}
            <a href='/users/settings#panel-banking_info'>My Account.</a>
          </p>
        </div>
      )}
    </>
  )
}

const AuthPaymentDetails = () => {
  const { userPayment, feesSubmitted, captureInitiatedAt, type, bankAccounts, isPendingVerification } = useContext(StripeAccContext)
    const userPaymentCollectionState = collectionState([userPayment],!!captureInitiatedAt)
    const bankAccountsExist = bankAccounts.length > 0
    const pendingVerification =
      bankAccountsExist && type === "ach" && isPendingVerification
    const isCollected = userPaymentCollectionState === "fullyCollected"
    const hasBalance = userPaymentCollectionState === "hasBalance"
    const isProcessing = userPaymentCollectionState === "processing"
    const notAuthorized = userPaymentCollectionState === "noneAuthorized"
    const isAfterCollectionForACH =
      isProcessing && userPayment?.type === "us_bank_account" && captureInitiatedAt

  return (
    <>
      <div className='authorization-details'>
        <SectionOne
          isCollected={isCollected}
          hasBalance={hasBalance}
          isProcessing={isProcessing}
          notAuthorized={notAuthorized}
        />
        {((feesSubmitted && userPayment) || userPayment) && <SectionTwo />}
        {userPayment && <SectionThree />}
        {!feesSubmitted && userPayment && <SectionFour />}
        {((!userPayment && !feesSubmitted) || hasBalance) && type && (
          <SectionFive
            hasBalance={hasBalance}
            bankAccountsExist={bankAccountsExist}
            pendingVerification={pendingVerification}
          />
        )}
        {hasBalance && <TypeSwitch />}
        {<SectionSix
          isAfterCollectionForACH={isAfterCollectionForACH}
          bankAccountsExist={bankAccountsExist}
          pendingVerification={pendingVerification}
          />}
        <LinkBox />
      </div>
    </>
  )
}

export default AuthPaymentDetails
