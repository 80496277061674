import React from 'react'

const CompletedPortions = ({numerator, denominator, label, package_id, slug}) => {
  return (
    <a
      href={`/package_hook/${package_id}/${slug}`}
      className='section-container container-small portions'
    >
      <div className='content'>
        <i className='fa-light fa-chevron-right'></i>
        <p className='tally'>
          {numerator}/{denominator}
        </p>
        <p className='tally-label'>{label}</p>
      </div>
    </a>
  )
}

export default CompletedPortions
