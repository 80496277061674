import React, {useContext, useEffect, useState} from 'react'
import { ModalContext } from '../../../ModalContext'
import { StripeAccContext } from "../../contexts/StripeAccContext"
import { stripe } from '../../../Stripe'
import { setupBankAccountApi } from '../../api'
import StripeMandateModal from '../../../../../../javascript/components/utility/stripe_mandate_modal'
import PaymentConnectModal from '../modals/stripe/PaymentConnectModal'
import Verified from '../modals/stripe/Verified'
import PaymentsDropdown from './PaymentsDropdown'
import ActionRequired from '../../../../../../javascript/plugins/plugins/leases_plugin/javascripts/fees/components/modals/stripe/ActionRequired'
import AccountRemoved from '../modals/stripe/AccountRemoved'
import DropdownCTA from './DropdownCTA'

const GetStartedWithStripe = () => {
  const { setCurrentModal } = useContext(ModalContext)
  return (
    <>
      <button
        className='stripe-get-started'
        onClick={() => setCurrentModal(<PaymentConnectModal />)}
      >
        <div>Get started with</div>
        <i className='stripe-wordmark'></i>
      </button>
    </>
  )
}

const PaymentContainer = ({ pendingVerification }) => {

  return (
    <>
      <div className='dropdown-container'>
        <PaymentsDropdown />
        <DropdownCTA
          pendingVerification={pendingVerification}
        />
      </div>

    </>
  )
}

const StripeConnectFlow = ({hasBalance, bankAccountsExist, pendingVerification}) => {
  const {
    setNewPaymentInfoAdded,
    setOpenInstantVerification,
    openInstantVerification,
    setBankAccounts,
    type,
  } = useContext(StripeAccContext)
  const {setCurrentModal} = useContext(ModalContext)
  const [modalCanceled, setModalCanceled] = useState(false)

  useEffect(() => {
    if (modalCanceled) {
      setCurrentModal(<AccountRemoved />)
      setModalCanceled(!modalCanceled)
    }
  }, [modalCanceled])

  const setupBankAccount = async () => {
    try {
      setOpenInstantVerification(false)
      const {secret} = await setupBankAccountApi()
      collectBankAccountForSetup(secret, (intentID) => confirmBankAccountSetup(intentID))
    } catch (error) {
      console.error(error)
    }
  }

  const collectBankAccountForSetup = async (clientSecret, onMandateAccepted) => {
    const clientPaymentDetails = {
      clientSecret,
      params: {
        payment_method_type: "us_bank_account",
        payment_method_data: {
          billing_details: {
            name: "applicant",
            email: "applicant.bptest+test_email@gmail.com",
          },
        },
        payment_method_options: {
          us_bank_account: {
            financial_connections: {
              permissions: ["payment_method", "balances"],
            },
          },
        },
      },
    }
    const { setupIntent, error } = await stripe().collectBankAccountForSetup(
      clientPaymentDetails
    )

    if (error) {
      console.log(error.message)
    } else if (setupIntent.status === "requires_payment_method") {
      // Customer canceled the Connections modal. Present them with other
      // payment method type options.
    } else if (setupIntent.status === "requires_confirmation") {
      // We collected an account - possibly instantly verified, but possibly
      // manually-entered. Display payment method details and mandate text
      // to the customer and confirm the intent once they accept
      // the mandate.
      setCurrentModal(
        <StripeMandateModal
          setCurrentModal={setCurrentModal}
          onMandateAccepted={() => onMandateAccepted(setupIntent.id)}
          onModalCancel={() => setModalCanceled(true)}
        />
      )
    }
  }

  const confirmBankAccountSetup = async (intentID) => {
    const response = await fetch("/users/show_setup_intent?" + new URLSearchParams({setup_intent_id: intentID}))
    const { secret } = await response.json()

    const { setupIntent, error } = await stripe().confirmUsBankAccountSetup(secret)

    if (error) {
      console.error(error.message)
    } else if (setupIntent.status === "requires_payment_method") {
      // Confirmation failed. Attempt again with a different payment method.
    } else if (
      setupIntent.status === "succeeded" ||
      setupIntent.next_action?.type === "verify_with_microdeposits"
    ) {
      // Confirmation succeeded! The account is now saved.
      createBankAccount(setupIntent)
    }
  }

  const handleVerification = (verified) => {
    setNewPaymentInfoAdded(true)
    if (verified) {
      setCurrentModal(<Verified withPaymentSelect={!hasBalance}/>)
    } else {
      setCurrentModal(<ActionRequired />)
    }
  }

  const createBankAccount = async (result) => {
    const response = await fetch("/users/confirm_setup_intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ setup_intent_id: result.id }),
    })
    const { verified, bank_accounts } = await response.json().catch(error => console.log(error))
    sessionStorage.removeItem('paymentMethodId') //remove this because it will be 'new-account'
    handleVerification(verified)
    setBankAccounts(bank_accounts)
  }

  useEffect(() => {
    if (openInstantVerification) setupBankAccount()
  }, [openInstantVerification])

  return (
    <>
      {!bankAccountsExist && type === "ach" ? (
        <GetStartedWithStripe />
      ) : (
        <PaymentContainer
          pendingVerification={pendingVerification}
        />
      )}
    </>
  )
}

export default StripeConnectFlow
