import React from 'react'

import BSDate from '../utility/date'

class ResetArchive extends React.Component {
  constructor(props) {
    super(props)

    let today
    if (props.share.archived_at)
      today = new Date(Date.parse(props.share.archived_at))
    else {
      today = new Date()
      today.setDate(today.getDate() + 60)
    }

    this.state = {}
    this.state.date = today

    this.changeDate = this.changeDate.bind(this)
    this.clickSubmit = this.clickSubmit.bind(this)
  }

  componentDidMount() {
    this.modal = $(this.refs.modal)
  }

  componentWillUnmount() {
    delete this.modal
  }

  open() {
    this.modal.modal("show")
  }

  month() {
    return this.state.date.getMonth() + 1
  }

  day() {
    return this.state.date.getDate()
  }

  year() {
    return this.state.date.getFullYear()
  }

  changeDate(date) {
    this.setState({date: new Date(date.year, date.month - 1, date.day, 0, 0, 0)})
  }

  formData() {
    return { archived_at: this.year() + "-" + (this.month() < 10 ? "0" : "") + this.month() + "-" + (this.day() < 10 ? "0" : "") + this.day() }
  }

  clickSubmit() {
    const swal_err_opts = { 
      title: "Oops!",
      text: "There was a problem resetting your Auto-Archive Date",
      type: "error"
    }

    const ajax_opts = {
      url: BoardShare.Constants.reset_archive_path.replace(":id", this.props.share.id),
      type: "post",
      data: hashToPostQueryString({share: this.formData()}),
      success: res => this.success(res),
      error: () => swal(swal_err_opts)    
    }

    $.ajax(ajax_opts)
  }

  success(res) {
    $(".share-status .days-left").html(res.days_left)
    $(".share-status .archived-at").html(res.share.archived_at)
    this.modal.modal("hide")
  }

  getError() {
    if (this.state.error)
      return this.state.error

    const today = new Date()
    if (this.state.date.getTime() <= new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0).getTime())
      return "This date must be in the future."

    return false
  }

  renderDescription() {
    return <p>
      BoardShares automatically archive {this.props.default_archive_period || 60} days after sharing with any Board Member. 
      You can reset the auto-archive date for this BoardShare below.
    </p>
  }

  renderError() {
    const error = this.getError()
    return error ? <div className="error">{error}</div> : ""
  }

  render() {
    const primary_button_classes = ["btn", "btn-primary"]
    if (this.getError())
      primary_button_classes.push("btn-disabled")

    return <div className="modal" id="boardshare-reset-archive-date" tabIndex="-1" role="dialog" ref="modal">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div>
              <h3>Reset the Auto-Archive Date</h3>
              { this.renderDescription() }
              <BSDate show_days={true} key="date_picker" ref="date_picker" month={this.month()} day={this.day()} year={this.year()} onChange={this.changeDate} />
              { this.renderError() }
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn" data-dismiss="modal">Cancel</button>
            <button type="button" className={primary_button_classes.join(" ")} disabled={this.getError() ? true : false} onClick={this.clickSubmit}>Reset Auto-Archive</button>
          </div>
        </div>
      </div>
    </div>

  }
}

export default ResetArchive