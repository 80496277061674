import React from "react";
import { Provider } from "react-redux";
import { store } from "../../store";

import { BoardMinutesList } from "./BoardMinutesList";

export default (props) => (
  <Provider store={store}>
    <BoardMinutesList {...props}/>
  </Provider>
);
