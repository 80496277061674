import React, { useContext } from 'react'
import { StripeAccContext } from '../../../../contexts/StripeAccContext'
import { currencyMask, stripNumber } from '../../../../currency'
import { FeeContext } from '../../../../contexts/FeeContext'
import { ModalContext } from '../../../../../ModalContext'
import Confirmation from './Confirmation'
import MicroTransactionVerification from '../../stripe/MicroTransactionVerification'

const Buttons = (props) => {
  const {
    closeModal,
    collapse,
    isNewAccountSelected,
    isNewCardSelected,
    isAchAccountSelected,
    setStep,
    amount,
    setTotalAmount,
  } = props
  const { isPendingVerification, setOpenInstantVerification, authorizedTotal } = useContext(StripeAccContext)
  const { addToModalQueue } = useContext(ModalContext)
  const { subTotal } = useContext(FeeContext)
  const remainingBalance = subTotal - authorizedTotal
  const disabled = amount <= 0 || remainingBalance <= 0 || amount > remainingBalance

  const setAmount = () => {
    let totalAmount = currencyMask(amount * 1.05, 'percentage')
    totalAmount = stripNumber(totalAmount, true)
    setTotalAmount(totalAmount)
  }

  const startInstantVerification = () => {
    setOpenInstantVerification(true)
    closeModal()
  }

  const startMicroDepositVerification = () => {
    setCurrentModal(<MicroTransactionVerification />)
    closeModal()
  }

  const goToAuthorizationAgreement = () => {
    if (disabled) return

    setAmount()
    addToModalQueue(<Confirmation amount={amount} />)
    closeModal()
  }

  const goToCardDetails = () => {
    if (disabled) return

    setAmount()
    setStep('card_info')
  }

  return (
    <div className='button-container'>
      <button className='cancel' onClick={closeModal}>
        Cancel
      </button>

      {isNewAccountSelected && collapse && (
        <button className='stripe-fill' onClick={startInstantVerification}>
          Get Started
        </button>
      )}

      {!isNewAccountSelected && !isNewCardSelected && !isPendingVerification && (
        <button className={`green${disabled ? ' disabled' : ''}`} onClick={goToAuthorizationAgreement}>
          Authorize
        </button>
      )}

      {isNewCardSelected && (
        <button className={`white${disabled ? ' disabled' : ''}`} onClick={goToCardDetails}>
          <>
            <strong>Next:</strong> Card Details
          </>
        </button>
      )}

      {isAchAccountSelected && collapse && isPendingVerification && (
        <button className='white' onClick={startMicroDepositVerification}>Continue</button>
      )}
    </div>
  )
}

export default Buttons
