import React from 'react'

function generateVariables() {
  const query = window.location.search.substring(1),
      pairs = query.split("&"),
      vars = {}

  let key_value
  for (let pair of pairs) {
    key_value = pair.split("=")
    vars[key_value[0]] = key_value[1]
  }

  return vars
}

class Paginate extends React.Component {
  constructor(props) {
    super(props)
    this.variables = generateVariables()
  }

  path_with_page(page) {
    const variables = Object.assign({}, this.variables)
    variables.page = page
    return window.location.pathname + "?" + $.param(variables)
  }

  max_page() {
    return Math.max(0, parseInt((this.props.count - 1) / this.props.limit) || 0)
  }

  renderNext() {
    if (this.props.page == this.max_page())
      return <span>Next</span>

    return <a href={this.path_with_page(this.props.page + 1)}>&gt;</a>
  }

  renderPrev() {
    if (this.props.page == 0)
      return <span>Prev</span>

    return <a href={this.path_with_page(this.props.page - 1)}>&lt;</a>
  }

  renderLink(page) {
    if (page < 0 || page > this.max_page())
      return ""

    if (this.props.page == page)
      return <span key={page} className="current">{page + 1}</span>

    return <a key={page} href={this.path_with_page(page)}>{page + 1}</a>
  }

  render() {
    const pages = []

    let page = 0

    for (; page <= Math.min(2, this.props.page - 2); page++)
      pages.push(this.renderLink(page))

    if (page + 1 == this.props.page - 2)
      pages.push(page + 1)
    else if (page + 1 < this.props.page - 2)
      pages.push(<span key="break-1">...</span>)

    for (page = Math.max(0, this.props.page - 2); page <= Math.min(this.max_page(), this.props.page + 2); page++)
      pages.push(this.renderLink(page))

    if (page == this.max_page() - 3)
      pages.push(page)
    else if (page < this.max_page() - 3)
      pages.push(<span key="break-2">...</span>)

    for (page = Math.max(page, this.max_page() - 2); page <= this.max_page(); page++)
      pages.push(this.renderLink(page))

    return <div className="pagination">
      {this.renderPrev()}
      <div className="numbers">{pages}</div>
      {this.renderNext()}
    </div>
  }
}

export default Paginate