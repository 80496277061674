import React from 'react'

export const HeaderHtmls = {
  buildingDefaults: <><span>Add all fees associated with Agreement.</span> Fees may be reordered and/or waived as necessary</>,
  noFees: <><span>There are no fees to assign.</span> To begin signature collection go to the <span>Share</span> area.</>,
  readyToShare: <><span>Fees are ready to share!</span> To assign fees + begin signature collection, go to the <strong>Share area</strong>.</>,
  feesShared: <>Users with access to the agreement must authorize payment of the remaining fees.</>,
  allFeesAuthorized: <><span>All fees are authorized for payment.</span> Once Agreement is submitted, you may collect fees.</>,
  readyToCollect: <><span>Fees are ready to collect!</span><br/>To complete Agreement, scroll to bottom of the page + <strong>Collect Fees</strong>.</>,
  partiallyCollected: <><span>The fees were partially collected.</span> Payors are in progress of resolving failed payment(s). You will be notified when all funds are collected.</>,
  feesCollected: <><span>Collection of payments has been initiated.</span><br/>It may take up to 5 business days for funds to reach designated account(s)</>,
  feesAreSet: <><span>Fees are set.</span> No further action is necessary. The Processor(s) will review + verify the values set.</>,
  reviewFeesAssigned: <><span>Review fees assigned by Management.</span><br/>To request an update to a fee, message the Processor in the <strong>Chat</strong> area.</>,
  editsInProgressPre: <><span style={{fontWeight: 600, color: '#f68a41'}}>Patty Processor</span> <span>is currently setting fees.</span> You will be notified when fees are set + ready to review.</>,
  editsInProgressSeq: <><span style={{fontWeight: 600, color: '#f68a41'}}>Patty Processor</span> <span>is currently editing fees.</span> You will be notified when fees are set + ready to review.</>,
  authorizedPayment: <><span>You have authorized a payment, but there is still a balance remaining.</span></>,
  allPaymentsAuthorized: <><span>All fees listed below have been authorized for payment.</span> Once all signatures are collected, ensure everything is correct before submitting Agreement to Management for execution.</>,
  paymentNotcollected: <><span>Your payment could not be collected.</span><br/>Please resolve the issue using the tools in the right side bar as soon as possible.</>,
  otherPaymentFailure: <><span>At least one payment could not be collected.</span><br/>A notification has been sent to the responsible party to resolve the issue as soon as possible</>,
  readyToAuthorize: <><span>All fees must be authorized to submit.</span><br />After reviewing, select your preferred method to authorize a payment towards the total.</>,
  feesSubmitted: <><span>Fees listed below have been authorized for payment.</span><br/>You will be notified when the Processor collects fees.</>,
  feeCollectionInitiated: <><span>Fee Collection initiated for this Agreement.</span><br />Review your payment on the right.</>,

}
