import React from 'react'
import Row from './row'

class Modal extends React.Component {
	constructor(props){
    super(props)
    this.state = {}
    this.state.noteHeader = "Add your administrative note in the space below. These notes are for internal purposes only and will not be shared with anyone outside your organization."
    this.state.noteMessage = ""
    this.state.notes = []
    this.state.renderNotifications = false
    this.state.notificationMessage = ''
    this.state.notificationType = null
    this.state.notesLoaded = false
  }

  componentDidMount() {
    this.getPackageNotes()
    this.setupModal()

    const toolbar = {
      'font-styles': false,
      'color': false,
      'emphasis': {
        'small': false
        },
      'blockquote': false,
      'link': false,
      'image': false
    }
    this.textarea = $(this.refs.textarea)
    this.textarea.wysihtml5({toolbar, events: { "interaction": (e) => setTimeout(() => this.setNoteMessage() ,200), change: () => this.setNoteMessage() } })

    this.addNotesbtn = $(this.refs.addNotesBtn)
    this.addNotesbtn.css({'backgroundColor': '#FFF', 'color': '#A4A4A4', 'borderColor': '#A4A4A4'})
 }

  componentWillUnmount(){
    this.props.updateCount()

    if (this.props?.updateParentRenderer) {
      this.props.updateParentRenderer({package_notes: this.state.notes})
    }

  }

  getPackageNotes() {
    $.ajax({
      type: 'GET',
      url: `/package_hook/${this.props.package_id}/package_notes/get_package_notes`,
      success: response => {
        this.setState({notes: response.package_notes, notesLoaded: true})
      },
      error: err => {
      }
    })
  }

 setupModal() {
   if (!this.refs.modal || this.modal)
     return

   this.modal = $(this.refs.modal)
   this.modal.on("hidden.bs.modal", () => this.props.onClose())
   this.modal.modal("show")
 }

  eraseText(){
    this.textarea.data("wysihtml5").editor.clear()
  }

  setNoteMessage(){
    this.setState({noteMessage: this.textarea.val()})
  }

  addPackageNote(e) {
    e.preventDefault()
    const data = {message: this.state.noteMessage}

    $.ajax({
      type: 'POST',
      url: `/package_hook/${this.props.package_id}/package_notes/create_package_note`,
      data: data,
      success: response => {
        this.getPackageNotes()
        this.setState({noteMessage: '', renderNotifications: true, notificationMessage: response.message, notificationType: 'success'}, this.eraseText())
      },
      error: response => {
        this.setState({renderNotifications: true, notificationMessage: response.responseJSON.error.message, notificationType: 'danger'})
      }
    })
  }

	renderModal(){
		return(
			<div className="modal" id= "notesModal" tabIndex="-1" role="dialog" ref="modal">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className='modal-body'>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                  <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
                <h4 className="modal-title pull-left" style={{color: '#4A4A4A'}}>Notes</h4>
                <h5 className='modal-title pull-right' style={{color: '#4A4A4A'}}> {this.props.address} </h5>
                <br/>
                <h5 className='modal-title pull-right mr-5' style={{color: '#4A4A4A'}}> Unit: {this.props.unit} </h5>
            </div>
            <hr className='w-95' style={{borderColor: '#4A4A4A', margin: '1.5rem'}}/>
            <div className="modal-body">
              {this.renderNotifications()}
              {this.renderModalTextBox()}
            </div>
            <hr className='w-95' style={{borderColor: '#4A4A4A', margin: '1.5rem'}}/>
            <div className="modal-body">
              <h5>Previous Notes:</h5>
              {this.renderNoteRows()}
            </div>
          </div>
        </div>
		  </div>
		)
	}

  validateLength() {

    this.addNotesBtn = $(this.refs.addNotesBtn)

    if (this.state.noteMessage.length > 0){
      this.addNotesBtn.css({'borderColor': '#008000', 'backgroundColor': '#fff', 'color': '#008000'})
        return true
    } else{
      this.addNotesBtn.css({'backgroundColor': '#FFF', 'color': '#A4A4A4', 'borderColor': '#A4A4A4'})
      return false
    }
  }

  renderModalTextBox() {
    return (
      <form>
        <div className="form-group">
          <div>{this.state.noteHeader}</div>
          <br/>
          <textarea ref='textarea' className="form-control wysihtml5" rows="5" name='noteModalTextArea' id='noteModalTextArea' placeholder="Type your note here." onChange={(e) => this.handleChange(e)}></textarea>
        </div>
          <button type="button" ref='addNotesBtn'className="btn pull-right" onClick={(e) => this.addPackageNote(e)} disabled={!this.validateLength()} style={this.state.addNotesStyle}>Add Note</button>
          <button type="button" className="btn" data-dismiss="modal" style = {{'backgroundColor': '#FFF', 'color': '#A4A4A4', 'borderColor': '#A4A4A4'}}>Cancel</button>
      </form>
    )
  }

  renderNoteRows(){
    const notes = this.state.notes
    const notesLoaded = this.state.notesLoaded
    if ( notes.length === 0 && notesLoaded == false ) return 'Loading Notes'
    if ( notes.length === 0 && notesLoaded == true ) return 'No notes yet, but you can add one of your own above'

    return (
      notes.map((note)=>{
        return(
          <Row
          key = {note.id}
          message={note.message}
          createdAtDate = {note.created_at_date}
          createdAtTime = {note.created_at_time}
          userFulleName = {note.user_full_name}
          />
        )
      })
    )
  }

  renderNotifications() {
    if (!this.state.renderNotifications) return
    return (
        <div className={`alert alert-${this.state.notificationType}`} id="PackageNotesNotification" role="alert">
          {this.state.notificationMessage}
          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    )
  }
	render() {
		return (<div>
      {this.renderModal()}
    </div>
		)
	}
}

export default Modal
