import React from 'react'
import NoteContextProvider from './contexts/NoteContext'
import { StatusContextProvider } from '../StatusContext'
import FeeContextProvider from "../fees/contexts/FeeContext"
import StripeAccContextProvider from '../fees/contexts/StripeAccContext'
import { ModalContextProvider } from "../ModalContext"
import OverviewArea from './components/OverviewArea'
import ComposedContextProviders from '../documents/ComposedContextProviders'

const Overview = (props) => {
  const { cards, bank_accounts, all_payments, user_payment, user_id, ACH_FEE, CARD_RATE, fees_submitted, capture_initiated_at, ...rest } = props

  const providers = [
    <FeeContextProvider {...rest} />,
    <StripeAccContextProvider
      cards={cards}
      bank_accounts={bank_accounts}
      all_payments={all_payments}
      user_payment={user_payment}
      user_id={user_id}
      ACH_FEE={ACH_FEE}
      CARD_RATE={CARD_RATE}
      fees_submitted={fees_submitted}
      capture_initiated_at={capture_initiated_at} />,
    <NoteContextProvider package_id={props.package_id} user_role={props.user_role} permissions={props.permissions} />,
    <ModalContextProvider />,
    <StatusContextProvider sidebarStatuses={props.sidebar_statuses} userRoles={props.roles} permissions={props.permissions} />,
  ]

  return (
    <ComposedContextProviders providers={providers}>
      <OverviewArea
        user_role={props.user_role}
        package_id={props.package_id}
        unit={props.unit}
        address={props.address}
        timeline={props.timeline}
        participants={props.participants}
        leaseData={props.lease_data}
        feeTotal={props.feeTotal}
        authorizedFees={props.authorizedFees}
        feesConfirmedAt={props.feesConfirmedAt}
        activityFeedUpdates={props.activityFeedUpdates}
        getStartedAcknowledged={props.get_started_acknowledged}
        permissions={props.permissions}
        signatureCounts={props.signature_counts}
        allPaymentsCaptured={props.all_payments_captured}
      />
    </ComposedContextProviders>

  )
}

export default Overview
