import React from 'react'

class UpdatePhoneModal extends React.Component {

  constructor(props){
      super(props)
      this.state = {}
      this.state.newPhoneNumber = ''
  }

  componentDidMount(){
      this.setupModal()        
  }

  setupModal() {
      if (!this.refs.modal || this.modal) {
          return
      }
    
      this.modal = $(this.refs.modal)
      this.modal.on("hidden.bs.modal", () => this.props.onClose())
      this.modal.modal("show")
  }

  UpdatePhoneNumber(){
    const data = {
      phone_number: this.state.newPhoneNumber,
      document_request_id: this.props.document_id,
    }
    $.ajax({
      url: BoardMinutes.Constants.Routes.edit_phone_number,
      type: 'post',
      data: data,
      success: response => {
        this.modal.modal("toggle")
        this.props.onClose()
        this.props.updateLocalPhoneNumber(response.updated_phone_number)
      },
      error: () => {
      },
    })
  }

  validateForm(){
    return this.state.newPhoneNumber.length >= 1
  }

  render() {
    return <div className="modal" tabIndex="-1" role="dialog" ref="modal">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header text-center">
            <h2 className="modal-title text-dark">Update Phone Number</h2>
          </div>
          <div className="modal-body w-100">

            <h4 className="modal-title text-danger text-center p-5 m-5">This phone number is being used for Two-Factor Authentication.</h4>
            <h5 className="modal-title text-center pb-5">You are updating the phone number associated with the following information:</h5>
    
            <table className="table table-borderless">
              <thead className="table-dark">
                <tr>
                  <th style={{color:'#404040'}}>Review Credentials</th>
                </tr>
              </thead>
              <tbody >
                <tr>
                  <td>{this.props.requester.name}</td>
                  <td>{this.props.building.title}</td>
                </tr>
                <tr>
                  <td>{this.props.requester.email}</td>
                  <td>{this.props.building.address}, Unit: {this.props.requester.unit}</td>
                </tr>
                <tr>
                  <td className='text-danger'>{this.props.requester.phone}</td>
                  <td>{this.props.building.city}, {this.props.building.state}</td>
                </tr>
              </tbody>
            </table>
            <div className="input-section">
              <div>New Phone Number<span className='text-danger'>*</span></div>
              <input type="text"  onChange={(e)=>{this.setState({newPhoneNumber: e.target.value})}}  />
              
          </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary pull-left" data-dismiss="modal">Close</button>
            <button type="button" className="btn btn-success pull-right" onClick={() => this.UpdatePhoneNumber()} disabled={!this.validateForm()}>Update Number</button>
          </div>
        </div>
      </div>
    </div>
  }
}

export default UpdatePhoneModal