import React, { useContext, useEffect, useState } from 'react'
import { StripeAccContext } from '../../contexts/StripeAccContext'
import { ModalContext } from '../../../ModalContext'
import MicroTransactionVerification from '../modals/stripe/MicroTransactionVerification'
import PaymentProcessModals from '../modals/fee_auth/PaymentProcessModals'
import Confirmation from '../modals/fee_auth/components/Confirmation'

const DropdownCTA = ({ pendingVerification }) => {
  const { setCurrentModal, currentModal } = useContext(ModalContext)
  const { userPayment, paymentMethodId } = useContext(StripeAccContext)
  const userPaymentId = userPayment?.payment_method_id
  const isAttemptedPayment = userPaymentId == paymentMethodId
  const [buttonAttributes, setButtonAttributes] = useState({text: 'Loading...', class: '', handleClick: () => {}})

  // Retry Payment, Complete Payment, Verify Account, Authorize Fees,

  //verify account
  const openMicrotransactionVerification = () => {
    setCurrentModal(<MicroTransactionVerification/>)
  }

  //authorize fees
  const openPaymentProcessModal = (step = 'select_amount', showCloseButton) => {
    setCurrentModal(<PaymentProcessModals step={step} showCloseButton={showCloseButton}/>)
  }

  //retry payment
  const retryPayment = () => {
    setCurrentModal(<Confirmation />)
  }

  useEffect(() => {
    const changeButtonAttributes = () => {
      if (pendingVerification) {
        return {
          text: "Verify Account",
          class: "verify",
          handleClick: () => openMicrotransactionVerification(),
        }
      }

      if (userPayment) {
        if (isAttemptedPayment) {
          return {
            text: "Retry Payment",
            class: "critical",
            handleClick: () => retryPayment(),
          }
        }

        if (paymentMethodId === "new-card") {
          return {
            text: "Complete Payment",
            class: "success",
            handleClick: () => openPaymentProcessModal("card_info", true),
          }
        }

        if (paymentMethodId === "new-account") {
          return {
            text: "Complete Payment",
            class: "success",
            handleClick: () => openPaymentProcessModal(),
          }
        }

        return {
          text: "Complete Payment",
          class: "success",
          handleClick: () => retryPayment(),
        }
      }

      return {
        text: "Authorize Fees",
        class: "success",
        handleClick: () => openPaymentProcessModal(),
      }
    }

    const newButtonAttributes = changeButtonAttributes()
    setButtonAttributes(newButtonAttributes)
  }, [pendingVerification, userPayment, isAttemptedPayment, paymentMethodId])

  return (
    <>
      <button
        className={`cta-button ${buttonAttributes.class}${currentModal ? " disabled" : ""}`}
        onClick={() => buttonAttributes.handleClick()}
      >
        {buttonAttributes.text}
      </button>
    </>
  )
}

export default DropdownCTA
