import { ajax } from 'jquery'
import axios from 'lib/axios'
import { isString } from 'lodash-es'

import { addCSRF, addCSRFtoFormData } from 'lib/utilities'
import { errorFromXhr } from 'lib/utilities/api'
import { connectToEventManager } from '@deathbyjer/react-event-manager'

export function checkFormBuildStates(form_ids) {
  const url = `/forms/v3/upload_statuses?${form_ids.map(id => `form_ids[]=${id}`).join("&")}`

  return new Promise((res, rej) => axios.get(url).then(({data}) => res(data)).catch(data => console.log("Nope", data)))
}

export function startFormGeneration(package_id, files) {
  const url = `/packages/v3/${package_id}/forms`

  const data = addCSRFtoFormData(new FormData())
  files.forEach((file, index) => {
    data.append(`forms[${index}][file]`, file.file)
    data.append(`forms[${index}][name]`, file.name)
  })

  return new Promise((res, rej) => {
    axios.post(url, data).then(({data}) => res(data)).catch((xhr) => {
      console.log(xhr)
      const { status, data} = xhr.response
      if (parseInt(status / 100) == 4) {
        if (data?.error) return rej(data.error)
        if (isString(data)) return rej(data)
      }

      rej("Unable to upload file. Try again later.")
    })
  })
}

export function rebuildForm({package_id, form_ids, form_documents, removed_user_forms: removed_user_form_ids, updates}) {
  const url = `/package_hook/${package_id}/leases/documents`

  // Use the index as a key in order to maintain ordering
  const doc_entries = form_ids.map((form_id, sort_order) => [sort_order, { form_id, sort_order, document_id: form_documents[form_id], form_updates }])
  const documents = Object.fromEntries(doc_entries)
  const removed_forms = removed_user_form_ids.map(id => ({ id }))
  const form_updates = Object.entries(updates || {}).map(([id, form]) => ({ ...form, id }))

  const data = addCSRF({ documents, removed_forms, form_updates })

  return new Promise((res, rej) => {
    const success = () => res()
    const error = xhr => rej(errorFromXhr(xhr))

    $.ajax({
      url, type: "post", data,
      success, error
    })
  })
}


export function uploadUserFormDocument({package_machine_id, file, name}) {
  const url = `/forms/v3`
  const data = new FormData()

  if (name)
    data.append('form[name]', name)

  data.append('form[form_type]', 'user')
  data.append('form[pdf_file]', file)
  data.append('form[base_id]', package_machine_id)
  addCSRFtoFormData(data)

  return new Promise((res, rej) => {
    const success = data => res(data)
    const error = xhr => errorFromXhr(xhr)

    ajax({
      url, data,
      type: 'post',
      contentType: false,
      processData: false,
      success, error
    })
  })
}

export function getUserForm({package_id, form_id}) {
  const url = `/package_hook/${package_id}/leases/user_form/edit?form_id=${form_id}`


  return new Promise((res, rej) => {
    const success = data => res(data)

    ajax({
      url, success
    })
  })
}
