import React from 'react'

class Buildings extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      input: "",
      possible_buildings: [],
      selected: (this.props.previously_selected || []).concat([]),
      dropdown_open: false
    }

    this.addSelectedBuilding = this.addSelectedBuilding.bind(this)
    this.removeSelectedBuilding = this.removeSelectedBuilding.bind(this)
    this.discoveredBuildings = this.discoveredBuildings.bind(this)

    this.handleInput = this.handleInput.bind(this)
    this.handleSelectBoxClick = this.handleSelectBoxClick.bind(this)
    this.handleWindowClick = this.handleWindowClick.bind(this)
  }

  isSelectBox() {
    return this.props.is_select_box || false
  }

  componentDidMount() {
    this.reorientDiscovered()

    if (window)
      $(window).on("click", this.handleWindowClick)
  }

  componentDidUpdate() {
    this.reorientDiscovered()
  }

  componentWillUnmount() {
    if (window)
      $(window).off("click", this.handleWindowClick)
  }

  handleInput(evt) {
    this.setInput(evt.target.value)
  }

  handleSelectBoxClick(evt) {
    this.setState({dropdown_open: true})
    evt.stopPropagation()
  }

  handleWindowClick() {
    this.setState({dropdown_open: false})
  }

  setInput(input) {
    this.setState({input: input})
    this.discoverBuildings(input)
  }

  selected() {
    return this.props.selected || this.state.selected
  }

  addSelectedToState(building) {
    if (this.findSelectedBuilding(building) >= 0)
      return {}

    const buildings = this.state.selected.concat([])
    buildings.push(building)

    return {selected: buildings}
  }

  removeSelectedFromState(building) {
    const idx = this.findSelectedBuilding(building)
    if (idx == -1)
      return {}

    const buildings = this.state.selected.concat([])
    buildings.splice(idx, 1)

    return {selected: buildings}
  }

  findSelectedBuilding(building) {
    if (this.props.selected)
      return -2

    for (let idx in this.state.selected)
      if (this.state.selected[idx].id == building.id)
        return idx

    return -1
  }

  discoverBuildings(input) {
    clearTimeout(this.throttle_discover)

    if (input.match(/^\s*$/))
      return this.discoveredBuildings([])

    this.throttle_discover = setTimeout(() => {
      $.ajax({
        url: this.props.search_path + "?q=" + input,
        success: this.discoveredBuildings
      })
    }, 500)
  }

  discoveredBuildings(res) {
    const buildings = (res.buildings || []).filter(building => !this.isBuildingSelected(building))
    this.setState({possible_buildings: buildings})
  }

  isBuildingSelected(building) {
    return this.selected().some(selected_building => building.id == selected_building.id)
  }

  renderFormElements() {
    if (!this.props.name_in_form)
      return

    const form_elements = []

    for (let building of this.selected())
      form_elements.push(<input type="hidden" name={this.props.name_in_form} value={building.id} key={building.id} />)

    return form_elements
  }

  addSelectedBuilding(building) {
    const selected_with_building = this.selected().concat([building]).sort((a,b) => {
      if ((a.title || a.address) == (b.title || b.address))
        return 0
      return (a.title || a.address) < (b.title || b.address) ? -1 : 1
    })

    if (this.props.setSelectedBuildings)
      this.props.setSelectedBuildings( selected_with_building )

    const state = Object.assign({input: "", possible_buildings: [], dropdown_open: false}, this.addSelectedToState(building))
    this.setState(state)
  }

  removeSelectedBuilding(building) {
    const selected_without_building = this.selected().filter(selected_building => building.id != selected_building.id)

    if (this.props.setSelectedBuildings)
      this.props.setSelectedBuildings( selected_without_building )

    const state = this.removeSelectedFromState(building)
    if (Object.keys(state).length > 0)
      this.setState(state)
  }

  renderSelectedBuilding(building) {
    return <div className="selected-building" key={`selected_building_${building.id}`}>
      <div className="name">{building.title || building.address}</div>
      <div className="remove" onClick={() => this.removeSelectedBuilding(building)}>&times;</div>
    </div>
  }

  renderSelectedBuildings() {
    const selected = this.selected().map(building => this.renderSelectedBuilding(building))

    return <div className="selected-buildings">{selected}</div>
  }

  renderDicoveredBuilding(building) {
    let disabled_text = ""
    if (building.disabled)
      disabled_text = <div className="disabled-text">{building.if_disabled || "This building is disabled"}</div>

    const classes = []
    if (building.disabled)
      classes.push("disabled")

    return <div className={classes.join(" ")} key={`discovered-building-${building.id}`} onClick={() => building.disabled ? null : this.addSelectedBuilding(building)}>
      <div>{building.title}</div>
      {disabled_text}
    </div>
  }

  renderDiscoveredBuildings() {
    const discovered_buildings = this.state.possible_buildings.map(building => this.renderDicoveredBuilding(building)),
          selected_buildings = this.selected().map(building => this.renderSelectedBuilding(building)),
          possible_buildings = this.isSelectBox() ? selected_buildings.concat(discovered_buildings) : discovered_buildings

    if (this.isSelectBox()) {
      possible_buildings.unshift(this.renderSearchArea(false))

      if (possible_buildings.length == 1) 
        possible_buildings.push(<div key="blank">{this.state.input.match(/^\s*$/) ? "Type Above to Search" : "None Found" }</div>)
    }

    if (possible_buildings.length == 0 || !this.state.dropdown_open)
      return

    return <div className="discovered" ref="discovered" onClick={e => e.stopPropagation()}>{possible_buildings}</div>
  }

  reorientDiscovered() {
    const discovered = $(this.refs.discovered),
          attach_dropdown = $(this.refs.attach_dropdown)

    discovered.css({top: attach_dropdown.position().top + attach_dropdown.outerHeight() + 2, width: attach_dropdown.outerWidth() + "px", left: attach_dropdown.position().left })
  }

  renderSearchArea(attach_dropdown) {
    return <div className="search" ref={attach_dropdown ? "attach_dropdown" : null} key="search_area" onClick={this.handleSelectBoxClick}>
      <input type="text" value={this.state.input} onInput={this.handleInput} />
      <i className="fa-light fa-magnifying-glass" />
    </div>
  }

  renderSelectBox() {
    const number_selected = this.selected().length
    return <div className="building-selection is-select-box">
      <div className="select-box" ref="attach_dropdown" onClick={this.handleSelectBoxClick}>
        <span>{number_selected == 0 ? "No" : number_selected} Building{number_selected == 1 ? "" : "s"} Selected</span>
        <i className="fa fa-caret-down" />
      </div>
     {this.renderDiscoveredBuildings()}
     {this.renderFormElements()}
    </div>
  }

  renderInputBox() {
    return <div className="building-selection is-input-box">
     {this.renderSelectedBuildings()}
     {this.renderSearchArea(true)}
     {this.renderDiscoveredBuildings()}
     {this.renderFormElements()}
    </div>
  }

  render() {
    return this.isSelectBox() ? this.renderSelectBox() : this.renderInputBox()
  }
}

export default Buildings