import React from 'react'

import ResetArchive from './reset_archive'

 class Actions extends React.Component {
  constructor(props) {
    super(props)

    this.clickArchive = this.clickArchive.bind(this)
    this.clickCancel = this.clickCancel.bind(this)
    this.clickIncreaseTime = this.clickIncreaseTime.bind(this)
    this.clickUndoArchive = this.clickUndoArchive.bind(this)
  }

  swalOptions(type, text, button, button_color) {
    const swal_options = { 
      title: "Are you sure?", 
      text: text,
      type: type,
      showCancelButton: true,
      cancelButtonText: "Nevermind",
      confirmButtonText: button
    }

    if (button_color)
      swal_options.confirmButtonColor = button_color

    return swal_options
  }

  errorOptions(error_text) {
    return { 
      title: "Oops!",
      text: error_text || "There was a problem",
      type: "error"
    }

  }

  clickArchive() {
    const swal_options = this.swalOptions("warning", `Pressing the "Archive" button below will immediately archive this share`, "Archive"),
          swal_err_options = this.errorOptions("There was a problem archiving this share")

    const ajax_opts = {
      url: BoardShare.Constants.archive_path.replace(":id", this.props.share.id),
      type: "post",
      data: hashToPostQueryString({}),
      success: () => window.location.reload(),
      error: () => setTimeout(() => swal(swal_err_options), 500)
    }

    swal(swal_options, confirm => confirm ? $.ajax(ajax_opts) : null)

  }

  clickCancel() {
    const swal_options = this.swalOptions("error", `Typing "Cancel" in the box and then pressing the "Cancel" button below will cancel the share. This action cannot be reversed.`, 
                                          "Cancel this Share", "#F27474"),
          swal_err_options = this.errorOptions("There was a problem canceling this share")

    swal_options.type = "input"
    swal_options.inputPlaceholder = `Type "Cancel" to cancel this BoardShare`

    const ajax_opts = {
      url: BoardShare.Constants.cancel_path.replace(":id", this.props.share.id),
      type: "delete",
      data: hashToPostQueryString({}),
      success: () => window.location = BoardShare.Constants.list_path,
      error: () => setTimeout(() => swal(swal_err_options), 500)
    }

    swal(swal_options, input => (input || "").toLowerCase() == "cancel" ? $.ajax(ajax_opts) : null)
  }

  clickUndoArchive() { 
    const swal_options = this.swalOptions("warning", `Pressing the "Restore" button below will restore the share from archived status.`, "Restore"),
          swal_err_options = this.errorOptions("There was a problem restoring this share")

    const ajax_opts = {
      url: BoardShare.Constants.unarchive_path.replace(":id", this.props.share.id),
      type: "post",
      data: hashToPostQueryString({}),
      success: () => window.location.reload(),
      error: () => setTimeout(() => swal(swal_err_options), 500)
    }

    swal(swal_options, confirm => confirm ? $.ajax(ajax_opts) : null)
  }

  clickIncreaseTime(evt) {
    evt.preventDefault()

    this.refs.reset_modal.open()
  }

  renderArchive() {
    if (this.props.share.is_archived)
      return <button className="btn btn-block btn-warning" onClick={this.clickUndoArchive}>Un-Archive</button> 

    const output = [
      <button className="btn btn-block btn-warning" onClick={this.clickArchive} key="archive">Archive</button>
    ]

    if (this.props.share.archived_at) {
      output.push(<ResetArchive key="reset-modal" ref="reset_modal" share={this.props.share} default_archive_period={this.props.default_archive_period} />)
      output.push(<a href="#" onClick={this.clickIncreaseTime} key="reset_archive">Extend Auto Archive</a>)
    }

    return output
  }

  render() {
    return <div className="buttons">
      {this.renderArchive()}
      <button className="btn btn-block btn-cancel" onClick={this.clickCancel}>Cancel</button>
    </div>
  }
}

export default Actions