import React from 'react'
import { StatusContextProvider } from '../StatusContext'
import FeeContextProvider from "../fees/contexts/FeeContext"

const Chat = (props) => {
  return (
    // <FeeContextProvider {...props}>
      // <StatusContextProvider sidebarStatuses={props.sidebar_statuses} userRoles={props.user_roles} permissions={props.permissions}>
        <div></div>
      // </StatusContextProvider>
    // </FeeContextProvider>
  )
}

export default Chat
