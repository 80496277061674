import React from 'react'
import {assignIntoNamespace} from 'lib/utilities'

class View extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.url = props.url
    this.state.loading = !props.url
    this.state.error = null

    this.lookup = this.lookup.bind(this)
    this.success = this.success.bind(this)
    this.error = this.error.bind(this)
  }

  componentDidMount() {
    this.el = $(this.refs.container)
    this.el.data("react", this)

    View.Instance = this
    if (this.props.start_loading && !this.props.url)
      this.lookup()
  }

  componentWillUnmount() {
    this.el.data("react", null)
    delete this.el
  }

  isNotHidden() {
    return this.el && this.el.closest(".hidden").length == 0
  }

  setUrl(url) {
    const state = {}

    state.loading = !!!url
    state.url = url ? url : null

    this.setState(state)

    const object = this.el.find("object")
    object.after(object.clone(1,1))
    object.remove()
  }

  getUrl() {
    if (this.state.loading)
      return null

    return this.state.url //+ "#view=FitW"
  }

  reset() {
    this.setState({loading: true, url: null})

    if (this.isNotHidden())
      this.lookup()
  }

  resetAndLookup() {
    this.reset()
    this.lookup()
  }

  lookup() {
    if (!this.state.loading)
      return

    $.ajax({
      url: BoardShare.Constants.preview_path.replace(":id", this.props.share_id),
      success: this.success,
      error: this.error
    })
  }

  success(data) {
    console.log(data)
    this.setState({error: null})

    if (data.generating)
      setTimeout(this.lookup, 5000)

    this.setUrl(data.pdf)
  }

  error(xhr) {
    const errors = JSON.parse(xhr.responseText),
          state = {}

    state.error = errors.error
    this.setState(state.error) 
  }

  renderLoading() {
    if (!this.state.loading)
      return

    return <div className="loading">
      <div className="spinner-border">
      </div> 
    </div>
  } 

  renderFullScreenButton() {
    return <div className="full-screen-view">
      <a href="btn btn-sm" href={this.getUrl()} target="_blank">
        Click here for full screen view <i className="fa-light fa-magnifying-glass"></i>
      </a>
    </div>
  }

  renderPdf() {
    if (this.state.loading)
      return

    return <div>
      {this.renderFullScreenButton()}
      <object type="application/pdf" data={this.getUrl()} />
    </div>
  }

  render() {
    return <div ref="container">
      {this.renderPdf()}
      {this.renderLoading()}
    </div>
  }
}

assignIntoNamespace("BoardShare.Show.resetView", () => {
  if (View.Instance)
    View.Instance.reset()
})

assignIntoNamespace("BoardShare.Show.lookupView", () => {
  if (View.Instance)
    View.Instance.lookup()
})

export default View