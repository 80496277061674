import React from 'react'
import ComposedContextProviders from './documents/ComposedContextProviders'
import LeaseContextProvider from './share/context/LeaseContext'
import FeeContextProvider from './fees/contexts/FeeContext'
import StripeAccContextProvider from './fees/contexts/StripeAccContext'
import { ModalContextProvider } from './ModalContext'
import { StatusContextProvider } from './StatusContext'
import { Provider } from 'react-redux'

function DocumentsUnavailable(props) {
  return <div id='documents-unavailable'>
    <div className='file-slash' style={{ width: 30, height: 30 }}></div>
    <div><h4 style={{ marginBottom: 5 }}>Documents Unavailable</h4></div>
    <div style={{ color: '#898989' }}>You do not have access to view this Agreement's documents at this time.</div>
  </div>
}

export default function (props) {
  const providers = props => {
    const { cards, bank_accounts, all_payments, user_id, ACH_FEE, CARD_RATE, ...rest } = props

    return [
      <LeaseContextProvider
        electronicSigningConsentedAt={props.electronic_signing_consented_at}
        remainingRequiredSignatureIdsByRole={props.remaining_required_signature_ids_by_role}
        permissions={props.permissions}
        userRoles={props.roles}
      />,
      <FeeContextProvider {...rest} />,
      <StripeAccContextProvider
        cards={cards}
        bank_accounts={bank_accounts}
        all_payments={all_payments}
        user_id={user_id}
        ACH_FEE={ACH_FEE}
        CARD_RATE={CARD_RATE} />,
      <ModalContextProvider />,
      <StatusContextProvider sidebarStatuses={props.sidebar_statuses} userRoles={props.roles} permissions={props.permissions} />,
    ]
  }

  return <ComposedContextProviders providers={providers(props)}>
    <DocumentsUnavailable {...props} />
  </ComposedContextProviders>
}
