import React from 'react'

import Row from './response_row'
import Paginate from './paginate'

class List extends React.Component {
  constructor(props) {
    super(props)

    this.state = { document_requests: props.document_requests, count: props.count }
  }

  removeRequest(request) {
    const requests = this.state.document_requests.concat([]),
          idx = requests.indexOf(request)

    requests.splice(idx, 1)
    this.setState({document_requests: requests})

    const data = {}
    data.limit = this.props.limit
    data.page = this.props.page
    data.history = this.props.history
    data.in_view = requests.map(r => r.id)

    $.ajax({
      url: "/minutes/document_request/next",
      type: "post",
      data: hashToPostQueryString(data),
      success: res => {
        if (!res.document_request)
          return

        const requests = this.state.document_requests.concat([])
        requests.push(res.document_request)
        this.setState({document_requests: requests, count: this.state.count - 1})
      }
    })
  }

  renderRows() {
    if ((this.state.document_requests || []).length == 0)
      return ""

    const requests = this.state.document_requests.map(request => <Row key={request.id} remove={() => this.removeRequest(request)} document_request={request} history={this.props.history ? true : false} onApprove={this.props.onApprove} onDeny={this.props.onDeny} />)

    return <div className="list">{requests}</div>
  }

  renderEmpty() {
    if ((this.state.document_requests || []).length > 0)
      return ""

    if (this.props.history)
      return <div className="no-results">You have no minutes in your history</div>

    return <div className="no-results">There are no more incoming requests. <a href="/minutes/document_request?history=1">Click here</a> to view your Board Minutes history.</div>
  }

  renderPaginate() {
    if (["page", "limit", "count"].map(k => typeof(this.props[k])).includes("undefined"))
      return ""

    return <Paginate page={this.props.page} limit={this.props.limit} count={this.state.count} />
  }

  render() {
    return <div className="requests">
      {this.renderEmpty()}
      {this.renderRows()}
      {this.renderPaginate()}
    </div>
  }
}

export default List
