import React from 'react'

const Table = (props) => {
  return (
    <>
      <div className='table-container'>
        <div className='column-names t-row'>
          <div className='t-col'>Name</div>
          <div className='t-col'>Role(s)</div>
          <div className='t-col'>Account</div>
          <div className='t-col'></div>
        </div>
        <div className='rounded-border'>{props.children}</div>
      </div>
    </>
  )
}

export default Table
