import React from 'react'
import Timeline from './Timeline'
import Notes from './Notes'
import {currencyMask} from '../../fees/currency'
import AtaGlance from './AtaGlance'
import Progress from './Progress'
import CompletedPortions from './CompletedPortions'

const Summary = ({timeline, participants, leaseData, package_id, unit, address, feeTotal, authorizedFees, feesConfirmedAt, permissions, signatureCounts, allPaymentsCaptured}) => {
  const startDate = new Date(leaseData.lease_duration.start_date)

  return (
    <div className='widgets'>
      <Timeline timeline={timeline} startDate={startDate} permissions={permissions}/>
      <div className='containers'>
        {permissions.includes("resident-team-summary-tab-view") ? (
          <>
            <CompletedPortions numerator={signatureCounts.signed_required} denominator={signatureCounts.required} label='Signatures Completed' package_id={package_id} slug='documents' />
            <CompletedPortions numerator={currencyMask(authorizedFees)} denominator={currencyMask(feeTotal)} label='Fees Authorized' package_id={package_id} slug='fees' />
          </>
        ) : (
          <>
            <AtaGlance feeTotal={feeTotal} authorizedFees={authorizedFees} feesConfirmedAt={feesConfirmedAt} signatureCounts={signatureCounts} allPaymentsCaptured={allPaymentsCaptured} />
            <Notes unit={unit} address={address} package_id={package_id} />
          </>
        )}
      </div>
      <Progress participants={participants} />
    </div>
  )
}

export default Summary
