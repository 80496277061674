import React, { useContext, useState, useMemo } from 'react'
import { currencyMask } from '../../currency'
import FeeActivity from './FeeActivity'
import SetByToolTip from '../manage_fees/SetByToolTip'
import { FeeContext } from '../../contexts/FeeContext'
import { StripeAccContext } from '../../contexts/StripeAccContext'

export const BalanceInfo = () => {
  const [hovered, setHovered] = useState(false)
  return (
    <div className='balance-info'>
      <i
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
        className='fa-light fa-circle-info'
      ></i>
      {hovered && (
        <div className='hover-info-box'>
          <header>There may be a balance after collection because: </header>
          <div className='bullets'>
            <div>::</div>
            <div>ACH payments process for up to 5 days before collecting.</div>
          </div>
          <div className='bullets'>
            <div>::</div>
            <div>A payment failed and is being resolved by the payer.</div>
          </div>
        </div>
      )}
    </div>
  )
}

const BreakdownTable = () => {
  const { fees, subTotal } = useContext(FeeContext)
  const { authorizedTotal, allPayments, captureInitiatedAt } = useContext(StripeAccContext)
  const [hovered, setHovered] = useState(false)

  const balance = useMemo(() => {
    const filteredArray = allPayments.filter(p => p.declined_at)
    const totalBalance = filteredArray.reduce(
      (acc, curr) => acc + +curr.amount,
      0
    )

    return totalBalance
  }, [allPayments])

  return (
    <>
      <div className='table-title'>
        <h3>Fee Breakdown</h3>
        {/* commented out of MVP */}
        {/* <div className='set-by'>
          Confirmed by Patricia Demo{" "}
          <i
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            className='fa-light fa-clock-rotate-left'
          ></i>
          {hovered && <SetByToolTip />}
        </div> */}
      </div>

      <div className='fees-table'>
        <div className='columns'>
          <div className='column-1'>Fee Name</div>
          <div className='column-3'>Amount</div>
        </div>
        <div className='rows'>
          {fees.map(f => {
            return (
              <div className='fee-row' key={f.id}>
                <div className='column-1'>{f.name}</div>
                <div
                  className='column-3'
                  // style={{textDecoration: f.waived ? 'line-through' : 'none'}}
                >
                  {currencyMask(f.amount)}
                </div>
              </div>
            )
          })}
        </div>
        <div className='totals'>
          <div className='rows totals-rows'>
            <div className='totals-row'>
              <div className='column-2'>Total</div>
              <div className='column-3'>{currencyMask(subTotal)}</div>
            </div>
            <div className='totals-row'>
              <div className='column-2'>Total Authorized</div>
              <div className='column-3'>
                {currencyMask(authorizedTotal, true)}
              </div>
            </div>
            {captureInitiatedAt && (
              <div className='totals-row remaining-balance'>
                <div className='column-2'>
                  <BalanceInfo />
                  Balance
                </div>
                <div
                  className='column-3'
                  style={{ color: balance > 0 ? "#f68a41" : "#56a256" }}
                >
                  {currencyMask(balance)}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <FeeActivity /> */}
    </>
  )
}

export default BreakdownTable
