import React from "react";
import { Provider } from "react-redux";
import { UploadMinutesModal } from "./UploadMinutesModal";
import { store } from "../../store";

export default (props) => (
  <Provider store={store}>
    <UploadMinutesModal {...props} />
  </Provider>
);
