import React from 'react'
// TODO: Might delete this file
const DropdownField = ({options, label, name, id}) => {
  console.log(options)
  return (
    <div className='input-group'>
      <label htmlFor={label}>Routing Account</label>
      <select name={name} id={id}>
        {options.map(option=> {
          return <option value={option?.id || option}>{option?.name || option}</option>
        })}
      </select>
    </div>
  )
}

export default DropdownField