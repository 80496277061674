import React, { useRef, useEffect, useContext } from "react"
import { ModalContext } from "../../ModalContext"
import { useSelector } from "react-redux"

const AgreementShared = () => {
  const {setCurrentModal} = useContext(ModalContext)
  // const { sharedWith, notificationsSentTo } = useContext(LeaseContext)
  const firstShare = useSelector(state => state.firstShare)
  const sharedWith = useSelector(state => state.lastSharedWith)

  const ref = useRef()

  const cleanup = () => $(ref.current).modal("hide")

  useEffect(() => {
    if (!ref.current) return

    const close = () => setCurrentModal(null)
    const modal = $(ref.current)
    modal.on("hidden.bs.modal", close)
    modal.modal("show")

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  return (
    <div className='lease-fees-modal lease-share-modal'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box agreement-shared'>
          <div className='modal-icon'>
            <i className='fa-thin fa-circle-check'></i>
          </div>
          <div className='content'>
            {/* // <h3>{sharedWith.every(p => notificationsSentTo.includes(p)) ? 'Agreement Shared!' : 'Access Updated'}</h3>
            // <p>Agreement has been shared with:</p>
            // <div className='deal-parties'>
            //   {notificationsSentTo.map((person, index) => <p key={index}>{person}</p>)} */}
            <h3>{ firstShare ? 'Agreement Shared!' : 'Access Updated'}</h3>
            <p>Agreement has been shared with:</p>
            <div className='deal-parties'>
              {sharedWith.map((person, index) => <p key={index}>{person}</p>)}
            </div>
            <hr />
            <div className='next-steps'>
              <p>
                <span>Next Steps:</span> You will receive an email
                notification when the Agreement is ready to be executed + any
                fees can be collected.
              </p>
            </div>
            <div className="button-container center">
              <button className="cancel" onClick={cleanup}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AgreementShared
