import React from 'react'

import { getFromNamespace } from 'lib/utilities'

function click() {
  const instance = getFromNamespace('BoardShare.Templates.EditModal.Instance')
  if (instance)
    instance.openBlank()
}

export default function(props) {
  return <button className="btn btn-bp-green" onClick={click}>
    Add Template
  </button>
}