import React from 'react'
import { nanoid } from '@reduxjs/toolkit'
import { compact, capitalize, trim, snakeCase } from 'lodash-es'
import { uploadDocumentApi } from 'engines/board_minutes/board_minutes_list/components/UploadMinutes/store/apis';
import UploadArea from 'components/utility/upload_area'
import ResponseFilesDropdown from './response_files_dropdown'

const Icons = {
  "application/pdf": "fa fa-file-pdf-o",
  "application/vnd.ms-excel": "fa fa-file-excel-o",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "fa fa-file-excel-o",
  "application/msword": "fa fa-file-word-o",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "fa fa-file-word-o",
  "image/jpg": "fa fa-file-image-o",
  "image/jpeg": "fa fa-file-image-o",
  "image/png": "fa fa-file-image-o",
  default: "fa fa-file-o"
}

const ExtTypes = {
  "pdf": "application/pdf",
  "pdfx": "application/pdf",
  "doc": "application/msword",
  "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "xls": "application/vnd.ms-excel",
  "xlsx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "jpg": "image/jpg",
  "jpeg": "image/jpeg",
  "png": "image/png"
}

function count_of_object(obj) {
  return (typeof (obj) == "object" && obj) ? Object.keys(obj).length : 0
}

function getType(filename) {
  const ext = filename.split(".").pop()

  return ExtTypes[ext] || 'unsupported';
}

function humanize(str) {
  return capitalize(trim(snakeCase(str).replace(/_id$/, '').replace(/_/g, ' ')));
}

class Response extends React.Component {
  constructor(props) {
    super(props)

    this.state = { ...this.defaultState(), isProcessing: false }
    this.clear = this.clear.bind(this)
    this.addedFiles = this.addedFiles.bind(this)
    this.toggleDocument = this.toggleDocument.bind(this)
    this.toggleAllDocument = this.toggleAllDocument.bind(this)
    this.handleClickBackButton = this.handleClickBackButton.bind(this)
    this.handleClickNextButton = this.handleClickNextButton.bind(this)
  }

  defaultState() {
    return {
      files: [],
      time_limit: 2,
      number_of_sessions: 3,
      board_minute_view_enabled: false,
      add_to_property_minutes: false,
      replace_files: false,
      selectedDocuments: {},
      isVerifyingInput: false,
      responseParams: [],
      isUploading: false
    }
  }

  clear() {
    this.setState(this.defaultState())
  }

  componentDidMount() {
    this.modal = $(this.refs.modal)
    this.modal.on("hidden.bs.modal", this.clear)
    window.BoardMinutesResponse = this
  }

  componentWillUnmount() {
    delete window.BoardMinutesResponse
    this.modal.off("hidden.bs.modal", this.clear)
    delete this.modal
  }

  open(row, options) {
    this.row = row

    if (typeof (options) != "object")
      options = {}

    //onCloseHandler(): make visible Open/Deny buttons
    this.setState({
      reconfirm: options.reconfirm,
      files_available: options.files_available,
      confirmation_error: null,
      processing_error: null,
      onCloseHandler: () => {
        options.onCloseHandler();
      },
      board_minute_view_enabled: row.isBoardMinuteViewEnabled(),
      add_to_property_minutes: row.isBoardMinuteViewEnabled(),
    });

    if (options.hasOwnProperty('loadDocuments')) {
      options.loadDocuments().then(data => {
        this.setState({
          documents: data.documents,
          selectedDocuments: data.documents.reduce((opts, opt) => {
            return { ...opts, [opt.id]: false };
          }, {})
        });
      })
    }

    this.loadRequest(this.row.random_id()).then(() => {
      this.modal.modal("show")

      if (options.check_processing)
        setTimeout(() => this.reloadWhileProcessing(), 2000)
    })
  }

  reloadWhileProcessing() {
    const success = res => {
      const request = res.document_request

      if (request.processing) {
        this.row.setRequest(request)
        return setTimeout(() => this.reloadWhileProcessing(), 2000)
      }
      else
        this.checkProcessing(request)
    }

    this.reloadRequest().then(success)
  }

  checkProcessing(request) {
    const prev_request = this.state.previous_request || {}
    if (request.confirmation_failed_at) {
      this.state.onCloseHandler()
      this.setState({ confirmation_error: request.confirmation_error, isProcessing: false })
    } else if (request.processing_status && request.processing_status.status == "error") {
      this.state.onCloseHandler()
      this.setState({ document_request: request, processing_error: request.processing_status.error })
      this.clearErrors()
    } else if (count_of_object(request.responses) == count_of_object(prev_request.responses)) {
      this.setState({ processing_error: "There was a problem processing this request. Please try again or contact BoardPackager for more assistance." })
      this.clearErrors()
    } else {
      this.row.approve()
      this.modal.modal("hide")

      const swal_options = {
        type: "success",
        title: "Request Fulfilled!",
        text: `A secure link was shared with ${this.state.document_request.requester.email} to view the selected documents within the chosen parameters.`,
        confirmButtonColor: "#56A256",
        customClass: 'request-confirm-success'
      }

      swal(swal_options)
    }
  }

  submitUrl() {
    const path = this.state.reconfirm ? "confirm_document_request_again" : "confirm_document_request"
    return BoardMinutes.Constants.Routes[path].replace("<<ID>>", this.state.document_request.random_id)
  }

  shouldIncludeFiles() {
    if (!this.state.reconfirm)
      return true

    if (!this.state.files_available)
      return true

    return this.state.replace_files
  }

  checkSubmitting() {
    if (this.submitting)
      return true

    this.setState({ submitting: true, uploaded_percentage: 0 })
    this.submitting = true
    return false
  }

  freeSubmitting() {
    setTimeout(() => this.setState({ submitting: false, uploaded_percentage: null }), 1000)
    this.submitting = false
  }

  buildSubmitData() {
    const data = new FormData();

    if (this.shouldIncludeFiles()) {
      const responseParams = this.state.add_to_property_minutes ? this.state.responseParams : this.state.files;
      responseParams.forEach((file, index) => {
        if (file.doc) {
          data.append(`document_request[document_id_with_positions][][doc_id]`, file.doc.id);
          data.append(`document_request[document_id_with_positions][][position]`, index);
        } else {
          data.append(`document_request[files][][file]`, file.file);
          data.append(`document_request[files][][position]`, index);
          data.append(`document_request[files][][meeting_date]`, file.meeting_date);
        }
      });

      if (this.state.board_minute_view_enabled) {
        data.append("document_request[add_to_property_minutes]", this.state.add_to_property_minutes)
      }

    } else {
      data.append("reuse_files", true)
    }

    if (!this.state.reconfirm) {
      data.append("document_request[time_limit]", this.state.time_limit)
      data.append("document_request[number_of_sessions]", this.state.number_of_sessions)
    }

    return data
  }

  successfulSubmit() {
    this.shouldIncludeFiles() ? this.reloadWhileProcessing() : this.reloadRequest().then(res => this.checkProcessing(res.document_request))
    this.freeSubmitting()
  }

  submit() {
    if (this.checkSubmitting())
      return

    this.setState({ confirmation_error: null, isProcessing: true })
    const req = new XMLHttpRequest()
    req.upload.addEventListener("progress", evt => evt.lengthComputable ? this.setState({ uploaded_percentage: evt.loaded / evt.total }) : null)
    req.addEventListener("load", () => this.successfulSubmit())
    req.addEventListener("error", () => this.freeSubmitting())
    req.open('post', this.submitUrl(), true)
    req.send(prepareFormDataForSubmission(this.buildSubmitData()))
  }

  prepareFilesForSubmission() {
    if (!this.state.add_to_property_minutes) {
      return this.submit();
    };
    
    this.setState({ isUploading: true });
    const buildingId = this.row.building().id;
    const uploadFileRequests = compact(this.state.files.map((file, index) => {
      if (file.doc) return false;

      const payload = {
        buildingId: buildingId,
        documentData: file
      }
      return new Promise((resolve, reject) => {
        return uploadDocumentApi(payload).then(response => resolve({ data: response.data, index: index })).catch(e => reject(e));
      })
    }));
    const self = this;
    return Promise.all(uploadFileRequests).then(values => {
      let responseParams = [...self.state.files];
      values.forEach(value => {
        responseParams[value.index]['doc'] = { id: value.data.document.id };
      });
      self.setState({responseParams: responseParams, isUploading: false}, () => self.submit());
    }).catch(errors => {
      const messages = Object.entries(errors.response.data.error).map(error => `${humanize(error[0])} ${error[1]}`).join('\n');
      self.setState({ confirmation_error: messages, isUploading: false })
      console.error(errors);
    })
  }

  reloadRequest() {
    return this.loadRequest(this.state.document_request.random_id)
  }

  loadRequest(random_id) {
    if (this.loading_response)
      return new Promise()

    this.loading_response = true

    return new Promise((resolve, reject) => {
      const url = BoardMinutes.Constants.Routes.get_document_request.replace("<<ID>>", random_id) + "?requester&property&processing_status&responses"

      $.ajax({
        url: url,
        complete: xhr => this.loading_response = false,
        success: response => {
          const previous_request = Object.assign({}, this.state.document_request || {})
          this.setState({ document_request: response.document_request, previous_request })
          resolve(response)
        },
        error: xhr => reject(xhr)
      })
    })
  }

  clearResponse() {
    this.setState({ document_response: null, processing_error: null })
  }

  clearErrors() {
    const url = BoardMinutes.Constants.Routes.clear_errors.replace("<<ID>>", random_id)
    $.ajax({
      url: url,
      type: "post",
      data: hashToPostQueryString({})
    })
  }

  requester_name() {
    if (!this.state.document_request)
      return null

    return this.state.document_request.requester.name
  }

  last_response() {
    if (!this.state.document_request || !this.state.document_request.responses)
      return null

    const last_key = Object.keys(this.state.document_request.responses).sort().pop()

    return this.state.document_request.responses[last_key]
  }

  isProcessing() {
    return this.state.document_request && this.state.document_request.processing
  }

  showProcessingView() {
    return this.state.submitting || this.isProcessing()
  }

  addedFiles(added_files) {
    const files = this.state.files.concat([])

    for (let file of added_files)
      files.push({ id: nanoid(), meeting_date: '', ...file })

    this.setState({ files: files })
  }

  removeFile(file) {
    const files = this.state.files.concat([]),
      index = files.indexOf(file)

    files.splice(index, 1)
    this.setState({ files: files })

    if (file?.doc?.id) {
      this.setState({
        selectedDocuments: { ...this.state.selectedDocuments, [file.doc.id]: false},
      })
    }
  }

  changeDate(fileId, newDate) {
    const fileIndex = this.state.files.findIndex((file) => file.id === fileId);

    if (fileIndex === -1) return;

    const file = { ...this.state.files[fileIndex] };
    file.meeting_date = newDate;

    const files = [...this.state.files];
    files[fileIndex] = file;

    this.setState({
      files: files,
    });
  }

  moveUp(index) {
    if (index <= 0) return;

    const { files } = this.state;
    const updatedFiles = [...files];
    const itemToMove = updatedFiles.splice(index, 1)[0];
    updatedFiles.splice(index - 1, 0, itemToMove);
    this.setState({ files: updatedFiles });
  }

  moveDown(index) {
    const { files } = this.state;
    if (index >= files.length - 1) return;

    const updatedFiles = [...files];
    const itemToMove = updatedFiles.splice(index, 1)[0];
    updatedFiles.splice(index + 1, 0, itemToMove);
    this.setState({ files: updatedFiles });
  }

  renderPreviousFile(filename, index) {
    return <div key={index}>
      <i className={Icons[getType(filename)] || Icons.default} />
      <span>{filename}</span>
    </div>
  }

  renderPreviousFiles() {
    if (!this.last_response() || !this.state.files_available || (this.last_response().filenames || []).length == 0)
      return ""

    return <div className="previous-files">
      <div>File name(s):</div>
      <div className="list">{this.last_response().filenames.map((filename, index) => this.renderPreviousFile(filename, index))}</div>
    </div>
  }

  renderAskReupload() {
    if (this.showProcessingView())
      return ""

    if (!this.state.reconfirm)
      return ""

    if (this.isVerifyingInput())
      return ""

    let description = ''

    if (this.state.files_available) {
      description = 'Confirm files to renew access.'
    } else if (this.state.board_minute_view_enabled) {
      description = 'Choose files to renew access.'
    } else {
      description = 'Upload files to renew access.'
    }

    const reupload = (
      <div className="ask-reupload">
        <label htmlFor="replace_files" className="d-flex">
          <input
            id="replace_files"
            type="checkbox"
            className="black-checkbox"
            onChange={(e) => this.setState({ replace_files: e.target.checked })}
            checked={this.state.replace_files}
          />
          Choose new files?
        </label>
      </div>
    );

    return <div className="reconfirm">
      <div className="description">{description}</div>
      {/*{this.renderPreviousFiles()}*/}
      {this.state.files_available ? reupload : ""}
    </div>
  }

  renderFiles() {
    if (this.showProcessingView()) return null;

    if (this.state.reconfirm && !this.state.replace_files && this.state.files_available) return null;

    return (
      <div className="section">
        {this.renderFilesSectionTitle()}
        {this.renderFilesSectionBody()}
      </div>
    );
  }

  renderFilesSectionTitle() {
    if (this.isVerifyingInput()) return null;

    return (
      <div className="section-title">{this.state.reconfirm ? '' : '1. Choose File(s)'}</div>
    );
  }

  renderFilesSectionBody() {
    if (this.state.board_minute_view_enabled) {
      return (
        <div className='section-body'>
          {this.renderFilesDropDownFromLibrary()}
          {this.renderFilesTable()}
          {this.renderNewFilesUploadToListCheckbox()}
          {this.renderUploadArea()}
        </div>
      )
    }

    return (
      <div className='section-body'>
        {this.renderUploadArea()}
        {this.renderFilesTable()}
      </div>
    )
  }

  renderFilesDropDownFromLibrary() {
    if (!this.state.board_minute_view_enabled) return null;

    if (this.isVerifyingInput()) return null;

    return (
      <ResponseFilesDropdown
        documents={this.state.documents}
        selectedDocuments={this.state.selectedDocuments}
        onToggleDocument={this.toggleDocument}
        onToggleAll={this.toggleAllDocument}
      />
    );
  }

  renderFilesTable() {
    return (
      <div className="fulfillment-modal-files-table__wrapper">
        <table className="table table-striped fulfillment-modal-files-table">
          <thead>
            {this.renderFilesHeader()}
          </thead>
          <tbody>{this.renderFilesBody()}</tbody>
        </table>
      </div>
    )
  }

  renderFilesHeader() {
    if (this.state.board_minute_view_enabled) {
      return (
        <tr>
          <th className='col-md-4'>Meeting Date</th>
          <th className='col-md-6'>File</th>
          <th className='col-md-2'><strong>{this.state.files.length}</strong> Doc{this.state.files.length === 1 ? "" : "(s)"}</th>
        </tr>
      )
    }

    return (
      <tr>
        <th className='col-md-10'>File</th>
        <th className='col-md-2'>{this.state.files.length} Doc{this.state.files.length === 1 ? "" : "s"}</th>
      </tr>
    )
  }

  renderFilesBody() {
    if (this.state.files.length == 0) {
      return (
        <tr>
          <td colSpan={3}>
            <div className="empty">Upload file(s) to fulfill this request.</div>
          </td>
        </tr>
      )
    }

    return this.state.files.map((file, index) => this.isVerifyingInput() ? this.renderFileReadOnly(file) : this.renderFile(file, index))
  }

  renderFileReadOnly(file) {
    if (this.state.board_minute_view_enabled) {
      return (
        <tr key={file.id}>
          <td>{file.meeting_date && new Date(file.meeting_date).toLocaleDateString('en-US', { timeZone: 'UTC' })}</td>
          <td>
            <i className={Icons[file.type] || Icons.default} />
            {file.name}
          </td>
          <td></td>
        </tr>
      );
    }

    return (
      <tr key={file.id}>
        <td>
          <i className={Icons[file.type] || Icons.default} />
          {file.name}
        </td>
        <td></td>
      </tr>
    )
  }

  renderFile(file, index) {
    if (this.state.board_minute_view_enabled) {
      return (
        <tr key={file.id}>
          <td>
            {file.doc ? (
              new Date(file.meeting_date).toLocaleDateString('en-US', { timeZone: 'UTC' })
            ) : (
              <input
                type="date"
                value={file.meeting_date}
                onChange={(e) => {
                  this.changeDate(file.id, e.currentTarget.value);
                }}
              />
            )}
          </td>
          <td><i className={Icons[file.type] || Icons.default} />{file.name}</td>
          <td>
            <div className="d-flex d-flex--center">
              <button className="button-order" onClick={() => this.moveUp(index)}>
                <i className="fa-thin fa-chevron-up"></i>
              </button>
              <button className="button-order" onClick={() => this.moveDown(index)}>
                <i className="fa-thin fa-chevron-down"></i>
              </button>
              <button className="remove-file-btn" onClick={() => this.removeFile(file)}>
                <i className="fa-regular fa-xmark text--red"></i>
              </button>
            </div>
          </td>
        </tr>
      )
    }

    return (
      <tr key={file.id}>
        <td><i className={Icons[file.type] || Icons.default} />{file.name}</td>
        <td>
          <div className="d-flex d-flex--center">
            <button className="button-order" onClick={() => this.moveUp(index)}>
              <i className="fa-thin fa-chevron-up"></i>
            </button>
            <button className="button-order" onClick={() => this.moveDown(index)}>
              <i className="fa-thin fa-chevron-down"></i>
            </button>
            <button className="remove-file-btn" onClick={() => this.removeFile(file)}>
              <i className="fa-regular fa-xmark text--red"></i>
            </button>
          </div>
        </td>
      </tr>
    )
  }

  renderNewFilesUploadToListCheckbox() {
    if (this.isVerifyingInput()) return null;

    return (
      <label className='d-flex new-files-upload-to-list' htmlFor='add_to_property_minutes-input'>
        <input
          type="checkbox"
          className='black-checkbox'
          id="add_to_property_minutes-input"
          checked={this.state.add_to_property_minutes}
          onChange={() => this.setState({add_to_property_minutes: !this.state.add_to_property_minutes})}
        />
        <span>Add uploaded files to property minutes</span>
      </label>
    )
  }

  renderUploadArea() {
    if (this.isVerifyingInput()) return null;

    return (
      <UploadArea
        renderCustomIcon={() => <i className="fa-regular fa-cloud-upload" aria-hidden="true"></i>}
        text="Drop files to upload, or click to browse."
        inputChanged={this.addedFiles}
      />
    )
  }

  toggleDocument(doc_id, checked) {
    const doc = this.state.documents.find((doc) => doc.id === doc_id);

    if (!doc) return;

    const selectedDocIndex = this.state.files.findIndex((file) => file?.doc?.id === doc.id);

    if (checked) {
      if (selectedDocIndex === -1) {
        this.setState({
          files: [
            ...this.state.files,
            {
              id: nanoid(),
              doc: { id: doc.id },
              name: doc.file_identifier,
              type: getType(doc.file_identifier),
              meeting_date: doc.meeting_date,
            },
          ],
          selectedDocuments: { ...this.state.selectedDocuments, [doc_id]: checked },
        });
      }
    } else {
      if (selectedDocIndex !== -1) {
        this.setState({
          files: [
            ...this.state.files.slice(0, selectedDocIndex),
            ...this.state.files.slice(selectedDocIndex + 1),
          ],
          selectedDocuments: { ...this.state.selectedDocuments, [doc_id]: checked },
        });
      }
    }
  }

  toggleAllDocument(checked) {
    const selectedDocIds = compact(this.state.files.map(file => file?.doc?.id))

    if (checked) {
      const unSelectedDocuments = this.state.documents.filter((doc) => !selectedDocIds.includes(doc.id))

      this.setState({
        files: this.state.files.concat(
          unSelectedDocuments.map((doc) => ({
            id: nanoid(),
            doc: { id: doc.id },
            name: doc.file_identifier,
            type: getType(doc.file_identifier),
            meeting_date: doc.meeting_date,
          }))
        ),
        selectedDocuments: this.state.documents.reduce((options, option) => {
          return { ...options, [option.id]: true };
        }, {})
      })
    } else {
      this.setState({
        files: this.state.files.filter(file => !file?.doc),
        selectedDocuments: this.state.documents.reduce((options, option) => {
          return { ...options, [option.id]: false };
        }, {})
      })
    }
  }

  statusForUploading() {
    return { status: null, }
  }

  renderProgressSpinner() {
    return <i className="fa-solid fa-spinner fa-spin fa-5x" style={{color: "#56a256"}}></i>;
  }

  uploadingProgress() {
    const progress_bar = this.renderProgressSpinner();

    return { progress_bar }
  }

  translateStatus(status) {
    let the_status = status.status
    if (status.status == "success")
      the_status = "Success"
    if (status.status == "error")
      the_status = status.error

    const progress_bar = this.renderProgressSpinner();

    return { status: the_status, progress_bar }
  }

  renderProcessing() {
    let status = null;

    if (this.isProcessing())
      status = this.translateStatus(this.state.document_request.processing_status)
    else if (this.state.submitting)
      status = this.uploadingProgress()

    if (!status) return null;

    return <div className="processing">
      <div className="processing__icon d-flex d-flex--center">{status.progress_bar}</div>
      <div className="title">{this.isProcessing() ? "Generating Document..." : "Uploading"}</div>
      {status.status ? <div className="status">{status.status}</div> : ""}
    </div>
  }

  renderRequestOptions() {
    if (this.showProcessingView() || this.state.reconfirm || this.isVerifyingInput()) return null;

    const hour_options = [],
      session_options = []

    for (let i = 1; i <= 5; i++)
      hour_options.push(<option key={i} value={i}>{i}</option>)

    for (let i = 1; i <= 5; i++)
      session_options.push(<option key={i} value={i}>{i}</option>)

    return (
      <div className="section">
        <div className="section-title">2. Manage Access</div>
        <div className="section-body">
          Allow access up to{" "}
          <select value={this.state.number_of_sessions} onChange={(e) => this.setState({ number_of_sessions: e.target.value })}>
            {session_options}
          </select>{" "}
          <strong>time{this.state.number == 1 ? "" : "s"}</strong> within{" "}
          <select
            value={this.state.time_limit}
            onChange={(e) => this.setState({ time_limit: e.target.value })}
          >
            {hour_options}
          </select>{" "}
          <strong>hour{this.state.time_limit == 1 ? "" : "s"}</strong>.
        </div>
      </div>
    );
  }

  renderShowProcessingError() {
    if ((!this.state.processing_error && !this.state.confirmation_error))
      return ""

    return <div className="error-area">
      <div className="error">{this.state.processing_error}</div>
      <div className="error">{this.state.confirmation_error}</div>
    </div>
  }

  renderPreviousRequestOptionsInfo() {
    if (this.showProcessingView() || !this.state.reconfirm || this.isVerifyingInput()) return null;

    const last_response = this.last_response();
    const number_of_sessions = last_response?.number_of_sessions;
    const time_limit = last_response?.time_limit && last_response.time_limit / 3600;
    const times_str = number_of_sessions === 1 ? 'time' : 'times';
    const hours_str = time_limit === 1 ? 'hour' : 'hours';

    return (
      <div className="section reconfirm-section">
        <div className="section-body">
          Renewed access allows review of file(s) up to <strong>{number_of_sessions}</strong> {times_str} in{' '}
          <strong>{time_limit}</strong> {hours_str}.
        </div>
      </div>
    );
  }

  renderCurrentRequestOptionsInfo() {
    if (!this.isVerifyingInput() || this.showProcessingView()) return null;

    const timeLimit = this.state.time_limit;
    const numberOfSessions = this.state.number_of_sessions;
    const timesStr = numberOfSessions === 1 ? 'time' : 'times';
    const hoursStr = timeLimit === 1 ? 'hour' : 'hours';

    return (
      <div className='section section-verify-input'>
        <div className='section-body text-italic text-center'>
          The party above may view the following files up to <strong>{numberOfSessions} {timesStr}</strong> within{' '}
          <strong>{timeLimit} {hoursStr}</strong>.
        </div>
      </div>
    )
  }

  renderForm() {
    return <div className="modal-body">
      {this.renderShowProcessingError()}
      {this.renderAskReupload()}
      {this.renderCurrentRequestOptionsInfo()}
      {this.renderFiles()}
      {this.renderPreviousRequestOptionsInfo()}
      {this.renderRequestOptions()}
      {this.renderProcessing()}
    </div>
  }

  renderHeader() {
    if (this.showProcessingView()) return null;

    let title = "Board Minutes Request";

    if (this.isVerifyingInput()) {
      title = "Confirm Choices";
    } else if (this.state.reconfirm) {
      title = "Renew Board Minutes Request?";
    }

    return <div className="modal-header">
      <h2 className='modal-title'>{title}</h2>
      <div className='requests-details'>
        <div className='requests-details__building-address'>{this.state.document_request?.building?.address}</div>
        <div className='requests-details__requestor-email'>{this.state.document_request?.requester?.email}</div>
      </div>
    </div>
  }

  canSubmit() {
    if (this.state.files.length > 0)
      return true

    return !this.shouldIncludeFiles()
  }

  verifyInput() {
    this.setState({ isVerifyingInput: true, confirmation_error: null });
  }

  isVerifyingInput() {
    return this.state.isVerifyingInput || false;
  }

  isChooseNewFilesBeforeVerifyWhenReconfirm() {
    return !this.isVerifyingInput() && this.state.replace_files;
  }

  handleClickBackButton(_e) {
    this.state.isVerifyingInput ? this.setState({ isVerifyingInput: false }) : this.modal.modal("hide");
  }

  handleClickNextButton(_e) {
    if (this.state.reconfirm) {
      if (this.isChooseNewFilesBeforeVerifyWhenReconfirm()) {
        return this.verifyInput();
      }

      return this.prepareFilesForSubmission();
    }

    if (!this.isVerifyingInput()) return this.verifyInput();

    return this.prepareFilesForSubmission();
  }

  renderSubmitButtonName() {
    if (this.state.reconfirm) {
      if (this.isChooseNewFilesBeforeVerifyWhenReconfirm()) {
        return "Confirm";
      }

      return "Renew Access";
    }

    if (!this.isVerifyingInput())
      return "Confirm";

    return "Share";
  }

  renderSubmitButtonIcon() {
    if (this.state.isUploading) {
      return <i className="fa-duotone fa-spinner-third fa-spin" />;
    }

    return null;
  }

  isButtonNextDisabled() {
    return (!this.canSubmit() || this.state.submitting || this.state.isUploading);
  }

  nextButtonClasses() {
    const classes = [];

    if (this.isButtonNextDisabled()) {
      classes.push('btn-positive-secondary', 'disabled');
    } else if (this.isVerifyingInput()) {
      classes.push('btn-positive-primary');
    } else {
      classes.push('btn-positive-secondary');
    }

    return classes;
  }

  renderFooter() {
    if (this.showProcessingView()) return null;

    return <div className="modal-footer">
      <button type="button" className="btn btn-neutral-secondary" onClick={this.handleClickBackButton}>Back</button>
      <button
        type="button"
        className={this.nextButtonClasses().join(' ')}
        disabled={this.isButtonNextDisabled()}
        onClick={this.handleClickNextButton}
      >
        {this.renderSubmitButtonIcon()}
        {this.renderSubmitButtonName()}
      </button>
    </div>
  }

  render() {
    return <div id="board-minutes-response-confirmation" className="modal" tabIndex="-1" role="dialog" ref="modal">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderForm()}
          {this.renderFooter()}
        </div>
      </div>
    </div>
  }
}

export default Response
