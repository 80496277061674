import { formatDistanceToNowStrict, differenceInDays } from "date-fns"
import { enUS as locale }  from "date-fns/locale/en-US"

const buildTimestampObject = (unixTime) => {
  // in the format of '02/16/2023, 12:19 PM'
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    lowercaseMeridian: true
  }

  return {
    timestamp: new Date(unixTime).toLocaleString('en-US', options),
  }
}

export const computeElapsedTime = (unixTime, opts = {addSuffix: true, testing: 'testtt'}) => {
  const timeInMilliseconds = new Date(unixTime)
  const elapsedDays = differenceInDays(new Date(), timeInMilliseconds)

  const timeStamp = buildTimestampObject(unixTime)

  if (elapsedDays > 7) {
    timeStamp.isFullTimestamp = true
    return timeStamp
  }

  const distanceToNowString = formatDistanceToNowStrict(timeInMilliseconds, {
    ...opts,
    locale: {
      ...locale,
      formatDistance,
    }
  })

  timeStamp.isFullTimestamp = false
  timeStamp.descriptor = distanceToNowString

  return timeStamp
}

const formatDistanceLocale = {
  xSeconds: '{{count}}s',
  xMinutes: '{{count}}m',
  xHours: '{{count}}h',
  xDays: '{{count}}d',
}

const formatDistance = (token, count, options) => {
  options = options || {}
  const result = formatDistanceLocale[token].replace('{{count}}', count)

  if (options.addSuffix) return result + ' ago'

  return result
}
