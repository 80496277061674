import React, { createContext, useState, useEffect } from 'react'

const SidebarContext = createContext()

const SidebarContextProvider = props => {
  const [activeTab, setActiveTab] = useState('documents')
  const [tabStatuses, setTabStatuses] = useState({
    documents: 'Status',
    fees: 'Status',
    share: 'Status'
  })

  const [statuses, setStatuses] = useState({
    selected: false,
    unpublishedEdits: false,
    readyToShare: false,
    shared: false,
    submitted: false,
    countersigned: false,
    setInProgress: false,
    set: false,
    collected: false
  })

  useEffect(() => {
    updateTabStatusDisplay('documents', 'teststwt')
  }, [statuses])

  const updateTabStatusDisplay = (tab, status) => {
    $(`.rental-tabs[data-tab="${tab}"] .status`).text(status)
  }

  return (
    <SidebarContext.Provider value={{ activeTab, tabStatuses, setActiveTab, setTabStatuses, statuses, setStatuses }}>
      {props.children}
    </SidebarContext.Provider>
  )
}

export { SidebarContextProvider, SidebarContext }
