import React from 'react'

const FeeActivity = () => {
  return (
    <div className='fee-activity'>
      <p>Fee Activity</p>
      <div className='activity-box-outer scrollable'>
        <div className='activity-box'>
          <ul>
            <li>
              <p className='title'>Tenant</p>
              <p className='name'>Andrew Demo</p>
              <p className='status'>Bank Account Ready</p>
            </li>
            <li>
              <p className='title'>Tenant</p>
              <p className='name'>Allison Demo</p>
              <p className='status'>Verifying Account Details</p>
            </li>
          </ul>
        </div>
      </div>
      <div className='missing-notice'>
        <p>
          <span>Missing a Deal Party?</span> Go to the <span>Share</span> tab to share the agreement with
          them.
        </p>
      </div>
    </div>
  )
}

export default FeeActivity
