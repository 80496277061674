import React, { useRef, useEffect, useState, useContext } from "react"
import { ModalContext } from "../../../ModalContext"
import { centsIntegerToDollarsString } from "../../../../../../javascript/lib/utilities"


const GetStartedModal = props => {
  const ref = useRef()
  const { setCurrentModal } = useContext(ModalContext)
  const { remainingBalance, requiredSignaturesRemaining: signaturesLeft} = props
  const cleanup = () => $(ref.current).modal("hide")

  useEffect(() => {
    if (!ref.current) return

    const modal = $(ref.current)
    const close = () => setCurrentModal(null)
    modal.on("hidden.bs.modal", close)
    modal.modal({ backdrop: "static", keyboard: false })

    return () => modal.off("hidden.bs.modal", close)
  }, [])


  return <div ref={ref} className='modal fade lease-documents-get-started-modal'>
    <div className='modal-box'>
      <div className='header-section'>
        <div className='modal-icon-container'>
          <div className='handshake-icon'></div>
        </div>
        <div>
          <div className='header'>
            <h2>complete requirements</h2>
          </div>
          <p>Complete the following requirements to submit the<br></br>
          Agreement to Management.</p>
        </div>
      </div>
      <div className='body'>
        <div className="row-section" style={{ marginBottom: '24px' }}>
          <div className='row-icon'>
            <div className='signature-file-grey'></div>
          </div>
          <div className='row-text' style={{ flexGrow: 3 }}>
            <div className="row-header"><p>Sign Documents</p></div>
            <div><p>Digitally review + sign the documents. You have <span>{signaturesLeft}</span> required signature{ signaturesLeft == 1 ? "" : "s"}.</p></div>
          </div>
        </div>
        <div className="row-section">
          <div className='row-icon'>
            <div className='money-check-dollar-pen-grey'></div>
          </div>
          <div className='row-text' style={{flexGrow: 3}}>
            <div className="row-header"><p>Authorize Fees</p></div>
            <div><p>The remaining balance of <span>{centsIntegerToDollarsString(remainingBalance)}</span> must be authorized before submission.</p></div>
          </div>
        </div>
      </div>
      <div className="button-container">
        <button className='get-started-btn green' onClick={cleanup}>get started</button>
      </div>
    </div>
  </div>
}

export default GetStartedModal
