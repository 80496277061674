import React from 'react'

import FormField from './form_field'

const CONTACT_FIELDS = ['name', 'email', 'phone', 'comany_name', 'attorney_license', 'reason']
class ContactForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { errors: {} }

    CONTACT_FIELDS.forEach(field => this.state[field] = (props.contact_info || {})[field])

    if (typeof(props.onSubmit) == "function")
      this.onSubmitListener = props.onSubmit

    if (typeof(props.onBack) == "function")
      this.onBackListener = props.onBack
  }

  componentDidMount() {
    this.checkErrors()
    $(this.refs.container).find("[data-tooltip]").tooltip()
  }

  componentDidUpdate() {
    $(this.refs.container).find("[data-tooltip]").tooltip()
  }

  updateField(name, value) {
    const state = {errors: Object.assign({}, this.state.errors)}

    state[name] = $.trim(value)
    delete state.errors[name]

    this.setState(state)
  }

  canSubmit() {
    return !this.hasErrors()
  }

  onBack() {
    if (this.onBackListener)
      this.onBackListener()
  }

  onSubmit() {
    if (this.checkErrors({submitting: true}))
      return

    const contact = {}
    for (let field of CONTACT_FIELDS)
      contact[field] = this.state[field]

    if (this.onSubmitListener)
      this.onSubmitListener(contact)
  }

  missingRequiredFields() {
    return !this.state.name || !this.state.email || !this.state.confirm_email || !this.state.phone || !this.state.company_name
  }

  hasErrors() {
    return !$.isEmptyObject(this.state.errors)
  }

  checkErrors(options) {
    const errors = Object.assign({}, this.state.errors)

    if (typeof(options) != "object")
      options = {}

    if (this.state.name) {
      if (this.state.name.match(/^\s*$/))
        errors.name = "This cannot be blank"
      else
        delete errors.name
    }
    else if (options.name || options.submitting)
      errors.name = "This cannot be blank"

    if (this.state.email) {
      if (this.state.email.match(/^\s*$/))
        errors.email = "This cannot be blank"
      else if (!this.state.email.match(/^.+@.+\..+$/))
        errors.email = "This is not a valid email"
      else
        delete errors.email
    } else if (options.email || options.submitting)
      errors.email = "This cannot be blank"

    if (this.state.confirm_email) {
      if (this.state.confirm_email.match(/^\s*$/))
        errors.confirm_email = "This cannot be blank"
      else if (this.state.email != this.state.confirm_email)
        errors.confirm_email = "Emails must match!"
      else
        delete errors.confirm_email
    } else if (options.confirm_email || options.submitting)
      errors.confirm_email = "This cannot be blank"

    if (this.state.phone) {
      if (this.state.phone.match(/^\s*$/))
        errors.phone = "This cannot be blank"
      else
        delete errors.phone
    } else if (options.phone || options.submitting)
      errors.phone = "This cannot be blank"

    if (this.state.company_name) {
      if (this.state.company_name.match(/^\s*$/))
        errors.company_name = "This cannot be blank"
      else
        delete errors.company_name
    }
    else if (options.company_name || options.submitting)
      errors.company_name = "This cannot be blank"

    if (this.state.attorney_license) {
      if (this.state.attorney_license.match(/^\s*$/))
        delete errors.attorney_license
      else if (this.state.attorney_license.length < 6)
        errors.attorney_license = "Attorney License must be at least 6 characters in length"
      else
        delete errors.attorney_license
    }

    this.setState({errors: errors})
    return !$.isEmptyObject(errors)
  }

  renderPhoneDescription() {
    const tooltip_text = "The phone number provided must be able to receive text (SMS) messages for security purposes. Mobile messaging rates may apply."

    return <div className="phone-description">
      <span>Must receive TXT messages</span>
      <i className="fa fa-info-circle" data-tooltip={true} title={tooltip_text} />
    </div>
  }

  renderField(name, options = {}) {
    return <FormField name={name} 
                      defaultValue={this.state[name]} 
                      error={this.state.errors[name]} 
                      placeholder={options.placeholder} 
                      description={options.description}
                      required={options.required} onChange={value => 
                      this.updateField(name, value)} 
                      onBlur={() => this.checkErrors({[name]: true})} />
  }

  renderForm() {
    return <div className="form">
      {this.renderField("name", { placeholder: "Name", required: true})}
      {this.renderField("email", { placeholder: "Email", required: true})}
      {this.renderField("confirm_email", { placeholder: "Confirm Email", required: true})}
      {this.renderField("phone", { placeholder: "Phone", required: true, description: this.renderPhoneDescription()})}
      {this.renderField("company_name", { placeholder: "Firm"})}
      {this.renderField("attorney_license", { placeholder: "Attorney License #"})}

      <div className="input-container textarea">
        <textarea placeholder="Notes" defaultValue={this.state.reason} onBlur={() => this.checkErrors()} onChange={e => this.updateField("reason", e.target.value)}></textarea>
      </div>
    </div>
  }

  render() {
    const button_classes = ["btn", "submit-button"],
          disabled = this.missingRequiredFields() || this.hasErrors()

    if (disabled)
      button_classes.push("disabled")

    return <div className="contact" ref="container">
      {this.renderForm()}
      <div className="buttons">
        <button type="button" className="btn back" onClick={() => this.onBack()}>Back</button>
        <button type="button" className={button_classes.join(" ")} onClick={() => this.onSubmit()}>Next</button>
      </div>
    </div>
  }
}

export default ContactForm
