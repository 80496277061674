import React, { useCallback } from 'react'

import { merge } from 'lodash'

import { useDispatch, useSelector } from 'react-redux'

import getSignatureToolbar, {
  signatureIsOnPage, separateComponents, generateEmpty
} from 'components/form_creator/tools/signature'

import { useGenerateComponent } from '.'
import { setSignatureIntoStore } from '../store/form'

export function useGenerator({keepDrawing} = {}) {
  const dispatch = useDispatch()
  
  return rect => {
    const signature = merge(generateEmpty(), {  
      positions: {
        signature: rect
      }
    })

    if (keepDrawing)
      signature.newlyAdded = true


    dispatch(setSignatureIntoStore(signature))
  }
}

export function useComponents(tools, page_id) {
  const signatures = useSelector(state => Object.values(state.form.signatures).filter(sig => signatureIsOnPage(sig, page_id)))
  const selectedComponents = useSelector(state => state.general.selectedComponents)

  // Important example for component generation
  const generateComponent = useGenerateComponent(tools, selectedComponents)

  return separateComponents('signature', signatures).map(generateComponent)
}

function useApi() {
  // Generate the API
  const dispatch = useDispatch()
  const signatures = useSelector(state => state.form.signatures)

  const update = (id, componentData) => {
    dispatch(setSignatureIntoStore({ id, ...componentData }))
  }

  const create = (componentData, id) => {
    update(id, merge(generateEmpty(), componentData))
  }

  const read = id => signatures[id]

  return { create, read, update }
}

export function useTool() {
  const api = useApi()
  return getSignatureToolbar({name: 'signature', api })
}