import React from 'react'

const Note = ({recentNote}) => {
  return (
    <>
      <div className='note-details'>
        <p>{recentNote?.user_full_name}</p>
        <p>{recentNote?.created_at_date}</p>
      </div>
      <p className='note-notice'>{ recentNote ? '' : 'Notes left here will not be shared with anyone outside of your organization.'}</p>
      <p dangerouslySetInnerHTML={{ __html: recentNote?.message }}>
      </p>
    </>
  )
}

export default Note
