import React, { useCallback, useState, useEffect } from 'react'

import { configureStore } from '@reduxjs/toolkit'
import { Provider, useDispatch } from 'react-redux'
import { ajax } from 'jquery'
import { mapValues } from 'lodash'

import AssignRoles from 'components/packages/role_assignment/assign_roles'
import { reducer, load as loadToRoles, useExportUpdates } from 'components/packages/role_assignment/assign_roles/state'

import Interstitial from 'components/utility/interstitial'
import { addCSRF } from 'lib/utilities'


export const reducers = {
  roles: reducer,
}

const store = configureStore({
  reducer: { ...reducers }
})

export function useSave(package_id) {
  const fromRoles = useExportUpdates()

  //const deal_party_mapping = useSelector(({data}) => data.person_for_deal_party)
  //const new_deal_partys = useSelector(({data}) => data.deal_party_next_index )

  const url = `/packages/v3/${package_id}/role_assignment`
  const type = 'put'
  const data = addCSRF({
    form: {
      updates: JSON.stringify(mapValues(fromRoles.people, v => v[1])),
      removals: JSON.stringify(fromRoles.removals),
      deal_party_people: JSON.stringify(fromRoles.deal_party_people),
      new_parties: JSON.stringify(fromRoles.new_parties),
      user_updates: JSON.stringify(fromRoles.users),
      user_roles: JSON.stringify(fromRoles.user_roles)
      //deal_party_mapping: JSON.stringify(deal_party_mapping),
      //new_deal_partys
    }
  })

  // return () => new Promise((res, rej) => {
  //   console.log("To Save", data)
  //   res()
  // })


  return () => new Promise((res, rej) => {
    const success = data => res(data)
    const error = xhr => rej()
    ajax({
      url, type, data, dataType: 'json',
      success, error
    })
  })
}

function Main({package_id, onSave}) {
  const save = useSave(package_id)

  const handleSave = useCallback(() => {
    save().then(() => onSave ? onSave() : null)
  }, [save, onSave])

  return <>
    <div className="interstitial-header">
      <div className="container">
        <h2>Roles + Permissions</h2>
        <button className="btn btn-green" onClick={handleSave}>Assign Roles</button>
      </div>
    </div>
    <div className="interstitial-main">
      <div className="container">
        <AssignRoles />
      </div>
    </div>
  </>
}

function LoadingArea() {
  return <>
  <div className="interstitial-header">
    <div className="container"></div>
  </div>
  <div className="interstitial-main">
    <div className="container loader">
    </div>
  </div>
</>
}

function Loader(props) {
  const { package_id, onSave } = props
  const [loaded, setLoaded] = useState(false)
  const dispatch = useDispatch()

  const url = props.url || `/packages/v3/${package_id}/role_assignment`
  const editUrl = props.editUrl || `/packages/v3/${package_id}/data`

  const success = useCallback(data => {
    dispatch(loadToRoles(data))
    setLoaded(true)
  }, [package_id, dispatch, setLoaded])

  useEffect(() => {
    $.ajax({
      url, success
    })
  }, [package_id, url])

  return <>
    { loaded ? <Main package_id={package_id} onSave={onSave} /> : <LoadingArea className="loader"></LoadingArea>}
  </>
}

function RolesInterstitial(props) {
  const { package_id, onClose } = props

  return <Interstitial onClose={onClose} className="role-interstitial">
    <Provider store={store}>
      <Loader {...props} />
    </Provider>
  </Interstitial>
}

export default RolesInterstitial