import React, { useEffect, useState } from 'react'
import { computeElapsedTime } from '../time'

const Tooltip = ({elapsedTime}) => {
  return (
    <div className='activity-tooltip'>
      <div>{elapsedTime}</div>
      <div className='arrow-tip'></div>
    </div>
  )
}

const ActivityTimestamp = ({unixTimestamp}) => {
  const [isMouseHovered, setIsMouseHovered] = useState(false)
  const [showTimestamp, setShowTimestamp] = useState(false)
  const [elapsedTime, setElapsedTime] = useState(null)
  const [descriptor, setDescriptor] = useState(null)

  useEffect(() => {
    const { timestamp, descriptor, isFullTimestamp } = computeElapsedTime(unixTimestamp)
    setShowTimestamp(!isFullTimestamp)
    setElapsedTime(timestamp)
    setDescriptor(descriptor)
  }, [unixTimestamp])


  return (
    <>
      {(isMouseHovered && showTimestamp) && <Tooltip elapsedTime={elapsedTime} />}
      <p
        onMouseEnter={() => setIsMouseHovered(true)}
        onMouseLeave={() => setIsMouseHovered(false)}
        className='time-ago'
      >
        {descriptor || elapsedTime}
      </p>
    </>
  )
}

export default ActivityTimestamp
