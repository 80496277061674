import React from 'react'

import ActivityUpdateRow from './ActivityUpdateRow'
//import ActivityTimestamp from './ActivityTimestamp'
//not altering exisitng component since it will be used post beta, we can remove this simple ActivityUpdateRow
//component eventually or combine the two

//TO-DO pagination, either use react library or..

const Activity = (props) => {
  const { activityFeedUpdates, permissions } = props

  return (
    // <div className="activity-container">
    //   {activities.map((activity, index) => {
    //     return (
    //       <div className="activity-row" key={index}>
    //         <div className="left-section">
    //           <p><span>{activity.title}</span></p>
    //           <p>{activity.username} {activity.descriptor}</p>
    //         </div>
    //         <div className="right-section">
    //           <p className="time-ago">{computeElapsedTime(activity.timestamp)}</p>
    //         </div>
    //       </div>
    //     )
    //   })}
    // </div>

    // <div>
    //   {props?.activityFeedUpdates?.length > 0 ? (
    //     props.activityFeedUpdates.map((feed_update, key) => (
    //       <p key={feed_update.description}>{trimAddress(feed_update.description)}</p>
    //     ))
    //   ) : (
    //     <p>There are currently 0 activity feed updates.</p>
    //   )}
    // </div>
    //TO-DO: possibly used in post-beta

    <div className='activity-container'>
      {activityFeedUpdates?.length > 0 ? (
        activityFeedUpdates.map(feedUpdate => (
          <>
            <ActivityUpdateRow feedUpdate={feedUpdate} key={feedUpdate.id} />
          </>
        ))
      ) : <NoFeedUpdateText permissions={permissions} />}
    </div>
  )
}

function NoFeedUpdateText({ permissions }) {

  if (permissions.includes("activity-updates:all-shares"))
    return <p className="no-updates">There are currently 0 activity feed updates.</p>
  else
    return <p className="no-updates">You do not have permission to view activity feed updates.</p>

}

export default Activity
