import React, {useRef, useEffect, useState, useContext} from 'react'
import SelectAmount from './components/SelectAmount'
import CardInfoForm from './components/CardInfoForm'
import { StripeAccContext } from '../../../contexts/StripeAccContext'
import { ModalContext } from '../../../../ModalContext'

const PaymentProcessModals = (props) => {
  const { userPayment } = useContext(StripeAccContext)
  const { setCurrentModal } = useContext(ModalContext)
  const [step, setStep] = useState(props.step ?? 'select_amount')
  const [amount, setAmount] = useState(null)
  const [totalAmount, setTotalAmount] = useState(null)
  const [optionAmount, setOptionAmount] = useState(0)
  const [optionBalance, setOptionBalance] = useState(0)
  const [paymentExists, setPaymentExists] = useState(!!userPayment)
  const ref = useRef()

  const cleanup = () => $(ref.current).modal("hide")
  const getOriginalAmount = () => userPayment.amount

  useEffect(() => {
    if (!ref.current) return

    const close = () => setCurrentModal(null)
    const modal = $(ref.current)
    modal.on("hidden.bs.modal", close)
    // modal.modal("show")
    modal.modal({backdrop: 'static', keyboard: false})

    return () => modal.off("hidden.bs.modal", close)
  }, [])
  // have to re-set the amount because when modal closes, it cleans up all state
  // useEffect(() => {
  //   if (userPayment) {
  //     setPaymentExists(true)
  //     setAmount(getOriginalAmount())
  //   }
  // }, [])

  return (
    <div className='lease-fees-modal stripe-payment-process'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box payment'>
          {step === "select_amount" && (
            <SelectAmount
              closeModal={cleanup}
              setStep={setStep}
              amount={amount}
              setAmount={setAmount}
              totalAmount={totalAmount}
              setTotalAmount={setTotalAmount}
              paymentExists={paymentExists}
              setPaymentExists={setPaymentExists}
              optionAmount={optionAmount}
              setOptionAmount={setOptionAmount}
              optionBalance={optionBalance}
              setOptionBalance={setOptionBalance}
            />
          )}
          {step === "card_info" && (
            <CardInfoForm
              closeModal={cleanup}
              setStep={setStep}
              amount={amount}
              setAmount={setAmount}
              setTotalAmount={setTotalAmount}
              totalAmount={totalAmount}
              setPaymentExists={setPaymentExists}
              showCloseButton={props.showCloseButton}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default PaymentProcessModals
