import React, { useContext } from 'react'
import { FeeContext } from '../../contexts/FeeContext'
import { currencyMask } from '../../currency'

const FeeActivity = () => {
  const {subTotal} = useContext(FeeContext)
  return (
    <div className='fee-activity-container'>
      <h3 className='fee-activity-title'>Fee Activity</h3>
      <div className='fee-activity--deal-party'>
        <div className='activity-details'>
          <div>
            <p>Fees Assigned <span className='at-time'>10/19/22 10:04 am</span></p>
            <p className='info'><span className='person'>Patricia Demo</span> assigned <span className='fee'>{currencyMask(subTotal)}</span> fees to all deal parties.</p>
          </div>
          <div>
            <p className='amount'>{currencyMask(subTotal)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeeActivity
