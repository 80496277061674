import React from 'react'
import {connect} from 'react-redux'

import {assignIntoNamespace} from 'lib/utilities'

import Store from './store'
import UploadArea from './upload_area'


// Singleton for the page.

class Generate extends React.Component {

    constructor(props) {
      super(props)

      if (this.props.id)
        this.props.generateId(this.props.id)

      this.state = {}
      this.state.errors = {}
      this.state.filenames = {}
      this.state.sections = {}

      this.addMoreFiles = this.addMoreFiles.bind(this)
      this.inputFileName = this.inputFileName.bind(this)
      this.submit = this.submit.bind(this)
      this.success = this.success.bind(this)
      this.errored = this.errored.bind(this)

      this.file_check_completed_timeouts= {}
    }

    componentDidMount() {
      assignIntoNamespace("BoardShare.Show.FileUploader.Modal", this)

      this.modal = $(this.refs.modal)
      this.modal.on("show.bs.modal", () => {
        this.setState({details: {}, errors: {}})
        this.processNonUploadedFiles()
      })

      this.modal.on("hidden.bs.modal", () => this.props.clearFiles())
    }

    componentWillUnmount() {
      if (BoardShare?.Show?.FileUploader?.Modal == this)
        assignIntoNamespace("BoardShare.Show.FileUploader.Modal", null)
    }

    open() {
      this.modal.modal("show")
    }

    close() {
      this.modal.modal("hide")
    }

    isFileValid(file) {
      return BoardShare.Constants.supported_file_types.includes(typeof(file) == "object" ? file.type : file)
    }

    id() {
      return this.props.id || this.props.default_id
    }

    inputFileName(evt, file_idx) {
      const val = evt.target.value,
            filenames = Object.assign({}, this.state.filenames)

      val.match(/^\s*$/) ? (delete filenames[file_idx]) : (filenames[file_idx] = val)

      this.setState({filenames: filenames})
    }

    inputSectionSelect(evt, file_idx) {
      const val = parseInt(evt.target.value),
            sections = Object.assign({}, this.state.sections)

      this.props.sections[val] ? (sections[file_idx] = val) : (delete sections[file_idx])

      this.setState({sections: sections})
    }

    filesAsArray() {
      return Object.values(this.props.files)
    }

    filesToUpload() {
      return this.filesAsArray().filter(f => this.isFileValid(f)).map(f => f.file)
    }

    processNonUploadedFiles() {
      for (let file_id in this.props.files)
        if (this.props.files[file_id].needs_uploading) 
          this.beginFileUpload(file_id)
    }

    beginFileUpload(file_id) {
      const url = BoardShare.Constants.file_path.replace(":id", this.props.share.id).replace(":section_id", this.props.section.id),
            data = new FormData(),
            file = Object.assign({}, this.props.files[file_id])

      file.upload_status = "Uploading"
      delete file.needs_uploading
      this.props.editFile(file_id, file)

      if (!this.isFileValid(file))
        return

      data.append("share[file]", file.file)

      $.ajax({
        url: url,
        type: "post",
        data: prepareFormDataForSubmission(data),

        processData: false,
        contentType: false,
        success: res => this.uploadSuccessful(res.file, file, file_id)
      })
    }

    uploadSuccessful(uploaded_file, stored_file, old_id) {
      this.props.swapFileId(old_id, uploaded_file.id)
      this.props.editFile(uploaded_file.id, Object.assign({}, stored_file, {upload_status: "Processing", share_file: uploaded_file, children: [] }))
      this.props.addFiles([uploaded_file])
      this.props.watchProcessing(uploaded_file.library_document_id, this.props.section.id)
      BoardShare.Show.resetView()
    }

    anyChanges() {
      return Object.values(this.state.filenames).some(name => !!name)
    }

    prepareFileData() {
      const data = new FormData(),
            files = this.filesToUpload()

      for (let id in this.state.filenames)
        data.append(`share[file_details][${id}][filename]`, this.state.filenames[id])

      return prepareFormDataForSubmission(data)
    }

    submit() {
      if (!this.anyChanges())
        return this.close()

      const url = BoardShare.Constants.file_details_path.replace(":id", this.props.share.id)

      $.ajax({
        url: url,
        type: "post",
        data: this.prepareFileData(),

        processData: false,
        contentType: false,
        success: res => this.success(res.files)
      })
    }

    addMoreFiles(files) {
      this.props.moreFilesToUpload(files)
      this.processNonUploadedFiles()
    }

    success(files) {
      for (let file of files)
        this.props.replaceShareFile(file)

      BoardShare.Show.resetView()
      this.close()
    }

    errored(xhr) {
      BoardShare.Globals.processErrors(xhr, this)
    }

    getFilename(name) {
      const split_name = name.split(".")
      split_name.pop()

      return split_name.join(".")
    }

    getExtension(name) {
      const split_name = name.split(".")
      return split_name.pop()
    }

    renderSectionSelect(file_id) {

    }

    renderProgress(status, percent) {
      const classes = "progress-bar progress-bar-success progress-bar-striped active",
            percent_with_sign = percent + "%",
            integer_percent = parseInt(percent) + "%",
            styles = { width: percent_with_sign, minWidth: "25px"}

      return <div className="progress-area">
        <div className="progress">
          <div className={classes} role="progressbar" aria-valuenow={percent} aria-valuemin={0} aria-valuemax={100} style={styles}>{integer_percent}</div>
        </div>
        <div className="status">{status}</div>
      </div>
    }

    renderExtra(file, options) {
      if (options.is_parent)
        return ""

      if (options.is_infected)
        return <span className="invalid infected">Our scan detected a virus within this file</span>

      if (options.has_processing_error)
        return <span className="invalid processing-error">We could not process this file</span>

      if (file.share_file && file.share_file.processing_status)
        return this.renderProgress(file.share_file.processing_status.status, file.share_file.processing_status.percentage * 100)

      if (!this.isFileValid(file))
        return <span className="invalid">We currently do not support this filetype</span>

      if (file.upload_status)
        return <span className="upload-status">{file.upload_status}</span>

      return ""
    }
  
    renderHeader() {
      return <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal">&times;</button>
        <h4 className="modal-title">Edit & Upload Files for <u>{this.props.section.title}</u></h4>
      </div>
    }

    renderName(file, id, is_static) {
      if (!this.isFileValid(file) || is_static)
        return file.name || file.filename

      return <input type="text" defaultValue={this.state.filenames[id]} placeholder={this.getFilename(file.name || file.filename)} onInput={e => this.inputFileName(e, id)} />
    }

    renderExtension(file) {
      if (this.isFileValid(file))
        return <div className="file-extension">.{this.getExtension(file.name)}</div>

      return ""
    }

    renderCheckbox(file) {
      if (file.upload_status)
        return <div className="spinner-border"></div>

      return ""
      //return <div className="check">
      //    <input type="checkbox" defaultChecked={this.isFileValid(file)} { ...(this.isFileValid(file) ? {} : {disabled: "disabled"}) } />
      //  </div>
    }

    renderSingleFileRow(file_id, file, options) {
      const classes = ["file"]

      if (typeof(options) != "object")
        options = {}

      if (!this.isFileValid(file))
        classes.push("unsupported")

      return <div className={classes.join(" ")} key={file_id}>
        {this.renderCheckbox(file)}
        <div className="file-name">
          { this.renderName(file, file_id, options.is_parent || file.upload_status || options.is_infected || options.has_processing_error) }
        </div>
        {options.is_parent || options.is_infected || options.has_processing_error ? "" : this.renderExtension(file)}
        <div className="file-size">{BoardShare.Globals.translateSize(file.size || file.file_size)}</div>
        <div className="file-extra">{ this.renderExtra(file, options)}</div>
      </div>

    }

    renderFileRow(file, idx) {
      const share_file = file[0].share_file || {},
            options = { is_infected: share_file.infected, has_processing_error: share_file.processing_error ? true : false }

      if (file.length == 1 && !share_file.parent_file) 
        return <div key={idx} className="file-group">{this.renderSingleFileRow(share_file.id || idx, file[0], Object.assign({ is_uploading: !share_file.id}, options))}</div>

      return <div key={idx} className="file-group">
        {this.renderSingleFileRow(share_file.library_document_id, share_file.parent_file, Object.assign({ is_parent: true }, options))}
        <div className="children">
          {file.map(file => this.renderSingleFileRow(file.share_file.id, file, options))}
        </div>
      </div>
    }

    renderFiles() {
      const uploaded = [], uploading = []
      for (let file_id in this.props.uploaded_files)
        uploaded.push(this.renderFileRow(this.props.uploaded_files[file_id], file_id))

      for (let file_id in this.props.uploading_files)
        uploading.push(this.renderFileRow([this.props.uploading_files[file_id]], file_id))


      return [<div key="uploaded" className="files">{uploaded}</div>, <div key="uploading" className="files uploading-files">{uploading}</div>]
    }

    renderBody() {
      return <div className="modal-body">

        <div className="main-area">
          <div className="upload-container">
            <UploadArea inputChanged={this.addMoreFiles} />
          </div>
          {this.renderFiles()}
        </div>
      </div>
    }

    renderFooter() {
      return<div className="modal-footer">
        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary" onClick={this.submit}>Update Files</button>
      </div>
    }
  
    render() {
      return <div className="modal fade" id={this.id()} role="dialog" ref="modal">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            {this.renderHeader()}
            {this.renderBody()}
            {this.renderFooter()}
          </div>
        </div>
      </div>
    }
  }


  const mapStateToProps = state => {
    const uploaded = {}, uploading = {}
    let lib_id, file
    for (let file_id in state.presentation.upload_files) {
      file = state.presentation.upload_files[file_id]

      if (!file.share_file || !file.share_file.library_document_id) {
        uploading[file_id] = file
        continue
      }

      lib_id = file.share_file.library_document_id
      uploaded[lib_id] ? uploaded[lib_id].push(file) : (uploaded[lib_id] = [file])
    } 

    return {
      default_id: state.presentation.file_uploader_id,
      share: state.share.share || {},
      section: state.share.sections[state.presentation.upload_section_id] || {},
      sections: state.share.sections,
      uploaded_files: uploaded,
      uploading_files: uploading,
      files: state.presentation.upload_files
    }
  }

  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      generateId: id => dispatch(Store.generateFileUploaderId(id)),
      moreFilesToUpload: (files, section_id) => dispatch(Store.prepareFilesforUpload(null, files)),
      addFiles: (files) => {
        for (let file of files)
          dispatch(Store.addFile(file, file.board_share_section_id))
      },
      swapFileId: (old_id, new_id) => dispatch(Store.swapUploadedFileId(old_id, new_id)),
      editFile: (file_id, file) => dispatch(Store.editUploadedFile(file_id, file)),
      editFiles: (files) => dispatch(Store.editUploadedFiles(files)),
      clearFiles: () => dispatch(Store.clearUploadedFiles()),

      watchProcessing: (library_file_id) => dispatch(Store.addProcessingFile(library_file_id)),

      replaceShareFile: (file) => dispatch(Store.replaceFile(file))
    }
  }

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Generate)