import React, { useRef, useEffect, useState, useContext } from "react"
import { ModalContext } from "../../../../ModalContext"
import { StripeAccContext } from "../../../contexts/StripeAccContext"
import useLoader from "../../../hooks/useLoader"

const ResetAuthorizationModal = () => {
  const {setPaymentMethodId, setPaymentSuccess, setAllPayments, setUserPayment, setNewPaymentInfoAdded} = useContext(StripeAccContext)
  const { setCurrentModal } = useContext(ModalContext)
  const { loading, withLoader } = useLoader()
  const ref = useRef()

  const cleanup = () => $(ref.current).modal("hide")

  useEffect(() => {
    if (!ref.current) return

    const close = () => setCurrentModal(null)
    const modal = $(ref.current)
    modal.on("hidden.bs.modal", close)
    modal.modal({ backdrop: "static", keyboard: false })

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  const resetAuthorization = async () => {
    const response = await fetch("leases/reset_payment", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({})
    })

    const data = await response.json()
    console.log(data)
    if (data.success) {
      setNewPaymentInfoAdded(false)
      setPaymentMethodId(null)
      setPaymentSuccess(false)
      setUserPayment(null)
      setAllPayments(data.all_payments)
    }
    cleanup()
  }

  const resetWithLoader = () => {
    withLoader(resetAuthorization)
  }

  return (
    <div className='lease-fees-modal'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box payment reset-auth'>
          <div className="modal-icon">
            <i className='exclamation-circle-red'></i>
          </div>
          <h3>Are you sure?</h3>
          <div className="body">
            <p>
              This payment authorization with the account provided will be reset,
              all other fee authorizations and Agreement details will remain
              intact. <br /> <span>You will be able to authorize fees again.</span>
            </p>
            <p>
              <em>
                Note: <span>
                  If a hold was placed on your account it may take up to 7
                  business days for reversal.
                </span>
              </em>
            </p>
          </div>
          <div className="button-container">
            <button className="cancel" onClick={cleanup}>Cancel</button>
            <button className="danger reset-auth" onClick={resetWithLoader}>
              {loading ? <i
                className='fa-duotone fa-loader fa-spin-pulse'
                style={{"--fa-primary-color": '#b30000', "--fa-secondary-color": '#c9c9c9'}}
              ></i> : 'Yes, Reset'}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetAuthorizationModal
