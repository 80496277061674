import React from 'react'

const Layout = ({top,left,right, className, topClassName}) => {
  return (
    <div className={topClassName || 'fee-area'}>
      <div className={className}>
        <div className="top-section">
          {top}
        </div>
        <div className="section">
          <div className="left-section scrollable">
            {left}
          </div>
          <div className="right-section scrollable">
            {right}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Layout
