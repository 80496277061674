import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useWatchableRef } from "lib/hooks";

export function DocumentsUploadedModal(props) {
  const { open, onClose, count } = props;
  const modalRef = useWatchableRef(null);

  useEffect(() => {
    if (!modalRef.current) return;

    const localRefValue = modalRef.current;
    const handleClose = (_e) => onClose();

    $(localRefValue).on("hidden.bs.modal", handleClose);
    return () => $(localRefValue).off("hidden.bs.modal", handleClose);
  }, [onClose, modalRef.version]);

  useEffect(() => {
    if (!modalRef.current) return;

    if (open) {
      $(modalRef.current).modal("show");
    } else {
      $(modalRef.current).modal("hide");
    }
  }, [open, modalRef.version]);

  // TODO: Replace jQuery bootstrap modal with react-bootstrap modal when master-rentals branch gets merged.
  return (
    <div ref={modalRef.ref} className="modal payment-request-sent-modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content new-brp-modal">
          <div className="modal-header">
            <i className="fa-thin fa-circle-check fa-3x"></i>
            <div>
              <h3 className="modal-title -modal__title">
                {count} File{count > 1 ? "(s)" : ""} Uploaded!
              </h3>
            </div>
          </div>
          <div className="modal-body"></div>
          <div className="modal-footer">
            <button className="btn btn-neutral-secondary" data-dismiss="modal" onClick={onClose}>
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

DocumentsUploadedModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
};
