import React, { createContext, useState, useEffect, useContext, useRef } from "react"
import { FeeContext } from "./fees/contexts/FeeContext"
import { StripeAccContext } from "./fees/contexts/StripeAccContext"
import { useEventListeners } from '@deathbyjer/react-event-manager'
import { dispatchEvent } from "../../../javascript/lib/utilities"
import ReadyToSubmit from "./shared/modals/ReadyToSubmit"
import { ModalContext } from "./ModalContext"
import SubmittedConfirmation from "./shared/modals/SubmittedConfirmation"

const StatusContext = createContext()

const StatusContextProvider = ({ children, sidebarStatuses, userRoles, permissions }) => {
  const [mounted, setMounted] = useState(false)
  /*
    Status definitions
    Processors: https://docs.google.com/spreadsheets/d/1XvC8LOZzcyXDs2KMZQoJZfESmoMWdR06GeEdHzeqEag/edit#gid=1935041562
    Deal Parties: https://docs.google.com/spreadsheets/d/1Hcf64VWuxwVTFy3cJHvJsM9RVFw702VrI3--CbqmCGg/edit#gid=1935041562
  */
  const [documentsSelected, setDocumentsSelected] = useState(sidebarStatuses.documents_selected)
  const [unpublishedEdits, setUnpublishedEdits] = useState(false)
  const [readyToShare, setReadyToShare] = useState(sidebarStatuses.ready_to_share)
  const [shared, setShared] = useState(sidebarStatuses.shared)
  const [signedRequiredSignaturesCount, setSignedRequiredSignaturesCount] = useState(sidebarStatuses.signed_required_signatures_count)
  const requiredSignatureTotal = sidebarStatuses.required_signatures_count

  const [counterSigned, setCounterSigned] = useState(sidebarStatuses.all_signatures_present)
  const [feesInProgress, setFeesInProgress] = useState(false)

  const { subTotal, feesSet } = useContext(FeeContext)
  const stripeAccount = useContext(StripeAccContext)
  const { bankAccounts, isPendingVerification, authorizedTotal, feesSubmitted, paymentCollectionStatus, userPayment } = stripeAccount || {}
  const [submitted, setSubmitted] = useState(feesSubmitted)
  const remainingBalance = parseFloat(subTotal - authorizedTotal)
  const hasRemainingBalance = remainingBalance > 0

  const someRequiredSignaturesSigned = signedRequiredSignaturesCount > 0
  const allRequiredSignaturesSigned = signedRequiredSignaturesCount == requiredSignatureTotal
  const readyToSubmit = allRequiredSignaturesSigned && !hasRemainingBalance

  const { addToModalQueue } = useContext(ModalContext)

  useEffect(() => {
    updateTabsStatusDisplay()
  }, [documentsSelected, unpublishedEdits, readyToShare, shared, feesSubmitted, submitted, counterSigned, feesInProgress, feesSet, allRequiredSignaturesSigned, someRequiredSignaturesSigned, remainingBalance, authorizedTotal, bankAccounts, isPendingVerification, userRoles, shared, paymentCollectionStatus])

  useEventListeners({
    'signatureAdded': ({ required }) => required ? incrementRequiredSignaturesCount() : null,
    'signatureDeleted': ({ required }) => required ? decrementRequiredSignaturesCount() : null
  })

  const incrementRequiredSignaturesCount = () => setSignedRequiredSignaturesCount(signedRequiredSignaturesCount + 1)
  const decrementRequiredSignaturesCount = () => setSignedRequiredSignaturesCount(signedRequiredSignaturesCount - 1)

  useEffect(() => {
    setMounted(true)
  }, [mounted])

  useEffect(() => {
    // skip dispatching event from states initially being set from props
    if (!mounted)
      return

    const detail = { signedRequiredSignaturesCount: signedRequiredSignaturesCount, requiredSignatureTotal: requiredSignatureTotal }

    dispatchEvent('lease-required-signatures-changed', detail)
  }, [requiredSignatureTotal, signedRequiredSignaturesCount])

  useEffect(() => {
    const submittedAtChanged = ({ detail }) => {
      setSubmitted(true)
      addToModalQueue(<SubmittedConfirmation />)
    }

    window.addEventListener('lease-submitted-to-processors', submittedAtChanged)

    return () => window.removeEventListener('lease-submitted-to-processors', submittedAtChanged)
  },[])

  useEffect(() => {
    if (mounted && readyToSubmit && permissions.includes('submit-to-processors'))
    setTimeout(() => {
      addToModalQueue(<ReadyToSubmit />)
    }, 2000)
  }, [readyToSubmit])

  const updateTabsStatusDisplay = () => {
    if (!userRoles)
      return

    $(`.rental-tabs[data-tab="documents"] .status`).html(documentsSidebarStatus())
    $(`.rental-tabs[data-tab="fees"] .status`).html(feesSidebarStatus())
    $(`.rental-tabs[data-tab="share"] .status`).html(shareSidebarStatus())
  }

  const processorDocumentTabStatuses = () => {
    if (!documentsSelected) {
      return '<span class="status-orange">Get Started</span>'
    } else if (!readyToShare) {
      return '<span class="status-orange">Reviewing</span>'
    } else if (unpublishedEdits && !shared) {
      return '<i class="fa-light fa-triangle-exclamation status-orange"/>'
    } else if (readyToShare && !shared) {
      return '<span class="status-green">Finished</span>'
    } else if (shared && !submitted) {
      return '<i class="fa-duotone fa-spinner-third status-green"/>'
    } else if (submitted && !counterSigned) {
      return '<span class="status-orange">Review + Sign</span>'
    } else if (counterSigned) {
      return '<i class="fa-light fa-circle-check status-green"/>'
    }
  }

  const dealPartyDocumentTabStatuses = () => {
    if (submitted) {
      return ''
    } else if (allRequiredSignaturesSigned) {
      return '<i class="fa-light fa-circle-check status-green"/>'
    } else if (someRequiredSignaturesSigned) {
      return '<i class="fa-duotone fa-spinner-third status-green"/>'
    } else {
      return '<span class="status-orange">Review + Sign</span>'
    }
  }

  const documentsSidebarStatus = () => {
    if (userRoles.includes('processor')) {
      return processorDocumentTabStatuses()
    } else {
      return dealPartyDocumentTabStatuses()
    }
  }

  const feesSidebarStatus = () => {
    if (userRoles.includes('processor')) {
      return processorFeeTabStatuses()
    } else {
      return dealPartyFeeTabStatuses()
    }
  }

  const processorFeeTabStatuses = () => {
    if (paymentCollectionStatus === "fullyCollected") {
      return '<i class="fa-light fa-circle-check status-green"/>'
    } else if (paymentCollectionStatus === "processing" && paymentCollectionStatus !== 'fullyCollected') {
      return '<span class="status-other-gray">Collecting</span>'
    } else if (submitted && paymentCollectionStatus !== "processing") {
      return '<span class="status-orange">Ready to Collect</span>'
    } else if (shared && !submitted) {
      return '<i class="fa-duotone fa-spinner-third status-green"/>'
    } else if (!shared && feesSet) {
      return '<span class="status-green">Confirmed</span>'
    } else {
      return '<span class="status-orange">Confirm</span>'
    }
  }

  const dealPartyFeeTabStatuses = () => {
    if (!hasRemainingBalance && !submitted) {
      return '<i class="fa-light fa-circle-check status-green"/>'
    } else if (authorizedTotal > 0 && hasRemainingBalance) {
      return '<i class="fa-duotone fa-spinner-third status-green"/>'
    } else if (!userPayment) {
      return '<span class="status-orange">Authorize</span>'
    } else if (!bankAccounts || bankAccounts.length == 0) {
      return '<span class="status-green">Get Started</span>'
    } else return ''
  }

  const shareSidebarStatus = () => {
    if (!feesSet || !readyToShare) {
      return ''
    } else if (!shared) {
      return '<span class="status-green">Ready to Share</span>'
    } else if (shared) {
      return '<i class="fa-light fa-circle-check status-green"/>'
    }
  }

  const setters = { setDocumentsSelected, setUnpublishedEdits, setReadyToShare, setShared, setSubmitted, setCounterSigned, setFeesInProgress }
  const getters = { documentsSelected, unpublishedEdits, readyToShare, shared, submitted, counterSigned, feesInProgress, feesSet }

  return (
    <StatusContext.Provider value={{ ...setters, ...getters }}>
      {children}
    </StatusContext.Provider>
  )
}

export { StatusContext, StatusContextProvider }
