import React from 'react'
import {connect} from 'react-redux'

import Store from './store'
import Section from './section'

class Sections extends React.Component {
  constructor(props) {
    super(props)

    this.reorderSections = this.reorderSections.bind(this)
  }

  componentDidMount() {
    this.el = $(this.refs.selection_list)

    this.el.sortable({
      update: this.reorderSections,
      cancel: ".not-draggable",
      containment: "#boardshare-show-page",
      start: (evt, ui) => { $(ui.item).addClass("is-dragging")},
      stop: (evt, ui) => { $(ui.item).removeClass("is-dragging")}
    }).disableSelection()
  }

  componentWillUnmount() {
    delete this.el
  }

  reorderSections(event, ui) {
    const url = BoardShare.Constants.reorder_section_path.replace(":id", this.props.share_id),
          order = this.el.children().toArray().map(el => $(el).data("react").section().id )

    $.ajax({
      url: url,
      type: "post",
      data: hashToPostQueryString({share: { section_order: order}}),
      success: () => { 
        this.props.reorderSectionsInState(order)
        BoardShare.Show.resetView()
      }
    })
  }

  renderNoSections() {
    return <div className="no-sections">
      <div>There are no files or sections yet.</div>
      <div className="details">Please click the &quot;Add Section&quot; button below to add a section and get started.</div>
    </div>
  }

  renderSections() {
    if (this.props.sections.length == 0)
      return this.renderNoSections()

    return this.props.sections.map(section => <Section key={section.id} section={section} />)
  } 

  render() {
    return <div className="sections" ref="selection_list">
      {this.renderSections()}
    </div>
  }
}

const mapStateToProps = state => {
  return {
    share_id: state.share.share.id,
    sections: state.share.section_order.map(section_id => state.share.sections[section_id])
  }
}

const mapDispatchToProps = dispatch => {
  return {
    reorderSectionsInState: ids => dispatch(Store.reorderSections(ids))
  }
}


export default connect(mapStateToProps,
  mapDispatchToProps
)(Sections)