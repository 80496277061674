import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBoardMinutesBuildingsListState, setInitialState } from './store/slice';

import TableHead from 'components/utility/table/TableHead';
import TableBody from 'components/utility/table/TableBody';
import usePagination from 'components/utility/table/hooks/usePagination';
import useSortableTable from 'components/utility/table/hooks/useSortableTable';
import { useFilterableHeader } from './hooks';
import TableHeaderWithInputText from './TableHeaderWithInputText';
import { nanoid } from '@reduxjs/toolkit';

const PER_PAGE = 10;

export function BoardMinutesBuildingsList(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    const buildingsWithSearchTerm = props.buildings.map((building) => ({
      ...building,
      searchTerm: `${building.address} ${building.city} ${building.zip}`.toLowerCase(),
    }));
    dispatch(setInitialState({ ...props, buildings: buildingsWithSearchTerm }));
  }, []);

  const { buildings: allBuildings } = useSelector(selectBoardMinutesBuildingsListState);

  const { filteredBuildings, filterProperty } = useFilterableHeader({ buildings: allBuildings });

  const columns = useMemo(
    () => [
      {
        label: 'Property',
        sortable: false,
        accessor: 'address',
        transformFn: (property) => {
          return `${property.address}, ${property.city}, ${property.zip}`;
        },
        headerComponent: <TableHeaderWithInputText key={nanoid()} filterProperty={filterProperty} />,
      },
      {
        label: 'File Count',
        sortable: false,
        accessor: 'board_minutes_documents_count',
      },
      {
        label: 'Last Updated',
        accessor: 'board_minutes_documents_updated_at',
        transformFn: (property) => {
          if (!property['board_minutes_documents_updated_at']) {
            return 'None';
          }

          const date = new Date(property['board_minutes_documents_updated_at']);
          return date.toLocaleString();
        },
      },
    ],
    [filterProperty]
  );

  const {
    tableData: sortedBuildings,
    handleSorting: handleBuildingsSorting,
    sortField,
    sortOrder,
  } = useSortableTable(filteredBuildings, 'title');
  const { currentPageData: buildings, component: Pagination } = usePagination(sortedBuildings, PER_PAGE);

  const renderActions = useCallback((property) => {
    return (
      <a href={`/minutes/buildings/${property.id}/documents`} className="btn btn-positive-secondary">
        Manage
      </a>
    );
  }, []);

  return (
    <>
      <div id="board-minutes-buildings">
        <div className="bp-ui-table" id="dash-my-packages">
          <div className="row">
            <div className="table-responsive">
              <table className="table mobile-table-display table-striped">
                <TableHead
                  columns={columns}
                  handleItemsSorting={handleBuildingsSorting}
                  sortField={sortField}
                  sortOrder={sortOrder}
                />
                <TableBody columns={columns} items={buildings} renderActions={renderActions} />
              </table>
            </div>
          </div>
        </div>
        {filteredBuildings.length > PER_PAGE && (
          <div className="pagination-container">
            <Pagination />
          </div>
        )}
      </div>
    </>
  );
}
