import React, { useEffect, useRef, useState } from 'react'

const BoxInputs = ({setDescriptorCode, attemptedCount, handleClick, error}) => {
  const [code, setCode] = useState('')
  const [shake, setShake] = useState(false)
  const CODE_LENGTH = 4
  const inputs = useRef(Array(CODE_LENGTH).fill(null))

  useEffect(() => {
    // focus trap modal on mount
    $(".modal.fade").on('transitionend', function(){
      if (inputs.current[0]) {
        inputs.current[0].focus()
      }
    })
  }, [])

  useEffect(() => {
    if (code.length < CODE_LENGTH ) {
      setDescriptorCode(null)
    }
  }, [code])

  useEffect(() => {
    setCode('')
    inputs.current[0].focus()
  }, [attemptedCount])

  useEffect(() => {
    if (error && attemptedCount < 7){
      handleWrongInput()
    }
  }, [error])

  const handleChange = (index, value) => {
    const allowedCharacters = /^[a-zA-Z0-9\s]*$/
    if (!allowedCharacters.test(value)) return

    const newCode = [...code]
    newCode[index] = value.toUpperCase()
    setCode(newCode.join(""))

    const nextIndex = index + 1
    if (value && nextIndex < CODE_LENGTH) {
      inputs.current[nextIndex].focus()
    } else if (nextIndex === CODE_LENGTH && newCode.join('')) {
      const finalCode = `SM${newCode.join('')}`
      setDescriptorCode(finalCode)

    }
  }

  const handleKeyDown = (index, e) => {
    if (e.keyCode === 8 && !e.target.value && index > 0) {
      e.preventDefault()
      inputs.current[index - 1].focus()
      const newCode = [...code]
      newCode[index] = ""
      setCode(newCode.join(""))
    }
  }

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleClick()
    }
  }

  const handleWrongInput = () => {
    setShake(true)

    setTimeout(() => {
      setShake(false)
    }, 400)
  }

  const inputFields = [...Array(CODE_LENGTH)].map((_, index) => (
    <input
      key={index}
      className={`box-input${shake ? ' shake' : ''}`}
      type='text'
      maxLength='1'
      value={code[index] || ""}
      onChange={e => handleChange(index, e.target.value)}
      onKeyDown={(e) => handleKeyDown(index, e)}
      onKeyUp={handleKeyUp}
      ref={el => inputs.current[index] = el}
    />
  ))

  return (
    <div className="descriptor-input">
      <div className='box-inputs'>
        <div className='prefix-letter'>S</div>
        <div className='prefix-letter'>M</div>
        {inputFields}
      </div>
    </div>
  )
}

export default BoxInputs
