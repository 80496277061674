import React, { useRef, useEffect, useContext } from "react"
import { LeaseContext } from "../../../contexts/LeaseContext"
import { ModalContext } from "../../../../ModalContext"
import BrokerView from "./components/BrokerView"
import ProcessorView from "./components/ProcessorView"

const FeesSetModal = () => {
  const { userRoles, documentsFinished, notifiedReadyToShare } = useContext(LeaseContext)
  const { setCurrentModal } = useContext(ModalContext)
  const ref = useRef()

  const cleanup = () => {
    $(ref.current).modal("hide")
    setCurrentModal(null)
  }

  useEffect(() => {
    const modal = $(ref.current)
    $(modal).on("hidden.bs.modal", cleanup)

    if (shouldShowModal()) {
      modal.modal("show")
    }

    return () => modal.off("hidden.bs.modal", cleanup)
  }, [])

  const shouldShowModal = () => {
    if (userRoles.includes('processor') && documentsFinished && notifiedReadyToShare) {
      return false
    }

    return true
  }

  return (
    <div className='lease-fees-modal'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box set-fees'>
          {userRoles.includes('processor') && <ProcessorView cleanup={cleanup} />}
        </div>
      </div>
    </div>
  )
}

export default FeesSetModal
