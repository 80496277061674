import { v4 as uuidv4 } from "uuid"

export const shareReducer = (state, action) => {
  switch (action.type) {
    case "ADD_SHARE":
      return [...state, { id: uuidv4(), ...action.person }]
    case "REMOVE_SHARE":
      return state.filter(person => person.account !== action.account)
    case 'RESET_SHARE':
      return []
    default:
      return state
  }
}
