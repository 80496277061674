const topLevel = typeof window === "undefined" ? this : window;

function fromContext(require_context, className) {
  const parts = className.split(".")
  const filename = parts.shift()

  // Load the module:
  var component = require_context("./" + filename)

  // Then access each key:
  parts.forEach(k => component = component[k])

  // support `export default`
  if (component.__esModule) 
    component = component["default"]
  
  return component
}

function fromGlobal(className) {
  var constructor = topLevel[className]
  constructor ||= eval(className)

  // Lastly, if there is a default attribute try that
  if (constructor && constructor['default']) 
    constructor = constructor['default']

  return constructor;
}

module.exports = function(contexts) {
  return className => {
    const filename = className.split(".")[0]
    const errors = []
    for (let context of contexts) {
      try {
        return fromContext(context, className)
      } catch(e) { 
        errors.push(e)
      }
    }

    try {
      return fromGlobal(className)
    } catch(e) {
      if (e instanceof ReferenceError) {
        if (!e.message.includes(className))
          errors.push(e)
      } else 
        errors.push(e)
    } 

    errors.filter(e => !e.message.includes(`'./${filename}'`)).forEach(e => console.error(e))
  }
}