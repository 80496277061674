import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectBoardMinutesListState, setInitialState, loadDocuments, downloadDocument } from './store/slice';
import {
  selectEditMinuteState,
  updateDocument,
  openEditBoardMinutesModal as openEditBoardMinutesModalAction,
  closeEditBoardMinutesModal as closeEditBoardMinutesModalAction,
  openEditBoardMinutesSuccessModal as openEditBoardMinutesSuccessModalAction,
  closeEditBoardMinutesSuccessModal as closeEditBoardMinutesSuccessModalAction,
} from '../EditMinute/store/slice';
import {
  selectUploadMinutesState,
  openBoardMinutesModal as openBoardMinutesModalAction,
  closeBoardMinutesModal as closeBoardMinutesModalAction,
  openDocumentsUploadedModal as openDocumentsUploadedModalAction,
  closeDocumentsUploadedModal as closeDocumentsUploadedModalAction,
} from '../UploadMinutes/store/slice';
import {
  selectDeleteMinuteState,
  deleteDocument,
  openDeleteBoardMinutesModal as openDeleteBoardMinutesModalAction,
  closeDeleteBoardMinutesModal as closeDeleteBoardMinutesModalAction,
  openDeleteBoardMinutesSuccessModal as openDeleteBoardMinutesSuccessModalAction,
  closeDeleteBoardMinutesSuccessModal as closeDeleteBoardMinutesSuccessModalAction,
} from '../DeleteMinute/store/slice';

import TableHead from 'components/utility/table/TableHead';
import TableBody from 'components/utility/table/TableBody';
import usePagination from 'components/utility/table/hooks/usePagination';
import useSortableTable from 'components/utility/table/hooks/useSortableTable';
import BuildingInfo from './BuildingInfo';
import { EditMinuteModal } from '../EditMinute/EditMinuteModal';
import { EditMinuteSuccessModal } from '../EditMinute/EditMinuteSuccessModal';
import { UploadMinutesModal } from '../UploadMinutes/UploadMinutesModal';
import { DocumentsUploadedModal } from '../UploadMinutes/DocumentsUploadedModal';
import { DeleteMinuteModal } from '../DeleteMinute/DeleteMinuteModal';
import { DeleteMinuteSuccessModal } from '../DeleteMinute/DeleteMinuteSuccessModal';

const PER_PAGE = 10;

const COLUMNS = [
  {
    label: 'File Name',
    accessor: 'file_identifier',
    sortable: false,
    transformFn: (property) => {
      return `${property.file_identifier}`;
    },
  },
  {
    label: 'Meeting',
    accessor: 'meeting_date',
    sortable: false,
    transformFn: (property) => {
      if (!property['meeting_date']) return 'None';

      const date = new Date(property['meeting_date']);
      return date.toLocaleDateString('en-US', { timeZone: 'UTC' });
    },
  },
  {
    label: 'Uploaded',
    accessor: 'created_at',
    transformFn: (property) => {
      if (!property['created_at']) {
        return 'None';
      }

      const date = new Date(property['created_at']);
      return date.toLocaleString();
    },
  },
];

export function BoardMinutesList(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setInitialState(props));
  }, []);

  const {
    documents: allDocuments,
    currentBuilding: currentBuilding,
    buildingsCount,
    documentUrl,
    isExportingDocumentUrl,
  } = useSelector(selectBoardMinutesListState);

  const { uploadMinutesModalVisible, uploaded, documentsUploadedModalVisible, files } =
    useSelector(selectUploadMinutesState);

  const { editMinuteModalVisible, editMinuteSuccessModalVisible, currentDocument, isDocumentUpdated } =
    useSelector(selectEditMinuteState);

  const { deleteMinuteModalVisible, deleteMinuteSuccessModalVisible, isDocumentDeleted } =
    useSelector(selectDeleteMinuteState);

  const {
    tableData: sortedDocuments,
    handleSorting: handleDocumentsSorting,
    sortField,
    sortOrder,
  } = useSortableTable(allDocuments, 'meeting_date', 'desc');

  const { currentPageData: documents, component: Pagination } = usePagination(sortedDocuments, PER_PAGE);

  const handleSubmitEditMinute = (data) => {
    const params = {
      board_minutes_document: { meeting_date: data.meetingDate },
      building_id: currentBuilding.id,
    };
    dispatch(updateDocument({ data: params, id: currentDocument.id }));
  };

  const handleSubmitDeleteMinute = () => {
    dispatch(
      deleteDocument({
        building_id: currentBuilding.id,
        id: currentDocument.id,
      })
    );
  };

  const handleClickDownloadDocument = useCallback(
    (document) => {
      if (isExportingDocumentUrl) return;

      dispatch(downloadDocument({ building_id: currentBuilding.id, document_id: document.id }));
    },
    [dispatch, currentBuilding, isExportingDocumentUrl]
  );

  const renderActions = useCallback(
    (document) => {
      return (
        <>
          <button
            type="button"
            className={["btn btn-neutral-secondary", "board-minute-list-btn__download", isExportingDocumentUrl ? 'not-allowed' : '' ].join(' ')}
            onClick={() => handleClickDownloadDocument(document)}
          >
            <i className="fa-light fa-arrow-down-to-bracket"></i>
          </button>
          <button type="button" className="btn btn-positive-secondary" onClick={() => openEditBoardMinutesModal(document)}>
            Edit
          </button>
        </>
      );
    },
    [openEditBoardMinutesModal, handleClickDownloadDocument]
  );

  // Download Minutes logic
  useEffect(() => {
    if (!documentUrl) return;

    window.location = documentUrl;
  }, [documentUrl]);

  // Upload Minutes logic
  const openBoardMinutesModal = useCallback(() => {
    dispatch(openBoardMinutesModalAction(currentBuilding));
  }, [dispatch, currentBuilding]);
  const closeBoardMinutesModal = useCallback(() => {
    dispatch(closeBoardMinutesModalAction());
  }, [dispatch]);
  const openDocumentsUploadedModal = useCallback(() => {
    dispatch(openDocumentsUploadedModalAction());
  }, [dispatch]);
  const closeDocumentsUploadedModal = useCallback(() => {
    dispatch(closeDocumentsUploadedModalAction());
  });

  useEffect(() => {
    if (uploaded) {
      closeBoardMinutesModal();
      openDocumentsUploadedModal();
      dispatch(loadDocuments({ building_id: currentBuilding.id }));
    }
  }, [uploaded, closeBoardMinutesModal, openDocumentsUploadedModal, dispatch]);

  // Edit Minute logic
  const openEditBoardMinutesModal = useCallback(
    (document) => {
      dispatch(openEditBoardMinutesModalAction(document));
    },
    [dispatch]
  );
  const closeEditMinuteModal = useCallback(() => {
    dispatch(closeEditBoardMinutesModalAction());
  }, [dispatch]);
  const openEditBoardMinutesSuccessModal = useCallback(() => {
    dispatch(openEditBoardMinutesSuccessModalAction());
  }, [dispatch]);
  const closeEditBoardMinutesSuccessModal = useCallback(() => {
    dispatch(closeEditBoardMinutesSuccessModalAction());
  }, [dispatch]);

  useEffect(() => {
    if (isDocumentUpdated) {
      closeEditMinuteModal();
      openEditBoardMinutesSuccessModal();
      dispatch(loadDocuments({ building_id: currentBuilding.id }));
    }
  }, [dispatch, isDocumentUpdated]);

  // Delete Minute logic
  const openDeleteBoardMinutesModal = useCallback(() => {
    dispatch(openDeleteBoardMinutesModalAction(currentDocument));
    dispatch(closeEditBoardMinutesModalAction());
  }, [dispatch, currentDocument]);
  const closeDeleteMinuteModal = useCallback(() => {
    dispatch(closeDeleteBoardMinutesModalAction());
  }, [dispatch]);
  const openDeleteBoardMinutesSuccessModal = useCallback(() => {
    dispatch(openDeleteBoardMinutesSuccessModalAction());
  }, [dispatch]);
  const closeDeleteBoardMinutesSuccessModal = useCallback(() => {
    dispatch(closeDeleteBoardMinutesSuccessModalAction());
  }, [dispatch]);
  const backToEditMinuteModal = useCallback(() => {
    dispatch(closeDeleteBoardMinutesModalAction());
    dispatch(openEditBoardMinutesModalAction(currentDocument));
  }, [dispatch, currentDocument]);

  useEffect(() => {
    if (isDocumentDeleted) {
      closeDeleteMinuteModal();
      openDeleteBoardMinutesSuccessModal();
      dispatch(loadDocuments({ building_id: currentBuilding.id }));
    }
  }, [isDocumentDeleted]);

  const renderGetStartedText = () => {
    return (
      <div className="get-started-container" style={{ padding: '20px 15px' }}>
        <div style={{ fontWeight: 600 }}>Get Started</div>
        <div>
          Upload meeting minutes to attach to viewing requests by selecting{' '}
          <span style={{ fontWeight: 600 }}>Upload Minutes</span> above.
        </div>
      </div>
    );
  };

  return (
    <>
      <div id="board-minutes-documents">
        <BuildingInfo
          building={currentBuilding}
          onOpenNewMinutesClick={openBoardMinutesModal}
          buildingsCount={buildingsCount}
        />
        {allDocuments.length == 0 ? (
          renderGetStartedText()
        ) : (
          <>
            <div className="bp-ui-table" id="dash-my-packages">
              <div className="row">
                <div className="table-responsive">
                  <table className="table mobile-table-display table-striped">
                    <TableHead
                      columns={COLUMNS}
                      handleItemsSorting={handleDocumentsSorting}
                      sortField={sortField}
                      sortOrder={sortOrder}
                    />
                    <TableBody columns={COLUMNS} items={documents} renderActions={renderActions} />
                  </table>
                </div>
              </div>
            </div>
            {allDocuments.length > PER_PAGE && (
              <div className="pagination-container">
                <Pagination />
              </div>
            )}
          </>
        )}
      </div>
      <UploadMinutesModal open={uploadMinutesModalVisible} onClose={closeBoardMinutesModal} />
      <DocumentsUploadedModal
        open={documentsUploadedModalVisible}
        onClose={closeDocumentsUploadedModal}
        count={files.length}
      />
      <EditMinuteModal
        open={editMinuteModalVisible}
        onClose={closeEditMinuteModal}
        onSubmit={handleSubmitEditMinute}
        building={currentBuilding}
        minuteDocument={currentDocument}
        openDeleteBoardMinutesModal={openDeleteBoardMinutesModal}
      />
      <EditMinuteSuccessModal open={editMinuteSuccessModalVisible} onClose={closeEditBoardMinutesSuccessModal} />
      <DeleteMinuteModal
        open={deleteMinuteModalVisible}
        onClose={closeDeleteMinuteModal}
        onSubmit={handleSubmitDeleteMinute}
        onBack={backToEditMinuteModal}
        minuteDocument={currentDocument}
      />
      <DeleteMinuteSuccessModal open={deleteMinuteSuccessModalVisible} onClose={closeDeleteBoardMinutesSuccessModal} />
    </>
  );
}
