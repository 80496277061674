import React from 'react'

// Define a new component that takes an array of context providers and a children component
const ComposedContextProviders = ({ providers, children }) => {
  // Use reduceRight to create a nested hierarchy of components
  return providers.reduceRight((child, parent) => {
    // Clone the current provider and pass in the previous component as a child
    return React.cloneElement(parent, { children: child });
  }, children);
};

export default ComposedContextProviders
