import React from 'react';
import { differenceInMilliseconds, format, millisecondsToHours } from 'date-fns';

const prettyDate = (unixTimestamp) => {
  if (unixTimestamp == 0) return '';
  return format(unixTimestamp, 'd MMM yyy');
};

const daysUntil = (startDate, unixTimestamp) => {
  const timeDifference = differenceInMilliseconds(startDate, unixTimestamp);
  const hours = millisecondsToHours(timeDifference);
  if (hours < 24) return 0;
  return Math.ceil(hours / 24);
};

const containsTime = (status) => {
  return status > 0 ? 'completed' : '';
};

const Timeline = ({ timeline, startDate, permissions }) => {
  return (
    <div className='timeline-container' style={{order: permissions.includes("sign_documents") ? 3 : null}}>
      <p className='days-remaining'>
        <span>{daysUntil(startDate.getTime(), new Date())} days </span>
        remaining until start date
      </p>
      <div className='timeline'>
        <div className='column'>
          <div className='dot-line-wrapper'>
            <div className={`dot ${containsTime(timeline.approved_at)}`}></div>
            <div className={`line ${containsTime(timeline.first_shared_at)}`}></div>
          </div>
          <div className='text'>
            <div className='state'>Approved</div>
            <div className='date'>{prettyDate(timeline.approved_at)}</div>
          </div>
        </div>
        <div className='column'>
          <div className='dot-line-wrapper'>
            <div className={`dot ${containsTime(timeline.first_shared_at)}`}></div>
            <div className={`line ${containsTime(timeline.submitted_at)}`}></div>
          </div>
          <div className='text'>
            <div className='state'>Shared</div>
            <div className='date'>{prettyDate(timeline.first_shared_at)}</div>
          </div>
        </div>
        <div className='column'>
          <div className='dot-line-wrapper'>
            <div className={`dot ${containsTime(timeline.submitted_at)}`}></div>
            <div className={`line ${containsTime(timeline.lease_executed_at)}`}></div>
          </div>
          <div className='text'>
            <div className='state'>Submitted</div>
            <div className='date'>{prettyDate(timeline.submitted_at)}</div>
          </div>
        </div>
        <div className='column'>
          <div className='dot-line-wrapper'>
            <div className={`dot ${containsTime(timeline.lease_executed_at)}`}></div>
          </div>
          <div className='text'>
            <div className='state'>Executed</div>
            <div className='date'>{prettyDate(timeline.lease_executed_at)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;