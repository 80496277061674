import React from 'react'

import Request from './request'

class RequestModals extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.modalWasHidden = this.modalWasHidden.bind(this)
  }

  componentDidMount() {
    window.BoardMinutesRequestModals = this

    $(this.refs.intro_modal).on("hidden.bs.modal", this.modalWasHidden)
    $(this.refs.request_modal).on("hidden.bs.modal", this.modalWasHidden)
    $(this.refs.success_modal).on("hidden.bs.modal", this.modalWasHidden)
  }

  componentWillUnmount() {
    if (window.BoardMinutesRequestModals == this)
      delete window.BoardMinutesRequestModals
  }

  modal() {
    return $(this.refs.request_modal).modal
  }

  showModal(modal) {
    if (modal == this.state.active_modal)
      return

    if (this.state.active_modal) {
      this.setState({awaiting_modal: modal})
      $(this.refs[this.state.active_modal]).modal("hide")
    } else {
      this.setState({active_modal: modal})
      $(this.refs[modal]).modal("show")
    }
  }

  clearState() {
    this.setState(this.blankState())
  }

  blankState() {
    return {
      active_modal: null,
      awaiting_modal: null,
      building: null
    }
  }

  hideModal() {
    this.clearState()
    $(this.refs[this.state.active_modal]).modal("hide")
  }

  modalWasHidden() {
    if ($(this.refs[this.state.active_modal]).hasClass("in"))
      return

    if (this.state.awaiting_modal)
      $(this.refs[this.state.awaiting_modal]).modal("show")
    else {
      this.clearState()
      $(document.body).removeClass("request-modals-open")
    }

    this.setState({active_modal: this.state.awaiting_modal, awaiting_modal: null})
  }

  open(type, price) {
    this.setState({type: type, price: price})
    this.showModal("intro_modal", true)

    $(document.body).addClass("request-modals-open")
  }

  startRequest() {
    this.setState({active_modal: "request_modal"})
    $(this.refs.intro_modal).modal("hide")
    $(this.refs.request_modal).modal("show")
  }

  type() {
    return BoardMinutes.Constants.DocumentTypes[this.state.type || this.props.document_type || "minutes"]
  }

  building() {
    if (!this.props.building)
      return null

    return this.props.building
  }

  price() {
    return this.building.price || this.type().price
  }

  success(state) {
    this.setState(state)
    this.showModal("success_modal")
  }

  renderStartModal() {
    return <div className="modal fade" ref="intro_modal" tabIndex="-1" role="dialog" id="board-minutes-intro">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="title">
              <div className="title__text">
                <i className="fa-thin fa-alarm-clock"></i>
                <h2>view {this.type()?.display_name.toLowerCase()}</h2>
              </div>
            </div>

            <div className="attention">
              <div><strong>Attorneys, Paralegals, and legal representatives can request to view Board Minutes via the Domecile platform.</strong></div>
              <div>Every request is submitted for approval to the Property Management Company, and approved requests will be fulfilled by the Property Management Company via Domecile.</div>
              <div>All content shared is under legal guardianship of the Property Management Company. Domecile is the platform for request and secure dissemination of information only.</div>
              <div>There is a fee associated with each approved request that is payable to Domecile. An authorization hold will be placed on your card at the time of request until property management grants access. Upon access being granted, your card will be charged and you will be notified via email.</div>
            </div>

            <hr />

            <div className="notes">
              <ul>
                <li>
                  <strong>Viewing is time and session sensitive:</strong>
                  <ul className="configs">
                    <li>Access will expire after a time (typically 2 hours).</li>
                    <li>Limited number of sessions (typically 3).</li>
                  </ul>
                </li>
                <li>See our <a href="/about/board_minutes_legal" target="_blank">Terms &amp; Conditions.</a></li>
                <li>The Board Minutes are to be viewed by the requester only. Under no circumstances can the Minutes be copied, shared, or otherwise engaged with.</li>
              </ul>
            </div>

            <div className="buttons">
              <button type="button" className="btn-link cancel-link" onClick={() => this.hideModal()}>cancel</button>
              <button type="button" className="btn-base btn-dark-green" onClick={() => this.showModal("request_modal")}>I understand</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  }

  renderRequestModal() {
    return <div className="modal fade" ref="request_modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <Request building={this.building()} document_type={this.state.type || "minutes"} onBackListener={() => this.showModal("intro_modal")} onSubmit={state => this.success(state)} />
          </div>
        </div>
      </div>
    </div>
  }

  renderSuccessModal() {
    return (
      <div
        className='modal fade'
        ref='success_modal'
        tabIndex='-1'
        role='dialog'
        id='board-minutes-success'
      >
        <div className='modal-dialog' role='document'>
          <div className='modal-content'>
            <div className='modal-body'>
              <div className='title'>
                <h2>success!</h2>
              </div>

              <div className='details'>
                <div>
                  Your request for {this.type()?.display_name} for the following
                  property has been submitted:
                </div>
                <div className='building'>
                  <div>{this.state.address && this.state.address.address}</div>
                  <div>
                    {this.state.building && this.state.building.city},{" "}
                    {this.state.building && this.state.building.state}{" "}
                    {this.state.building && this.state.building.zip}
                  </div>
                </div>
                <div>
                  When Property Management processes the request, a link will be
                  emailed to you granting you limited access to the{" "}
                  {this.type()?.display_name}.
                </div>
              </div>

              <div className='notes'>
                <div className='please-note'>please note:</div>
                <ul>
                  <li>
                    Once opened, the document will expire after a limited time &mdash;
                    typically 2 hours.
                  </li>
                  <li>
                    The link to the document will only work a limited number of
                    times &mdash; typically 3.
                  </li>
                  <li>
                    Document is to be viewed only by the requesting party. It may
                    not be downloaded, copied, shared, or otherwise engaged with
                    under any circumstances.
                  </li>
                  <li>
                    Ensure your phone is on hand as it will be needed to access
                    the document.
                  </li>
                </ul>
              </div>
              <div className='see-tos'>
                For more information, please see our{" "}
                <a href='/about/board_minutes_legal' target='_blank' className='aqua-link'>
                  Terms + Conditions
                </a>
              </div>
              <div className='buttons'>
                <button
                  type='button'
                  className='btn-base btn-aqua-stroke'
                  onClick={() => this.hideModal()}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return <div id="board-minutes-request-modals">
      {this.renderStartModal()}
      {this.renderRequestModal()}
      {this.renderSuccessModal()}
    </div>
  }
}

export default RequestModals
