import React from 'react'

import InputGroup from 'board_share/components/utility/input_group'

class Header extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.title = props.share.title
    this.state.notes = props.share.notes
    this.state.errors = {}

    this.handleChangedTitle = this.handleChangedTitle.bind(this)
    this.handleChangedNotes = this.handleChangedNotes.bind(this)
    this.handleClickSave = this.handleClickSave.bind(this)
  }

  handleChangedTitle(evt) {
    this.setState({title: evt.target.value})
  }

  handleChangedNotes(evt) {
    this.setState({notes: evt.target.value})
  }

  handleClickSave() {
    this.update()
  }

  update() {
    const data = {title: this.state.title, notes: this.state.notes},
          url = BoardShare.Constants.update_path.replace(":id", this.props.share.id)

    $.ajax({
      url: url,
      type: "put",
      data: hashToPostQueryString({ share: data }),
      success: () => {
        if (data.title == "")
          $(this.refs.title_input).val(this.state.default_title)

        BoardShare.Show.resetView()
      }
    })
  }

  renderBuilding(building) {
    return <div className="building" key={`building_${building.id}`}>
      <div className="building-title">{building.title}</div>
      <div className="address">{building.address}</div>
    </div>
  }

  renderBuildings() {
    const buildings = this.props.share.buildings.map(building => this.renderBuilding(building))

    if (buildings.length % 3 == 2)
      buildings.push(<div key="blank 1"></div>)

    return <div className="buildings">{buildings}</div>
  }

  renderTitle() {
    if (this.props.show_only)
      return this.props.share.title || this.props.share.default_title

    return <input type="text" ref="title_input" placeholder={this.props.share.default_title} defaultValue={this.state.title || this.props.share.default_title} onBlur={this.handleChangedTitle} />
  }

  renderNotes() {
    if (this.props.show_only)
      return this.props.notes

    return <textarea defaultValue={this.state.notes} onBlur={this.handleChangedNotes}></textarea>
  }

  renderButtons() {
    if (this.props.show_only)
      return ""

    return <div className="buttons">
      <button className="btn btn-bp-green" onClick={this.handleClickSave}>Save</button>
    </div>
  }

  render() {
    return <div className="show-header">
      <InputGroup label="Property Address" className="buildings">
        {this.renderBuildings()}
      </InputGroup>
      <InputGroup label="Type" className="type">
        <div>{this.props.share.category_display}</div>
      </InputGroup>
      <InputGroup label="Title" className="title">
        {this.renderTitle()}
      </InputGroup>
      <InputGroup label={this.props.show_only ? "Notes" : "Add Notes"} className="notes">
        {this.renderNotes()}
      </InputGroup>

      {this.renderButtons()}
    </div>
  }
}

export default Header