const getStoredPaymentMethodId = () => {
  return sessionStorage.getItem("paymentMethodId")
}

const getPaymentMethodId = (paymentMethodList, newPaymentInfoAdded, defaultId) => {
  if (paymentMethodList.length === 0) {
    return defaultId
  }

  const index = newPaymentInfoAdded ? paymentMethodList.length - 1 : 0
  return paymentMethodList[index].id
}

const getPaymentMethodIdForType = (type, paymentMethodList, newPaymentInfoAdded) => {
  if (!type) return null

  const defaultId = type === 'ach' ? 'new-account' : 'new-card'
  return getPaymentMethodId(paymentMethodList, newPaymentInfoAdded, defaultId)
}

export const getDefaultPaymentMethodId = (type, paymentMethodList, newPaymentInfoAdded) => {
  const storedPaymentMethodId = getStoredPaymentMethodId()
  let paymentMethodId
  if (newPaymentInfoAdded) {
    paymentMethodId = getPaymentMethodIdForType(type, paymentMethodList, newPaymentInfoAdded)
  } else if (storedPaymentMethodId) {
    paymentMethodId = storedPaymentMethodId
  } else {
    paymentMethodId = getPaymentMethodIdForType(type, paymentMethodList, newPaymentInfoAdded)
  }
  return paymentMethodId

}
