import React from 'react'

import RequestHelp from './two_factor_modal/request_help'

class TwoFactorModal extends React.Component {  
  constructor(props) {
    super(props)
    
    this.state = {
      modal: 'check',
      code: ''
    }
  }

  open() {
    this.send()
    $(this.refs.modal).modal("show")
  }

  close() {
    $(this.refs.modal).modal("hide")
  }

  checkLock() {
    if (this.checking)
      return true
    
    this.checking = false
    this.setState({checking: true})
  }

  releaseLock() {
    this.setState({checking: false}, () => this.checking = false)
  }

  getError(xhr) {
    try {
      this.setState({error: JSON.parse(xhr.responseText).error})
    } catch (e) {
      this.setState({error: "server"})
    }
  }

  send(options) {
    if (this.checkLock())
      return
    
    options = typeof options == "object" ? options : {}
    const url =  `/minutes/access/${this.props.passphrase}/2fa/send`
    const type = "post"
    const data = { resend: options.resending }
    const success = () => {
      this.setState({error: null})
      options.resending ? this.onResent() : null
    }

    const error = xhr => this.getError(xhr)

    $.ajax({ url, type, data, dataType: 'json',
      success, error,
      complete: () => this.releaseLock()
    })
  }

  onResent() {
    const timeout = 5000
    this.setState({code_sent: true}, () => {
      setTimeout(() => this.setState({code_sent: null}), timeout)
    })
  }

  check() {
    if (this.checkLock())
      return
    
    const url = `/minutes/access/${this.props.passphrase}/2fa/check`
    const type = "post"
    const data = { code: this.state.code }
    const error = xhr => this.getError(xhr)

    const success = response => {
      if (typeof this.props.onConfirm == "function")
        this.props.onConfirm()
    }

    $.ajax({ url, type, data, dataType: 'json',
      success, error,
      complete: () => this.releaseLock()
    })
  }
  
  render() {
    return <div className="modal" ref="modal" id="board-minutes-two-factor-modal" tabIndex="-1" role="dialog" data-show="true" data-backdrop="static">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <h3>Identity Verification</h3>
            {this.renderCheck()}
            {this.renderRequestHelp()}
          </div>
        </div>
      </div>
    </div>
  }

  renderCheck() {
    if (this.state.modal != 'check')
      return null

    const updateCode = e => {
      this.setState({code: e.target.value})
    }

    const check = e => {
      this.check()
    }

    const resend = e => {
      e.preventDefault()
      this.send({resending: true})
    }

    const requestHelp = e => {
      e.preventDefault()
      this.setState({modal: 'help'})
    } 

    const input_placeholder = "#".repeat(this.props.code_length)

    return <div className="check-2fa">
      <div className="description-section">
        <div>A confirmation code has been sent to the phone number associated with this request</div>
        <div className="number">{this.props.masked_phone}</div>
      </div>

      { this.renderResent() }
      { this.renderError() }

      <div className="input-section">
        <div>Please enter the code below:</div>
        <input type="text" placeholder={input_placeholder} onChange={updateCode} maxLength={this.props.code_length} />
        <button type="button" className="btn btn-md btn-bper" onClick={check} disabled={this.state.code.length === this.props.code_length ? false : true}>Confirm</button>
      </div>
      <div className="details-section">
        <div>
          <span>Didn't receive the code?</span>
          <a href="#" onClick={resend}>Send new code</a>
        </div>
        <div>
          <span>Don't recognize the number?</span>
          <a href="#" onClick={requestHelp}>Click for help</a>
        </div>
      </div>
    </div>
  }

  renderError() {
    if (!this.state.error)
      return null

    const default_error = "There was an unknown server error"
    const error = {
      locked: "Too many attempts. Please try again a little later.",
      incorrect: "The entered code is incorrect. Please try again.",
      requires_resend: `You need a new code. Please resend a new code below.`
    }[this.state.error] || default_error

    return <div className="error">
      {error}
    </div>
  }

  renderResent() {
    if (!this.state.code_sent)
      return null

    return <div className="code-resent">
      A new code has been sent to the phone number listed above
    </div>
  }

  renderRequestHelp() {
    if (this.state.modal != 'help')
      return null

    return <RequestHelp passphrase={this.props.passphrase} onBack={() => this.setState({modal: "check"})} />
  }
}

export default TwoFactorModal