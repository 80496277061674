import React from 'react'
import FormField from './form_field'

let _stripe;
let _elements;

const stripe = () => {
  if (!_stripe)
    _stripe = Stripe(BoardMinutes.Constants.StripeId)

  return _stripe
}

const elements = () => {
  if (!_elements)
    return _elements = stripe().elements();

  return _elements
}

class PaymentForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}

    this.state.name = props.name

    if (typeof(props.onSubmit) == "function")
      this.onSubmitListener = props.onSubmit

    if (typeof(props.onBack) == "function")
      this.onBackListener = props.onBack


  }

  setupCard() {
    this.card = elements().create('card')
    this.card.addEventListener('change', event => {
      const error = event.error
      this.setState({card_error: error ? error.message : null, complete: event.complete})
    });
  }

  componentDidMount() {
    this.setupCard()
    this.card.mount(this.refs.card_element)
  }

  componentWillUnmount() {
    this.card.destroy()
  }

  checkNameForError() {
    const errors = {name_error: null}

    if (this.state.name && this.state.name.match(/^\s*$/))
      errors.name_error = "This must not be blank"

    this.setState(errors)
  }

  canSubmit() {
    if (this.props.paid)
      return true

    const name_errors = !this.state.name || this.state.name_error

    return !name_errors && this.state.complete
  }

  onBack() {
    if (this.onBackListener)
      this.onBackListener()
  }

  gatherPaymentMethod() {
    if (this.props.paid)
      return this.onSubmitListener({})

    if (this.state.processing)
      return

    this.setState({processing: true})

    stripe().createPaymentMethod({
      type: 'card',
      card: this.card,
      billing_details: {
        name: this.state.name,
      },
    }).then(result => {
      if (result.error)
        return this.setState({card_error: result.error.message, processing: false})

      if (this.onSubmitListener)
        this.onSubmitListener({payment_method: result.paymentMethod.id, card: result.paymentMethod.card, billing_details: result.paymentMethod.billing_details})
    })
  }

  renderPrice() {
    const price = this.props.price / 100.0,
          dollars = parseInt(price),
          cents = (Math.abs(dollars - price) * 100).toFixed()

    if (price == dollars)
      return price

    return dollars + "." + (cents < 10 ? "0" : "") + cents
  }

  renderPaid() {
    if (!this.props.paid)
      return ""

    return <div className="paid-area">
      <div className="screen"></div>
      <div className="text">The fee for this service has been waived.</div>
    </div>
  }

  render() {
    const button_classes = ["btn-base", "btn-green-stroke"],
        disabled = !this.canSubmit() || this.state.processing,
        card_error = this.state.card_error ? <div className="error">{this.state.card_error}</div> : ""

    if (disabled)
      button_classes.push("btn-disabled")

    return <div className="payment">

      <div className="form-area">
        <div className="description">
          <i className="fa-thin fa-circle-exclamation"></i>
          <p>A hold of ${this.renderPrice()} will be placed on your card until your request is approved.</p>
        </div>

        <div className="column">
          <FormField name="cardholder-name" defaultValue={this.state.name} error={this.state.name_error} labelText="cardholder name" required={true} onChange={value => this.setState({name: value})} onBlur={() => this.checkNameForError()} />
        </div>
        <div className="column">
          <div className="field">
            <div ref="card_element" className="payment-input" name="card-number"></div>
          </div>
          {card_error}
        </div>

        {this.renderPaid()}
      </div>

      <div className="buttons">
        <button type="button" className="btn-link back-link" onClick={() => this.onBack()}>back</button>
        <button type="button" className={button_classes.join(" ")} disabled={disabled} onClick={() => this.gatherPaymentMethod()}>next</button>
      </div>
    </div>
  }

}

export default PaymentForm
