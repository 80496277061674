import React, {useMemo, useContext, useState} from 'react'
import FeeCollection from "./FeeCollection"
import { currencyMask } from "../../currency"
import { FeeContext } from "../../contexts/FeeContext"
import { StripeAccContext, checkPaymentStatus } from "../../contexts/StripeAccContext"
import { LeaseContext } from "../../contexts/LeaseContext"
import FeesContainer from '../set_fees/FeesContainer'
import FeeTableItems from './FeeTableItems'
import { addCSRF } from 'lib/utilities'
import SetFees from '../set_fees/SetFees'
import { ModalContext } from '../../../ModalContext'
import CollectionSummary from '../modals/manage_fees/CollectionSummary'
import { BalanceInfo } from '../authorize_fees/BreakdownTable'
import { format } from 'date-fns'

const CollectedInfo = () => {
  const [hovered, setHovered] = useState(false)
  return (
    <span className='collected-info'>
      <i
        className='fa-light fa-clock-rotate-left'
        onMouseOver={() => setHovered(true)}
        onMouseOut={() => setHovered(false)}
      ></i>
      {hovered && (
        <div className='collected-info-tooltip'>
          <div className='processor'>
            <div>Collected by:</div>
            <div>Collector Name</div>
          </div>
          <div className='datetime'>{format(new Date(Date.now()), 'MM/dd/yyyy, MM:HH')}</div>
        </div>
      )}
    </span>
  )
}

const RowTotal = () => {
  const { subTotal } = useContext(FeeContext)

  return (
    <div className='breakdown-row total'>
      <p>Total</p>
      <p>{currencyMask(subTotal)}</p>
    </div>
  )
}

const RowCollected = ({collectedTotal}) => {
  const { paymentCollectionStatus } = useContext(StripeAccContext)
  const collected = paymentCollectionStatus === 'fullyCollected'
  return (
    <div className='breakdown-row collection'>
      <div>
        {/* commented out for MVP */}
        {/* {collected && <CollectedInfo />} */}
        Collected
      </div>
      <p className={collected ? "collected" : ""}>
        {currencyMask(collectedTotal)}
      </p>
    </div>
  )
}

const RowBalance = ({balance}) => {
  return (
    <div className='breakdown-row remaining-balance'>
      <div>
        <BalanceInfo />
        Balance
      </div>
      <p className=''>{currencyMask(balance)}</p>
    </div>
  )
}

const Summary = ({balance, collectedTotal}) => {
  const { paymentCollectionStatus, captureInitiatedAt } = useContext(StripeAccContext)
  const { setCurrentModal } = useContext(ModalContext)
  const handleClick = () => {
    setCurrentModal(<CollectionSummary />)
  }

  return (
    <div className='breakdown-amounts'>
      <RowTotal />
      {paymentCollectionStatus === "fullyCollected" && (
        <RowCollected collectedTotal={collectedTotal} />
      )}
      {(paymentCollectionStatus === "processing" ||
        paymentCollectionStatus === "hasBalance") && (
        <>
          <RowCollected collectedTotal={collectedTotal} />
          <RowBalance balance={balance} />
        </>
      )}
      {captureInitiatedAt && (
        <span className='details'>
          <button onClick={handleClick} className='balance-details'>
            Details
          </button>
        </span>
      )}
    </div>
  )
}

const parseOutErrors = xhr => {
  try {
    const errors = JSON.parse(xhr.responseText)
    return errors
  } catch (e) {
    return { server_error: "There was a server error" }
  }
}

const postFees = async (fees) => {
  const url = 'leases/set_fees'
  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(addCSRF({ fees_data: fees })),
  }
  try {
    const response = await fetch(url, options)
    const data = await response.json()
    if (!response.ok) {
      throw new Error(data)
    }
    return data
  } catch (e) {
    return parseOutErrors(e)
  }

}

const FeeTable = () => {
  const { fees, dispatch, feesSet, setFeesSet, setCurrentView } = useContext(FeeContext)
  const { allPayments, paymentCollectionStatus } = useContext(StripeAccContext)
  const { hasBeenShared, permissions } = useContext(LeaseContext)
  const { hasCollected } = checkPaymentStatus(allPayments)
  const sharedWithAndCanViewCollect = hasBeenShared && permissions.includes("view-collect-fees")
  const paymentExists = allPayments.length > 0

  const [balance, collectedTotal] = useMemo(() => {
    const declinedOrAuthorizedButNotCaptured = allPayments.filter(p => p.declined_at || (p.authorized_at && !p.captured_at))
    const capturedPayments = allPayments.filter(p => p.captured_at)

    const balance = declinedOrAuthorizedButNotCaptured.reduce((acc, curr) => (acc + (+curr.amount)), 0)
    const collectedTotal = capturedPayments.reduce((acc, curr) => (acc + (+curr.amount)), 0)

    return [balance, collectedTotal]
  }, [allPayments])

  const editFees = () => {
    setCurrentView(<SetFees />)
    setFeesSet(false)
  }

  const setFees = async () => {
    try {
      const feesData = await postFees(fees)
      setFeesSet(feesData.fees.length > 0)
      dispatch({ type: "UPDATE_FEES", updatedFees: feesData.fees })
    } catch (error) {
      console.error('Error setting fees:', error)
    }
  }

  return (
    <>
      {sharedWithAndCanViewCollect && feesSet && paymentCollectionStatus === 'noneAuthorized' && (
        <FeeCollection />
      )}
      <h3>Fee Breakdown</h3>
      <FeesContainer>
        <FeeTableItems/>
        <Summary balance={balance} collectedTotal={collectedTotal} hasCollected={hasCollected} />
      </FeesContainer>

      {paymentCollectionStatus === 'noneAuthorized' && !paymentExists && permissions.includes("can-edit-fees") && (
        <div className='inline'>
          <button
            id='edit-fees'
            className='edit-fees button'
            onClick={editFees}
          >
            Edit Fees
          </button>
          {!feesSet && permissions.includes("can-confirm-fees") && (
            <button id='set-fee' onClick={setFees} className='set-fee button'>
              Confirm Fees
            </button>
          )}
        </div>
      )}
    </>
  )
}

export default FeeTable
