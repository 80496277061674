import React from 'react'

class Select extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      input: "",
      possible_users: [],
      dropdown: false
    }

    this.handleInput = this.handleInput.bind(this)
    this.handleWindowClick = this.handleWindowClick.bind(this)

    this.discoveredUsers = this.discoveredUsers.bind(this)
    this.addSelectedUser = this.addSelectedUser.bind(this)
  }

  clear() {
    this.setState({dropdown: false, input: "", possible_users: []})
  }

  componentDidMount() {
    this.reorientDiscovered()
    if (window)
      $(window).on("click", this.handleWindowClick)
  }

  componentDidUpdate() {
    this.reorientDiscovered()
  }

  componentWillUnmount() {
    if (window)
      $(window).off("click", this.handleWindowClick)
  }

  handleInput(evt) {
    this.setInput(evt.target.value)
  }

  handleWindowClick(evt) {
    this.setState({dropdown: false})
  }

  setInput(input) {
    this.setState({input: input, dropdown: true})
    this.discoverUsers(input)
  }

  discoverUsers(input) {
    clearTimeout(this.throttle_discover)

    if (input.match(/^\s*$/))
      return this.discoveredUsers([])

    this.throttle_discover = setTimeout(() => {
      $.ajax({
        url: this.props.search_path + "?q=" + input,
        success: this.discoveredUsers
      })
    }, 500)
  }

  discoveredUsers(res) {
    const users = (res.users || []).filter(user => !this.isUserSelected(user))
    this.setState({possible_users: users})
  }

  isUserSelected(user) {
    return false //this.props.selected.some(selected_building => building.id == selected_building.id)
  }

  addSelectedUser(user) {
    if (this.props.selectUser)
      this.props.selectUser(user)

    this.clear()
  }

  renderDicoveredUser(user) {
    return <div key={user.id} onClick={() => this.addSelectedUser(user)}>{user.full_name}</div>
  }

  renderDiscoveredUsers() {
    if (this.state.possible_users.length == 0)
      return

    const possible_users = this.state.possible_users.map(user => this.renderDicoveredUser(user))

    return <div className="discovered" ref="discovered">{possible_users}</div>
  }

  reorientDiscovered() {
    const discovered = $(this.refs.discovered),
          search = $(this.refs.search)

    discovered.css({top: search.position().top + search.outerHeight() + 2, width: search.outerWidth() + "px", left: search.position().left})
  }

  render() {
    return <div className="user-selection" onClick={e => e.stopPropagation()}>
      <div className="search" ref="search">
        <input type="text" value={this.state.input} onInput={this.handleInput} />
        <i className="fa-light fa-magnifying-glass" />
      </div>
      {this.renderDiscoveredUsers()}
    </div>
  }
}

Select.defaultProps = {
  selected: []
}

export default Select