import React, { useContext, useEffect, useCallback } from 'react'
import { FeeContext } from '../contexts/FeeContext'
import ManageFees from './manage_fees/ManageFees'
import AuthFees from './authorize_fees/AuthFees'
import { LeaseContext } from '../contexts/LeaseContext'

const FeeArea = () => {
  const { currentView, setCurrentView } = useContext(FeeContext)
  const { userRole, userRoles, hasBeenShared, permissions } = useContext(LeaseContext)

  const setRoleview = useCallback(userRole => {
    // if (permissions.includes('pay_fees')) return setCurrentView(hasBeenShared && <AuthFees />)
    if (permissions.includes('pay_fees')) return setCurrentView(<AuthFees />)

    setCurrentView(<ManageFees />)
  }, [hasBeenShared, setCurrentView])


  useEffect(() => {
    setRoleview(userRoles)
  }, [userRoles, setRoleview])

  return currentView
}

export default FeeArea
