import axios from "lib/axios";

export const uploadDocumentApi = async (payload) => {
  const { buildingId, documentData } = payload;

  const data = new FormData();
  data.append("board_minutes_document[meeting_date]", documentData.meeting_date);
  data.append("board_minutes_document[file]", documentData.file);

  return await axios.post(`/minutes/buildings/${buildingId}/documents`, data);
};
