import React from 'react'
import { HeaderHtmls } from './HeaderHtmls'

const Header = ({leaseStates, title}) => {
  return (
    <>
      {title && <h3>{title}</h3>}
      <p>
        {HeaderHtmls[leaseStates]}
      </p>
    </>
  )
}

export default Header
