import React, { createContext, useReducer, useState } from "react"
import { shareReducer } from "../reducers/shareReducer"

export const LeaseContext = createContext()

const LeaseContextProvider = props => {
  const [toBeSharedWith, dispatch] = useReducer(shareReducer, [])
  const [sharedWith, setSharedWith] = useState([])
  const [selectedToRevoke, setSelectedToRevoke] = useState(null)
  const [notificationsSentTo, setNotificationsSentTo] = useState([])

  return (
    <LeaseContext.Provider
      value={{
        toBeSharedWith,
        dispatch,
        sharedWith,
        setSharedWith,
        selectedToRevoke,
        setSelectedToRevoke,
        notificationsSentTo,
        setNotificationsSentTo,
        ...props,
      }}
    >
      {props.children}
    </LeaseContext.Provider>
  )
}

export default LeaseContextProvider
