import React, { useContext, useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { compact } from 'lodash'

import { StatusContext } from '../../StatusContext'
import { ModalContext } from '../../ModalContext'
import RolesInterstitial from '../RolesInterstitial'
import { deselectAll, shareWith } from '../state/state'
import { share } from '../api'
import AgreementShared from '../modals/AgreementShared'

const asCssClass = classes => compact(classes).join(" ")

const Header = () => {
  const dispatch = useDispatch()
  const package_id = useSelector(state => state.package_id)
  const { setShared } = useContext(StatusContext)
  const { setCurrentModal } = useContext(ModalContext)
  const [ showRoles, setShowRoles ] = useState(false)
  const selectedEmails = useSelector(state => state.selected)
  const shareable = selectedEmails.length > 0

  const handleClick = useCallback(() => {
    if (selectedEmails.length == 0)
      return

    share(package_id, selectedEmails).then(({users, emails}) => {
      dispatch(deselectAll())
      dispatch(shareWith(users))
      setShared(true)
      setCurrentModal(<AgreementShared emails={emails} />)
    })
  }, [ package_id, selectedEmails, setCurrentModal ])

  const savedRoles = () => window.location.reload()
  const closedRoles = () => setShowRoles(false)

  return (
    <>
      <div className="header">
        <p>Use checkboxes next to names to manage access. Then, select <span>Share.</span></p>
        <div className='button-container'>
          <button className='white' onClick={() => setShowRoles(true)}>Roles + Permissions</button>
          <button className={asCssClass(['green', !shareable && "disabled"])} disabled={!shareable} onClick={handleClick}>Share</button>
        </div>
      </div>
      { showRoles ? <RolesInterstitial package_id={package_id} onSave={savedRoles} onClose={closedRoles} /> : null }
    </>
  )
}

export default Header
