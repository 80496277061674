import React, { useState } from 'react'
import Modal from 'components/utility/Modal'
import axios from 'axios'

const ApprovalModal = props => {
  const modalId = 'approve-rental-application-modal'
  const [approving, setApproving] = useState(false)

  const closeModal = () => window.dispatchEvent(new Event(`closeModal-${modalId}`))

  const clickApprove = () => {
    setApproving(true)
    const path = props.approvePath

    axios.post(path, { closing_status: 'application_approved' })
      .then(() => window.location.reload())
  }

  const modalStyles = {
    textAlign: 'center'
  }

  const headerContent = <div>
    <div>
      <i className='fa-thin fa-circle-question' style={{ fontSize: 50 }} />
    </div>
    <div>
      <h3>Approve Application?</h3>
    </div>
  </div>

  const bodyContent = <div>
    <p>Approving this Application will generate a Lease.</p>
    <p>Once the Lease is generated, <b>no Application information can be edited</b>.</p>
    <p><i>This includes Deal Party information and Application Requirements.</i></p>
  </div>

  const approveButton = () => {
    const buttonProps = {
      className: 'btn btn-success-filled pull-right',
      style: { borderRadius: 5 },
      onClick: clickApprove
    }

    if (approving)
      buttonProps['className'] += ' disabled'

    const buttonText = approving ? <i className='fa-duotone fa-spinner fa-spin-pulse'></i> : 'Approve + Continue'

    return <button {...buttonProps}>
      {buttonText}
    </button>
  }

  const footerContent = <div>
    <div>
      <button className='btn btn-default pull-left' style={{ borderRadius: 5 }} onClick={closeModal}>Back</button>
      {approveButton()}
    </div>
    <div style={{ clear: 'both', paddingTop: 1 }}>
      <hr style={{ height: 5 }} />
      <div><b>Note</b>: Approving will <b>not</b> notify deal parties.</div>
    </div>
  </div>

  return <Modal
    modalId={modalId}
    modalStyles={modalStyles}
    headerContent={headerContent}
    bodyContent={bodyContent}
    footerContent={footerContent}
  />
}

export default ApprovalModal
