import React from 'react'
import {connect} from 'react-redux'

import Store from './store'

function renderTime(time_string) {
  const date = new Date(Date.parse(time_string))

  return `${timeFromDate(date)}, ${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

function timeFromDate(date) {
  const hour = date.getHours() % 12 == 0 ? 12 : (date.getHours() % 12),
        minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes(),
        ampm = date.getHours() < 12 ? "am" : "pm"
  return `${hour}:${minutes} ${ampm}`
}

class User extends React.Component {
  constructor(props) {
    super(props)

    this.handleShare = this.handleShare.bind(this)
    this.shareSuccess = this.shareSuccess.bind(this)

    this.handleUnshare = this.handleUnshare.bind(this)
    this.unshareSuccess = this.unshareSuccess.bind(this)

    this.clickSelect = this.clickSelect.bind(this)
  }

  componentDidMount() {
    this.el = $(this.refs.el)
    this.el.find("[data-toggle=tooltip]").tooltip()
  }

  componentWillUpdate() {
    this.el.find("[data-toggle=tooltip]").tooltip()
  }

  componentWillUnmount() {
    delete this.el
  }

  clickSelect() {
    if (this.props.is_selected)
      this.props.unselectUser()
    else
      this.props.selectUser()
  }

  handleShare() {
    const url = BoardShare.Constants.share_with_user.replace(":id", this.props.share.id).replace(":user_id", this.props.user.id)

    $.ajax({
      url: url,
      type: "post",
      data: hashToPostQueryString({}),
      success: this.shareSuccess
    })
  }

  shareSuccess(res) {
    this.props.addSharesToState(res.shares)
  }

  handleUnshare() {
    const url = BoardShare.Constants.unshare_with_user.replace(":id", this.props.share.id).replace(":user_id", this.props.user.id)
    $.ajax({
      url: url,
      type: "delete",
      data: hashToPostQueryString({}),
      success: this.unshareSuccess
    })
  }

  unshareSuccess() {
    this.props.removeShareFromState()
  }

  renderBuildingTitleLine(building_id, role) {
    const building = this.props.buildings[building_id]
    return <div key={building_id}>
      <div className="role">{role}</div>
      <div className="building-name">{building.title}</div>
    </div>
  }

  renderCheckbox() {
    if (this.props.user.id == parseInt(this.props.share.user_id) || this.props.is_current_user)
      return ""

    return <input type="checkbox" checked={this.props.is_selected} onClick={this.clickSelect} readOnly={true} />
  }


  renderIcons() {
    const icons = []
    if (this.props.user.id == this.props.share.user_id || this.props.is_current_user)
      return icons

    if (!this.props.user_share) 
      icons.push(<i className="fa fa-user-plus clickable" onClick={this.handleShare} key="share" />)
    else if (this.props.user_share.viewed_at)
      icons.push(<i className="fa fa-eye" key="viewed" data-toggle="tooltip" title={"First Viewed on  " +renderTime(this.props.user_share.viewed_at) } />)
    else if (this.props.user_share.created_at)
      icons.push(<i className="fa fa-clock-o" key="unviewed" data-toggle="tooltip" title={"Shared " +renderTime(this.props.user_share.created_at) } />)

    if (this.props.user_share)
      icons.push(<i className="fa fa-times clickable" data-toggle="tooltip" title="Revoke from BoardShare" onClick={this.handleUnshare} key="unshare" />)

    return icons
  }

  renderBuildings() {
    const building_titles = []
    for (let building_id in this.props.user_buildings) {
      for (let role of this.props.user_buildings[building_id])
        building_titles.push(this.renderBuildingTitleLine(building_id, role))
    }

    return <div className="buildings">
      { building_titles }
    </div>
  }

  render() {
    return <div className="user" ref="el">
      <div className="checkbox">{this.renderCheckbox()}</div>  
      <div className="user-details">
        <div className="name">{this.props.user.full_name}</div>
        { this.renderBuildings() }
      </div>
      <div className="email">{this.props.user.email}</div>
      <div className="user-icons">
        {this.renderIcons()}
      </div>
    </div>

  }
}

const mapStateToProps = (state, ownProps) => {
  const select_key = `selected_${ownProps.type}s`
  const building_type = `${ownProps.type}_buildings`
  return {
    share: state.users.share,
    buildings: state.users.buildings,
    user_buildings: state.users[building_type][ownProps.user.id],
    user_share: state.users.user_shares[ownProps.user.id],
    is_selected: state.presentation[select_key].includes(parseInt(ownProps.user.id))
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {

  return {
    addSharesToState: shares => dispatch(Store.shareWithUsers(shares)),
    removeShareFromState: () => dispatch(Store.unshareWithUsers([ownProps.user.id])),
    selectUser: () => dispatch(Store.selectUser(ownProps.user, ownProps.type)),
    unselectUser: () => dispatch(Store.unselectUser(ownProps.user, ownProps.type))
//      reorderSectionsInState: ids => dispatch(Store.reorderSections(ids))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(User)