import { configureStore } from "@reduxjs/toolkit";
import { listenerMiddleware } from "./listener";

import boardMinutesListReducer from "./components/BoardMinutesList/store/slice";
import BoardMinutesBuildingsListReducer from "./components/BoardMinutesBuildingsList/store/slice";
import uploadMinutesReducer from "./components/UploadMinutes/store/slice";
import editMinuteReducer from "./components/EditMinute/store/slice";
import deleteMinuteReducer from "./components/DeleteMinute/store/slice";

export const store = configureStore({
  reducer: {
    boardMinutesList: boardMinutesListReducer,
    boardMinutesBuildingsList: BoardMinutesBuildingsListReducer,
    uploadMinutes: uploadMinutesReducer,
    editMinute: editMinuteReducer,
    deleteMinute: deleteMinuteReducer,
  },
  middleware: (createListenerMiddleware) =>
    createListenerMiddleware().prepend(listenerMiddleware.middleware),
});
