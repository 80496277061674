import React, {useRef, useEffect, useContext} from 'react'
import { ModalContext } from '../../../../ModalContext'
import PaymentProcessModals from '../fee_auth/PaymentProcessModals'

const Verified = ({withPaymentSelect}) => {
  const { setCurrentModal } = useContext(ModalContext)
  const ref = useRef()

  const cleanup = () => $(ref.current).modal("hide")
  const clickhandler = () => {
    setCurrentModal(<PaymentProcessModals type='ach' />)
    cleanup()
  }

  useEffect(() => {
    if (!ref.current) return

    const close = () => setCurrentModal(null)
    const modal = $(ref.current)
    modal.on("hidden.bs.modal", close)
    modal.modal("show")

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  return (
    <div className='lease-fees-modal'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box verified'>
          <div className='modal-icon'>
            <i className='fa-thin fa-shield-check'></i>
          </div>
          <h3>Verified!</h3>
          <p className='body'>Your account is ready to authorize payment.</p>
          <div className={`button-container${!withPaymentSelect ? ' center' : ''}`}>
            <button className='cancel' onClick={cleanup}>Close</button>
            { withPaymentSelect && <button className='green' onClick={clickhandler}>
              Select Amount <i className='fa-regular fa-chevron-right'></i>
            </button>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Verified
