
import React, { useState, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import { centsIntegerToDollarsString, dispatchEvent, formatRailsTimestamp } from '../../../javascript/lib/utilities'
import ConfirmSubmitToProcessors from './shared/modals/ConfirmSubmitToProcessors'

const SubmitToProcessorButton = function(props) {
  const [signedRequiredSignaturesCount, setSignedRequiredSignaturesCount] = useState(props.signedRequiredSignaturesCount || 0)
  const [feesSubtotal, setFeesSubtotal] = useState(props.feesSubtotal || 0)
  const [authorizedTotal, setAuthorizedTotal] = useState(props.authorizedTotal || 0)
  const [submittedAt, setSubmittedAt] = useState(formatRailsTimestamp(props.submittedAt))
  const [confirmingSubmit, setConfirmingSubmit] = useState(false)
  const requiredSignatureTotal = props.requiredSignatureTotal

  useEffect(() => {
    const subtotalChanged = ({ detail }) => setFeesSubtotal(detail.subTotal)

    window.addEventListener('lease-subtotal-changed', subtotalChanged)
    return () => window.removeEventListener('lease-subtotal-changed', subtotalChanged)
  }, [setFeesSubtotal])

  useEffect(() => {
    const totalChanged = ({ detail }) => setAuthorizedTotal(detail.authorizedTotal)

    window.addEventListener('lease-authorized-total-changed', totalChanged)
    return () => window.removeEventListener('lease-authorized-total-changed', totalChanged)
  }, [setAuthorizedTotal])

  useEffect(() => {
    const signaturesChanged = ({ detail }) => setSignedRequiredSignaturesCount(detail.signedRequiredSignaturesCount)

    window.addEventListener('lease-required-signatures-changed', signaturesChanged)
    return () => window.removeEventListener('lease-required-signatures-changed', signaturesChanged)
  }, [setSignedRequiredSignaturesCount])

  useEffect(() => {
    const submittedAtChanged = ({ detail }) => {
      setSubmittedAt(formatRailsTimestamp(detail.submit_date))
      setConfirmingSubmit(false)
    }

    window.addEventListener('lease-submitted-to-processors', submittedAtChanged)

    return () => window.removeEventListener('lease-submitted-to-processors', submittedAtChanged)
  })

  useEffect(() => {
    dispatchEvent("lease-has-been-submitted", { submittedAt })
  }, [submittedAt])

  const submit = () => {
    setConfirmingSubmit(true)
  }

  const canSubmit = (authorizedTotal == feesSubtotal) && (requiredSignatureTotal == signedRequiredSignaturesCount)

  const tooltipContent = <div className='tooltip-content'>
    <div>
      <b>All signatures</b>
      <span className='pull-right'>{`${signedRequiredSignaturesCount}/${requiredSignatureTotal}`}</span>
    </div>
    <div>
      <b>Fees</b>
      <span className='pull-right'>{`${centsIntegerToDollarsString(authorizedTotal)}/${centsIntegerToDollarsString(feesSubtotal)}`}</span>
    </div>
  </div>

  const tooltipAttributes = {
    'data-for': 'lease-submit-btn-tooltip',
    'data-tip': '',
    'data-place': 'bottom',
    'data-effect': 'solid',
    'data-background-color': 'white',
    'data-text-color': 'black',
    'data-border': true,
    'data-border-color': '#E5E5E5',
  }

  let renderButtonClasses = 'btn'
  if (!canSubmit) renderButtonClasses += ' disabled'

  const renderButton = <>
    <button className={renderButtonClasses} id='lease-submit-btn' onClick={submit} disabled={!canSubmit} {...tooltipAttributes} >
      Submit Lease
    </button>
    <ReactTooltip id='lease-submit-btn-tooltip' getContent={() => tooltipContent} />
  </>

  const renderSubmitted = <>
    <b>Submitted</b><span className='pull-right'>{submittedAt}</span>
  </>

  return <div id='lease-submit-btn-container'>
    { confirmingSubmit ? <ConfirmSubmitToProcessors resetConfirmSubmit={() => setConfirmingSubmit(false)}/> : null }
    { submittedAt ? renderSubmitted : renderButton }
  </div>
}

export default SubmitToProcessorButton
