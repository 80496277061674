import React from 'react'

const is_blank = val => !val || String(val).match(/^\s*$/)

const translateError = error => ({

})[error] || "A server error has occurred"

const translateErrors = errors => Object.fromEntries(
  Object.keys(errors).map(key => [key, translateError(errors[key])])
)

class RequestHelp extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.errors = {}
  }

  updateInput(name, value) {
    const errors = { ... this.state.errors }
    delete errors.server
    delete errors[name]
    this.setState({[name]: value, errors})
  }

  checkLock() {
    if (this.locked)
      return true

    this.locked = true
    this.setState({locked: true})
    return false
  }

  releaseLock() {
    this.setState({locked: null}, () => delete this.locked )
  }

  check() {
    const errors = {}

    if (is_blank(this.state.typed_phone))
      errors.phone = "This is required"
    else if (String(this.state.phone).length < 10)
      errors.phone = "Must contain at least 10 digits"

    if (is_blank(this.state.reason))
      errors.reason = "This is required"

    this.setState({errors})
    return Object.keys(errors).length == 0
  }

  canSubmit() {
    return Object.keys(this.state.errors).length == 0
  }

  submit() {
    if (this.checkLock())
      return

    if (!this.check())
      return this.releaseLock()

    const url = `/minutes/access/${this.props.passphrase}/2fa/help`
    const type = `post`
    const data = { phone: this.state.phone, reason: this.state.reason }
    const success = () => this.setState({sent: true})
    const error = xhr => {
      let errors = { server: null }
      try {
        errors = JSON.parse(xhr.responseText).error
        if (typeof errors == "string")
          errors = { server: errors }
      } catch(e) {}

      this.setState({errors: translateErrors(errors)})
    }

    $.ajax({
      url, type, data, dataType: 'json',
      success, error,
      complete: () => this.releaseLock()
    })
  }

  back() {
    if (typeof this.props.onBack == "function")
      this.props.onBack()
  }

  render() {
    return <div className="request-help">
      {this.renderForm()}
      {this.renderSent()}
    </div>
  }

  renderForm() {
    if (this.state.sent)
      return

    const updatePhone = e => {
      const typed_phone = e.target.value
      this.setState({typed_phone})
      this.updateInput('phone', typed_phone.replaceAll(/[^0-9]/g, ''))
    }
    const updateReason = e => this.updateInput('reason', e.target.value)

    const button_disabled = !this.canSubmit()
    const button_classes = ['btn', 'btn-bper']
    if (button_disabled)
      button_classes.push('disabled')

    return <div className="request-help-form">
      <div className="text-center">
      Please provide a phone number that is capable of receiving text messages and the reason the originally provided number did not work.
      </div>
      { this.renderError('server') }
      <div className="fields">
        <div>
          <label>New Phone Number</label>
          <input type="text" onChange={updatePhone} />
          {this.renderError('phone')}
        </div>
        <div>
          <label>Reason for Change</label>
          <textarea onChange={updateReason}></textarea>
          {this.renderError('reason')}
        </div>
      </div>

      <div className="buttons">
        <button className="btn" onClick={() => this.back()}>Go Back</button>
        <button className={button_classes.join(" ")} disabled={button_disabled} onClick={() => this.submit()}>Submit Request</button>
      </div>

    </div>
  }

  renderError(name) {
    const error = this.state.errors[name]
    if (!error)
      return null

    return <div className="error">
      {error}
    </div>
  }

  renderSent() {
    if (!this.state.sent)
      return null

    return <div className="sent">
      <h5>
        Your request has been sent.
      </h5>
      <div>
        A representative will reach out to help you complete this process.
      </div>
      <button className="btn" onClick={() => this.back()}>Go Back</button>
    </div>
  }
}

export default RequestHelp
