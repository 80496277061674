import React, { useContext, useEffect, useRef, useState } from 'react'
import { FeeContext } from '../../contexts/FeeContext'

const AddItems = () => {
  const { dispatch, fees, subMerchants } = useContext(FeeContext)
  const hiddenRef = useRef()
  const [buttonClicked, setButtonClicked] = useState(false)

  const handleClick = (e) => {
    const feeType = e.target.id
    dispatch({type: 'ADD_FEE', payload: { feeType, subMerchants }})
    setButtonClicked(true)
  }
  // after add fee button clicked, hidden div (placed at very bottom of container) will scroll down into view
  useEffect(() => {
    if (buttonClicked){
      hiddenRef.current.scrollIntoView({ behavior: "smooth", block: "nearest", inline: 'end' })
    }
    return setButtonClicked(false)
  }, [fees])

  return (
    <div className='add-items' id='add'>
      <button id='fee' className='add-fee button' onClick={handleClick}>Add New Fee</button>
      <div ref={hiddenRef} id='hidden-div' style={{position: 'absolute', bottom: '-20px'}}></div>
    </div>
  )
}

export default AddItems
