import React from 'react'
import {Provider} from 'react-redux'

import Store from './store'
import OrgUsers from './org_users'
import BoardMembers from './board_members'

class Users extends React.Component {
  constructor(props) {
    super(props)

    this.store = Store.getStore()

    this.state = {}
    this.state.showing = 'org-users'

    this.loadShare()
    this.loadCurrentUser()
  }

  loadShare() {
    this.store.dispatch(Store.setShare(this.props.share))
  }

  loadCurrentUser() {
    this.store.dispatch(Store.setCurrentUser(this.props.current_user))
  }

  handleChangeTab(evt, showing) {
    evt.preventDefault()
    this.setState({showing: showing})
  }

  classes(tab) {
    const classes = [tab]
    if (this.state.showing == tab)
      classes.push("active")

    return classes.join(" ")
  }

  renderOrgMembers() {
    if (this.state.showing != "org-users")
      return

    return <OrgUsers>{this.renderDescription()}</OrgUsers>
  }

  renderBoardMembers() {
    if (this.state.showing != "board-members")
      return

    return <BoardMembers>{this.renderDescription()}</BoardMembers>
  }

  renderNonOrgMembers() {
    if (this.state.showing != "non-org-users")
      return 

    return <OrgUsers non_org={true}>{this.renderDescription()}</OrgUsers>
  }

  renderDescription() {
    switch (this.state.showing) {
    case "org-users":
      return <div className="description">Documents are editable once shared with Organization Members</div>
    case "board-members":
      return <div className="description">Documents are view-only when shared with Board Members</div>
    case "non-org-users":
      return <div className="description">Documents are view-only when shared with all others</div>
    }

    return null
  }

  render() {
    return <Provider store={this.store}>
      <div id="boardshare-users-component">

        <div className="boardshare-user-tabs">
          <span></span>
          <a href="#" className={this.classes('org-users')} onClick={e => this.handleChangeTab(e, 'org-users')}>Organization Members</a>
          <span></span>
          <a href="#" className={this.classes('board-members')} onClick={e => this.handleChangeTab(e, 'board-members')}>Board Members</a>
          <span></span>
          <a href="#" className={this.classes('non-org-users')} onClick={e => this.handleChangeTab(e, 'non-org-users')}>Others</a>
          <span></span>
        </div>

        { this.renderOrgMembers() }
        { this.renderBoardMembers() }
        { this.renderNonOrgMembers() }
      </div>
    </Provider>
  }
}

export default Users