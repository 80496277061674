import React, { useRef, useEffect, useContext } from "react"
import { FeeContext } from "../../../contexts/FeeContext"
import { ModalContext } from "../../../../ModalContext"

const underScoreToHyphen = word => word.split("_").join("-")
const PascalToKebab = word => word.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase()
const changeColorIcon = (type) =>  type === 'reset_fees' || type === 'collect_fees' ? 'orange' : null

const Button = ({type, execute}) => {
  let className = ''
  let buttonText = ''

  switch (type) {
    case "no_fees":
      buttonText = "Continue — No Fees"
      className = "confirm danger"
      break
    case "all_waived":
      buttonText = "Continue — All Waived"
      className = "confirm danger"
      break
    case "reset_fees":
      buttonText = "Confirm + Restore"
      className = "confirm dark"
      break
    case "collect_fees":
      buttonText = "Confirm + Collect"
      className = "confirm green"
      break
    default:
      break
  }

  return (
    <button className={className} onClick={execute}>
      {buttonText}
    </button>
  )
}

const WarningModals = ({ type, func }) => {
  const { currentView } = useContext(FeeContext)
  const { setCurrentModal } = useContext(ModalContext)
  const ref = useRef()
  console.log(currentView)
  const execute = () => {
    func()
    cleanup()
  }

  const cleanup = () => $(ref.current).modal("hide")

  useEffect(() => {
    if (!ref.current) return

    const close = () => setCurrentModal(null)

    const modal = $(ref.current)
    modal.on("hidden.bs.modal", close)
    modal.modal("show")

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  return (
    <div className='lease-fees-modal'>
      <div ref={ref} className='modal fade'>
        <div className={`modal-box warning ${PascalToKebab(currentView.type.name)}`}>
          <div className='modal-icon'>
            {/* <i className={`fa-light fa-exclamation ${changeColorIcon(type)}`}></i> */}
            <i className='exclamation-circle-orange'></i>
          </div>
          <div className='content'>
            <div className={`${underScoreToHyphen(type)}`}>
              <h3>Are you sure?</h3>
              <div className='body'>
                <p>
                  {type === "no_fees" &&
                    <>There are no fees for this agreement.</>}
                  {type === "all_waived" &&
                    <>You have waived all fees from this agreement.</>}
                  {type === "reset_fees" &&
                    <>All fees will be restored to the Property's default values. <span>All changes + new fees added will be discarded.</span></>}
                  {type === "collect_fees" &&
                    <>By initiating collection you are confirming that all fee amounts listed are correct.<br /> <span>Note: </span>ACH Payments may take up to 5 days to process.</>}
                </p>
              </div>
              <div className='button-container'>
                <button className='cancel' onClick={cleanup}>
                  Cancel
                </button>
                <Button type={type} execute={execute} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WarningModals
