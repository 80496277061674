import React from 'react'

const BrokerView = ({cleanup}) => {
  return (
    <div className='broker-view'>
      <h3>Fees Confirmed</h3>
      <div className='body'>
        <p>
          No further action is necessary. <br />
          Next, the Processor(s) will review + verify values set.
        </p>
      </div>
      <div className='button-container'>
        <button className='cancel no-float' onClick={cleanup}>
          OK
        </button>
      </div>
    </div>
  )
}

export default BrokerView
