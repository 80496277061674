import React from 'react'
import {connect} from 'react-redux'

import Store from './store'
import EditFile from './edit_file'

function renderTypeIcon (type) {
  if (BoardShare.Constants.image_file_types.includes(type))
    return <i className="fa fa-picture-o" />
  if (BoardShare.Constants.pdf_file_types.includes(type))
    return <i className="fa fa-file-pdf-o" />

  return ""
}

class ShowFile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
    this.state.editing_name = null
    this.state.allow_dragging = true

    this.handleRemove = this.handleRemove.bind(this)
    this.view = this.view.bind(this)
    this.edit = this.edit.bind(this)
    this.remove = this.remove.bind(this)
    this.removed = this.removed.bind(this)
  }

  componentDidMount() {
    this.el = $(this.refs.file)
    this.el.data("react", this)
  }

  componentWillUnmount() {
    this.el.data("react", null)
    delete this.el
  }

  cancelDraggingEvents() {
    return { onMouseEnter: () => setTimeout(() => this.setCanDrag(false), 10), 
              onMouseLeave: () => setTimeout(() => this.setCanDrag(true), 10) }
  }

  setCanDrag(can_drag) {
    if (this.el) 
      this.setState({allow_dragging: can_drag})
  }

  file() {
    return this.props.file
  }

  handleRemove(evt) {
    evt.preventDefault()

    this.remove()
  }

  view(evt) {
    evt.preventDefault()

    if (!BoardShare.Show.EditFile.Instance)
      return

    this.props.openEditFile()
    BoardShare.Show.EditFile.Instance.openViewing()
  }

  edit(evt) {
    evt.preventDefault()

    if (!BoardShare.Show.EditFile.Instance)
      return

    this.props.openEditFile()
    BoardShare.Show.EditFile.Instance.openEditing()
  }

  remove(evt) {
    const swal_options = { 
      title: "Are you sure?", 
      text: `This action will permanently delete this file. Do you want to proceed?`,
      type: "warning",
      showCancelButton: true,
      cancelButtonText: "Nevermind",
      confirmButtonText: "Delete File",
      confirmButtonColor: "#dd6b55"
    }

    const swal_err_options = {
      title: "Oops!",
      text: "There was a problem removing the file",
      type: "error"
    }

    const ajax_options = {
      url: BoardShare.Constants.file_id_path.replace(":id", this.props.share.id).replace(":section_id", this.props.section.id).replace(":file_id", this.props.file.id),
      type: "DELETE",
      success: this.removed,
      error: () => setTimeout(() => swal(swal_err_options), 500)
    }

    swal(swal_options, confirm => confirm ? $.ajax(ajax_options) : null)
  }

  removed() {
    this.props.removeSectionFromState()
    BoardShare.Show.resetView()
  }

  renderViewFile() {
    const components = [],
          buttons = [],
          name = [<div key="name">{this.props.file.filename}</div>]

    if (this.props.file.infected) 
      name.push(<div key="infected" className="infected invalid">This file is infected and will not be included</div>)
    else if (this.props.file.processing_error)
      name.push(<div key="processing-error" className="processing-error invalid">There was an error while processing this file. It will not be included.</div>)

    if (this.props.file.processed)  
      components.push(<span className="type-icon" key="type">{renderTypeIcon(this.props.file.file_type)}</span>)
    else 
      components.push(<span className="type-icon" key="type"><span className="spinner-border"></span></span>)

    components.push(<span className="name" key="name">{name}</span>)
    components.push(<span className="size" key="size">{BoardShare.Globals.translateSize(this.props.file.file_size)}</span>)

    if (!this.props.file.processed) {
      components.push(<span className="buttons" key="buttons"></span>)
    } else {
      if (!this.props.file.infected)
        buttons.push(<a className="fa fa-download" key="download" href={this.props.file.url} download={this.props.file.filename} />)

      if (!this.props.file.infected && !this.props.file.processing_error) {
        buttons.push(<a className="fa-light fa-magnifying-glass" key="view" href="#" onClick={this.view} />)

        if (!this.props.file.processing_error)
          buttons.push(<a className="fa fa-pencil" key="edit" href="#" onClick={this.edit} />)
      }

      buttons.push(<a className="fa fa-trash" key="delete" href="#" onClick={this.handleRemove} />)
      components.push(<span {...this.cancelDraggingEvents()} className="buttons" key="buttons">{buttons}</span>)
    }
    return components
  }

  renderEditFile() {
    const components = []

    return components
  }

  render() {
    const classes = ["share-file"]
    if (!this.state.allow_dragging || !this.props.file.processed)
      classes.push("not-draggable")

    return <div className={classes.join(" ")} ref="file" key={this.props.file.id}>
      { this.state.editing_name ? this.renderEditFile() : this.renderViewFile() }
    </div>
  }
}

const mapStateToProps = (state, ownProps) => {
  const section_id = ownProps.file.board_share_section_id
  return {
    share: state.share.share,
    section: state.share.sections[section_id]
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    removeSectionFromState: () => dispatch(Store.removeFile(ownProps.file.id)),
    openEditFile: () => dispatch(Store.openEditFile(ownProps.file.id))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowFile)
