import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { submitToProcessors } from '../api'
import { dispatchEvent } from '../../../../../javascript/lib/utilities'

const ConfirmSubmitToProcessors = ({resetConfirmSubmit}) => {
  const [show, setShow] = useState(true)
  const close = () => setShow(false)
  const submit = () => {
    submitToProcessors().then(({ submit_date }) => {
      setShow(false)
      dispatchEvent('lease-submitted-to-processors', { submit_date: submit_date })
    })
  }

  return (
    <Modal show={show} centered={true} size='md' dialogClassName='modal-size' className="lease-submit-modals lease-confirm-submit-modal" onExited={resetConfirmSubmit}>
      <div className='content'>
        <h3>Are you sure?</h3>
        <p>By selecting to Submit, you are submitting the Agreement + Fee Authorizations to Management on behalf of <u>all parties</u>.</p>
        <div className='button-container'>
          <button className='cancel' onClick={close}>Cancel</button>
          <button className='green' onClick={submit}>Submit to Processor(s)</button>
        </div>
      </div>
    </Modal>
  )
}

export default ConfirmSubmitToProcessors
