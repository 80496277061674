import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useWatchableRef } from "lib/hooks.js";

export function DeleteMinuteModal({
  open,
  onClose,
  onSubmit,
  onBack,
  minuteDocument,
}) {
  const modalRef = useWatchableRef(null);
  const { handleSubmit } = useForm({
    mode: "onSubmit",
  });

  useEffect(() => {
    if (!modalRef.current) return;

    const localRefValue = modalRef.current;
    const handleClose = (_e) => onClose();

    $(localRefValue).on("hidden.bs.modal", handleClose);
    return () => $(localRefValue).off("hidden.bs.modal", handleClose);
  }, [onClose, modalRef.version]);

  useEffect(() => {
    if (!modalRef.current) return;

    if (open) {
      $(modalRef.current).modal("show");
    } else {
      $(modalRef.current).modal("hide");
    }
  }, [open, modalRef.version]);

  const onFormSubmit = (data, _event) => {
    onSubmit(data);
  };

  const renderFileName = useCallback((doc) => {
    return <span>{` ${doc?.file_identifier}`}</span>;
  }, []);

  return (
    <>
      <div
        ref={modalRef.ref}
        className="modal delete-minute-modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content new-brp-modal">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="modal-header">
                <i
                  className="fa-thin fa-circle-exclamation fa-3x"
                  style={{ color: "#B30000" }}
                ></i>
              </div>
              <div className="modal-body">
                <div>
                  <h4 className="modal-title">Are you sure?</h4>
                </div>
                <div>
                  You are attempting to permanently remove the minutes
                  <span style={{ fontWeight: 600 }}>
                    {renderFileName(minuteDocument)}
                  </span>
                  , for
                  <span style={{ fontWeight: 600 }}>
                    {` ${new Date(
                      minuteDocument?.meeting_date
                    ).toLocaleDateString('en-US', { timeZone: 'UTC' })}`}
                  </span>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-neutral-secondary pull-left"
                  type="button"
                  onClick={onBack}
                >
                  Back
                </button>
                <button className="btn btn-negative pull-right" type="submit">
                  Remove File
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
