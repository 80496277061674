import React, { useState, useContext, useEffect } from 'react'
import { StripeAccContext } from '../../../../contexts/StripeAccContext'
import { currencyMask, stripNumber } from '../../../../currency'
import PaymentsDropdown from '../../../authorize_fees/PaymentsDropdown'
import Buttons from './Buttons'
import RadioSelectors from './RadioSelectors'

const PaymentMethod = ({ type }) => {

  return (
    <>
      <div className='payment-method'>
        <div>
          <p>{type === "ach" ? "ACH " : "Card "}Payment</p>
        </div>
        <PaymentsDropdown />
      </div>
      <div className='payment-icon'><div className='pwrd-by-stripe'></div></div>
    </>
  )
}

const AdminFee = ({ type, amount, selected }) => {
  const { ACH_FEE, CARD_RATE } = useContext(StripeAccContext)
  const [showTooltip, setShowTooltip] = useState(false)

  const handleMouseOver = () => setShowTooltip(true)
  const handleMouseOut = () => setShowTooltip(false)

  let feeAmount

  if (type === "ach") {
    feeAmount = ACH_FEE
  } else {
    const fee = selected && amount ? amount * CARD_RATE : null
    feeAmount = fee || `${CARD_RATE * 100}%`
  }

  return (
    <>
      <div className='admin-fee'>
        <p>
          + App Admin Fee
          <i className='fa-light fa-circle-question' onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}></i>
        </p>
        <p>{currencyMask(feeAmount, 'percentage')}</p>
      </div>
      {showTooltip && (
        <div className='hover-info-box'>
          This charge is for the costs related to facilitating this digital transaction.
        </div>
      )}
    </>
  )
}

const Total = ({ type, amount }) => {
  const { ACH_FEE, CARD_RATE } = useContext(StripeAccContext)
  let totalAmount

  if (type === 'ach') {
    totalAmount = amount ? amount + ACH_FEE : 0
  } else {
    totalAmount = amount ? amount * (1 + CARD_RATE) : 0
  }

  return (
    <div className='total'>
      <p>Total</p>
      <p>{currencyMask(totalAmount, 'percentage')}</p>
    </div>
  )
}

const Summary = ({ type, amount, selected }) => {
  return (
    <div className='summary-charges'>
      <AdminFee type={type} amount={amount} selected={selected} />
      {selected && <Total type={type} amount={amount} />}
    </div>
  )
}

const Notice = ({ isNewAccountSelected, isAchAccountSelected, isPendingVerification }) => {
  const newAccountText = <>In the next step, you will be directed to <br /> authorize an ACH payment with a new account</>
  const pendingVerificationText = <>This account needs to be verified before authorizing a payment.</>
  return (
    <div className='notice'>
      <p>
        {isNewAccountSelected && newAccountText}
        {isAchAccountSelected && isPendingVerification && pendingVerificationText}
      </p>
    </div>
  )
}

const PaymentTypeToggle = ({ type, setType, setCollapse }) => {
  const clickHandler = () => {
    const newType = type === "ach" ? "card" : "ach"
    setType(newType)
    setCollapse(false)
    sessionStorage.setItem('paymentMethodType', newType)
  }

  const targetType = type === 'ach' ? 'Card' : 'ACH'

  return (
    <button className='switch-payment' onClick={clickHandler}>Switch to {targetType} Payment?</button>
  )
}

const SelectAmount = ({ setStep, closeModal, setAmount, amount, setTotalAmount, setPaymentExists, paymentExists, optionAmount, setOptionAmount, optionBalance, setOptionBalance }) => {
  const [selected, setSelected] = useState(false)
  const [collapse, setCollapse] = useState(false)
  const { paymentMethodId, type, setType, isPendingVerification, userPayment } = useContext(StripeAccContext)

  const isNewCardSelected = paymentMethodId === 'new-card' && type === 'card'
  const isNewAccountSelected = paymentMethodId === 'new-account' && type === 'ach'
  const isAchAccountSelected = paymentMethodId !== 'new-account' && type === 'ach'

  useEffect(() => {
    if (paymentMethodId === 'new-card' || type === 'card') return

    setCollapse(paymentMethodId === 'new-account' || isPendingVerification)
  }, [paymentMethodId, type])

  useEffect(() => {
    if (userPayment) {
      setPaymentExists(true)
      setAmount(userPayment?.amount)
    }
  }, [])

  return (
    <div className='choose-amount'>
      <h3>Authorize Fees</h3>
      <PaymentMethod type={type} />
      {!collapse ? (
        <>
          <p>How much would you like to authorize?</p>
          <div className={`test${collapse ? " collapse-down" : ""}`}>
            <RadioSelectors
              selected={selected}
              setSelected={setSelected}
              amount={amount}
              setAmount={setAmount}
              paymentExists={paymentExists}
              optionAmount={optionAmount}
              setOptionAmount={setOptionAmount}
              optionBalance={optionBalance}
              setOptionBalance={setOptionBalance}
            />
            <Summary type={type} amount={amount} selected={selected} />
          </div>
        </>
      ) : (
        <Notice
          isNewAccountSelected={isNewAccountSelected}
          isAchAccountSelected={isAchAccountSelected}
          isPendingVerification={isPendingVerification}
        />
      )}
      <Buttons
        closeModal={closeModal}
        collapse={collapse}
        isNewCardSelected={isNewCardSelected}
        isNewAccountSelected={isNewAccountSelected}
        isAchAccountSelected={isAchAccountSelected}
        setStep={setStep}
        amount={amount}
        setTotalAmount={setTotalAmount}
      />
      <PaymentTypeToggle type={type} setType={setType} setCollapse={setCollapse} />
    </div>
  )
}

export default SelectAmount
