import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { uniq } from "lodash-es";
import { useWatchableRef } from "lib/hooks";

import MinutesMeetingDates from "./MinutesMeetingDates";
import UploadArea from "components/utility/upload_area";
import {
  selectUploadMinutesState,
  selectIsFormValid,
  addFiles as addFilesAction,
  removeFile as removeFileAction,
  handleDateChange as handleDateChangeAction,
  submit as submitAction,
} from "./store/slice";

export function UploadMinutesModal(props) {
  const dispatch = useDispatch();
  const { open, onClose } = props;
  const modalRef = useWatchableRef(null);
  const { building, files, form, uploading, errorMessages } = useSelector(selectUploadMinutesState);
  const isFormValid = useSelector(selectIsFormValid);

  useEffect(() => {
    if (!modalRef.current) return;

    const localRefValue = modalRef.current;
    const handleClose = (_e) => onClose();

    $(localRefValue).on("hidden.bs.modal", handleClose);
    return () => $(localRefValue).off("hidden.bs.modal", handleClose);
  }, [onClose, modalRef.version]);

  useEffect(() => {
    if (!modalRef.current) return;

    if (open) {
      $(modalRef.current).modal("show");
    } else {
      $(modalRef.current).modal("hide");
    }
  }, [open, modalRef.version]);

  const addFiles = useCallback(
    (files) => {
      dispatch(addFilesAction(files));
    },
    [dispatch],
  );

  const removeFile = useCallback(
    (fileId) => {
      dispatch(removeFileAction(fileId));
    },
    [dispatch],
  );

  const handleDateChange = useCallback(
    (fileId, newMeetingDate) => {
      dispatch(handleDateChangeAction({ fileId, newMeetingDate }));
    },
    [dispatch],
  );

  const formatedErrors = useMemo(() => {
    if (uploading) return;

    let messages = [];
    files.forEach((file) => {
      if (errorMessages.system) {
        messages = [...messages, errorMessages.system];
        return;
      }

      if (!errorMessages[file.id]) return;
    
      messages = [
        ...messages,
        errorMessages[file.id]?.file_type,
        errorMessages[file.id]?.file,
        errorMessages[file.id]?.meeting_date,
      ];
    });

    return uniq(messages.flat(Infinity));
  }, [errorMessages])

  const onSubmit = useCallback(() => {
    if (uploading) return;

    dispatch(submitAction());
  }, [dispatch, uploading]);

  // TODO: Replace jQuery bootstrap modal with react-bootstrap modal when master-rentals branch gets merged.
  return (
    <div ref={modalRef.ref} className="modal upload-minutes-modal" tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content new-brp-modal">
          <div className="modal-header">
            <h3 className="modal-title upload-minutes-modal__title">Board Minutes List</h3>
            <div className="upload-minutes-modal__property-info">
              <div className="upload-minutes-modal__property-subtitle">Property</div>
              <div className="upload-minutes-modal__property-name">{building?.title}</div>
              <div className="upload-minutes-modal__property-address">{building?.address}</div>
            </div>
          </div>
          <div className="modal-body">
            <div className="section">
              <div className="section-title">1. Upload File(s)</div>
              <div className="section-body">
                <UploadArea
                  renderCustomIcon={() => <i className="fa-regular fa-cloud-upload" aria-hidden="true"></i>}
                  text="Drop files to upload, or click to browse."
                  inputChanged={addFiles}
                  disabled={uploading}
                />
              </div>
            </div>
            <div className="section">
              <div className="section-title">2. Set Meeting Date</div>
              <div className="section-body">
                <MinutesMeetingDates
                  files={files}
                  removeFile={removeFile}
                  onDateChange={handleDateChange}
                  form={form}
                  uploading={uploading}
                  errorMessages={errorMessages}
                />
              </div>
            </div>
            {formatedErrors?.length > 0 && formatedErrors.map((message, index) => (
              <p key={index} style={{ color: "red" }}>
                {message}
              </p>
            ))}
          </div>
          <div className="modal-footer">
            <button className="btn btn-neutral-secondary" data-dismiss="modal" onClick={onClose}>
              Cancel
            </button>
            <button
              className={["btn btn-positive-primary", uploading ? "clicked not-allowed" : ""].join(' ')}
              onClick={onSubmit}
              disabled={files.length === 0 || !isFormValid}
            >
              <div className="loader"></div>
              Add to List
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

UploadMinutesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
