import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: null,
  name: null,
  pages: {},
  page_files: {},
  deal_parties: {},
  field_defs: {},
  field_positions: [],
  signatures: {},
  signature_groups: {},
  highlights: {}
}

const store = createSlice({
  name: "form",
  initialState,
  reducers: {
    loadIntoStore(state, { payload }) {
      for (let attr in initialState)
        if (payload[attr])
          state[attr] = payload[attr]
    },

    setSignatureIntoStore(state, {payload: signature}) {
      const id = signature.id || Object.keys(state.signatures).length + 1
      const signatures = { ...state.signatures, [id]: { ...signature, id} }

      state.signatures = signatures
    },

    setHighlightIntoStore(state, {payload: highlight}) {
      const id = highlight.id || Object.keys(state.highlights).length + 1
      const highlights = { ...state.highlights, [id]: { ...highlight, id} }

      state.highlights = highlights

    }
  }
})

export const { loadIntoStore, setSignatureIntoStore, setHighlightIntoStore } = store.actions

export const { reducer } = store