import { createSlice } from "@reduxjs/toolkit"
import { first, groupBy, mapValues } from 'lodash'
import { formatISO } from "date-fns"

const initialState = {
  package_id: null,
  user: {},
  participants: {},
  participantOrder: [],
  sharingUsers: {},
  groups: {},
  selected: [],
  revoking: null,
  lastSharedWith: [],
  alreadyShared: null
}

const store = createSlice({
  name: 'share',
  initialState,
  reducers: {
    load(state, { payload: { package_id, participants, groups, sharing_users, user }}) {
      state.package_id = package_id
      state.participants = mapValues(groupBy(participants, 'email'), first)
      state.participantOrder = participants.map(p => p.email)
      state.groups = groups
      state.sharingUsers = sharing_users
      state.user = user

      state.alreadyShared = Object.values(participants).some(person => person.has_access)
    },

    resetParticipants(state, { payload: particpants}) {
      state.participants = mapValues(groupBy(participants, 'email'), first)
      state.participantOrder = participants.map(p => p.email)
    },

    select(state, { payload: email }) {
      if (!state.selected.includes(email))
        state.selected.push( email )
    },

    deselect(state, { payload: email }) {
      state.selected = state.selected.filter(e => e != email)
      state.participants[email].sent_at = null
    },

    deselectAll(state) {
      state.selected = []
    },

    shareWith(state, { payload: users }) {
      const now = new Date()

      let user
      for (let email in users) {
        user = users[email]
        state.participants[email].has_access = true
        state.participants[email].sent_at = user.sent_at || formatISO(now)
        state.participants[email].explicit_access = user.explicit_access
        state.participants[email].granted_at = user.access_granted_at
        state.participants[email].granted_by = user.access_granted_by
        state.participants[email].revoked_at = user.access_revoked_at
        state.participants[email].revoked_by = user.access_revoked_by
      }

      state.lastSharedWith = Object.keys(users)
    },

    revoke(state, { payload: email}) {
      state.revoking = email
    },

    confirmRevoke(state, { payload: users }) {
      if (!users)
        return

      let user
      for (let email in users) {
        user = users[email]
        state.participants[email].has_access = false
        state.participants[email].sent_at = user.sent_at
        state.participants[email].explicit_access = user.explicit_access
        state.participants[email].granted_at = user.access_granted_at
        state.participants[email].granted_by = user.access_granted_by
        state.participants[email].revoked_at = user.access_revoked_at
        state.participants[email].revoked_by = user.access_revoked_by
      }

      state.revoking = null
    },

    shareWithPerson(state, { payload: email}) {
    },

    unshareWtthPerson(state, { payload: email}) {
    }
  }
})

export const reducer = store.reducer

export const { load, select, deselect, deselectAll, shareWith, revoke, confirmRevoke, shareWithPerson, unshareWtthPerson } = store.actions
