import React, { useState, useCallback, useEffect, useMemo } from 'react'
import { Provider, useSelector, useDispatch, shallowEqual } from 'react-redux'
import { createSlice, configureStore } from '@reduxjs/toolkit'
import { compact, random, groupBy, mapValues, isPlainObject } from 'lodash'
import ReactTooltip from 'react-tooltip'
import { Modal } from 'react-bootstrap'

import UploadArea from 'components/utility/upload_area'

import ComposedContextProviders from './documents/ComposedContextProviders'
import LeaseContextProvider from './share/context/LeaseContext'
import FeeContextProvider from './fees/contexts/FeeContext'
import StripeAccContextProvider from './fees/contexts/StripeAccContext'
import { ModalContextProvider } from './ModalContext'
import { StatusContextProvider } from './StatusContext'

// import UploadArea from 'components/utility/upload_area'

import { rebuildForm, startFormGeneration, checkFormBuildStates } from './api'
// import EditCustomForm from './edit_custom_form'


const toDict = arr => mapValues(groupBy(arr, 'id'), a => a[0])

const initialState = {
  package_id: null,
  package_machine_id: null,
  possible_lease_types: [],
  lease_type: null,
  possible_riders: [],
  riders: [],

  form_documents: {},

  form_drafts: [],

  files: [],
  user_forms: {},
  user_form_updates: {},
  user_forms_order: [],
  selected_user_forms: {},
  removed_user_forms: {},

  hoveringButton: null,
}

const Store = createSlice({
  name: "global",
  initialState,
  reducers: {
    setHoveringButton(state, { payload: {on, id}}) {
      if (on)
        state.hoveringButton = id
      else if (id == state.hoveringButton)
        state.hoveringButton = null
    },

    loadIntoStore(state, { payload: { package_id, package_machine_id, lease_type, riders, possible_riders, possible_lease_types, form_documents, user_forms }}) {
      state.package_id = package_id
      state.package_machine_id = package_machine_id
      state.possible_lease_types = possible_lease_types
      state.possible_riders = possible_riders // full rider objects

      state.lease_type = lease_type
      state.riders = riders // array of ids,

      state.form_documents = form_documents
      state.user_forms = toDict(user_forms)
      state.user_forms_order = user_forms.map(form => form.id)

      state.selected_user_forms = {}
      for (let form_id in form_documents)
        if (state.user_forms[form_id])
          state.selected_user_forms[form_id] = true
    },

    setLeaseType(state, { payload: lease_type}) {
      state.lease_type = lease_type
    },

    addRider(state, { payload: rider_id }) {
      if (state.riders.includes(rider_id))
        return

      const riders = state.riders.concat([rider_id])

      state.riders = state.possible_riders.filter(rider => riders.includes(rider.id)).map(rider => rider.id)
    },

    removeRider(state, { payload: rider_id }) {
      const riders = state.riders.filter(id => rider_id != id)

      state.riders = state.possible_riders.filter(rider => riders.includes(rider.id)).map(rider => rider.id)
    },

    addFiles(state, { payload: files }) {
      state.files = state.files.concat(files)
    },

    addForms(state, { payload: forms }) {
      forms = forms.filter(form => !state.user_forms[form.id])
      state.user_forms = { ...state.user_forms, ...toDict(forms) }
      state.user_forms_order = state.user_forms_order.concat(forms.map(form => form.id))

      for (let form of forms)
        state.selected_user_forms[form.id] = true
    },

    removeForm(state, { payload: form_id}) {
      state.removed_user_forms[form_id] = true
      delete state.selected_user_forms[form_id]
    },

    restoreForm(state, { payload: form_id }) {
      delete state.removed_user_forms[form_id]
    },

    selectUserForm(state, { payload: { form_id, on=true} }) {
      if (state.removed_user_forms[form_id])
        return
      
      if (on)
        state.selected_user_forms[form_id] = on
      else
        delete state.selected_user_forms[form_id]
    },

    updateUserForm(state, { payload: { form_id, updates }}) {
      state.user_form_updates[form_id] = updates
    },

    mergeUserFormUpdates(state) {
      for (let form_id in state.user_forms) {
        state.user_forms[form_id] = { 
          ...state.user_forms[form_id], 
          ...state.user_form_updates[form_id]
        }
      }
    }
  }
})

const { 
  loadIntoStore, 
  setLeaseType, 
  addRider, 
  removeRider, 
  addForms, 
  setHoveringButton, 
  selectUserForm, 
  removeForm, 
  restoreForm,
  updateUserForm,
  mergeUserFormUpdates
} = Store.actions

const store = configureStore({
  reducer: Store.reducer
})

function Button(props) {
  const dispatch = useDispatch()
  const hoveringButton = useSelector(state => state.hoveringButton)
  const isHoverOpen = hoveringButton && hoveringButton == props.id

  const onClick = () => props.onClick()
  const onHover = () => dispatch(setHoveringButton({ on: true, id: props.id }))
  const offHover = () => dispatch(setHoveringButton({ on: false, id: props.id }))

  const tooltipId = `select-button-${props.id}`
  
  const classes = compact([
    'btn btn-default select-doc-button',
    , props.checked ? "checked" : null
  ]).join(' ')

  const tooltipAttributes = props.showTooltip ? {
    onMouseOver: onHover,
    onMouseOut: offHover,
    'data-tooltip-id': tooltipId,
    'data-for': tooltipId,
    'data-tip': '',
    'data-effect': 'solid'
  } : {}

  const tooltipProps = compact({
    id: tooltipId,
    isOpen: isHoverOpen,
    className: "bp-default-tooltip",
    getContent: () => <div className='tooltip-content'>{props.children || props.title}</div>,
    arrowColor:"transparent",
    opacity: isHoverOpen ? null : 0
  })

  return <>
    <button className={classes} onClick={onClick} {...tooltipAttributes}>
      <div>{ props.children || props.title }</div>
      {props.checked ? <i className="fa-regular fa-check" style={{ color: '#56A256' }} /> : null}
    </button>
    <ReactTooltip { ... tooltipProps } />
  </>
}

function LeaseTypes() {
  const dispatch = useDispatch()
  const types = useSelector(state => state.possible_lease_types)
  const selected = useSelector(state => state.lease_type)
  const setSelected = type_id => dispatch(setLeaseType(type_id))

  return <>
    <p className="section-title">Select Lease Type</p>
    <div className="select-lease-type">
      {types.map(type => <Button key={type.id} id={type.id} checked={selected == type.id} onClick={() => setSelected(type.id)}>{type.title}</Button>)}
    </div>
  </>
}

function Riders() {
  const dispatch = useDispatch()
  const riders = useSelector(state => state.possible_riders) // array of full rider objects
  const selected = useSelector(state => state.riders) // array of ids

  const inSelected = rider => selected.some(selectedRider => selectedRider == rider.id)
  const select = rider => dispatch(addRider(rider.id))
  const deselect = rider => dispatch(removeRider(rider.id))
  const onClick = rider => { inSelected(rider) ? deselect(rider) : select(rider)}

  return <>
    <div className="section-title">
      <span>
        Choose Riders
      </span>
      <span className="subtext">By default, all riders associated with this building have been pre-selected.</span>
    </div>
    <div className="select-riders">
      {riders.map(rider => <Button key={rider.id} id={rider.id} checked={inSelected(rider)} onClick={() => onClick(rider)}>{rider.title}</Button>)}
    </div>
  </>
}

const errorString = err => {
  if (!err) return null
  if (isPlainObject(err)) err = Object.values(err)
  if (!Array.isArray(err)) return err

  return err.length == 0 ? null : err.join(", ")
}

function UploadFiles() {
  const dispatch = useDispatch()
  const packageId = useSelector(state => state.package_id)
  const [ files, setFiles ] = useState([])
  
  const user_forms_order = useSelector(state => state.user_forms_order)

  const addMoreFiles = useCallback(newFiles => {
    for (let file of newFiles)
      file.id = `${random(999999)}-${file.size}`

    setFiles(files => files.concat(newFiles))
    
    startFormGeneration(packageId, newFiles).then(({forms}) => {
      newFiles.forEach((file, index) => {
        setFiles(files => {
          files.filter(f => f.id == file.id).forEach(f => f.form = forms[index])
          return [ ...files ]
        })
      })
    }).catch(errors => {
      const allErrors = Object.fromEntries(newFiles.map((file, index) => {
        return [file.id, errorString(errors[index]) || "An error occured with another file. Upload this file again." ]
      }))

      setFiles(files => {
        files.filter(f => allErrors[f.id]).forEach(f => {
          f.state = 'failed'
          f.error = allErrors[f.id]
        })

        return [ ...files ]
      })
    })
  }, [packageId, setFiles])

  useEffect(() => {
    const form_ids = compact(files.map(file => file.form?.id))

    if (form_ids.length == 0)
      return

    let timeout
    const checkStatus = () => setTimeout(() => checkFormBuildStates(form_ids).then(({statuses}) => {
      setFiles(files => compact(files.map(file => {
        switch(statuses[file.form?.id]?.state) {
        case 'complete': return { ... file, complete: true }
        case 'failed': return { ...file, error: statuses[file.form?.id].error}
        }
        
        return file
      })))
      timeout = checkStatus()
    }), 4000)

    timeout = checkStatus()

    return () => clearTimeout(timeout)
  }, [files, setFiles])

  useEffect(() => {
    if (files.length == 0)
      return

    const completedList = files.filter(file => file.complete)
    const completedFiles = toDict(completedList)

    if (completedList.length == 0)
      return
    
    setFiles(files => files.filter(file => !completedFiles[file.id]))
    dispatch(addForms(completedList.map(file => file.form)))
  }, [dispatch, ,files, setFiles])

  return <>
    <UploadArea inputChanged={addMoreFiles}>
      <div className="upload-area">
        <div className="icon">
          <i className="fa-light fa-upload"></i>
        </div>
        <div>
          <div className="drop-title">Drag &amp; Drop or <u>Choose a Document</u></div>
          <div className="drop-subtitle">Must be a pdf, jpg, png, doc or docx</div>
        </div>
      </div>
    </UploadArea>
    <div className="file-upload-area user-forms-area">
      { user_forms_order.map(form_id => <UserForm form_id={form_id} key={form_id} />)}
      { files.map(file => <FileInTransit file={file} key={file.id} />)}
    </div>
  </>
}

function FileInTransitStatus({file}) {
  if (file.error)
    return <div className="error">{file.error}</div>

  if (!file.form?.id) {
    return <>
      <div>uploading ...</div>
    </>
  }

  if (file.complete)
    return <div>done!</div>

  return <>
    <div>processing ...</div>
  </>
}

function FileInTransit({file}) {
  const loadingClasses = compact([
    "file-in-transit",
    file.error ? "errored" : null,
    file.complete ? "complete" : null
  ]).join(" ")

  const props = {
    key: file.id,
    id: file.id,
    showTooltip: false
  }

  const loading = !file.error && !file.complete
  
  return <div className={loadingClasses}>
    <Button {...props}>
      <div className="name">{file.name}</div>
      <div className="message">
        <FileInTransitStatus file={file} />
      </div>
      { loading && <div className="loader">
        <i className="fa-duotone fa-spinner fa-spin"></i>
      </div> }
    </Button>
  </div>
}

function EditForm({form_id, onClose}) {
  const dispatch = useDispatch()
  const form = useSelector(state => state.user_forms[form_id])
  const updates = useSelector(state => state.user_form_updates[form_id] || {}, shallowEqual)

  const originalName = updates.name || form.name
  const [ name, setName ] = useState(updates.name || form.name)
  const [ show, setShow ] = useState(true)

  const handleSave = useCallback(() => {
    dispatch(updateUserForm({ form_id, updates: { ...updates, name }}))
    setShow(false)
  }, [dispatch, setShow, updates, name])

  return <Modal onExited={onClose} show={show} centered={true} size="sm">
    <Modal.Body className="lease-edit-user-form">
      <h4>Rename Document</h4>
      <div className="line disabled-line">
        <label className="control-label">Current Name</label>
        <input disabled={true} type="text" className="form-control" onChange={() => {}} value={originalName} />
      </div>  
      <div className="line">
        <label className="control-label">New Name</label>
        <input type="text" className="form-control" onChange={e => setName(e.target.value)} value={name} />
      </div>  
      <div className="buttons">
        <span className="cancel" onClick={() => setShow(false)}><i className="fas fa-times" />Cancel</span>
        <button className="btn btn-success" onClick={handleSave}>Update</button>
      </div>
    </Modal.Body>
  </Modal>
}

function RestoreButton({form_id}) {
  const dispatch = useDispatch()
  const form = useSelector(state => state.user_forms[form_id])
  const updates = useSelector(state => state.user_form_updates[form_id], shallowEqual)
  
  const handleRestore = useCallback(() => {
    console.log("Yo")
    dispatch(restoreForm(form_id))
  }, [form_id, dispatch])

  const props = {
    key: form.id,
    id: form.id,
    checked: false,
    showTooltip: false,
    onClick: handleRestore
  }

  return <div className="removed">
      <Button {...props}>
        <div className="name">To Be Deleted: {updates?.name || form.name}</div>
        <div className="actions">Select to restore</div>
    </Button>
  </div>
}

function UserForm({form_id}) {
  const dispatch = useDispatch()
  const selected = useSelector(state => state.selected_user_forms[form_id])
  const removed = useSelector(state => state.removed_user_forms[form_id])
  const form = useSelector(state => state.user_forms[form_id])
  const updates = useSelector(state => state.user_form_updates[form_id], shallowEqual)

  const [ editing, setEditing ] = useState(false)

  const handleClick = useCallback(() => {
    dispatch(selectUserForm({form_id, on: !selected}))
  }, [form_id, selected, dispatch])

  const handleCloseEditing = useCallback(() => setEditing(false), [setEditing])

  const confirmRemoval = useCallback(() => {
    dispatch(removeForm(form_id))
  }, [dispatch, form_id])

  const clickEdit = useCallback(e => {
    e.stopPropagation()
    setEditing(true)
  }, [setEditing])

  if (removed)
    return <RestoreButton form_id={form_id} />

  const classes = compact([
    removed ? "removed" : null
  ]).join(' ')

  const props = {
    key: form.id,
    id: form.id,
    checked: !!selected,
    showTooltip: !removed,
    onClick: handleClick
  }

  return <div className={classes}>
    <Button {...props}>
      <div className="name">{updates?.name || form.name}</div>
      <div className="actions">
        <div className="edit" onClick={clickEdit}>
          <span>Edit</span>
        </div>
        <div className="space">&bull;</div>
        <div className="uploaded-by">
          uploaded by {form.owner_name}
        </div>
      </div>
      { !selected && <div className="remove-button" onClick={confirmRemoval}>
        <i className="fa fa-times" />
      </div> }
    </Button>
    { editing && <EditForm form_id={form_id} onClose={handleCloseEditing} />}
  </div>
}

function CustomForms() {
  return <>
    <div className="section-title">
      <span>
        Upload Additional Documentation
      </span>
      <span className="subtext">
        <strong>Please note:</strong> Any uploaded files cannot be autofilled or signed. Text overlay tools are available
      </span>
    </div>
    <div className="custom-user-forms">   
      <UploadFiles />
    </div>
  </>
}

const useSelectedForms = () => {
  const lease_type = useSelector(state => state.lease_type)
  const riders = useSelector(state => state.riders)
  const user_forms = useSelector(state => Object.keys(state.selected_user_forms), shallowEqual)

  return useMemo(() => {
    return [lease_type].concat(riders).concat(user_forms)
  }, [lease_type, riders, user_forms])
}

function GenerateButton({package_id, style}) {
  const lease_type = useSelector(state => state.lease_type)
  const form_ids = useSelectedForms()
  const form_documents = useSelector(state => state.form_documents)
  const removed_user_forms = useSelector(state => Object.keys(state.removed_user_forms), shallowEqual)
  const updates = useSelector(state => state.user_form_updates)

  const disabled = lease_type ? false : true
  const classes = compact([
    'btn', 
    disabled ? ' disabled' : ' btn-success-filled'
  ]).join(' ')

  const onClick = useCallback(() => {
    if (disabled)
      return

    rebuildForm({package_id, form_ids, form_documents, removed_user_forms, updates})
      .then(() => window.location = `/package_hook/${package_id}/documents`)
      .catch(errors => {
        console.log(errors)
      })
  }, [ package_id, form_ids, form_documents, removed_user_forms, updates ])

  const generateText = `Generate ${form_ids.length} Document${form_ids.length != 1 ? "s" : ""}`

  if (removed_user_forms.length == 0)
    return <button disabled={disabled} className={classes} onClick={onClick} style={style}>
      {generateText}
    </button>

  const removeText = `remove ${removed_user_forms.length} document${removed_user_forms.length != 1 ? "s" : ""}`
  return <div className="generation-area">
    <div className="description">{generateText} + <span className="remove">{removeText}</span></div>
    <button disabled={disabled} className={classes} onClick={onClick} style={style}>
      Confirm
    </button>
  </div>
}

function SelectDocuments(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(loadIntoStore({
      package_id: props.package_id,
      lease_type: props.selected_lease_type,
      riders: props.selected_riders,
      possible_riders: props.riders,
      possible_lease_types: props.lease_types,
      form_documents: props.form_documents,
      user_forms: props.user_forms
    }))
  }, [])


  // if (inDraft) {
  //   return <CustomFormDraft />
  // }

  const containerStyle = { display: 'flex', marginBottom: 25 }
  const itemNumberStyle = { fontSize: 16, flex: '0 0 20px' }
  const itemTitleStyle = { fontSize: 16, flexGrow: 1 }

  return <div className="select-documents">
    <div style={containerStyle}>
      <div style={itemNumberStyle}>1. </div><div style={itemTitleStyle}><LeaseTypes /></div>
    </div>
    <div style={containerStyle}>
      <div style={itemNumberStyle}>2. </div><div style={itemTitleStyle}><Riders /></div>
    </div>
    <div style={containerStyle}>
      <div style={itemNumberStyle}>3. </div><div style={itemTitleStyle}><CustomForms /></div>
    </div> 
    <div className="text-right">
      <GenerateButton package_id={props.package_id} style={{}} />
    </div>
  </div>
}


export default function (props) {
  const providers = props => {
    const { cards, bank_accounts, all_payments, user_id, ACH_FEE, CARD_RATE, ...rest } = props

    return [
      <LeaseContextProvider
        electronicSigningConsentedAt={props.electronic_signing_consented_at}
        remainingRequiredSignatureIdsByRole={props.remaining_required_signature_ids_by_role}
        permissions={props.permissions}
        userRoles={props.roles}
      />,
      <FeeContextProvider {...rest} />,
      <StripeAccContextProvider
        cards={cards}
        bank_accounts={bank_accounts}
        all_payments={all_payments}
        user_id={user_id}
        ACH_FEE={ACH_FEE}
        CARD_RATE={CARD_RATE} />,
      <ModalContextProvider />,
      <StatusContextProvider sidebarStatuses={props.sidebar_statuses} userRoles={props.roles} permissions={props.permissions} />,
    ]
  }

  return <Provider store={store}>
    <ComposedContextProviders providers={providers(props)}>
      <SelectDocuments {...props} />
    </ComposedContextProviders>
  </Provider>
}
