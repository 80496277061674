import React, { useContext } from 'react'
import { currencyMask } from '../../currency'
import { FeeContext } from '../../contexts/FeeContext'
import { LeaseContext } from '../../contexts/LeaseContext'

const FeeTableItems = () => {
  const { fees, subMerchants } = useContext(FeeContext)
  const { permissions } = useContext(LeaseContext)

  return (
    fees.map(fee => {
      return (
        <div className='fee' key={fee.id ?? fee.uuid}>
          <div className='column-1'>{fee.name}</div>
          {permissions.includes("view-additional-fee-info") ?
            <>
              <div className='column-2'>
                {subMerchants.find(sm => sm.id == fee.sub_merchant_id)?.name}
              </div>
              <div className='column-3'>{fee.gl_code || "None"}</div>
              <div className='column-4'>{fee.charge_code || "None"}</div>
            </>
            : null}
          <div className='column-5'>{currencyMask(fee.amount)}</div>
        </div>
      )
    })
  )
}

export default FeeTableItems
