import React, { useRef, useEffect, useContext } from "react"
import { ModalContext } from "../../../../ModalContext"

const DocsReadySetFeesModal = () => {
  const ref = useRef()
  const {setCurrentModal} = useContext(ModalContext)

  const cleanup = () => {
    $(ref.current).modal("hide")
    setCurrentModal(null)
  }

  useEffect(() => {
    const modal = $(ref.current)
    $(modal).on("hidden.bs.modal", cleanup)
    modal.modal("show")

    return () => modal.off("hidden.bs.modal", cleanup)
  }, [])

  const goToFeesTab = () => {
    window.location.href = './fees'
  }

  const modalContent = () => {
    return <>
      <h3>Documents Ready to Share</h3>
      <div className='body'>
        <p>
          Next, set the associated fees before sharing.
        </p>
      </div>
      <div className='button-container'>
        <button className='cancel' onClick={cleanup}>
          Close
        </button>
        <button className='white share-setting pull-right' onClick={goToFeesTab}>Go to Fees</button>
      </div>
    </>
  }

  return (
    <div className='lease-fees-modal'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box set-fees'>
          <div className='modal-icon'>
            <i className='custom-lock-icon'></i>
          </div>
          <div className="content">
            {modalContent()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DocsReadySetFeesModal
