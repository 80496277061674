import React from 'react'
import { connect } from 'react-redux'

import {assignIntoNamespace} from 'lib/utilities'

import Store from './store'
import InputGroup from '../utility/input_group'

class EditFile extends React.Component {
    constructor(props) {
      super(props)

      this.state = {}

      this.state.hidden = true
      this.state.editing = false
      this.state.errors = {}

      this.handleSave = this.handleSave.bind(this)
      this.handleFileName = this.handleFileName.bind(this)
    }

    id() {
      return "board_share-edit_file_modal"
    }

    componentDidMount() {
      this.modal = $(this.refs.modal)
      this.modal.on("show.bs.modal", () => this.setState({hidden: false}))
      this.modal.on("hidden.bs.modal", () => this.setState({hidden: true}))

      assignIntoNamespace("BoardShare.Show.EditFile.Instance", this)
    }

    componentWillUnmount() {
      if (BoardShare?.Show?.EditFile?.Instance == this)
      assignIntoNamespace("BoardShare.Show.EditFile.Instance", null)
      
      delete this.modal
    }

    open() {
      this.modal.modal("show")
      this.modal.find(".edit input").each((idx, el) => $(el).val(""))
    }

    openEditing() {
      this.setState({editing: true})
      this.open()
    }

    openViewing() {
      this.setState({editing: false})
      this.open()
    }

    close() {
      this.modal.modal("hide")
    }

    getFilename(name) {
      const split_name = (name || "").split(".")
      split_name.pop()

      return split_name.join(".")
    }

    getExtension(name) {
      const split_name = (name || "").split(".")
      return split_name.pop()
    }

    handleFileName(evt) {
      const value = (evt.target.value || "").trim()
      this.setState({filename: value})
    }

    handleSave() {
      const url = BoardShare.Constants.file_id_path.replace(":id", this.props.share_id).replace(":section_id", this.props.section_id).replace(":file_id", this.props.file.id)
      const file = {
        file_name: this.state.filename
      }

      $.ajax({
        url: url,
        type: "put", 
        data: hashToPostQueryString({share: file}),
        success: res => {
          this.props.editFileInState({id: this.props.file.id, filename: res.file.filename})
          BoardShare.Show.resetView()
          this.close()
        }
      })
    }

    renderHeader() {
      return <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal">&times;</button>
        <h4 className="modal-title"><u>{this.props.file.filename || this.props.file.name}</u></h4>
      </div> 
    }

    clickTab(evt, tab) {
      evt.preventDefault()
      this.setState({editing: tab == "edit"})
    }


    renderFullScreenButton() {
      return <div className="full-screen-view">
        <a className="btn btn-sm" href={this.props.file.url} target="_blank">
          Click here for full screen view <i className="fa-light fa-magnifying-glass"></i>
        </a>
      </div>
    }

    renderView() {
      if (this.state.editing)
        return ""

      let inside = ""
      if (BoardShare.Constants.image_file_types.includes(this.props.file.file_type))
        inside = <img key={this.props.file.id} src={this.props.file.url} />
      else if (BoardShare.Constants.pdf_file_types.includes(this.props.file.file_type))
        inside = <object key={this.props.file.id} type="application/pdf" data={this.props.file.url} />

      return <div className="view">
        {this.renderFullScreenButton()}
        {inside}
      </div> 
    }

    renderEdit() {
      if (!this.state.editing)
        return ""

      const components = []
      components.push(<InputGroup label="Name" labelColumns={5} errors={this.state.errors} key="name" classes={["name"]}>
        <span className="filename"><input type="text" defaultValue={this.getFilename(this.props.file.filename || this.props.file.name)} onInput={this.handleFileName} /></span>
        <span className="extension">.{this.getExtension(this.props.file.filename || this.props.file.name)}</span>
        </InputGroup>)

      components.push(<InputGroup label="Size" labelColumns={5} errors={this.state.errors} key="size">
        <span>{BoardShare.Globals.translateSize(this.props.file.file_size)}</span>
        </InputGroup>)

      components.push(<InputGroup label="Uploaded By" labelColumns={5} errors={this.state.errors} key="user">
        <span>{this.props.file.appended_by ? this.props.file.appended_by.full_name : "Undefined"}</span>
        </InputGroup>)

      return <div className="edit">
        {components}
      </div>
    }

    renderTabs() {
      if (this.props.file.processing_error)
        return ""

      const editClass = ["edit"],
            viewClass = ["view"]

      this.state.editing ? editClass.push("active") : viewClass.push("active")

      return <div className="edit-view">
          <a className={viewClass.join(" ")} href="#" onClick={e => this.clickTab(e, "view")}>View</a>
          <a className={editClass.join(" ")} href="#" onClick={e => this.clickTab(e, "edit")}>Edit</a>
        </div>
    }

    renderBody() {
      return <div className="modal-body">
        {this.renderTabs()}
        {this.renderView()}
        {this.renderEdit()}
      </div>
    }

    renderFooter() {
      const buttons = [<button type="button" className="btn" data-dismiss="modal" key="close">Close</button>]
      if (this.state.editing)
        buttons.push(<button type="button" className="btn btn-bp-green" key="submit" onClick={this.handleSave}>Save</button>)

      return <div className="modal-footer">
        {buttons}
      </div>
    }

    renderModalContent() {
      if (this.state.hidden)
        return null

      return <div className="modal-content">
        {this.renderHeader()}
        {this.renderBody()}
        {this.renderFooter()}
      </div>
    }

    render() {
      return <div className="modal fade" id={this.id()} role="dialog" ref="modal">
        <div className="modal-dialog modal-lg">
          { this.renderModalContent() }
        </div>
      </div>
    }
  }

  const mapStateToProps = (state, ownProps) => {
    const file_id = state.presentation.edit_file_id,
          section_files = state.share.section_files

    let section_id = null
    for (let id in section_files)
      if ($.isArray(section_files[id]) && section_files[id].includes(file_id))
        section_id = id

    return {
      share_id: state.share.share.id,
      section_id: section_id,
      file: state.share.files[file_id] || {}
    }
  }

  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      startFileUpload: files => dispatch(Store.prepareFilesforUpload(ownProps.section.id, files)),
      editFileInState: file => dispatch(Store.editFile(file))
      //reorderSectionsInState: ids => dispatch(Store.reorderSections(ids))
    }
  }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditFile)
