import React, { useState, useContext } from 'react'
import { Modal } from 'react-bootstrap'
import { LeaseContext } from '../../../context/LeaseContext'

const Confirmation = ({person, onClose}) => {
  const { name } = person
  const [ show, setShow ] = useState(true)
  const close = () => setShow(false)
  const { icons } = useContext(LeaseContext)

  return (
    <Modal show={show} centered={true} onExited={onClose} className="leases-revoke-modal">
      <div className='modal-icon confirmation'>
        <i className='user-x-mark' />
      </div>
      <div className='content'>
        <h3>Access Revoked</h3>
        <p>Agreement access was revoked from:</p>
        <div className='deal-parties'>
          <p>{name}</p>
        </div>
        <div className='button-container center'>
          <button className='cancel' onClick={close}>Close</button>
        </div>
      </div>
    </Modal>
  )
}

export default Confirmation
