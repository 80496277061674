import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash-es";
import { useWatchableRef } from "lib/hooks.js";

export function EditMinuteModal(props) {
  const { open, onClose, onSubmit, building, minuteDocument, openDeleteBoardMinutesModal } = props;
  const { title, address } = building;
  const modalRef = useWatchableRef(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      meetingDate: "",
    },
    mode: "onSubmit",
  });

  useEffect(() => {
    if (!modalRef.current) return;

    const localRefValue = modalRef.current;
    const handleClose = (_e) => onClose();

    $(localRefValue).on("hidden.bs.modal", handleClose);
    return () => $(localRefValue).off("hidden.bs.modal", handleClose);
  }, [onClose, modalRef.version]);

  useEffect(() => {
    if (!modalRef.current) return;

    if (open) {
      $(modalRef.current).modal("show");
    } else {
      $(modalRef.current).modal("hide");
    }
  }, [open, modalRef.version]);

  useEffect(() => {
    if (isEmpty(minuteDocument)) return;

    reset({ meetingDate: minuteDocument?.meeting_date });
  }, [minuteDocument]);

  const onFormSubmit = (data, _event) => {
    onSubmit(data);
  };

  return (
    <>
      <div
        ref={modalRef.ref}
        className="modal edit-minute-modal"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content new-brp-modal">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="modal-header">
                <h4>Edit Minutes</h4>
              </div>
              <div className="modal-body">
                <div className="building-info">
                  <div className="building-info__title">Property</div>
                  <div className="building-info__content">{title}</div>
                  <div className="building-info__content">{address}</div>
                </div>
                <div className="board-minute-detail">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col-md-6">Name</th>
                        <th className="col-md-6">Meeting Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="table-active">
                        <td>{minuteDocument?.file_identifier}</td>
                        <td>
                          <input
                            {...register("meetingDate", {
                              required: "Meeting Date is required",
                            })}
                            type="date"
                          ></input>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {errors.meetingDate && (
                  <p className="error-messages">{errors.meetingDate.message}</p>
                )}
                <div className="board-minute-delete">
                  <div className="board-minute-delete--red" onClick={openDeleteBoardMinutesModal}>
                    <i className="fa-regular fa-xmark"></i> Remove File
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  className="btn btn-neutral-secondary pull-left"
                  type="button"
                  onClick={onClose}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-positive-primary pull-right"
                  type="submit"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
