import React, { memo, useCallback } from "react";

function BuildingInfo(props) {
  const {
    building,
    onOpenNewMinutesClick,
    buildingsCount,
  } = props;

  const renderSwitchProperty = useCallback(() => {
    return (
      <a
        className="building-info-content__text--orange building-info-content__text--bold"
        href={`/minutes/buildings`}
      >
        <i className="fa fa-chevron-left fa-sm" aria-hidden="true"></i>
        &nbsp;Switch Property
      </a>
    );
  }, []);

  const renderViewProperty = useCallback((property) => {
    return (
      <a
        className="building-info-content__text--orange building-info-content__text--bold"
        href={`/buildings/${property.id}`}
      >
        View Property
      </a>
    );
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className="building-info row">
          <div className="col-sm-1 building-info-thumbnail">
            <img
              className="building-info-thumbnail__item"
              src={building.get_thumbnail}
            />
          </div>
          <div className="col-sm-8 building-info-content">
            {building?.title && (
              <div className="building-info-content__text building-info-content__text--bold">
                {building.title}
              </div>
            )}
            <div className="building-info-content__text">
              {building.address}
            </div>
            <div className="building-info-content__text margin-top-20">
              {buildingsCount && buildingsCount > 1
                ? renderSwitchProperty()
                : renderViewProperty(building)}
            </div>
          </div>
          <div className="col-sm-3">
            <button
              className="btn-neutral-primary"
              style={{ width: 151 }}
              onClick={onOpenNewMinutesClick}
            >
              Upload Minutes
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default memo(BuildingInfo);
