import React, { useRef, useEffect, useContext, useState } from "react"
import { ModalContext } from "../../../../ModalContext"
import { StripeAccContext, collectionState } from "../../../contexts/StripeAccContext"
import { currencyMask } from "../../../currency"

const parseStatus = (status) => {
  switch (status) {
    case "noneAuthorized":
      return {status: "N/A", color: '#4a4a4a'}
    case "fullyCollected":
      return {status: "Collected", color: '#4a4a4a'}
    case "processing":
      return {status: "Initiated", color: '#4a4a4a'}
    case "hasBalance":
      return {status: "Unresolved", color: '#b30000'}
  }
}

const Row = ({ payment, updateTotalBalance }) => {
  const { captureInitiatedAt } = useContext(StripeAccContext)
  const status = collectionState([payment], captureInitiatedAt)
  const statusObj = parseStatus(status)

  useEffect(() => {
    if (status === 'hasBalance') {
      updateTotalBalance(+payment.amount)
    }
  }, [status])

  return (
    <div className='summary-row'>
      <div className='row-item'>{payment?.user_name}</div>
      <div
        className='row-item'
        style={{color: statusObj.color}}
      >
        {statusObj.status}
      </div>
      <div className='row-item'>{currencyMask(payment.amount)}</div>
    </div>
  )
}

const Header = () => {
  return (
    <header className='summary-header'>
      <div className="row-item">Payer</div>
      <div className="row-item">Status</div>
      <div className="row-item">Amount</div>
    </header>
  )
}

const PaymentList = ({ updateTotalBalance }) => {
  const { allPayments } = useContext(StripeAccContext)
  return (
    <div className="rows scrollable">
      {allPayments.map((p, idx) => {
        return (
          <Row key={idx} payment={p} updateTotalBalance={updateTotalBalance} />
        )
      })}
    </div>
  )
}

const SummaryTable = ({ updateTotalBalance }) => {
  return (
    <div className='summary-table'>
      <Header />
      <PaymentList updateTotalBalance={updateTotalBalance} />
    </div>
  )
}

const Footer = () => {
  return (
    <div className="footer">
      <div className="status-key">
        <p><span>Initiated</span> - Collection has been initiated but funds have not yet been deposited.</p>
        <p><span>Collected</span> - Collection is complete. Deposit times vary based on payment method (ACH, card, etc.) + payout schedule.</p>
        <p><span>Unresolved</span> - Parties were asked to resolve issue(s). You'll be notified once resolved.</p>
      </div>
    </div>
  )
}

const CollectionSummary = () => {
  const { setCurrentModal } = useContext(ModalContext)
  const [totalBalance, setTotalBalance] = useState(0)
  const [totalCollected, setTotalCollected] = useState(0)
  const ref = useRef()

  const cleanup = () => $(ref.current).modal("hide")

  const updateTotalBalance = (rowAmount) => {
    setTotalBalance(prevTotal => prevTotal + rowAmount)
  }

  useEffect(() => {
    if (!ref.current) return

    const modal = $(ref.current)
    const close = () => setCurrentModal(null)
    modal.on("hidden.bs.modal", close)
    modal.modal('show')

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  return (
    <div className='lease-fees-modal'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box collection-summary'>
          <h3>
            <i className='fa-thin fa-circle-info'></i> Collection Summary
          </h3>
          <p className="heading">You initiated collection on the following payments:</p>
          <div className='body'>
            <SummaryTable updateTotalBalance={updateTotalBalance} />
          </div>
          <div className='button-container center'>
            <button className='cancel' onClick={cleanup}>
              Close
            </button>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default CollectionSummary
