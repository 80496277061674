import React, { useContext, useEffect, useState } from "react"
import BoxInputs from "./BoxInputs"
import { StripeAccContext, collectionState } from "../../../../contexts/StripeAccContext"
import { verifyMicrodeposits } from "../../../../api"
import Verified from "../Verified"
import { ModalContext } from "../../../../../ModalContext"
import useLoader from "../../../../hooks/useLoader"

const ButtonContainer = ({cleanup, attemptedCount, allowedAttempts, descriptorCode, handleClick, loading}) => {
  return (
    <div className='button-container'>
      <button className='cancel' onClick={cleanup}>
        Close
      </button>
      <div
        className={`attempts-remaining${attemptedCount > 0 ? " error" : ""}`}
      >
        Attempts remaining:{" "}
        <span className={`${attemptedCount > 0 ? "error" : ""}`}>
          {allowedAttempts - attemptedCount}
        </span>
      </div>
      <button
        className={`${descriptorCode ? "green" : "disabled"}`}
        onClick={handleClick}
      >
        {loading ? <i
          className='fa-duotone fa-loader fa-spin-pulse'
          style={{"--fa-primary-color": '#f7f7f7', "--fa-secondary-color": '#c9c9c9'}}
        ></i> : 'Verify'}
      </button>
    </div>
  )
}

const Footer = () => {
  return (
    <div className='footer'>
      <p>
        <span>Having trouble?</span> <a className="learn-more" href='#'>Learn more</a>
      </p>
      <p>
        We're happy to help! Contact us at
        <br />
        <a href='mailto:info@boardpackager.com'>info@boardpackager.com</a>.
      </p>
    </div>
  )
}

const WarningAttempts = ({attemptsLeft, cleanup, setStep}) => {
  const singularOrPlural = attemptsLeft === 1 ? "Attempt" : "Attempts"
  const numberToWord = (num) => {
    if (attemptsLeft > 3) return

    const words = ['zero', 'one', 'two', 'three']
    return words[num]
  }

  return (
    <>
      <div className='modal-icon'>
        <i className='fa-thin fa-circle-exclamation'></i>
      </div>
      <h3>{attemptsLeft} {singularOrPlural} Remaining!</h3>
      <div className='body'>
        <p>
          <span className='warning'>Warning:</span> You have{" "}
          <span className='numeric-attempts'>
            {numberToWord(attemptsLeft)} {singularOrPlural.toLowerCase()} remaining
          </span>
          <br />
          to verify this account.
        </p>
        <p>
          After a 10th failed attempt, this account may no longer be used for
          any future payments on BoardPackager.
        </p>
      </div>
      <div className='button-container'>
        <button className='cancel' onClick={cleanup}>
          Close
        </button>
        <button className='danger' onClick={() => setStep(1)}>
          I understand
        </button>
      </div>
      <hr />
      <Footer />
    </>
  )
}

const Verify = ({descriptorCode, setDescriptorCode, cleanup, attemptedCount, allowedAttempts, handleClick, loading, error}) => {
  const [displayError, setDisplayError] = useState(false)
  const [addTransition, setAddTransition] = useState(false)

  useEffect(() => {
    if (error && attemptedCount < 7) {
      setDisplayError(true)

      const timer = setTimeout(() => {
        setAddTransition(true)
      }, 5000)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [error])

  useEffect(() => {
    if (addTransition) {
      const timer = setTimeout(() => {
        setDisplayError(false)
        setAddTransition(false)
      }, 500)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [addTransition])

  return (
    <>
      <h3>Verify</h3>
      <div className='body'>
        <p>
          Identify the unique, 6-digit descriptor of the micro-deposit on the
          account below.
        </p>
        <div className='bank-statement'>
          <div className='image'></div>
        </div>
        {displayError && <div className={`verify-error-msg${addTransition ? ' scale-down' : ''}`}>Code did not match. Please try again.</div>}
        <BoxInputs setDescriptorCode={setDescriptorCode} attemptedCount={attemptedCount} handleClick={handleClick} error={error} />
      </div>
      <ButtonContainer
        cleanup={cleanup}
        attemptedCount={attemptedCount}
        allowedAttempts={allowedAttempts}
        descriptorCode={descriptorCode}
        handleClick={handleClick}
        loading={loading}
      />
      <hr />
      <Footer />
    </>
  )
}

const Verification = ({
  descriptorCode,
  setDescriptorCode,
  cleanup,
  attemptedCount,
  setAttemptedCount,
  allowedAttempts,
  error,
  setError,
}) => {
  const { paymentMethodId, setBankAccounts, userPayment, captureInitiatedAt } = useContext(StripeAccContext)
  const { setCurrentModal } = useContext(ModalContext)
  const [step, setStep] = useState(1)
  const { loading, withLoader } = useLoader()
  const userPaymentCollectionState = collectionState([userPayment], !!captureInitiatedAt)
  const hasBalance = userPaymentCollectionState === "hasBalance"


  const handleApiCall = async () => {
    if (!descriptorCode) return

    const data = await verifyMicrodeposits(paymentMethodId, descriptorCode)

    setAttemptedCount(data.verification_count)
    setBankAccounts(data.bank_accounts)

    if (Object.hasOwn(data,'code')) {
      setError(data)
    } else {
      setCurrentModal(<Verified withPaymentSelect={!hasBalance} />)
      cleanup()
    }
  }

  const handleApiCallWithLoader = () => {
    withLoader(handleApiCall)
  }

  useEffect(() => {
    if (attemptedCount >= 7) {
      setStep(2)
    }
  }, [setStep, attemptedCount])
  return (
    <div className='verify'>
      {step === 1 && (
        <Verify
          cleanup={cleanup}
          attemptedCount={attemptedCount}
          allowedAttempts={allowedAttempts}
          descriptorCode={descriptorCode}
          setDescriptorCode={setDescriptorCode}
          handleClick={handleApiCallWithLoader}
          loading={loading}
          error={error}
        />
      )}

      {step === 2 && (
        <WarningAttempts
          attemptsLeft={allowedAttempts - attemptedCount}
          setStep={setStep}
          cleanup={cleanup}
        />
      )}
    </div>
  )
}

export default Verification
