import React from 'react'
import {Provider} from 'react-redux'

import Store from './store'
import GenerateSection from './generate_section'
import FileUploader from './upload_files'
import EditFile from './edit_file'
import Sections from './sections'

class Edit extends React.Component {
  constructor(props) {
    super(props)

    this.store = Store.getStore()

    this.loadShare()
    this.checkProcessingFiles()
  }

  componentDidMount() {
    $(window).on({dragover: this.windowDragging, drop: this.windowDragging})
  }

  componentWillUnmount() {
    $(window).off({dragover: this.windowDragging, drop: this.windowDragging})
  }

  windowDragging(evt) {
    evt.preventDefault()
  }

  loadShare() {
    this.store.dispatch(Store.setShare(this.props.share))
  }

  fileIdForLibraryDocument(doc_id) {
    const state = this.store.getState()
    let file

    for (let file_id in state.presentation.upload_files) {
      file = state.presentation.upload_files[file_id].share_file
      if (file && file.library_document_id == doc_id)
        return file_id
    }

    return null
  }

  checkProcessingFiles() {
    const url = BoardShare.Constants.documents_in_process_path.replace(":id", this.props.share.id),
          state = this.store.getState(),
          data = { library_document_id: state.presentation.processing_files }

    if (data.library_document_id.length == 0)
      return setTimeout(() => this.checkProcessingFiles(), 1000)

    $.ajax({
      url: url,
      type: "get",
      data: data,
      success: res => this.finishProcessingDocuments(res.documents),
      complete: () => setTimeout(() => this.checkProcessingFiles(), 1500)
    })
  }

  finishProcessingDocuments(documents) {
    const state = this.store.getState()
    let uploaded_id, uploaded_files, file, uploaded_file
    for (let document_id in documents) {
      uploaded_files = documents[document_id].share_files

      if (!documents[document_id].processed) {
        for (file of uploaded_files) {
          uploaded_file = state.presentation.upload_files[file.id]
          if (!uploaded_file)
            continue

          uploaded_file = Object.assign({}, uploaded_file, {share_file: file})
          this.store.dispatch(Store.replaceFile(file))
          this.store.dispatch(Store.editUploadedFile(file.id, uploaded_file))
        }

        continue
      }

      this.store.dispatch(Store.removeProcessingFile(document_id))

      uploaded_id = this.fileIdForLibraryDocument(document_id)

      // If there are generated_documents for this share file, then the share file that does NOT have generated
      // documents needs to be removed, as it has already *been* removed by the background worker
      if (uploaded_files.length > 0) {
        this.store.dispatch(Store.removeUploadedFile(uploaded_id))
        this.store.dispatch(Store.removeLibraryFiles(document_id))

        for (file of uploaded_files)
          this.store.dispatch(Store.addFile(file, file.board_share_section_id))

        this.store.dispatch(Store.prepareFilesforUpload(null, uploaded_files.map(file => BoardShare.Globals.prepareUploadFile(file, true))))
      }
    }
  }

  render() {
    return <Provider store={this.store}>
      <div id="boardshare-show-component">
        <Sections />
        <div className="bottom-buttons">
          <button type="button" className="btn btn-primary btn-lg" data-toggle="modal" data-target={`#${this.store.getState().presentation.generate_section_id}`}>Add Section</button>
        </div>
        <GenerateSection />
        <FileUploader />
        <EditFile />
      </div>
    </Provider>
  }
}

export default Edit