import axios from 'axios'

export function verifyRequiredSignatures() {
  const url = 'leases/documents/verify_required_signatures'

  return axios.get(url)
    .then(response => {
      return response.data
    })
    .catch(error => {
      // Handle any errors that occur during the API call
      console.error('Error verifying required signatures:', error)
      throw error
    })

}
