import React, { useContext, useEffect } from 'react'
import { FeeContext } from '../../../../contexts/FeeContext'
import { LeaseContext } from '../../../../contexts/LeaseContext'
import { setNotifiedOfReadyToShare } from 'plugins/plugins/leases_plugin/javascripts/share/api'

const DocumentsFinished = ({ cleanup, goToShareTab }) => {
  useEffect(() => {
    setNotifiedOfReadyToShare()
  }, [])

  return (
    <>
      <div className='modal-icon'>
        <i className="memo-icon"></i>
      </div>
      <div className="content">
        <h3>Agreement is Ready to Share</h3>
        <div className='body'>
          <p>
            Documents are marked as <span>Finished</span> and fees are confirmed.
          </p>
          <br />
          <p>Share to start collecting signatures.</p>
        </div>
        <div className='button-container'>
          <button className='cancel' onClick={cleanup}>
            I am still reviewing
          </button>
          <button onClick={goToShareTab} className='white share-setting'>
            Set up Sharing
          </button>
        </div>
      </div>
    </>
  )
}

const FeesConfirmed = ({cleanup, goToDocumentsTab}) => {
return (
  <>
    <div className='modal-icon'>
      <i className='fa-thin fa-circle-check'></i>
    </div>
    <div className='content'>
      <h3>Fees Confirmed</h3>
      <div className='body'>
        <p>
          Next, review + mark documents as <br />
          <span>Finished.</span>
        </p>
        <p>Then, share Agreement.</p>
      </div>
      <div className='button-container'>
        <button className='cancel' onClick={cleanup}>
          Close
        </button>
        <button onClick={goToDocumentsTab} className='white review-docs'>
          Review Documents
        </button>
      </div>
    </div>
  </>
)
}

const ProcessorView = ({cleanup}) => {
  const { documentsFinished } = useContext(LeaseContext)
  const { packageId } = useContext(FeeContext)

  const goToDocumentsTab = () => {
    window.location.href = `/package_hook/${packageId}/documents`
  }

  const goToShareTab = () => {
    window.location.href = `/package_hook/${packageId}/share`
  }


  return (
    <div className='processor-view'>
      {documentsFinished ? <DocumentsFinished goToShareTab={goToShareTab} cleanup={cleanup} /> : <FeesConfirmed goToDocumentsTab={goToDocumentsTab} cleanup={cleanup} />}
    </div>
  )
}

export default ProcessorView
