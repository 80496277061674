import React, {useContext, useEffect, useState} from 'react'
import PropTypes from "prop-types"
import EditableField from "./EditableField"
import WaiveFee from "./WaiveFee"
import Toolbar from './Toolbar'
import { FeeContext } from '../../contexts/FeeContext'
import { LeaseContext } from '../../contexts/LeaseContext'
import { currencyMask } from '../../currency'

const LeaseFee = ({ isFirst,onClickUp, onClickDown, fee, isLast, wrap}) => {
  const [waivedState, setWaivedState] = useState(fee.waived)
  const { dispatch, subMerchants } = useContext(FeeContext)
  const { userRole, userRoles } = useContext(LeaseContext)

  const shouldDisable = (field) => {
    const unEditableFields = ['gl_code', 'charge_code', 'sub_merchant_id']

    if (!fee.editable) return true
    if (userRole === 'broker_specialist' && unEditableFields.includes(field)) return true
    if (!(userRole === 'broker_specialist' || userRoles.includes('processor'))) return true
    if (fee.waived) return true

    return false
  }

  const updateStateOnChange = (key,value) => {
    const parsedValue = isNaN(value) ? value : parseFloat(value)
    dispatch({type: 'EDIT_FEES', editedFees: [{...fee, [key]: parsedValue || 0}]})
  }

  useEffect(() => {
    dispatch({ type: "EDIT_FEES", editedFees: [{ ...fee, waived: waivedState }] })
  },[waivedState])


  return (
    <div className='fee' key={fee.id}>
      {fee.type === "fee" && (
        <>
          <EditableField
            label='Name'
            id='name'
            name='name'
            type='text'
            data={fee.name}
            groupClass='column-1'
            required={true}
            disabled={shouldDisable("name")}
            updateStateOnChange={updateStateOnChange}
          />
          <EditableField
            label='Routing Account'
            id='sub_merchant_id'
            name='sub_merchant_id'
            type='dropdown'
            data={fee.sub_merchant_id}
            groupClass='column-2'
            required={true}
            disabled={shouldDisable("sub_merchant_id")}
            subMerchants={subMerchants}
            updateStateOnChange={updateStateOnChange}
          />
          <EditableField
            label='GL Code'
            id='gl_code'
            name='gl_code'
            type='text'
            data={fee.gl_code}
            groupClass='column-3'
            disabled={shouldDisable("gl_code")}
            updateStateOnChange={updateStateOnChange}
          />
          <EditableField
            label='Charge Code'
            id='charge_code'
            name='charge_code'
            type='text'
            data={fee.charge_code}
            groupClass='column-4'
            disabled={shouldDisable("charge_code")}
            updateStateOnChange={updateStateOnChange}
          />
          <EditableField
            label='Amount'
            id='amount'
            name='amount'
            type='text'
            data={currencyMask(fee.amount)}
            groupClass='column-5'
            required={true}
            disabled={shouldDisable("amount")}
            updateStateOnChange={updateStateOnChange}
            isDigitsOnly={true}
          />
          <div
            className='section--x'
            onClick={() => dispatch({ type: "REMOVE_FEE", id: fee.uuid })}
          >
            <i className='fa-light fa-xmark'></i>
          </div>
        </>
      )}
    </div>
  )
}

LeaseFee.defaultProps = {
  onClickUp: () => {},
  onClickDown: () => {},
  onRemove: () => {}
}

LeaseFee.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
}

export default LeaseFee
