import React from 'react'

class DateComponent extends React.Component {
  static get MONTHS() {
    return {  1: "January", 2: "February", 3: "March", 4: "April",
              5: "May", 6: "June", 7: "July", 8: "August",
              9: "September", 10: "October", 11: "November", 12: "December"}
  }

  static get THIS_MONTH() {
    return (new Date()).getMonth() + 1
  }

  static get THIS_DAY() {
    return (new Date()).getDate()
  }

  static get THIS_YEAR() {
    return (new Date()).getFullYear()
  }

  constructor(props) {
    super(props)

    this.handleChangeMonth = this.handleChangeMonth.bind(this)
    this.handleChangeDay = this.handleChangeDay.bind(this)
    this.handleChangeYear = this.handleChangeYear.bind(this)

    this.state = {}
    this.state.year = this.defaultYear()
    this.state.month = this.defaultMonth()
  }

  defaultMonth() {
    return this.state.month || this.props.month || this.constructor.THIS_MONTH
  }

  defaultDay() {
    return this.state.day || this.props.day || this.constructor.THIS_DAY
  }

  defaultYear() {
    return this.state.year || this.props.year || this.constructor.THIS_YEAR
  }

  handleChange(options) {
    const date = { month: this.props.month, year: this.props.year }
    if (this.props.show_days)
      date.day = this.props.day

    Object.assign(date, options)

    this.setState(options)

    if (this.props.onChange)
      this.props.onChange(date)
  }

  handleChangeMonth(evt) {
    this.handleChange({month: parseInt(evt.target.value)})
  }

  handleChangeDay(evt) {
    this.handleChange({day: parseInt(evt.target.value)})
  }

  handleChangeYear(evt) {
    this.handleChange({year: parseInt(evt.target.value)})
  }

  renderMonths() {
    return <select onChange={this.handleChangeMonth} defaultValue={this.defaultMonth()}>{this.renderMonthOptions()}</select>
  }

  renderMonthOptions() {
    return Object.keys(this.constructor.MONTHS).map(month => <option value={month} key={`month_${month}`}>{this.constructor.MONTHS[month]}</option>)
  }

  renderDays() {
    return <select onChange={this.handleChangeDay} defaultValue={this.defaultDay()}>{this.renderDayOptions()}</select>
  }

  renderDayOptions() {
    const startDate = new Date(this.state.year, this.state.month - 1, 1, 0, 0, 0),
          days = []

    let date

    while (startDate.getMonth() == this.state.month - 1) {
      date = startDate.getDate() + 0
      days.push(<option value={date} key={date}>{date}</option>)

      startDate.setDate(date + 1)
    }

    return days
  }

  renderYears() {
    return <select onChange={this.handleChangeYear} defaultValue={this.defaultYear()}>{this.renderYearOptions()}</select>
  }

  renderYearOptions() {
    const range = []
    for (let i = this.constructor.THIS_YEAR - 1; i < this.constructor.THIS_YEAR + 5; i++)
      range.push(i)

    return range.map(year => <option value={year} key={`year_${year}`}>{year}</option>)
  }

  render() {
    return <div className="date-input" ref="container">
      {this.renderMonths()}
      {this.props.show_days ? this.renderDays() : null}
      {this.renderYears()}
    </div>
  }
}

export default DateComponent