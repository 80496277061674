import {createStore, combineReducers} from 'redux'  
  
  // Action Definitions
  const SET_SHARE = "SET_SHARE"
  const UPDATE_SHARE_DETAILS = "UPDATE_SHARE_DETAILS"

  const ADD_SECTION = "ADD_SECTION"
  const EDIT_SECTION = "EDIT_SECTION"
  const REMOVE_SECTION = "REMOVE_SECTION"
  const REORDER_SECTIONS = "REORDER_SECTIONS"
  const MOVE_FILE = "MOVE_FILE"
  const ADD_FILE = "ADD_FILE"
  const EDIT_FILE = "EDIT_FILE"
  const REPLACE_FILE = "REPLACE_FILE"
  const REMOVE_FILE = "REMOVE_FILE"
  const REMOVE_LIBRARY_FILE = "REMOVE_LIBRARY_FILE"
  const LOAD_SECTIONS = "LOAD_SECTIONS"


  const GENERATE_SECTION_MODAL_ID = "GENERATE_SECTION_MODAL_ID"
  const GENERATE_FILE_UPLOAD_MODAL_ID = "GENERATE_FILE_UPLOAD_MODAL_ID"
  const PREPARE_FILES_FOR_UPLOAD = "PREPARE_FILES_FOR_UPLOAD"
  const SWAP_UPLOADED_FILE_ID = "SWAP_UPLOADED_FILE_ID"
  const ADD_FILES_FOR_UPLOAD = "ADD_FILES_FOR_UPLOAD"
  const EDIT_UPLOADED_FILE = "EDIT_UPLOADED_FILE"
  const EDIT_UPLOADED_FILES = "EDIT_UPLOADED_FILES"
  const REMOVE_UPLOADED_FILE = "REMOVE_UPLOADED_FILE"
  const CLEAR_UPLOADED_FILES = "CLEAR_UPLOADED_FILES"
  const OPEN_EDIT_SECTION = "OPEN_EDIT_SECTION"
  const OPEN_EDIT_FILE = "OPEN_EDIT_FILE"

  const ADD_PROCESSING_FILE = "ADD_PROCESSING_FILE"
  const REMOVE_PROCESSING_FILE = "REMOVE_PROCESSING_FILE"

  // Action Creators

  function loadSections(sections) {
    sections = sections || []
    sections = $.isArray(sections) ? sections : [ sections ]
    
    return {
      type: LOAD_SECTIONS,
      sections 
    }
  }

  function reorderSections(section_ids) {
    section_ids = section_ids || []
    section_ids = $.isArray(section_ids) ? section_ids : [section_ids]

    return {
      type: REORDER_SECTIONS,
      section_ids
    }
  }

  function prepareFilesforUpload(section_id, files) {
    files = $.isArray(files) ? files : [ files ]
    return {
      type: PREPARE_FILES_FOR_UPLOAD,
      section_id, 
      files
    }
  }

  function moveFile(file_id, new_place, old_section, new_section) {
    return {
      type: MOVE_FILE,
      file_id,
      new_place,
      old_section, 
      new_section
    }
  }

  function addFilesForUpload(files) {
    files = $.isArray(files) ? files : [ files ]
    return {
      type: ADD_FILES_FOR_UPLOAD,
      files
    }
  }

  function updateShareDetails(title, notes) {
    if (title.match(/^\s*$/))
      title = null
    if (notes.match(/^\s*$/))
      notes = null

    return {
      type: UPDATE_SHARE_DETAILS,
      title,
      notes
    }
  }

  const publicSectionActions = { 
    loadSections,
    updateShareDetails,
    reorderSections,
    moveFile,
    setShare(share) { return { type: SET_SHARE, share}},
    addSection(section) { return { type: ADD_SECTION, section }},
    editSection(section) { return { type: EDIT_SECTION, section}},
    removeSection(section_id) { return { type: REMOVE_SECTION, section_id}},
    addFile(file, section_id) { return { type: ADD_FILE, file, section_id}},
    editFile(file) { return { type: EDIT_FILE, file}},
    replaceFile(file) { return { type: REPLACE_FILE, file }},
    removeFile(file_id) { return { type: REMOVE_FILE, file_id}},
    removeLibraryFiles(library_document_id) { return { type: REMOVE_LIBRARY_FILE, library_document_id}}
  }

  const publicPresentationActions = {
    prepareFilesforUpload,
    addFilesForUpload,
    generateSectionModalId(id) { return { type: GENERATE_SECTION_MODAL_ID, id}},
    generateFileUploaderId(id) { return { type: GENERATE_FILE_UPLOAD_MODAL_ID, id}},
    openEditSection(section_id) { return { type: OPEN_EDIT_SECTION, section_id}},
    openEditFile(file_id) { return { type: OPEN_EDIT_FILE, file_id }},
    editUploadedFile(file_id, file) { return {type: EDIT_UPLOADED_FILE, file_id, file}},
    editUploadedFiles(files) { return {type: EDIT_UPLOADED_FILES, files}},
    removeUploadedFile(file_id) { return { type: REMOVE_UPLOADED_FILE, file_id}},
    swapUploadedFileId(old_file_id, new_file_id) { return { type: SWAP_UPLOADED_FILE_ID, old_file_id, new_file_id }},
    clearUploadedFiles() { return { type: CLEAR_UPLOADED_FILES }},
    addProcessingFile(library_document_id) { return { type: ADD_PROCESSING_FILE, library_document_id}},
    removeProcessingFile(library_document_id) { return { type: REMOVE_PROCESSING_FILE, library_document_id }}
  }

  // Initial State

  const initialSectionState = {
    sections: {},
    files: {},

    section_order: [],
    section_files: {}
  }


  /*
   * Reducers
   *
   ******************************************/

  function loadSectionIntoState(state, section) {
    state.sections[parseInt(section.id)] = section
    state.section_order.push(section.id)
    state.section_files[parseInt(section.id)] = []

    for (let file of section.files) 
      loadFileIntoState(state, file, parseInt(section.id))
  }

  function loadFileIntoState(state, file, section_id) {
    if (!section_id)
      section_id = file.board_share_section_id
    state.section_files[section_id].push(file.id)
    state.files[file.id] = file
  }

  function removeFileFromState(state, file_id) {
      let idx

      // Remove the file from the section
      for (let i in state.section_files) {
        idx = state.section_files[i].indexOf(file_id)
        if (idx == -1) continue

        state.section_files[i].splice(idx, 1)
        break
      }

      delete state.files[file_id]
      return state
  }

  function shareDataReducer(state = initialSectionState, action) {
    let new_state, id, idx, share, file_id
    switch(action.type) {
    case SET_SHARE:
      new_state = Object.assign({}, initialSectionState, {share: action.share})
      if (action.share.sections)
        for (let section of action.share.sections)
          loadSectionIntoState(new_state, section)

      return Object.assign({}, initialSectionState, new_state)

    case UPDATE_SHARE_DETAILS:
      share = Object.assign({}, state.share, { title: action.title, notes: action.notes })
      return Object.assign({}, state, { share })


    case LOAD_SECTIONS:
      new_state = $.extend(true, {}, initialSectionState)
      for (let section of action.sections) 
        loadSectionIntoState(new_state, section)

      return Object.assign({}, state, new_state)

    case REORDER_SECTIONS:
      new_state = Object.assign({}, state)
      new_state.section_order = action.section_ids

      // Ensure all the section_ids in the sort order have corresponding sections
      if (!new_state.section_order.every(id => state.sections[id]))
        return state

      // Ensure all sections are represented
      if (!Object.keys(new_state.sections).every(id => new_state.section_order.includes(id)))
        return state

      return new_state

    case MOVE_FILE:
      new_state = $.extend(true, {}, state)
      if (action.old_section) {
        idx = new_state.section_files[parseInt(action.old_section)].indexOf(action.file_id)
        if (idx >= 0)
          new_state.section_files[parseInt(action.old_section)].splice(idx, 1)
      }

      new_state.section_files[parseInt(action.new_section)].splice(action.new_place, 0, action.file_id)
      return new_state

    case ADD_SECTION:
      new_state = $.extend(true, {}, state)
      loadSectionIntoState(new_state, action.section)
      return new_state

    case EDIT_SECTION: 
      new_state = $.extend(true, {}, state)
      new_state.sections[parseInt(action.section.id)] = action.section
      return new_state

    case REMOVE_SECTION:
      new_state = $.extend(true, {}, state)
      id = parseInt(action.section_id)
      idx = new_state.section_order.indexOf(id)

      if (new_state.section_files[id])
        for (file_id of new_state.section_files[id])
          delete new_state.files[file_id]

      if (idx != -1)
        new_state.section_order.splice(idx, 1)

      delete new_state.section_files[id]
      delete new_state.sections[id]
      return new_state

    case ADD_FILE:
      new_state = $.extend(true, {}, state)

      loadFileIntoState(new_state, action.file, parseInt(action.section_id))
      return Object.assign({}, new_state)

    case EDIT_FILE:
      new_state = $.extend(true, {}, state)
      new_state.files[parseInt(action.file.id)].filename = action.file.filename
      return new_state

    case REPLACE_FILE:
      new_state = removeFileFromState($.extend(true, {}, state), action.file.id)
      loadFileIntoState(new_state, action.file, parseInt(action.file.board_share_section_id))
      return new_state

    case REMOVE_FILE:
      return removeFileFromState($.extend(true, {}, state), action.file_id)

    case REMOVE_LIBRARY_FILE:
      new_state = $.extend(true, {}, state)
      id = parseInt(action.library_document_id)

      for (file_id in state.files)
        if (state.files[file_id].library_document_id == id)
          removeFileFromState(new_state, parseInt(file_id))

      return new_state
    default:
      return state
    }
  }


  const initialPresentationState = {
    generate_section_id: "board_share-generate_section_modal",
    file_uploader_id: "board_share-file_uploader",
    upload_files: {},
    latest_file_id_for_new_files: 0,
    upload_section_id: null,

    edit_section_id: null,
    edit_file_id: null,

    processing_files: []
  }

  function presentationReducer(state = initialPresentationState, action) {
    let new_state, files, file, file_id, idx
    switch(action.type) {
    case GENERATE_SECTION_MODAL_ID:
      return Object.assign({}, state, {generate_section_id: action.id}) 
    case GENERATE_FILE_UPLOAD_MODAL_ID: 
      return Object.assign({}, state, {file_uploader_id: action.id}) 
    case PREPARE_FILES_FOR_UPLOAD: 
      files = Object.assign({}, state.upload_files)
      file_id = state.latest_file_id_for_new_files

      for (file of action.files)
        files[file.share_file && file.share_file.id ? file.share_file.id : --file_id] = file

      new_state = Object.assign({}, state, { upload_files: files, latest_file_id_for_new_files: file_id})
      if (action.section_id)
        new_state.upload_section_id = action.section_id

      return new_state

    case SWAP_UPLOADED_FILE_ID:
      files = Object.assign({}, state.upload_files)
      file = files[action.old_file_id]
      delete files[action.old_file_id]
      files[action.new_file_id] = files

      return Object.assign({}, state, { upload_files: files })
    case EDIT_UPLOADED_FILE:
      files = Object.assign({}, state.upload_files)
      files[action.file_id] = action.file
      return Object.assign({}, state, { upload_files: files })

    case EDIT_UPLOADED_FILES:
      return Object.assign({}, state, { upload_files: action.files })

    case CLEAR_UPLOADED_FILES:
      return Object.assign({}, state, { upload_files: {}})

    case REMOVE_UPLOADED_FILE:
      files = Object.assign({}, state.upload_files)
      delete files[action.file_id]
      return Object.assign({}, state, { upload_files: files})

    case OPEN_EDIT_FILE:
      return Object.assign({}, state, { edit_file_id: action.file_id })
    case OPEN_EDIT_SECTION:
      return Object.assign({}, state, { edit_section_id: action.section_id })

    case ADD_PROCESSING_FILE: 
      file_id = parseInt(action.library_document_id)
      if (state.processing_files.includes(file_id))
        return state

      return Object.assign({}, state, { processing_files: state.processing_files.concat([file_id])})
    case REMOVE_PROCESSING_FILE:
      file_id = parseInt(action.library_document_id)
      idx = state.processing_files.indexOf(file_id)
      if (idx < 0)
        return state

      files = state.processing_files.concat([])
      files.splice(idx, 1)
      return Object.assign({}, state, { processing_files: files})

    default:
      return state   
    }
  }

  const getStore = () => createStore(combineReducers({
      share: shareDataReducer, 
      presentation: presentationReducer
    }))

  export default Object.assign({getStore}, publicSectionActions, publicPresentationActions)
