import React, { useContext, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ModalContext } from '../../ModalContext'

const SubmittedConfirmation = () => {
  const { setCurrentModal } = useContext(ModalContext)
  const [show, setShow] = useState(true)
  const close = () => {
    setShow(false)
    setCurrentModal(null)
  }


  return (
    <Modal
      show={show}
      centered={true}
      size='md'
      dialogClassName='modal-size'
      className='lease-submit-modals lease-submitted-confirmation-modal'
    >
      <div className='modal-icon'></div>
      <div className='content'>
        <h3>Agreement Submitted to Processor(s)</h3>
        <p>
          Congratulations!{" "}
          <span>
            Property Management has been notified that the Agreement is ready
            for execution.
          </span>
        </p>
        <p>
          <span className='orange'>Next Steps</span>: You'll be notified when the Agreement is executed and all
          payments have been collected.
        </p>
        <div className="button-container center">
          <button className='cancel' onClick={close}>OK</button>
        </div>
      </div>
    </Modal>
  )
}

export default SubmittedConfirmation
