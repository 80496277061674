import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { format } from 'date-fns'

function prettyDate(date) {
  return format(new Date(date), "MMM do, yyyy @ h:mm:ss aaa")
}

function Granted({person}) {
  const granter = useSelector(state => state.sharingUsers[person.granted_by])

  return <>  
    <div className='f-row'>
      <p>Date Shared</p>
      <p>{person.granted_at ? prettyDate(person.granted_at) : "N/A"}</p>
    </div>
    { granter ? (<div className='f-row'>
      <p>Shared by</p>
      <p>{granter.name}</p>
    </div>) : null }
  </>
}

function Revoked({person}) {
  const revoker = useSelector(state => state.sharingUsers[person.revoked_by])

  if (!person.revoked_at)
    return null
    
  return <>  
  <div className='f-row'>
    <p>Date Revoked</p>
    <p>{person.revoked_at ? prettyDate(person.revoked_at) : "N/A"}</p>
  </div>
  { revoker ? (<div className='f-row'>
    <p>Revoked by</p>
    <p>{revoker.name}</p>
  </div>) : null }
</>
}

const ShareInfoToolTip = ({person}) => {
  const [isHovered, setIsHovered] = useState(false)
  return (
    <div>
      <i
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        className='fa-light fa-clock-rotate-left'
      ></i>
      {isHovered && <div className='share-tooltip'>
        { <Granted person={person} /> }
        { person.revoked_at && <Revoked person={person} /> }
      </div>}
    </div>
  )
}

export default ShareInfoToolTip
