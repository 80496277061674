import React, { createContext, useEffect, useState } from "react"
export const NoteContext = createContext()

const fetchData = async (package_id) => {
  try {
    const response = await fetch(`/package_hook/${package_id}/package_notes/get_package_notes`)
    if (!response.ok) throw new Error('Failed to fetch data')

    return await response.json()
  } catch (error) {
    console.error(error)
  }
}

const NoteContextProvider = ({ children, package_id, user_role, permissions }) => {
  const [notes, setNotes] = useState([])

  useEffect(() => {
    const getNotes = async () => {
      try {
        if (permissions.includes('sign_documents')) return

        const notes = await fetchData(package_id, user_role)
        if (!notes) throw new Error('Error setting notes')
        setNotes(notes)
      } catch(error) {
        console.error(error)
      }
    }

    getNotes()
  }, [])

  return (
    <NoteContext.Provider
      value={{ notes, setNotes, package_id }}
    >
      {children}
    </NoteContext.Provider>
  )
}

export default NoteContextProvider
