import React from 'react'

import GenerationModal from 'board_share/components/generate'

class AddButton extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <div className="add-button">
      <button type="button" className="btn btn-lg btn-bp-green" onClick={() => this.refs.modal.open()}>Initiate BoardShare</button>
      <GenerationModal ref="modal" building_search_path={BoardShare.Constants.possible_buildings} />
    </div>
  } 
}

export default AddButton