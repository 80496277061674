import { useCallback } from "react"
import { useSelector } from "react-redux"

// Signature Stuff
import {  useComponents as useSignatures, 
          useTool as useSignatureTool, 
          useGenerator as useSignatureGenerator
        } from "./signature"

// Signature Stuff
import {  useComponents as useHighlights, 
          useTool as useHighlightTool, 
          useGenerator as useHighlightGenerator
        } from "./highlight"

import { mergeTools } from 'components/form_creator/tools/base'


/*
 * Tools must have the following items for implementation
 *
 * useGenerator
 * useComponents
 * useTool
 * 
 */

// This function uses the useComponents
export function usePageToolComponents(tools, page_id) {
  const signatures = useSignatures(tools, page_id)
  const highlights = useHighlights(tools, page_id)

  return [
    signatures,
    highlights
  ]
}

// This function uses the useGenerator
export function useToolGenerator({keepDrawing} = {}) {
  const signatureGenerator = useSignatureGenerator({keepDrawing})
  const highlightGenerator = useHighlightGenerator({keepDrawing})

  return useCallback((type, rect) => {
    switch(type) {
      case 'signature':
        return signatureGenerator(rect)
      case 'highlight':
        return highlightGenerator(rect)
    }
  }, [keepDrawing])
}

// This function uses the useTool
export function useTools() {
  const signatures = useSignatureTool()
  const highlights = useHighlightTool()
  
  return mergeTools([
    signatures,
    highlights
  ])
}


/***********************************/
/** DO NOT EDIT BEYOND THIS POINT **/
/***********************************/

export function useToolPencil(page) {
  const type = useSelector(state => state.general.activeButton)
  const generator = useToolGenerator({keepDrawing: true})

  return useCallback((rect) => {
    if (!type)
      return

    // Prepare the rect
    rect = { ...rect, page }
    rect.width ||= 0
    rect.height ||= 0

    generator(type, rect)
  }, [generator, type])
}


export function useDrawPageDrop(tools, page) {
  const generator = useToolGenerator()

  return useCallback((componentId, rect) => {
    rect = { ...rect, page }
    const [check, type] = componentId.split(",")

    if (check == '__new')
      generator(type, rect) 
    else
      tools.tryDroppingItem(componentId, rect)
  }, [generator, tools, page])
}

export function useGenerateComponent(tools, selectedComponents) {
  return useCallback(({type, id, props}) => {
    const key = [type, id].join("#")
    const opts = {
      allSelected: selectedComponents
    }
    
    return tools.generateComponent(type, { key, ...props }, opts)
  }, [ tools, selectedComponents ])
}
