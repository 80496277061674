import React, { createContext, useEffect, useState, useMemo, useCallback } from "react"
import { getDefaultPaymentMethodId } from "../../defaultPaymentMethod"
import { dispatchEvent } from "../../../../../javascript/lib/utilities"
import { sum } from "lodash"

export const StripeAccContext = createContext()

export const checkPaymentStatus = (payments) => {
  const hasAuthorized = payments.some(payment => payment?.authorized_at)
  const hasUninitiated = payments.some(payment => !payment?.authorized_at)
  const allCollected = payments.every(payment => payment?.captured_at)
  const hasCollected = payments.some(payment => payment?.captured_at)
  const paymentsFailed = payments.some(payment => payment?.declined_at)

  return { hasAuthorized, hasUninitiated, allCollected, hasCollected, paymentsFailed }
}

export const collectionState = (allPayments, paymentsCaptured) => {
  const {paymentsFailed, allCollected, hasAuthorized} = checkPaymentStatus(allPayments)

  if (!paymentsCaptured) {
    return 'noneAuthorized'
  }

  if (paymentsFailed) {
    return 'hasBalance'
  }

  if (allCollected) {
    return 'fullyCollected'
  }

  if (hasAuthorized) {
    return 'processing'
  }

  return 'noneAuthorized'

}

// sets initial value for when user navigates away/refreshes page
const setInitialType = () => {
  const storedPaymentMethodType = sessionStorage.getItem('paymentMethodType')

  return storedPaymentMethodType || null
}

const StripeAccContextProvider = props => {
  const { all_payments, user_payment, bank_accounts, user_id, ACH_FEE, CARD_RATE, userRole } = props
  const [newPaymentInfoAdded, setNewPaymentInfoAdded] = useState(false)
  const [paymentSuccess, setPaymentSuccess] = useState(!!user_payment?.created_at)
  const [userPayment, setUserPayment] = useState(user_payment)
  const [cards, setCards] = useState(props.cards)
  const [allPayments, setAllPayments] = useState(all_payments)
  const [bankAccounts, setBankAccounts] = useState(bank_accounts)
  const [amountWithCommission, setAmountWithCommission] = useState(null)
  const [openInstantVerification, setOpenInstantVerification] = useState(false)
  const [paymentMethodId, setPaymentMethodId] = useState(getDefaultPaymentMethodId || null)
  const [type, setType] = useState(setInitialType)
  const [failedInitiation, setFailedInitiation] = useState(false)
  const [paymentCollectionStatus, setPaymentCollectionStatus] = useState(collectionState(all_payments, props.capture_initiated_at))
  const [feesSubmitted, setFeesSubmitted] = useState(props.fees_submitted)
  const [captureInitiatedAt, setCaptureInitiatedAt] = useState(props.capture_initiated_at)

  const updateCollectionStatus = useCallback(() => {
    if (!feesSubmitted && !captureInitiatedAt) return 'noneAuthorized'

    return collectionState(allPayments, !!captureInitiatedAt)
  }, [allPayments, feesSubmitted, captureInitiatedAt])

  const authorizedTotal = useMemo(() => sum(allPayments.map(p => +p.amount)), [allPayments])

  const isPendingVerification = useMemo(() => {
    const isNew = paymentMethodId === 'new-account' || paymentMethodId === 'new-card'
    const selectedAccount = bankAccounts.find(account => +paymentMethodId === account.id)

    if (isNew) {
      return false
    }

    if (!selectedAccount || type === "card") {
      return false
    }
    // return truthy or falsy value of account NOT verified
    return !selectedAccount.verified
  }, [paymentMethodId, bankAccounts, type])

  useEffect(() => {
    const leaseSubmitted = ({ detail }) => setFeesSubmitted(!!detail.submittedAt)

    window.addEventListener("lease-has-been-submitted", leaseSubmitted)
    return () => window.removeEventListener("lease-has-been-submitted", leaseSubmitted)
  }, [setFeesSubmitted])

  useEffect(() => {
    const updatedStatus = updateCollectionStatus()
    setPaymentCollectionStatus(updatedStatus)
  }, [updateCollectionStatus])

  useEffect(() => {
    if (allPayments.length > 0){
      const userPayment = allPayments.find(p => p.user_id === user_id)
      setUserPayment(userPayment)
    }

  },[allPayments, user_id])

  useEffect(() => {
    if (userPayment?.created_at) {
      setPaymentMethodId(userPayment.payment_method_id)
      setAmountWithCommission((+userPayment.amount) + (+userPayment.commission))
    }
    if (userPayment?.amount) {
      setPaymentSuccess(true)
    }

  }, [userPayment])

  useEffect(() => {
    dispatchEvent('lease-authorized-total-changed', { authorizedTotal: authorizedTotal })
  }, [authorizedTotal])

  return (
    <StripeAccContext.Provider
      value={{
        newPaymentInfoAdded,
        setNewPaymentInfoAdded,
        paymentMethodId,
        setPaymentMethodId,
        paymentSuccess,
        setPaymentSuccess,
        cards,
        setCards,
        userPayment,
        setUserPayment,
        allPayments,
        setAllPayments,
        authorizedTotal,
        bankAccounts,
        setBankAccounts,
        amountWithCommission,
        setAmountWithCommission,
        openInstantVerification,
        setOpenInstantVerification,
        type,
        setType,
        isPendingVerification,
        failedInitiation,
        setFailedInitiation,
        paymentCollectionStatus,
        setPaymentCollectionStatus,
        feesSubmitted,
        setFeesSubmitted,
        ACH_FEE,
        CARD_RATE,
        captureInitiatedAt,
        setCaptureInitiatedAt,
      }}
    >
      {props.children}
    </StripeAccContext.Provider>
  )
}

export default StripeAccContextProvider
