import React from 'react'

class TimedOutModal extends React.Component {
  componentDidMount() {
    this.open()
  }

  open() {
    $(this.refs.modal).modal("show")
  }

  cancel() {
    window.location = "/"
  }

  timedOut() {
    return this.props.reason == "timed_out"
  }

  title() {
    return this.timedOut() ? "Document Expired" : "Access Limit Reached"
  }

  reason() {
    if (this.timedOut())
      return <div>Your access to this document has timed out.</div>

    return <div>You have already reached the maximum number of accesses allowed for this document</div>
  }

  render() {
    return <div className="modal timed-out" ref="modal" id="board-minutes-accessibility-warning" tabIndex="-1" role="dialog" data-show="true" data-backdrop="static">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <h3>{this.title()}</h3>

            <div className="clock">
              <div><img src={BoardMinutes.Constants.Images.clock} /></div>
            </div>

            {this.reason()}

            <div>If you feel you've reached this screen in error or you need to renew your access, please contact Property Management.</div>
          </div>
          <div className="modal-footer">
            <div className="buttons">
              <button type="button" className="cancel" onClick={() => this.cancel()}>OK</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default TimedOutModal