import React, { useContext } from 'react'
import { FeeContext } from '../../contexts/FeeContext'
import LeaseFee from './LeaseFee'

const LeaseFeeList = ({title}) => {
  const { fees, dispatch } = useContext(FeeContext)

  const moveFeeUp = (fee) => {
    const index = fees.indexOf(fee)
    if (index <= 0) return
    const swapWith = fees[index - 1]
    dispatch({
      type: "EDIT_FEES",
      editedFees: [
        { ...fee, order: swapWith.order },
        { ...swapWith, order: fee.order },
      ],
    })
  }
  const moveFeeDown = (fee) => {
    const index = fees.indexOf(fee)
    if (index < 0 || index === fees.length - 1) return
    const swapWith = fees[index + 1]
    dispatch({
      type: "EDIT_FEES",
      editedFees: [
        { ...fee, order: swapWith.order },
        { ...swapWith, order: fee.order },
      ],
    })
  }

  const isFirst = (fee) => {
    const index = fees.indexOf(fee)
    return index === 0
  }

  const isLast = (fee) => {
    const index = fees.indexOf(fee)
    return index === fees.length - 1
  }

  const handleClick = e => {
    const feeType = e.target.id
    dispatch({ type: "ADD_FEE", feeType })
  }

  return (
    <div className='lease-fee-list'>
      {(fees.length < 1) &&
        <>
          <p className='no-fee-text'>Assign a fee to this agreement to get started.</p>
        </>
      }
      { fees.map(fee => <LeaseFee key={fee.uuid} fee={fee} isFirst={isFirst} isLast={isLast} onClickUp={moveFeeUp} onClickDown={moveFeeDown} />) }
    </div>
  )
}

export default LeaseFeeList
