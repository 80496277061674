import React from 'react'

const LinkBox = () => {
  return (
    <div className='box-payment-links'>
      <a href="#" className='payment-link'>
        <div><i className='fa-light fa-code-compare'></i></div>
        <div>Compare Methods</div>
      </a>
      <a target="_blank" href="https://stripe.com/legal/ach-payments/authorization" className='payment-link'>
        <div><i className='fa-light fa-circle-info'></i></div>
        <div className="stripe-ach-policy">
          <div className='stripe-wordmark'></div>
          <div>ACH Policy</div>
        </div>
      </a>
      <a target="_blank" href="/about/refunds" className='payment-link'>
        <div><i className='fa-light fa-circle-question'></i></div>
        <div>Refunds</div>
      </a>
    </div>
  )
}

export default LinkBox
