import React, { useRef, useEffect, useState, useContext } from "react"
import { ModalContext } from "../../../ModalContext"
import axios from 'axios'
import { LeaseContext } from "../../../fees/contexts/LeaseContext"

const ElectronicSignatureConsentModal = ({ onCancel }) => {
  const [isConfirming, setIsConfirming] = useState(false);
  const ref = useRef()
  const { setCurrentModal } = useContext(ModalContext)
  const { setElectronicSigningConsentedAt } = useContext(LeaseContext)

  const cleanup = () => $(ref.current).modal("hide")

  useEffect(() => {
    if (!ref.current) return

    const modal = $(ref.current)
    const close = () => setCurrentModal(null)
    modal.on("hidden.bs.modal", close)
    modal.modal({ backdrop: "static", keyboard: false })

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  const cancel = () => {
    onCancel()
    cleanup()
  }

  const confirm = () => {
    setIsConfirming(true)
    axios.post('leases/documents/consent_to_electronic_signing')
      .then(response => {
        console.log('Request successful')
        setElectronicSigningConsentedAt(response.data.electronic_signing_consented_at)
        cleanup()
      })
      .catch(error => {
        console.log('Error:', error)
      })
      .finally(() => {
        setIsConfirming(false);
      })
  }

  const confirmButton = () => (
    <button className={isConfirming ? 'disabled' : ''} onClick={isConfirming ? null : confirm} style={{backgroundColor: '#56a256', color: 'white', float: 'right', fontWeight: 'bold'}}>
      {isConfirming ? <i className='fa fa-spinner fa-spin fa-2x' /> : 'Agree + Continue'}
    </button>
  );

  return <div ref={ref} className='modal fade lease-documents-get-started-modal'>
    <div className='modal-box' style={{width: '50rem'}}>
      <div className='header'>
        <h4><i className='fa fa-thin fa-circle-info' style={{ fontSize: 23, verticalAlign: 'middle' }} /> <span style={{ verticalAlign: 'middle' }}>Electronic Signature Consent</span></h4>
      </div>
      <div className='body'>
        <div style={{ marginBottom: 5 }}>By inserting your electronic signature on the following documents, you agree it is the legally binding equivalent to your handwritten signature. You also confirm that you are the individual named in the electronic signature, and you are authorized to sign this document.</div>
        <div style={{color: '#898989'}}><i>Once all requirements are met, another deal party may submit the completed Agreement on your behalf.</i></div>
        <div className="button-container">
          <button className='cancel' onClick={cancel} style={{ float: 'left', backgroundColor: 'white', border: '1px solid #898989'}}>Cancel</button>
          {confirmButton()}
        </div>
      </div>
    </div>
  </div>
}

export default ElectronicSignatureConsentModal
