import React from 'react'
import HeaderDescriptionSelector from '../HeaderDescriptionSelector'
import Layout from '../../../Layout'
import AuthPaymentDetails from './AuthPaymentDetails'
import BreakdownTable from './BreakdownTable'

const AuthFees = () => {

  return (
    <Layout
      className='auth-fees'
      top={<HeaderDescriptionSelector />}
      left={<BreakdownTable />}
      right={<AuthPaymentDetails />}
    />
  )
}

export default AuthFees
