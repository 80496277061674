import React, { useContext, useEffect, useState } from 'react'
import { getDefaultPaymentMethodId } from '../../../defaultPaymentMethod'
import { StripeAccContext } from '../../contexts/StripeAccContext'

const generateOptions = (options, prefix) => {
  return (options || []).map(option => {
    const label = `${prefix} Ending in ${option.last_4}`
    return (
      <option key={option.id} value={option.id}>
        {label}
      </option>
    )
  })
}

const SelectOptions = ({ cards, bankAccounts, type }) => {
  const options = type === "ach" ? bankAccounts : cards
  const prefix = type === "ach" ? "Acct" : "Card"
  const newOptionLabel = type === "ach" ? "Account" : "Card"
  const newOptionValue = newOptionLabel.toLowerCase()

  const generateLastOption = (value, label) => (
    <option key={`new-${type}-${value}`} value={`new-${value}`} style={{color: '#f68a41', fontWeight: '600'}}>
      + New {label}
    </option>
  )

  return [...generateOptions(options, prefix), generateLastOption(newOptionValue, newOptionLabel)]
}

const Select = ({name, id, onChange, value, children}) => {
  return (
    <>
      <select name={name} id={id} onChange={onChange} value={value}>
        {children}
      </select>
    </>
  )
}

const PaymentsDropdown = () => {
  const { cards, bankAccounts, type, paymentMethodId, setPaymentMethodId, newPaymentInfoAdded, setNewPaymentInfoAdded } = useContext(StripeAccContext)
  const [currentType, setCurrentType] = useState(type)

  useEffect(() => {
    // switching types: reset the state if type switches
    if (currentType !== type) {
      sessionStorage.removeItem("paymentMethodId")
      setNewPaymentInfoAdded(false)
      setCurrentType(type)
    }
  }, [type, currentType])

  useEffect(() => {
    // any time new paymentmethod added, or type changes
    const paymentMethodList = type === 'ach' ? bankAccounts : cards
    const defaultPaymentMethodId = getDefaultPaymentMethodId(type, paymentMethodList, newPaymentInfoAdded)
    sessionStorage.setItem('paymentMethodId', defaultPaymentMethodId)
    setPaymentMethodId(defaultPaymentMethodId)
  }, [type, cards, bankAccounts, newPaymentInfoAdded])

  // upon changing value of pm, set it to new value, reset state of new info added
  // and add new value to session storage
  const handlePaymentMethodChange = e => {
    setPaymentMethodId(e.target.value)
    setNewPaymentInfoAdded(false)
    sessionStorage.setItem("paymentMethodId", e.target.value)
  }

  return (
    <div className='payment-selector-container'>
      <Select
        id='payments-dropdown-select'
        name='payments-dropdown-select'
        onChange={handlePaymentMethodChange}
        value={paymentMethodId || ''}
      >
        <SelectOptions cards={cards} bankAccounts={bankAccounts} type={type} />
      </Select>
    </div>
  )
}

export default PaymentsDropdown
