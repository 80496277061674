import React from 'react'

import InputGroup from '../utility/input_group'
import DateComponent from '../utility/date'
import BuildingsComponent from '../utility/buildings'

class GenerationModal extends React.Component {
  constructor(props) {
    super(props)

    const now = new Date()

    this.state = {}
    this.state.errors = {}
    this.state.type = this.props.type
    this.state.month = this.props.month || now.getMonth() + 1
    this.state.year = this.props.year || now.getFullYear()

    this.state.selected_buildings = []
    this.state.possible_templates = { org: [], personal: []}

    this.generate = this.generate.bind(this)
    this.typeChanged = this.typeChanged.bind(this)
    this.dateChanged = this.dateChanged.bind(this)
    this.templateChanged = this.templateChanged.bind(this)
    this.setSelectedBuildings = this.setSelectedBuildings.bind(this)

    this.handleIgnoreTemplate = this.handleIgnoreTemplate.bind(this)
  }

  componentDidMount() {
    const reorient = this.refs.buildings_component.reorientDiscovered.bind(this.refs.buildings_component)

    this.modal = $(this.refs.modal)
    this.modal.on("show.bs.modal", reorient)
  }

  open() {
    this.modal.modal("show")
  }

  close() {
    this.modal.modal("hide")
  }

  typeChanged(type) {
    const state = Object.assign({}, this.state, {type: type})
    delete state.errors.category

    this.setState(state, () => this.gatherTemplates())
  }

  dateChanged(new_date) {
    const state = Object.assign({}, this.state, new_date)
    delete state.errors.period

    this.setState(state)
  }

  templateChanged(template) {
    if (typeof(template) == "string" && template.length == 0)
      template = null

    const state = Object.assign({}, this.state, {template: template})
    delete state.errors.template

    this.setState(state)
  }

  setSelectedBuildings(selected_buildings) {
    const state = Object.assign({}, this.state, { selected_buildings: selected_buildings})
    delete state.errors.buildings
    delete state.errors.organization

    this.setState(state, () => this.gatherTemplates())
  }

  isFormFilled() {
    if (this.state.selected_buildings.length == 0)
      return false

    if (!this.state.type || this.state.type.match(/^\s*$/))
      return false

    if (!this.state.year || !this.state.month || this.state.day)
      return false

    if (!this.state.template && !this.state.ignore_template && this.hasTemplates())
      return false

    return true
  }

  generate() {
    if (!this.isFormFilled())
      return

    const data = {}
    data.buildings = this.state.selected_buildings.map(building => building.id)
    data.category = this.state.type

    if (this.state.template && !this.state.ignore_template)
      data.template_id = this.state.template

    data.period = `${this.state.year}-${this.state.month}-${this.state.day || 1}`

    $.ajax({
      url: BoardShare.Constants.create_path,
      type: "post",
      data: hashToPostQueryString({ share: data }),
      success: this.success.bind(this),
      error: this.error.bind(this)
    })
  }

  totalTemplates() {
    let count = this.state.possible_templates.org.length + this.state.possible_templates.personal.length

    if (this.state.possible_templates.default_template)
      count++

    return count
  }

  hasTemplates() {
    return this.totalTemplates() > 0
  }

  gatherTemplates() {
    const data = {
      building_ids: this.state.selected_buildings.map(building => building.id),
      category: this.state.type
    }

    if (data.building_ids.length == 0 || !data.category)
      return this.setState({possible_templates: { org: [], personal: []}})

    $.ajax({
      url: BoardShare.Constants.Templates.possible_templates,
      type: "get",
      data: data,
      success: (res) => this.gatheredTemplates(res.templates, res.default_template)
    })
  }

  success(share) {
    window.location = BoardShare.Constants.get_path.replace(":id", share.id)
  }

  gatheredTemplates(templates, default_template) {
    const possible_templates = { org: [], personal: [] }
    for (let template of templates)
      if (!default_template || template.id != default_template.id)
        possible_templates[template.personal ? "personal" : "org"].push(template)

    if (default_template)
      possible_templates.default_template = default_template

    const state = {}
    state.possible_templates = possible_templates

    if (possible_templates.default_template)
      state.template = possible_templates.default_template.id
    else if (templates.length == 1)
      state.template = templates[0].id

    this.setState(state)
  }

  error(xhr) {
    BoardShare.Globals.processErrors(xhr, this)
  }

  renderTitle() {
    return <div className="modal-header">
      <h4 className="modal-title">Create a BoardShare</h4>
      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  }

  gatherErrors(error_keys) {
    const keys = $.isArray(error_keys) ? error_keys : Array(error_keys),
          errors = []

    for (var err of keys)
      if (this.state.errors[err])
        errors.push(this.state.errors[err])

    return errors
  }

  renderErrors(error_keys) {
    const keys = $.isArray(error_keys) ? error_keys : Array(error_keys),
          errors = []

    for (var err of keys)
      if (this.state.errors[err])
        errors.push(<div className="error">{this.state.errors[err]}</div>)

    return errors
  }

  renderInputGroup(label, input, options) {
    const error_keys = options.error_id || options.error_ids

    return <InputGroup label={label} errors={this.state.errors} error_keys={error_keys}>
      {input}
    </InputGroup>
  }

  renderSelectType() {
    return <select className="category" defaultValue={this.state.type} onChange={(e) => this.typeChanged(e.target.value)}>{this.renderSelectTypeOptions()}</select>
  }

  renderSelectTypeOptions() {
    const options = [<option value="" key="default_selectType">-- Select a Type --</option>]

    return options.concat(Object.keys(BoardShare.Constants.Categories).map(key => <option value={key} key={`selectType_${key}`}>{BoardShare.Constants.Categories[key]}</option>))
  }

  handleIgnoreTemplate(e) {
    this.setState({ignore_template: e.target.checked})
  }

  renderPossibleTemplates() {
    if (!this.hasTemplates())
      return ""

    const opt_groups = [],
          default_template = this.state.possible_templates.default_template,
          org = this.state.possible_templates.org,
          personal = this.state.possible_templates.personal,
          select_classes = this.state.ignore_template ? ["disabled"] : [],
          decision_required = <div className="decision-required">You must either select a template or check the box indicating you want to ignore the templates.</div>

    if (default_template)
      opt_groups.push(<optgroup key="default" label="Default"><option value={default_template.id}>{default_template.title}</option></optgroup>)
    else if (this.totalTemplates() > 1)
      opt_groups.push(<option key="pick" value=""> -- Select a Template -- </option>)

    if (org.length > 0) {
      opt_groups.push(<optgroup key="org" label="Shared Across Organization">
        {org.map(template => <option key={template.id} value={template.id}>{template.title}</option>)}
      </optgroup>)
    }

    if (personal.length > 0) {
      opt_groups.push(<optgroup key="personal" label="Your Templates">
        {personal.map(template => <option key={template.id} value={template.id}>{template.title}</option>)}
      </optgroup>)
    }

    const inside = <div className="template-area">
      <select disabled={this.state.ignore_template} className={select_classes.join(" ")} onChange={evt => this.templateChanged(evt.target.value)}>{opt_groups}</select>
      <div className="no-template">
        Click here to ignore templates: <input type="checkbox" onChange={this.handleIgnoreTemplate} />
      </div>
      {this.state.template || this.state.ignore_template ? "" : decision_required}
    </div>

    return this.renderInputGroup("Template", inside, {error_id: "template"})
  }

  renderBody() {
    return <div className="modal-body">
      {this.renderInputGroup("Select Type", this.renderSelectType(), {error_id: "category"})}
      {this.renderInputGroup("Monthly Report Date", <DateComponent month={this.state.month} year={this.state.year} onChange={this.dateChanged} />, {error_id: "period"})}
      {this.renderInputGroup("Building(s) or Code", <BuildingsComponent search_path={this.props.building_search_path} ref="buildings_component" selected={this.state.selected_buildings} setSelectedBuildings={this.setSelectedBuildings} />, { error_ids: ["buildings", "organization"]})}
      {this.renderPossibleTemplates()}
    </div>
  }

  renderFooter() {
    const classes = ["btn", "btn-bp-green"]
    if (!this.isFormFilled())
      classes.push("disabled")

    return <div className="modal-footer text-center">
      <button type="button" disabled={!this.isFormFilled()} className={classes.join(" ")} onClick={this.generate}>Inititate BoardShare</button>
    </div>
  }

  render() {
    return <div className="modal boardshare-generation-modal" tabIndex="-1" role="dialog" ref="modal">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {this.renderTitle()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    </div>
  }
}

export default GenerationModal