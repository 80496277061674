import React from 'react'
import { format, parseISO } from 'date-fns'


const prettyDate = (timeStamp) => {
  const unixTime = parseISO(timeStamp)

  return format(unixTime, 'MM/dd/yyyy, hh:mm aa')
}

const ActivityUpdateRow = ({ feedUpdate }) => {
  return (
    <div className='activity-row'>
      <div className='left-section'>
        <p>{feedUpdate.description}</p>
      </div>
      <div className="right-section">
        <p> {prettyDate(feedUpdate.created_at)}</p >
      </div>
    </div>
  )
}

export default ActivityUpdateRow
