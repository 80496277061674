import {createStore} from 'redux'

const LOAD_SHARE = "LOAD_SHARE"
const ADD_EDIT_USER = "ADD_EDIT_USER"
const SHARE_WITH_USERS = "SHARE_WITH_USERS"
const UNSHARE_WITH_USERS = "UNSHARE_WITH_USERS"

const SELECT_USER = "SELECT_USER"
const UNSELECT_USER = "UNSELECT_USER"
const SELECT_ALL_USERS = "SELECT_ALL_USERS"
const UNSELECT_ALL_USERS = "UNSELECT_ALL_USERS"

const SET_CURRENT_USER = "SET_CURRENT_USER"

function addEditUser(user, building_roles, shares) {
  for (let building_id in building_roles)
    if (!$.isArray(building_roles[building_id]))
      building_roles[building_id] = []

  return {
    type: ADD_EDIT_USER,
    user,
    building_roles,
    shares
  }
}

const publicUserActions = {
  addEditUser,
  setShare: share => { return { type: LOAD_SHARE, share }},
  shareWithUsers: shares => { return { type: SHARE_WITH_USERS, shares} },
  unshareWithUsers: user_ids => { return { type: UNSHARE_WITH_USERS, user_ids }}
}

const publicPresentationActions = {
  selectUser: (user, user_type) => { return { type: SELECT_USER, user_type, user }},
  unselectUser: (user, user_type) => { return { type: UNSELECT_USER, user_type, user }},

  selectAllUsers: (user_type) => { return { type: SELECT_ALL_USERS, user_type}},
  unselectAllUsers: (user_type) => { return { type: UNSELECT_ALL_USERS, user_type}},

  setCurrentUser: (user_id) => { return { type: SET_CURRENT_USER, user_id} }
}

const initialUserState = {
  share: {},
  users: {},
  buildings: {},

  org_users: [],
  non_org_users: [],
  board_members: [],
  excluded_board_members: [],

  user_shares: {},
  org_user_buildings: {},
  non_org_user_buildings: {},
  board_member_buildings: {}
}

function uniquelyAdd(item, list) {
  list.includes(item) ? null : list.push(item)
}

function ensureKeyIsHash(hash, key) {
  if (!hash[key])
    hash[key] = {}

  return hash[key]
}

function ensureKeyIsArray(hash, key) {
  if (!hash[key])
    hash[key] = []

  return hash[key]
}

function translateUserShares(state_shares, shares) {
  shares = typeof(shares) == "object" ? shares : {}

  for (let key in shares)
    if (shares[key].user_id)
      state_shares[parseInt(shares[key].user_id)] = shares[key]
}

function removeUserShares(state_shares, user_ids) {
  for (let user_id of user_ids)
    delete state_shares[parseInt(user_id)]
}

function addUser(users, user_id, user) {
  user_id = parseInt(user_id)
  users[user_id] = {
    first: user.first_name || user.first,
    last: user.last_name || user.last,
    full_name: user.full_name,
    email: user.email,
    phone: user.phone || user.phone_number
  }
}

function propertyContactsByUser(property_contacts) {
  const users = {}
  if (!property_contacts)
    return users

  for (let contact of property_contacts)
    uniquelyAdd(contact, ensureKeyIsArray(users, contact.user_id))

  return users
}

function addPropertyContactsToState(building_contacts, users, user_list, user_property_contacts) {
  let contacts
  for (let building_id in building_contacts) {
    contacts = propertyContactsByUser(building_contacts[building_id])

    for (let user_id in contacts) {
      addUser(users, user_id, contacts[user_id][0])
      uniquelyAdd(parseInt(user_id), user_list)
      ensureKeyIsHash(user_property_contacts, user_id)[building_id] = contacts[user_id].map(contact => contact.display_role)
    }
  }
}

function userReducer(state = initialUserState, action) {
  let new_state, share, building, building_id, user_id, user, arr, role_label
  switch(action.type) {
  case LOAD_SHARE:
    new_state = Object.assign({}, initialUserState)
    share = action.share
    new_state.share = share

    for (building of share.buildings)
      new_state.buildings[building.id] = building

    addPropertyContactsToState(share.organization_property_contacts, new_state.users, new_state.org_users, new_state.org_user_buildings)
    addPropertyContactsToState(share.out_of_org_property_contacts, new_state.users, new_state.non_org_users, new_state.non_org_user_buildings)
    addPropertyContactsToState(share.board_member_property_contacts, new_state.users, new_state.board_members, new_state.board_member_buildings)

    for (user_id in share.board_members_and_buildings) {
      addUser(new_state.users, user_id, share.board_members_and_buildings[user_id].user)
      uniquelyAdd(parseInt(user_id), new_state.board_members)
      for (building_id in new_state.users[user_id].buildings)
        ensureKeyIsArray(ensureKeyIsHash(new_state.board_member_buildings, user_id), building_id)
    }

    for (user of share.users_with_edit_access) {
      addUser(new_state.users, user.id, user)
      uniquelyAdd(user.id, new_state.org_users)
    }

    translateUserShares(new_state.user_shares, share.shares)
    return new_state
  case ADD_EDIT_USER:
    new_state = $.extend(true, {}, state)
    addUser(new_state.users, action.user.id, action.user)
    uniquelyAdd(parseInt(action.user.id), new_state.org_users)

    for (building_id in action.building_roles) {
      arr = ensureKeyIsArray(ensureKeyIsHash(new_state.org_user_buildings, action.user.id), building_id)
      for (role_label of action.building_roles[building_id])
        uniquelyAdd(role_label, arr)
    }

    translateUserShares(new_state.user_shares, action.shares)

    return new_state
  case SHARE_WITH_USERS:
    new_state = $.extend(true, {}, state)
    translateUserShares(new_state.user_shares, action.shares)
    return new_state

  case UNSHARE_WITH_USERS:
    new_state = Object.assign({}, state.user_shares)
    removeUserShares(new_state, action.user_ids)

    return Object.assign({}, state, { user_shares: new_state })
  default:
    return state
  }
}

const initialPresentationState = {
  selected_org_users: [],
  selected_non_org_users: [],
  selected_board_members: []
}

function presentationReducer(state = initialPresentationState, action) {
  let new_state = Object.assign({}, state), user_list, all_users, list_key, idx, user_id
  switch(action.type) {
  case SELECT_USER:
    list_key = `selected_${action.user_type}s`
    user_list = new_state[list_key] = new_state[list_key].concat([])

    uniquelyAdd(parseInt(action.user.id), user_list)
    return new_state

  case UNSELECT_USER:
    list_key = `selected_${action.user_type}s`
    user_list = new_state[list_key] = new_state[list_key].concat([])
    idx = user_list.indexOf(parseInt(action.user.id))
    if (idx == -1)
      return state

    user_list.splice(idx, 1)
    return new_state

  case SELECT_ALL_USERS:
    list_key = `selected_${action.user_type}`
    if (!list_key.endsWith('s')) list_key += 's'

    user_list = new_state[list_key] = new_state[list_key].concat([])
    all_users = action[action.user_type] || []
    for (user_id of all_users)
      uniquelyAdd(parseInt(user_id), user_list)

    return new_state

  case UNSELECT_ALL_USERS:
    list_key = `selected_${action.user_type}`
    if (!list_key.endsWith('s')) list_key += 's'

    new_state[list_key] = []
    return new_state

  case SET_CURRENT_USER:
    new_state.current_user_id = action.user_id
    return new_state

  default:
    return state
  }
}

const rootReducers = (state = {}, action) => {
  const org_users = state.users ? state.users.org_users : [],
        non_org_users = state.users ? state.users.non_org_users : [],
        board_members = state.users ? state.users.board_members : []

  return {
    users: userReducer(state.users, action),
    presentation: presentationReducer(state.presentation, { ...action, org_users, non_org_users, board_members})
  }
}

const getStore = () => createStore(rootReducers)

export default Object.assign({getStore}, publicUserActions, publicPresentationActions)