import React, { useEffect, useState } from 'react'
import { currencyMask, stripNumber } from '../../currency'

const onKeyDownHandler = (event) => {
  const keyCode = event.keyCode
  const excludedKeys = [8, 37, 39, 46, 9];

  if (!((keyCode >= 48 && keyCode <= 57) ||
    (keyCode >= 96 && keyCode <= 105) ||
    (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
  }
}

const EditableField = ({name, label, data, required, disabled, groupClass, id, isDigitsOnly, updateStateOnChange, type, subMerchants}) => {
  const [value, setValue] = useState(data)
  const [amountValue, setAmountValue] = useState(value)

  const onChangeHandler = event => {
    let value = event.target.value

    if (type === 'text' & id === 'amount'){
      if (event.target.value == "" || event.target.value == "$") {
        value = 0
      }
      setAmountValue(stripNumber(value, true))
    } else {
      setValue(value)
    }
  }

  useEffect(() => {
    if (id === 'amount') {
      const stripped = stripNumber(amountValue, isDigitsOnly)
      updateStateOnChange(id, stripped)
    } else {
      updateStateOnChange(id, value)
    }

  },[value, amountValue])

  return (
    <>
      {type == "text" && (
        <div className={`input-group ${groupClass ? groupClass : ""}`}>
          <input
            className={`input-field ${disabled ? "read-only-input" : ""}`}
            type='text'
            name={name}
            id={id}
            value={id === "amount" ? currencyMask(amountValue) : value || ""}
            onChange={onChangeHandler}
            onKeyDown={isDigitsOnly ? onKeyDownHandler : null}
            readOnly={disabled}
          />
        </div>
      )}
      {type === "dropdown" && (
        <div className={`input-group ${groupClass ? groupClass : ""}`}>
          <select
            className='input-field option-fill'
            name={name}
            id={id}
            onChange={onChangeHandler}
            value={data || ""}
            disabled={disabled}
          >
            {subMerchants.map((option, i) => {
              return (
                <option key={i} value={option.id}>
                  {option.name}
                </option>
              )
            })}
          </select>
        </div>
      )}
    </>
  )
}

export default EditableField
