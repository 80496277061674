import React from 'react'

class ViewingAgreement extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  open() {
    $(this.refs.modal).modal("show")
  }

  close() {
    $(this.refs.modal).modal("hide")
  }

  trim(string) {
    return string.replace(/(^\s+)|(\s+$)/g, "").replace(/\s+/g, " ")
  }

  error() {
    if (!this.state.name)
      return "Please type your name"
    if (this.state.name.match(/^\s*$/))
      return "Please type your name"

    let lowercase = this.trim(this.state.name.toLowerCase()),
        original_lowercase = this.trim(this.props.name.toLowerCase())

    if (lowercase.replace(/[^a-z0-9]/g, "") != original_lowercase.replace(/[^a-z0-9]/g, ""))
      return "Please use the same name as above."

    if (lowercase != original_lowercase)
      return "Please match the names and punctuation precisely."

    if (this.state.name.replace(/[^A-Za-z0-9]/g, "") != this.props.name.replace(/[^A-Za-z0-9]/g, ""))
      return "Please match capitalization exactly"

    return null
  }

  accept() {
    const error = this.error()
    if (error)
      return this.setState({ error })

    if (typeof(this.props.onAccept) == "function")
      this.props.onAccept()
  }

  cancel() {
    window.location = "/"
  }

  renderFirm() {
    return <div>
      <div>Firm:</div>
      <div>{this.props.firm}</div>
    </div>
  }

  renderLicense() {
    if(!this.props.license || this.props.license.match(/^\s*$/))
      return ""
    
    return <div>
      <div>License:</div>
      <div>{this.props.license}</div>
    </div>
  }

  render() {
    const error = this.state.error ? <div className="error">{this.state.error}</div> : ""

    return <div className="modal" ref="modal" id="board-minutes-viewing-agreement" tabIndex="-1" role="dialog" data-show="true" data-backdrop="static">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-body">
            <h3>Viewing Agreement</h3>
            <div>By typing your name below, you agree to the <a href="/about/board_minutes_legal" target="_blank">Terms &amp; Conditions</a>:</div>
            <div>Please note that there is to be no duplication, sharing or other distribution of the document. All information herewith must be kept private &amp; confidential.</div>
          
            <div className="details">
              <div>
                <div>Name:</div>
                <div>{this.props.name}</div>
              </div>
              {this.renderFirm()}
              {this.renderLicense()}
            </div>

            <div className="signing">
              <div className="text-input">
                <span>*</span>
                <input type="text" placeholder="Enter name" onChange={e => this.setState({name: e.target.value})} />
              </div>
              {error}
            </div>
          </div>
          <div className="modal-footer">
            <div className="buttons">
              <button type="button" className="cancel" onClick={() => this.cancel()}>Cancel</button>
              <button type="button" className="accept" onClick={() => this.accept()}>Accept</button>
            </div>
            <div className="terms">
              <a href="/about/board_minutes_legal" target="_blank">Terms &amp; Conditions</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}

export default ViewingAgreement