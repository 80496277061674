import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateDocumentApi } from "./apis";

const initialState = {
  isDocumentUpdated: false,
  editMinuteModalVisible: false,
  editMinuteSuccessModalVisible: false,
  currentDocument: {},
};

export const updateDocument = createAsyncThunk(
  "boardMinutesList/updateDocument",
  async ({ data, id }) => {
    const response = await updateDocumentApi(data, id);
    const { document } = response.data;
    return { document };
  }
);

const editMinuteSlice = createSlice({
  name: "editMinute",
  initialState,
  reducers: {
    openEditBoardMinutesModal: (state, action) => {
      state.editMinuteModalVisible = true;
      state.currentDocument = action.payload;
    },
    closeEditBoardMinutesModal: (state, _action) => {
      state.editMinuteModalVisible = false;
    },
    openEditBoardMinutesSuccessModal: (state, action) => {
      state.editMinuteSuccessModalVisible = true;
    },
    closeEditBoardMinutesSuccessModal: (state, _action) => {
      state.editMinuteSuccessModalVisible = false;
      state.isDocumentUpdated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateDocument.fulfilled, (state, _action) => {
      state.isDocumentUpdated = true;
    });
  },
});

const { reducer, actions } = editMinuteSlice;

export const selectEditMinuteState = (state) => state.editMinute;

export const {
  openEditBoardMinutesModal,
  closeEditBoardMinutesModal,
  openEditBoardMinutesSuccessModal,
  closeEditBoardMinutesSuccessModal,
} = actions;

export default reducer;
