import React, { useContext, useRef, useEffect, useState } from 'react'
import { ModalContext } from '../../../../../ModalContext'
import { FeeContext } from '../../../../contexts/FeeContext'
import { StripeAccContext } from '../../../../contexts/StripeAccContext'
import { currencyMask } from '../../../../currency'
import PaymentProcessModals from '../PaymentProcessModals'
import { getPaymentMethodByID } from '../../../../helpers'
import { LeaseContext } from '../../../../contexts/LeaseContext'

const StatusSuccess = ({cleanup, remainingBalance}) => {
  const { amountWithCommission } = useContext(StripeAccContext)
  const { buildingShortAddress } = useContext(LeaseContext)
  return (
    <>
      <div className='auth-status-icon success'>
        <i className='fa-light fa-check'></i>
      </div>
      <h3>
        {currencyMask(amountWithCommission)} <br />
        <span>Authorized</span>
      </h3>
      <div className='body'>
        <p>
          This payment will show on your account statement as:
          <br />
          <span className='regular'>{buildingShortAddress} FEES</span>
        </p>
        <p className='remaining-balance'>
          {remainingBalance === 0
            ? "All fees are authorized."
            : <>Fees remaining: <span>{currencyMask(remainingBalance)}</span></>
          }
        </p>
      </div>
      <div className='button-container center'>
        <button onClick={cleanup} className='cancel'>
          OK
        </button>
      </div>
      <div className='footer-container'>
        <p className='header'>Deposits</p>
        <p>
          Contact{" "}
          <a href='mailto:refunds@domecile.com'>
            refunds@domecile.com
          </a>{" "}
          or Management to initiate deposit return.
        </p>
        <p className='header'>Refunds</p>
        <p>
          Contact{" "}
          <a href='mailto:refunds@domecile.com'>
            refunds@domecile.com
          </a>{" "}
          before filing a dispute with your bank.
        </p>
      </div>
    </>
  )
}

const StatusFail = ({cleanup, response, setResponse, setCurrentModal, retryPayment, submittedPaymentInfo}) => {
  const { setPaymentSuccess, type, bankAccounts, cards } = useContext(StripeAccContext)
  const _type = type === 'ach' ? 'Acct': 'Card'
  const last_4 = (getPaymentMethodByID(submittedPaymentInfo.payment_method_id, [...bankAccounts, ...cards]))?.last_4
  const amount = submittedPaymentInfo.amount + submittedPaymentInfo.commission

  // const editHandler = () => {
  //   setCurrentModal(<PaymentProcessModals />)
  //   cleanup()
  // }

  const retryHandler = async () => {
    const res = await retryPayment()
    if (res.success) setPaymentSuccess(true)
    setResponse(res)
  }

  return (
    <>
      <div className='auth-status-icon failed'>
        <i className='fa-light fa-exclamation'></i>
      </div>
      <h3>Authorization Failed</h3>
      <div className="body">
        <div className='payment-decline-details'>
          <div className='d-row row1'>
            <p className='d-column1'>Payment Method</p>
            <p className='d-column2'>{_type} Ending in {last_4}</p>
          </div>
          <div className='d-row row2'>
            <p className='d-column1'>Amount</p>
            <p className='d-column2'>{currencyMask(amount)}</p>
          </div>
          <div className='d-row row3'>
            <p className='d-column1'>Reason</p>
            <p className='d-column2'>{response?.error || response?.declineCode}</p>
          </div>
        </div>
        <p className='regular'>
          <span className='orange'>Need Help?</span> Please contact your bank
          for more info on why your payment may have been declined.
        </p>
        <p className='light'>
          If (a) there are sufficient funds available and (b) your bank is not
          declining the authorization, you may attempt to re-authorize. If you
          continue to experience issues, contact{" "}
          <a href='mailto:info@domecile.com'>info@domecile.com</a>.
        </p>
      </div>
      <div className="button-container center">
        <button onClick={cleanup} className='cancel'>Close</button>
        {/* <button onClick={editHandler} className='bp-no-fill'>Edit</button> */}
        {/* Per prod might re-add if edit comes back <button onClick={retryHandler} className='dark'>Try Again</button> */}
      </div>
    </>
  )
}

const AuthStatus = ({res, retryPayment, submittedPaymentInfo}) => {
  const { authorizedTotal } = useContext(StripeAccContext)
  const { subTotal } = useContext(FeeContext)
  const { setCurrentModal } = useContext(ModalContext)
  const [response, setResponse] = useState(res)
  const remainingBalance = subTotal - authorizedTotal

  const ref = useRef()

  const cleanup = () => $(ref.current).modal("hide")

  useEffect(() => {
    if (!ref.current) return

    const close = () => setCurrentModal(null)
    const modal = $(ref.current)
    modal.on("hidden.bs.modal", close)
    modal.modal({ backdrop: "static", keyboard: false })

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  return (
    <>
      <div className='lease-fees-modal'>
        <div ref={ref} className='modal fade'>
          <div className='modal-box auth-status'>
            {response.success ? (
              <StatusSuccess
                cleanup={cleanup}
                remainingBalance={remainingBalance}
              />
            ) : (
              <StatusFail
                cleanup={cleanup}
                response={response}
                setResponse={setResponse}
                setCurrentModal={setCurrentModal}
                retryPayment={retryPayment}
                submittedPaymentInfo={submittedPaymentInfo}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AuthStatus
