import React from 'react'
import {connect} from 'react-redux'

import InputGroup from '../utility/input_group'
import Store from './store'

  class Generate extends React.Component {

    constructor(props) {
      super(props)

      if (this.props.id)
        this.props.generateId(this.props.id)

      this.state = {}
      this.state.errors = {}

      this.submit = this.submit.bind(this)
      this.success = this.success.bind(this)
      this.errored = this.errored.bind(this)

      this.handleInput = this.handleInput.bind(this)
    }

    componentDidMount() {
      Generate.Modal = this

      this.modal = $(this.refs.modal)
      this.modal.on("show.bs.modal", () => {
        this.setState({section_title: ""})
      })
    }

    componentWillUnmount() {
      if (Generate.Modal == this)
        delete Generate.Modal
    }

    handleInput(evt, field) {
      const val = evt.target.value
      let state = {}

      state[field] = val
      this.setState(state)
    }

    open() {
      this.modal.modal("show")
    }

    close() {
      this.modal.modal("hide")
    }

    id() {
      return this.props.id || this.props.default_id
    }

    submit() {
      const data = {}
      data.section_title = this.state.section_title

      $.ajax({
        url: BoardShare.Constants.section_path.replace(":id", this.props.share.id),
        type: "post",
        data: hashToPostQueryString({ share: data }),
        success: this.success,
        error: this.errored
      })
    }

    success(section) {
      BoardShare.Show.resetView()
      this.props.addSectionToState(section)
      this.close()
    }

    errored(xhr) {
      BoardShare.Globals.processErrors(xhr, this)
    }

    renderHeader() {
      return <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal">&times;</button>
        <h4 className="modal-title">Generate a Section</h4>
      </div>
    }

    renderBody() {
      return <div className="modal-body">
        <InputGroup label="Section Title" errors={this.state.errors} error_keys="section_title">
          <input type="text" value={this.state.section_title || ''} onChange={evt => this.handleInput(evt, "section_title")} />
        </InputGroup>
      </div>
    }

    renderFooter() {
      return<div className="modal-footer">
        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary" onClick={this.submit}>Generate</button>
      </div>
    }

    render() {
      return <div className="modal fade" id={this.id()} role="dialog" ref="modal">
        <div className="modal-dialog">
          <div className="modal-content">
            {this.renderHeader()}
            {this.renderBody()}
            {this.renderFooter()}
          </div>
        </div>
      </div>
    }
  }


  const mapStateToProps = state => {
    return {
      default_id: state.presentation.generate_section_id,
      share: state.share.share
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      generateId: id => dispatch(Store.generateSectionModalId(id)),
      addSectionToState: section => dispatch(Store.addSection(section))
    }
  }

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Generate)