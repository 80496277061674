import React, { useContext } from 'react'
import { ModalContext } from '../../../ModalContext'
import FeesCollectPrompt from '../modals/manage_fees/FeesCollectPrompt'
import { StripeAccContext } from '../../contexts/StripeAccContext'
import { FeeContext } from '../../contexts/FeeContext'
import { LeaseContext } from '../../contexts/LeaseContext'

const ConditionCheckList = ({collectFees}) => {
  const { setCurrentModal } = useContext(ModalContext)
  const { feesSubmitted, authorizedTotal, allPayments } = useContext(StripeAccContext)
  const { subTotal, readyForCollection } = useContext(FeeContext)
  const { allRequiredSignaturesSigned } = useContext(LeaseContext)
  const paymentsExist = allPayments.length > 0
  const authPaymentsComplete = subTotal - authorizedTotal === 0 && paymentsExist

  const clickHandler = () => {
    if (readyForCollection) {
      setCurrentModal(<FeesCollectPrompt onCollectFees={collectFees}/>)
    }
  }

  return (
    <div className='checklist'>
      <h5>To Collect Fees:</h5>
      <div className='checklist-items'>
        <p>
          All Residents Signed
          {allRequiredSignaturesSigned && <i className='checklist-icon fa-solid fa-check'></i>}
        </p>
        <p>
          Fees Fully Authorized
          {authPaymentsComplete && <i className='checklist-icon fa-solid fa-check'></i>}
        </p>
        <p>
          Lease Submitted
         {feesSubmitted && <i className='checklist-icon fa-solid fa-check'></i>}
        </p>
      </div>
      <button
        className={`button collect-fees-button ${!readyForCollection ? 'disabled' : ''}`}
        disabled={!readyForCollection}
        onClick={clickHandler}
      >
        Collect Fees
      </button>
    </div>
  )
}

export default ConditionCheckList
