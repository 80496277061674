import { createSlice, createAsyncThunk, nanoid, createSelector, createAction } from "@reduxjs/toolkit";
import { startAppListening } from "../../../listener";
import { fileValidateRules, validateItem, validateAllItems } from "./validates";
import { uploadDocumentApi } from "./apis";

const initialState = {
  uploadMinutesModalVisible: false,
  building: null,
  files: [],
  form: {},
  uploadedIds: [],
  uploading: false,
  uploaded: false,
  documentsUploadedModalVisible: false,
  errorMessages: {}
};

const uploadDocument = createAsyncThunk("uploadMinutes/uploadDocument", async (payload, { rejectWithValue }) => {
  try {
    const response = await uploadDocumentApi(payload);
    return response;
  } catch (error) {
    if (error?.response?.data) {
      return rejectWithValue(error.response.data);
    } else {
      throw error;
    }
  }
});

export const submit = createAction("uploadMinutes/submit");

const uploadMinutesSlice = createSlice({
  name: "uploadMinutes",
  initialState,
  reducers: {
    openBoardMinutesModal: (state, action) => {
      return { ...initialState, uploadMinutesModalVisible: true, building: action.payload };
    },
    closeBoardMinutesModal: (state, _action) => {
      state.uploadMinutesModalVisible = false;
      state.errorMessages = {};
    },
    addFiles: (state, action) => {
      state.files.push(
        ...action.payload.map((file) => {
          delete file.needs_uploading;
          file.id = nanoid();
          file.meetingDate = "";
          return file;
        }),
      );
    },
    removeFile: (state, action) => {
      const fileIndex = state.files.findIndex((file) => file.id === action.payload);
      if (fileIndex !== -1) {
        delete state.form[`file-${state.files[fileIndex].id}`];
        state.files.splice(fileIndex, 1);
      }
    },
    handleDateChange: (state, action) => {
      const { fileId, newMeetingDate } = action.payload;
      const file = state.files.find((file) => file.id === fileId);
      if (file) {
        file.meetingDate = newMeetingDate;

        validateItem(state.form, fileValidateRules, {
          id: file.id,
          type: "file",
          key: "meetingDate",
          value: newMeetingDate,
        });
      }
    },
    validateForm: (state, _action) => {
      validateAllItems(state);
    },
    openDocumentsUploadedModal: (state, action) => {
      state.documentsUploadedModalVisible = true;
    },
    closeDocumentsUploadedModal: (state, action) => {
      return initialState;
    },
    setErrorMessages: (state, action) => {
      state.errorMessages = action.payload;
    },
    setUploading: (state, action) => {
      state.uploading = action.payload;
    },
    setUploaded: (state, action) => {
      state.uploaded = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(uploadDocument.pending, (state, action) => {
      state.uploading ||= true;
    });
    builder.addCase(uploadDocument.fulfilled, (state, action) => {
      state.uploadedIds.push(action.meta.arg.fileId);

      if (state.files.filter((file) => state.uploadedIds.includes(file.id)).length === state.files.length) {
        state.uploaded = true;
      }
    })
  },
});

export const selectUploadMinutesState = (state) => state.uploadMinutes;

const selectForm = createSelector(selectUploadMinutesState, (state) => state.form);
export const selectIsFormValid = createSelector(selectForm, (form) => {
  const formItems = Object.values(form);
  let valid = true;

  for (const formItem of formItems) {
    for (const attributeErrors of Object.values(formItem?.errors || {})) {
      if (attributeErrors.length > 0) {
        valid = false;
        break;
      }
    }
  }

  return valid;
});

export const {
  openBoardMinutesModal,
  closeBoardMinutesModal,
  addFiles,
  removeFile,
  handleDateChange,
  validateForm,
  openDocumentsUploadedModal,
  closeDocumentsUploadedModal,
  setErrorMessages,
  setUploading,
  setUploaded
} = uploadMinutesSlice.actions;

startAppListening({
  actionCreator: submit,
  effect: async (_action, listenerApi) => {
    listenerApi.dispatch(setErrorMessages({}));
    listenerApi.dispatch(validateForm());

    const state = listenerApi.getState();

    if (!selectIsFormValid(state)) return;

    const { files, building, uploadedIds } = state.uploadMinutes;

    if (files.length === 0 || !building) return;

    const results = await Promise.allSettled(
      files
        .filter((file) => !uploadedIds.includes(file.id))
        .map((file) => {
          return listenerApi.dispatch(
            uploadDocument({
              buildingId: building.id,
              fileId: file.id,
              documentData: {
                file: file.file,
                meeting_date: file.meetingDate,
              },
            })
          );
        })
    );

    if (results.length === 0) return listenerApi.dispatch(setUploaded(true));

    let newErrorMessages = {};
    const fulfilled = results.filter(result => result.status === 'fulfilled').map(result => result.value);
    const rejected = results.filter(result => result.status === 'rejected').map(result => result.reason);
    if (rejected.length > 0) {
      newErrorMessages = { system: "Some errors happened, please try again!" };
    } else {
      fulfilled.forEach(action => {
        if (action.payload?.error) {
          const { error } = action.payload;
          const fileId = action.meta.arg.fileId;
          newErrorMessages = { ...newErrorMessages, [fileId]: error };
        } else if (!action.payload && action.error) {
          newErrorMessages = { system: "Some errors happened, please try again!" };
        }
      })
    }
    listenerApi.dispatch(setErrorMessages(newErrorMessages));
    listenerApi.dispatch(setUploading(false));
  },
});

export default uploadMinutesSlice.reducer;
