import React, { useEffect, useState } from "react"
import { Provider, useDispatch } from "react-redux"
import { configureStore } from "@reduxjs/toolkit"

import ShareArea from "./components/ShareArea"
import Header from "./components/Header"
import Layout from "../Layout"
import StripeAccContextProvider from "../fees/contexts/StripeAccContext"
import LeaseContextProvider from "./context/LeaseContext"
import FeeContextProvider from "../fees/contexts/FeeContext"
import { ModalContextProvider } from "../ModalContext"
import { StatusContextProvider } from "../StatusContext"
import ComposedContextProviders from "../../../../javascript/plugins/plugins/leases_plugin/javascripts/documents/ComposedContextProviders"

import { reducer, load } from './state/state'

const store = configureStore({ reducer })

function Loader({ package_id, participants, groups, sharing_users, user, children }) {
  const [ loaded, setLoaded] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(load({ package_id, participants, sharing_users, groups, user }))

    setLoaded(true)
  }, [participants, groups, sharing_users])

  return loaded ? <>{children}</> : <></>
}

const Share = props => {
  const providers = props => {
    const { cards, bank_accounts, all_payments, user_id, ACH_FEE, CARD_RATE, fees_submitted, capture_initiated_at, ...rest } = props

    return [
      <LeaseContextProvider
        electronicSigningConsentedAt={props.electronic_signing_consented_at}
        remainingRequiredSignatureIdsByRole={props.remaining_required_signature_ids_by_role}
        permissions={props.permissions}
        userRoles={props.roles}
        groups={props.groups}
        icons={props.icons}
        documentsFinished={props.documents_marked_as_reviewed}
      />,
      <FeeContextProvider {...rest} />,
      <StripeAccContextProvider
        cards={cards}
        bank_accounts={bank_accounts}
        all_payments={all_payments}
        user_id={user_id}
        ACH_FEE={ACH_FEE}
        CARD_RATE={CARD_RATE}
        fees_submitted={fees_submitted}
        capture_initiated_at={capture_initiated_at} />,
      <ModalContextProvider />,
      <StatusContextProvider sidebarStatuses={props.sidebar_statuses} userRoles={props.roles} permissions={props.permissions} />,
    ]
  }

  return (
    <Provider store={store}>
      <ComposedContextProviders providers={providers(props)}>
        <Loader {...props}>
          <Layout
            className='share'
            top={<Header package_id={props.package_id} />}
            left={<ShareArea />}
          />
        </Loader>
      </ComposedContextProviders>
    </Provider>
  )
}

export default Share
