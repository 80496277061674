import React, { useCallback } from "react";

export default function MinutesMeetingDates({ files, removeFile, onDateChange, form, uploading, errorMessages }) {
  const dateInputClass = useCallback(
    (fileId) => {
      const classes = [];
      const formId = `file-${fileId}`

      if (form[formId]) {
        if (form[formId]?.errors?.meetingDate?.length > 0) {
          classes.push("error");
        }
      }

      return classes.length === 0 ? undefined : classes.join(" ");
    },
    [form],
  );

  const renderFiles = () => {
    if (files.length === 0) {
      return (
        <tr className="empty-row">
          <td colSpan={3}>Upload minutes to set a date.</td>
        </tr>
      );
    }

    return files.map((file) => (
      <tr key={file.id}>
        <td className={(errorMessages[file.id]?.file_type || errorMessages[file.id]?.file) ? 'text-red' : null}>{file.name}</td>
        <td>
          <input
            type="date"
            value={file.meetingDate}
            onChange={(e) => onDateChange(file.id, e.currentTarget.value)}
            className={dateInputClass(file.id)}
            disabled={uploading}
          />
        </td>
        <td>
          <div>
            <button className="remove-file-btn" onClick={() => removeFile(file.id)} disabled={uploading}>
              <i className="fa-solid fa-xmark"></i>
            </button>
          </div>
        </td>
      </tr>
    ));
  };

  return (
    <table className="table table-striped minutes-meeting-dates-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Meeting Date</th>
          <th></th>
        </tr>
      </thead>
      <tbody>{renderFiles()}</tbody>
    </table>
  );
}

MinutesMeetingDates.defaultProps = {
  files: [],
};
