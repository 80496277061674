import React, { useContext, useEffect, useRef } from "react"
import { ModalContext } from "../../../../ModalContext"
import { StripeAccContext } from "../../../contexts/StripeAccContext"
const PaymentConnectModal = () => {
  const {setOpenInstantVerification} = useContext(StripeAccContext)
  const { setCurrentModal } = useContext(ModalContext)
  const ref = useRef()

  const cleanup = () => $(ref.current).modal("hide")

  const clickhandler = () => {
    // flag to call setupBankAccount after modal closes
    setOpenInstantVerification(true)
    cleanup()
  }

  useEffect(() => {
    if (!ref.current) return

    const close = () => setCurrentModal(null)
    const modal = $(ref.current)
    modal.on("hidden.bs.modal", close)
    modal.modal("show")

    return () => modal.off("hidden.bs.modal", close)
  }, [])

  return (
    <div className='lease-fees-modal'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box stripe-ach'>
          <div className='circle'>
            <i className='fa-light fa-building-columns'></i>
          </div>
          <h3>ACH Transfers</h3>
          <i className="pwrd-by-stripe"></i>
          <p className='subtitle'>
            In the next step, you will be directed to <br />authorize an ACH payment.
          </p>
          <div className="button-container">
            <button className="cancel" onClick={cleanup}>Back</button>
            <button className="stripe-continue" onClick={clickhandler}>
              Continue <i className="fa-regular fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentConnectModal
