import React, { useRef, useEffect, useContext } from "react"
import { LeaseContext } from "../../../contexts/LeaseContext"
import { ModalContext } from "../../../../ModalContext"

const AllRequiredSignaturesSigned = () => {
  const { userRole, userRoles, documentsFinished } = useContext(LeaseContext)
  const { setCurrentModal } = useContext(ModalContext)
  const ref = useRef()

  const cleanup = () => {
    $(ref.current).modal("hide")
    setCurrentModal(null)
  }

  useEffect(() => {
    const modal = $(ref.current)
    $(modal).on("hidden.bs.modal", cleanup)
    modal.modal("show")

    return () => modal.off("hidden.bs.modal", cleanup)
  }, [])

  return (
    <div className='lease-fees-modal'>
      <div ref={ref} className='modal fade'>
        <div className='modal-box set-fees'>
          <div className='modal-icon'>
            <i
              className={
                userRoles.includes('processor') && documentsFinished
                  ? "memo-icon"
                  : "fa-thin fa-circle-check"
              }
            ></i>
          </div>
          <div className='content'>
            {userRoles.includes('processor') && <ProcessorView cleanup={cleanup} />}
            {userRole == "broker_specialist" && (
              <BrokerView cleanup={cleanup} />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeesSetModal
