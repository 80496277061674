import React from 'react'
import { format } from "date-fns"
import { currencyMask } from '../../fees/currency'

const Tab = ({setCurrentTab, currentTab, leaseData}) => {
  let startDate = format(new Date(leaseData.lease_duration.start_date), 'MM/dd/yyyy')
  let endDate = format(new Date(leaseData.lease_duration.end_date), 'MM/dd/yyyy')

  const hasDates = (duration) => Object.values(duration).every(value => value !== null)

  return (
    <div className='tab-container'>
      <div className='lease-info'>
        <div className="info">
          <p>Lease Duration:</p>
          <p>{hasDates(leaseData.lease_duration) ? `${startDate} - ${endDate}` : 'N/A'}</p>
        </div>
        <div className="info">
          <p>Monthly Rent:</p>
          <p>{currencyMask(leaseData.rent_amount) || 'N/A'}</p>
        </div>
      </div>
      <div className='tabs'>
        <button id='summary' className={currentTab == 'summary' ? 'active' : null} onClick={(e) => setCurrentTab(e.target.id)}>
          Summary
        </button>
        <button id='activity' className={currentTab == 'activity' ? 'active' : null} onClick={(e) => setCurrentTab(e.target.id)}>
          Activity Feed
        </button>
      </div>
    </div>
  )
}

export default Tab
