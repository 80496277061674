import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

const Warning = ({ person, onConfirm, onClose }) => {
  const { name, email } = person
  const [show, setShow] = useState(true)
  const close = () => setShow(false)

  return (
    <Modal show={show} centered={true} onExited={onClose} className="leases-revoke-modal">
      <div className='modal-icon'>
        <i className='exclamation-circle-red'></i>
      </div>
      <div className='content'>
        <h3>Are you sure?</h3>
        <p>By revoking {name}'s access you understand:</p>
        <ul className='bullet-point'>
          <li>All of their signatures will be removed.</li>
          <li>Any authorizations made by them will be reset.</li>
        </ul>
        <div className='button-container'>
          <button className='cancel' onClick={close}>Cancel</button>
          <button className='danger' onClick={onConfirm}>Revoke Access</button>
        </div>
      </div>
    </Modal>
  )
}

export default Warning
