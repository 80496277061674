import React from 'react'

const Container = (props) => {
  return (
    <div className={`section-container ${props.nameOfClass}`}>
      {props.title && <div className="section-title">
        <h3>{props.title}</h3>
      </div>}
      <div className="section-content">
        {props.children}
      </div>
    </div>
  )
}

export default Container
